import React from "react";
import routes from "../../routes";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAuthAuthenticated,
  getAuthLoggedOut
} from "../../../redux/state/auth/selectors";

/**
 * This component is a representation of a private route.
 * It checks for the authenticated state and in response to that
 * renders a component or it redirects to a login component if trying to access a private route.
 * ALSO* If you logged out, it redirects back to landing_showcase instead of login
 *
 * - IT IS TIED TO APPLICATION STATE -
 *   Uses 2 states that should be tracked from application state
 *      1. authenticated:   If the user is loged in
 *      2. loggedOut:       If the last action was the logged out
 *
 * @param location       object passed through <Router />
 * @param children       React component passed to be render
 * @param redirectTo     uri where it should redirect in case of unauthenticated (optional)
 * @param rest           of props passed to the component
 */
const PrivateRoute = ({ location, children, redirectTo, ...rest }) => {
  const loggedOut = useSelector(state => getAuthLoggedOut(state));
  const authenticated = useSelector(state => getAuthAuthenticated(state));
  return (
    <Route {...rest}>
      {authenticated ? (
        children
      ) : (
        <Redirect to={{ pathname: loggedOut ? routes.index.route : redirectTo, state: { from: location } }} />
      )}
    </Route>
  );
};

PrivateRoute.propTypes = {
  redirectTo: PropTypes.string
};

PrivateRoute.defaultProps = {
  redirectTo: routes.login.route
};

export default PrivateRoute;
