import { createSelector } from "reselect";

const eProfileResultsUI = state => state.ui.pages.home.eProfileResults;
const homeComponents = state => state.ui.pages.home.homeComponents;

export const getUIeProfileResultsComponents = state => eProfileResultsUI(state).components;
export const getUIeProfileResultsStats = state => eProfileResultsUI(state).stats;
export const getUIeProfileResultsWorstStat = state => getUIeProfileResultsComponents(state).worstStat;
export const getUIeProfileResultsBestStat = state => getUIeProfileResultsComponents(state).bestStat;
export const getUIeProfileResultsRanking = state => getUIeProfileResultsComponents(state).ranking;

export const getUiHomeComponentsSelected = state => homeComponents(state).selected;
export const getUiHomeComponents = state => homeComponents(state).components;

export const getUIHomeComponentsLoaded = state => state.ui.pages.home.componentsLoaded;
export const getUIHomeComponentsLoadedSpider = state => getUIHomeComponentsLoaded(state).spider;
export const getUIHomeComponentsLoadedeProfileResults = state => getUIHomeComponentsLoaded(state).eProfileResults;
export const getUIHomeAreComponentsLoaded = createSelector(
  [getUIHomeComponentsLoadedeProfileResults, getUIHomeComponentsLoadedSpider],
  (eProfileResultsLoaded, spiderLoaded) => eProfileResultsLoaded && spiderLoaded
);

export const getUIHomeIsMyProfileSelected = createSelector([getUiHomeComponentsSelected], selected => selected === 1);
