// USER
export const USER_COMPARISON_FETCH_START = "USER_COMPARISON_FETCH_START";
export const USER_COMPARISON_FETCH_SUCCESS = "USER_COMPARISON_FETCH_SUCCESS";
export const USER_COMPARISON_FETCH_FAILURE = "USER_COMPARISON_FETCH_FAILURE";
export const USER_COMPARISON_SELECT = "USER_COMPARISON_SELECT";

export const COMPARISON_UPDATE_USER_GAME = "COMPARISON_UPDATE_USER_GAME";
export const COMPARISON_UPDATE_USER_REGION = "COMPARISON_UPDATE_USER_REGION";
export const COMPARISON_UPDATE_USER_NICKNAME = "COMPARISON_UPDATE_USER_NICKNAME";

export const COMPARISON_RESET_USER_GAME = "COMPARISON_RESET_USER_GAME";
export const COMPARISON_RESET_USER_REGION = "COMPARISON_RESET_USER_REGION";
export const COMPARISON_RESET_USER_NICKNAME = "COMPARISON_RESET_USER_NICKNAME";


export const COMPARISON_UPDATE_COMPARED_GAME = "COMPARISON_UPDATE_COMPARED_GAME";
export const COMPARISON_UPDATE_COMPARED_REGION = "COMPARISON_UPDATE_COMPARED_REGION";
export const COMPARISON_UPDATE_COMPARED_NICKNAME = "COMPARISON_UPDATE_COMPARED_NICKNAME";

export const COMPARISON_RESET_COMPARED_GAME = "COMPARISON_RESET_COMPARED_GAME";
export const COMPARISON_RESET_COMPARED_REGION = "COMPARISON_RESET_COMPARED_REGION";
export const COMPARISON_RESET_COMPARED_NICKNAME = "COMPARISON_RESET_COMPARED_NICKNAME";

