import * as actions from "./actions";
import api from "api";
import { gameSelectors } from "redux/state/games";
import { registerFormSelectors } from "redux/state/forms/register";
import { setRegisterFragmentNext } from "redux/state/ui/pages/register/actions";

/**
 * This function fetches summoner info for League of Legends.
 * This action creator dispatches 3 aTypes: FETCH_SUMMONER_INFO_START,
 *                                           FETCH_SUMMONER_INFO_SUCCESS
 *                                           FETCH_SUMMONER_INFO_FAILURE
 * @returns {Function}
 */
export const fetchSummonerInfo = callback => async (dispatch, getState) => {
  const state = getState();
  const { getRegisterSummonerName, getRegisterRegion } = registerFormSelectors;

  // Gets summonerName and region from the state
  const summonerName = getRegisterSummonerName(state);
  const region = getRegisterRegion(state);
  const game = gameSelectors.getSelectedGameId(state);

  if (region === "") {
    dispatch(actions.fetchSummonerInfoContinue());
  } else {
    dispatch(actions.fetchSummonerInfo(api.games.lol.summoners.fetchSummonerByName(region, summonerName), game));
  }
};
