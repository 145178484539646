import { combineEpics } from "redux-observable";

import reducer from "./reducer";

import * as profileOPS from "./operations";
import * as profileSelectors from "./selectors";

import {
  fetchProfileEpic,
  startUpdateProfileEpic,
  unlockProfileComparisonEpic,
  updateProfileEpic,
  validateProfileEpic,
  profileValidateImageEpic,
  profileRequestPutUpdateImageEpic,
  profileFetchUserSocialNetworksEpic,
  profileUpdateUserSocialNetworkNameEpic
} from "./operations";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile/types";

export { profileSelectors, profileOPS, PROFILE_CHECK_SUCCESS };

export const profileEpic = combineEpics(
  fetchProfileEpic,
  startUpdateProfileEpic,
  unlockProfileComparisonEpic,
  updateProfileEpic,
  validateProfileEpic,
  profileValidateImageEpic,
  profileRequestPutUpdateImageEpic,
  profileFetchUserSocialNetworksEpic,
  profileUpdateUserSocialNetworkNameEpic
);

export default reducer;
