import { createSelector } from "reselect";

const userComparison = state => state.forms.userComparison;

export const getUserComparisonFormUserGame = state => userComparison(state).userGame;
export const getUserComparisonFormUserRegion = state => userComparison(state).userRegion;
export const getUserComparisonFormUserNickname = state => userComparison(state).userNickname;

export const getUserComparisonFormComparedGame = state => userComparison(state).comparedGame;
export const getUserComparisonFormComparedRegion = state => userComparison(state).comparedRegion;
export const getUserComparisonFormComparedNickname = state => userComparison(state).comparedNickname;
