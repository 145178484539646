export const setNext = state => {
  const list = state.list;
  const selected = state.selected;

  if (selected === list.length) {
    return state;
  } else {
    return { ...state, selected: selected + 1 };
  }
};

export const setBack = state => {
  const selected = state.selected;

  if (selected === 0) {
    return state;
  } else {
    return { ...state, selected: selected - 1 };
  }
};