import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as userStudyOPS from "./operations";
import * as userStudySelectors from "./selectors";
import {} from "./operations";

export { userStudyOPS, userStudySelectors };

export const userStudyEpic = combineEpics();

export default reducer;
