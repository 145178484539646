// LOL
import * as aTypes from "./types";

export const fetchSummonerInfo = (promise, game) => ({
  type: aTypes.FETCH_SUMMONER_INFO,
  payload: promise,
  meta: { game }
});
export const fetchSummonerInfoStart = () => ({ type: aTypes.FETCH_SUMMONER_INFO_START });
export const fetchSummonerInfoSuccess = lolResponse => ({
  type: aTypes.FETCH_SUMMONER_INFO_SUCCESS,
  payload: lolResponse
});
export const fetchSummonerInfoFailure = error => ({
  type: aTypes.FETCH_SUMMONER_INFO_FAILURE,
  payload: error,
  error: true
});

export const fetchSummonerInfoContinue = () => ({type: aTypes.FETCH_SUMMONER_INFO_CONTINUE});
