import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { MEDIA_URL } from "config/settings";

import profile from "app/assets/img/profile.png";
import lockImg from "app/assets/img/lock.png";
import border1 from "app/assets/svg/border1.svg";
import border2 from "app/assets/svg/border2.svg";

const useStyles = makeStyles((theme) => ({
  center: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  input: {
    display: "none",
  },
  button: {
    height: "100%",
    borderRadius: "1000px",
    "&>span": {
      height: "100%",
      borderRadius: "1000px",
    },
  },
  avatar: {
    width: "70%",
    height: "70%",
    padding: "4%",
    background: theme.palette.home.blueGradient,
  },
  userGradient: {
    background: theme.palette.home.pinkGradient,
  },
  disabled: {
    background: lighten(theme.palette.home.lightBlue, 0.25),
  },
  border: {
    border: "10px solid pink",
    borderRadius: "50%",
  },
  img: {
    background: "#7b73c7",
    borderRadius: "50%",
    borderStyle: "solid",
    borderWeight: theme.spacing(1),
    borderColor: lighten(theme.palette.home.lightBlue, 0.25),
  },
  label: {
    borderRadius: "1000px",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    fontWeight: "bold",
    fontFamily: [
      "Exo",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "70%",
    opacity: 0,
    transition: ".3s ease",
    borderRadius: "1000px",
    margin: "0 15%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",

    "&:hover": {
      opacity: 1,
    },
  },
  overlayBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "70%",
    margin: "0 15%",
  },
}));

const BigAvatar = ({ isUser, disabled, handleChangeImage, imgUrl, isPublic }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.center}>
      <Avatar
        alt={"Profile image"}
        src={disabled ? lockImg : imgUrl ? MEDIA_URL + imgUrl : profile}
        classes={{ img: disabled ? classes.img : null }}
        className={clsx(classes.avatar, { [classes.userGradient]: isUser, [classes.disabled]: disabled })}
      />
      <img alt={"Oops"} src={isPublic || isUser ? border2 : border1} className={classes.overlayBorder} />
      {!isPublic && isUser && (
        <Fragment>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-avatar-file"
            type="file"
            name="avatar-file"
            onChange={(e) => handleChangeImage(e.target.files[0])}
          />
          <div className={classes.overlay}>
            <IconButton className={classes.button}>
              <label htmlFor="contained-avatar-file" className={classes.label}>
                cambiar avatar
              </label>
            </IconButton>
          </div>
        </Fragment>
      )}
    </Grid>
  );
};

BigAvatar.propTypes = {
  isUser: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChangeImage: PropTypes.func,
  imgUrl: PropTypes.string,
  isPublic: PropTypes.bool,
};

BigAvatar.defaultProps = {
  isUser: false,
  disabled: false,
  isPublic: false,
};

export default BigAvatar;
