import * as validation from "redux/state/forms/validation";
import {
  updateProfileFormLastname,
  updateProfileFormName,
  updateProfileFormEmail,
  updateProfileEprofileUsername,
  validateEmailFailure,
  validateEmailSuccess,
  validateEmailStart,
  validateUsernameFailure,
  validateUsernameSuccess
} from "./actions";
import { PROFILE_UPDATE_EMAIL, PROFILE_UPDATE_USERNAME } from "redux/state/profile/types";
import { getProfileFormEmail, getProfileFormUsername } from "./selectors";

const validateEmail = () => (dispatch, getState) => {
  dispatch(validateEmailStart());
  const email = getProfileFormEmail(getState());
  const error = validation.validateRegisterEmail(email);
  return error ? dispatch(validateEmailFailure(error)) : dispatch(validateEmailSuccess(email));
};

const validateUsername = () => (dispatch, getState) => {
  dispatch(validateEmailStart());
  const username = getProfileFormUsername(getState());
  const error = validation.validateRegisterMindsetUsername(username);
  return error
    ? dispatch(validateUsernameFailure(error))
    : dispatch(validateUsernameSuccess(username));
};

export const validateProfileForm = type => {
  switch (type) {
    case PROFILE_UPDATE_EMAIL:
      return validateEmail();
    case PROFILE_UPDATE_USERNAME:
      return validateUsername();
  }
};

export {
  updateProfileFormLastname,
  updateProfileFormName,
  updateProfileFormEmail,
  updateProfileEprofileUsername
};
