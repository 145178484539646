export const mapTransformToNotificationState = ({ data }) => {
  const notifications = data.notifications;
  return notifications
    .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
    .reduce((obj, notification) => {
      obj[notification.notification_id] = {
        id: notification.notification_id,
        isRead: notification.is_read,
        message: notification.message,
        created: notification.created_on
      };
      return obj;
    }, {});
};

export const mapUnreadNotifications = payload => Object.keys(payload).filter(id => !payload[id].isRead);
