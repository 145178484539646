import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import PropTypes from "prop-types";
import BootstrapPurpleInput from "app/components/bootstrap-input/BootstrapPurpleInput";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  label: {
    top: "25%",
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  select: {
    "&:focus": {
      // Show that it's not an text input
      borderRadius: 0, // Reset Chrome style,
      backgroundColor: theme.palette.primary.main_dark5
    }
  }
}));

const CustomSelect = ({ disabled, label, data, change, value }) => {
  const classes = useStyles();
  const handleChange = event => {
    change(event.target.value);
  };
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel className={classes.label} variant={"outlined"} htmlFor="demo-customized-select-native">
        {label}
      </InputLabel>
      <NativeSelect
        value={value}
        classes={{ icon: classes.icon, select: classes.select }}
        id="demo-customized-select-native"
        input={<BootstrapPurpleInput />}
        onChange={handleChange}
      >
        <option value="" />
        {data.map(value => (
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.string })).isRequired,
  change: PropTypes.func.isRequired
};

CustomSelect.defaultProps = {
  label: "Custom Select",
  disabled: false
};

export default CustomSelect;
