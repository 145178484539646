const authMiddleware = store => next => action => {
  if (action === undefined) {
    console.error("authMiddleware () => Action can't be undefined");
    throw "Action can't be undefined";
  }

  if ("error" in action && action.error) {
    if ("payload" in action && typeof action.payload === "object" && "status" in action.payload) {
      if (action.payload.status === 401) next({ type: "LOGOUT" });
    } else {
      const payloadError =
        "If an error is set, it should contain an object in the payload with the error representation {payload: {error: bool, status: integer, statusText: string, message: string}}";
      console.error(`authMiddleware () => ${payloadError}`);
      throw payloadError;
    }
  }
  return next(action);
};

export default authMiddleware;
