import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import routes from "app/routes";
import { logoExceptions } from "app/components/navbar/utils";

const withStyles = makeStyles(theme => ({
  root: {
    marginLeft: logoExceptions.includes(window.location.pathname) ? "auto" : "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto"
    }
  }
}));

const NavbarButtonGroup = props => {
  const { children, ...rest } = props;
  const classes = withStyles();
  return (
    <div {...rest} className={classes.root}>
      {children}
    </div>
  );
};

NavbarButtonGroup.propTypes = {};

export default NavbarButtonGroup;
