const getMonth = date => (date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1);
const getDay = date => (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate());
const getYear = date => date.getFullYear();
const getHours = date => date.getHours();
const getMinutes = date => date.getMinutes();

const readTime = num => (num < 9 ? `0${num}` : num);

const setTime = (hours, minutes) => `${readTime(hours)}:${readTime(minutes)}`;

/**
 * This function formats a date object into a more readable one.
 *
 * Note:
 *  - Date.getMonth():     returns a number between 0 and 11 so to get the actual
 *                         month we need to add +1
 *  - Date.getFullYear():  returns the actual year and not .getYear()
 *  - Date.getDate():      returns the day and not .getDay()
 * @param date    Javascript Date object
 * @returns {string} formated like: 'year/month/day
 */
export const formatDate = date => {
  const month = getMonth(date);
  const day = getDay(date);
  return `${date.getFullYear()}/${month}/${day}`;
};

export const formatSmallDate = date => {
  const now = new Date();
  const year = getYear(date);
  const month = getMonth(date);
  const day = getDay(date);
  const hours = getHours(date);
  const minutes = getMinutes(date);

  const currentYear = getYear(now);
  const currentMonth = getMonth(now);
  const currentDay = getDay(now);
  const currentHour = getHours(now);
  const currentMinutes = getMinutes(now);

  // From top to bottom hierarchy
  // 1. Today
  if (year === currentYear && month === currentMonth && day === currentDay) {
    return setTime(hours, minutes);
  }

  // 2. Yesterday
  if (year === currentYear && month === currentMonth && day === currentDay - 1) {
    return "Ayer";
  }

  // 3. Any other date
  return formatDate(date);
};
