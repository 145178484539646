import React from "react";
import PropTypes from "prop-types";
import PublicProfileView from "app/pages/public-profile/PublicProfileView";
import { useDispatch, useSelector } from "react-redux";
import { publicProfilePageLoaded } from "redux/state/ui/pages/public-profile/operations";
import { getPublicProfileErrorIsError } from "redux/state/public-profile/selectors";
import NotFound from "app/pages/not-found/NotFound";

const PublicProfile = props => {
  const dispatch = useDispatch();
  const isError = useSelector(state => getPublicProfileErrorIsError(state));

  React.useEffect(() => {
    dispatch(publicProfilePageLoaded());
  }, []);

  return isError ? <NotFound /> : <PublicProfileView />;
};

PublicProfile.propTypes = {};

export default PublicProfile;
