import { combineReducers } from "redux";
import * as aTypes from "./types";
import { STUDY_FETCH_BY_ID_SUCCESS } from "redux/state/studies/types";
import ERROR_SHAPE from "api/error-model";
import { FETCH_PROFILE_SUCCESS } from "redux/state/profile/types";
import { REGISTER_PAGE_LOADED } from "redux/state/ui/pages/register/types";
import {
  LOGOUT,
  PASSWORD_RESET_SEND_EMAIL_FAILURE,
  PASSWORD_RESET_SEND_EMAIL_START,
  PASSWORD_RESET_SEND_EMAIL_SUCCESS, PASSWORD_RESET_UPDATE_PASSWORD_FAILURE,
  PASSWORD_RESET_UPDATE_PASSWORD_START,
  PASSWORD_RESET_UPDATE_PASSWORD_SUCCESS,
  PASSWORD_RESET_VALIDATE_TOKEN_FAILURE,
  PASSWORD_RESET_VALIDATE_TOKEN_START,
  PASSWORD_RESET_VALIDATE_TOKEN_SUCCESS
} from "./types";
import {
  PASSWORD_RESET_EMAIL_FORM_VALIDATION_START,
  PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START
} from "redux/state/forms/password-reset/types";
import { PASSWORD_RESET_UPDATE_PASSWORD_RESET } from "redux/state/auth/types";

/**
 * INIT STATE
 */
const errorInit = ERROR_SHAPE;
const credentialsInit = { token: "" };
const passwordResetInit = { loading: false, status: 0, error: false };
const passwordResetUpdatePasswordInit = { loading: false, status: 0, error: false };
const passwordResetTokenValidationInit = { loading: false, status: 0, error: false, token: "" };

const credentials = (state = credentialsInit, action) => {
  switch (action.type) {
    case aTypes.AUTH_LOGIN_FAILURE:
    case aTypes.REGISTER_FAILURE:
    case aTypes.LOGOUT:
      return { ...state, ...credentialsInit };

    case aTypes.AUTH_LOGIN_SUCCESS:
    // case aTypes.REGISTER_SUCCESS:
    case aTypes.VALIDATE_TOKEN_SUCCESS:
      return { ...state, token: action.payload.token };

    default:
      return state;
  }
};

const authenticated = (state = false, action) => {
  switch (action.type) {
    case aTypes.AUTH_LOGIN_START:
    case aTypes.AUTH_LOGIN_FAILURE:
    case aTypes.VALIDATE_TOKEN_FAILURE:
    case aTypes.VALIDATE_TOKEN_START:
    case aTypes.LOGOUT:
      return false;

    // case aTypes.REGISTER_SUCCESS:
    case aTypes.AUTH_LOGIN_SUCCESS:
    case aTypes.VALIDATE_TOKEN_SUCCESS:
      return true;

    default:
      return state;
  }
};

const loggedOut = (state = false, action) => {
  switch (action.type) {
    case aTypes.AUTH_LOGIN_START:
    case aTypes.AUTH_LOGIN_FAILURE:
    case aTypes.AUTH_LOGIN_SUCCESS:
    // case aTypes.REGISTER_SUCCESS:
    case aTypes.VALIDATE_TOKEN_FAILURE:
    case aTypes.VALIDATE_TOKEN_START:
      return false;

    case aTypes.LOGOUT:
      return true;

    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case aTypes.AUTH_LOGIN_START:
    case aTypes.REGISTER_START:
    case aTypes.VALIDATE_TOKEN_START:
      return true;

    case aTypes.AUTH_LOGIN_SUCCESS:
    case aTypes.REGISTER_SUCCESS:
    case aTypes.AUTH_LOGIN_FAILURE:
    case aTypes.REGISTER_FAILURE:
    case aTypes.VALIDATE_TOKEN_FAILURE:
      return false;

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.AUTH_LOGIN_FAILURE:
    case aTypes.REGISTER_FAILURE:
    case aTypes.VALIDATE_TOKEN_FAILURE:
      return { ...state, ...action.payload, error: true };

    case aTypes.AUTH_LOGIN_SUCCESS:
    case aTypes.REGISTER_SUCCESS:
    case aTypes.AUTH_LOGIN_START:
    case aTypes.REGISTER_START:
    case aTypes.VALIDATE_TOKEN_START:
      return { ...errorInit };

    default:
      return state;
  }
};

const register = (state = true, action) => {
  switch (action.type) {
    case aTypes.REGISTER_START:
      return false;

    case aTypes.REGISTER_SUCCESS:
      return true;

    case aTypes.REGISTER_FAILURE:
    case REGISTER_PAGE_LOADED:
      return false;

    default:
      return state;
  }
};

const passwordResetSendMail = (state = passwordResetInit, action) => {
  switch (action.type) {
    case PASSWORD_RESET_SEND_EMAIL_START:
    case LOGOUT:
      return { ...state, loading: true, status: 0, error: false };

    case PASSWORD_RESET_SEND_EMAIL_SUCCESS:
      return { ...state, loading: false, status: action.payload.status, error: false };

    case PASSWORD_RESET_SEND_EMAIL_FAILURE:
      return { ...state, loading: false, status: 500, error: true };

    default:
      return state;
  }
};

const passwordResetTokenValidation = (state = passwordResetTokenValidationInit, action) => {
  switch (action.type) {
    case PASSWORD_RESET_VALIDATE_TOKEN_START:
      return { ...state, loading: true, status: 0, error: false, token: "" };
    case PASSWORD_RESET_EMAIL_FORM_VALIDATION_START:
    case LOGOUT:
      return { ...state, loading: false, status: 0, error: false, token: "" };

    case PASSWORD_RESET_VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: false,
        token: action.payload.data.token
      };

    case PASSWORD_RESET_VALIDATE_TOKEN_FAILURE:
      return { ...state, loading: false, status: action.payload.status, error: true, token: "" };

    default:
      return state;
  }
};

const passwordResetUpdatePassword = (state = passwordResetUpdatePasswordInit, action) => {
  switch (action.type) {
    case PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START:
    case PASSWORD_RESET_UPDATE_PASSWORD_RESET:
    case LOGOUT:
      return { ...state, loading: false, status: 0, error: false };

    case PASSWORD_RESET_UPDATE_PASSWORD_START:
      return { ...state, loading: true, status: 0, error: false };

    case PASSWORD_RESET_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: false
      };

    case PASSWORD_RESET_UPDATE_PASSWORD_FAILURE:
      return { ...state, loading: false, status: action.payload.status, error: true };

    default:
      return state;
  }
};

export default combineReducers({
  credentials,
  authenticated,
  loading,
  loggedOut,
  error,
  register,
  passwordResetSendMail,
  passwordResetTokenValidation,
  passwordResetUpdatePassword
});
