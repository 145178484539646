import { LOGOUT } from "redux/state/auth/types";
import {
  FETCH_SUMMONER_INFO_FAILURE,
  FETCH_SUMMONER_INFO_START,
  FETCH_SUMMONER_INFO_SUCCESS
} from "redux/state/game-stats/types";
import { RESULTS_TOGGLE_PUBLIC_PROFILE } from "redux/state/ui/pages/results/types";
import { combineReducers } from "redux";
import {
  NAVBAR_MENU_CLOSE,
  NAVBAR_MENU_OPEN,
  NAVBAR_MENU_PERMANENT_FALSE,
  NAVBAR_MENU_PERMANENT_TRUE,
  NAVBAR_MENU_TOGGLE
} from "redux/state/ui/components/types";

/**
 * REDUCERS
 */

const lolHandlerInit = "search";
const publicProfileInit = false;
const navarMenuInit = {
  open: false,
  permanent: false,
  displayNotificationMenu: false
};

const lolHandlerReducer = (state = lolHandlerInit, action) => {
  switch (action.type) {
    case FETCH_SUMMONER_INFO_SUCCESS:
      return "display";

    case FETCH_SUMMONER_INFO_FAILURE:
      return "search";

    case FETCH_SUMMONER_INFO_START:
      return "search";

    case LOGOUT:
      return lolHandlerInit;

    default:
      return state;
  }
};

const resultsPublicProfile = (state = publicProfileInit, action) => {
  switch (action.type) {
    case RESULTS_TOGGLE_PUBLIC_PROFILE:
      return !state;

    case LOGOUT:
      return publicProfileInit;

    default:
      return state;
  }
};

const navbarMenu = (state = navarMenuInit, action) => {
  switch (action.type) {
    case NAVBAR_MENU_OPEN:
      return state.open === true ? state : { ...state, open: true };

    case NAVBAR_MENU_CLOSE:
      return state.open === false ? state : { ...state, open: false };

    case NAVBAR_MENU_TOGGLE:
      return { ...state, open: !state.open };

    case NAVBAR_MENU_PERMANENT_TRUE:
      return state.permanent === true ? state : { ...state, permanent: true };

    case NAVBAR_MENU_PERMANENT_FALSE:
      return state.permanent === false ? state : { ...state, permanent: false };

    default:
      return state;
  }
};

export default combineReducers({
  lolhandler: lolHandlerReducer,
  publicProfile: resultsPublicProfile,
  navbarMenu
});
