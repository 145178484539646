import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import HomePageTemplate from "app/components/profile-page-template/HomePageTemplate";
import Grid from "@material-ui/core/Grid";
import LightRedButton from "app/components/custom-button/LighRedButton";

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    marginTop: theme.spacing(10),
    margin: "auto"
  }
}));

const Payment = props => {
  const classes = useStyles();
  return (
    <HomePageTemplate>
      <Grid container>
        <LightRedButton
          className={classes.button}
          href={"https://b-dev.pgf.cl/solunova_pagofa/13984/b0010961-71f0-4c8e-8601-833010122ddf"}
          component={"a"}
        >
          El terrible de pago
        </LightRedButton>
      </Grid>
    </HomePageTemplate>
  );
};

Payment.propTypes = {};

export default Payment;
