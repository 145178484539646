import * as aTypes from "./types";

export const sessionSaveUserInHomeResults = userId => ({
  type: aTypes.SESSION_SAVE_USER_IN_HOME_RESULTS,
  payload: userId
});

export const sessionSaveUser = userId => ({
  type: aTypes.SESSION_SAVE_USER,
  payload: userId
});

export const sessionFinishHomeResultsTour = () => ({
  type: aTypes.SESSION_FINISH_HOME_RESULTS_TOUR
});

export const sessionSaveHomeResultsTourFinished = userId => ({
  type: aTypes.SESSION_SAVE_HOME_RESULTS_TOUR_FINISHED,
  payload: userId
});
