import React from "react";
import PropTypes from "prop-types";
import AuthPageTemplate from "app/components/auth-page-template/AuthPageTemplate";
import AuthPageContainer from "app/components/auth-page-container/AuthPageContainer";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";

const PasswordUpdateView = props => {
  return (
    <AuthPageTemplate>
      <AuthPageContainer>{props.tokenHasError && <AuthFormContainer title={"Recurso inválido"} />}</AuthPageContainer>
    </AuthPageTemplate>
  );
};

PasswordUpdateView.propTypes = {
  tokenHasError: PropTypes.bool.isRequired
};

export default PasswordUpdateView;
