import api from "api";
import { ofType } from "redux-observable";
import { GAMES_FETCH_ALL_SUCCESS } from "redux/state/games/types";
import { map } from "rxjs/operators";
import { fetchRegions } from "./actions";
import { mapRegionsToState } from "./utils";
import { PREFETCH_REGIONS } from "redux/state/prefetch/types";

/**
 * This action creator dispatch 3 aTypes: REGIONS_FETCH_ALL_START,
 *                                         REGIONS_FETCH_ALL_SUCCESS,
 *                                         REGIONS_FETCH_ALL_FAILURE
 * It fetches region information for League of Legends
 * And it keeps track of the state of the LOADING using it.
 * @returns {Function}
 */
const _fetchRegions = () => {
  return fetchRegions(api.games.lol.regions.fetchAll(), mapRegionsToState);
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const fetchRegionsEpic = action$ =>
  action$.pipe(
    ofType(PREFETCH_REGIONS),
    map(action => _fetchRegions())
  );
