import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: theme.spacing(1, 2, 1, 4),
    borderRadius: "5px"
  },
  primary: {
    backgroundColor: theme.palette.primary.main
  },
  textWrapper: {
    padding: theme.spacing(0, 2, 0, 0),
    flex: "auto",
    display: "flex",
    alignItems: "center"
  },
  iconWrapper: {
    textAlign: "center",
    cursor: "pointer"
  }
}));

const Alert = ({ msg, color, onClose }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classes[color])}>
      <div className={classes.textWrapper}>
        <Typography variant={"body1"}>{msg}</Typography>
      </div>
      <div className={classes.iconWrapper}>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize={"small"} />
        </IconButton>
      </div>
    </div>
  );
};

Alert.propTypes = {
  color: PropTypes.oneOf(["primary", "error", "success"])
};

export default Alert;
