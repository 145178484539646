import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const SurveyError = props => {
  return (
    <div>
      <center>
        <br />
        <br />
        <br />
        <Typography variant={"h4"}>{props.helperText}</Typography>
      </center>
    </div>
  );
};

SurveyError.propTypes = {
  helperText: PropTypes.string.isRequired
};

export default SurveyError;
