import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as prefetchOPS from "./operations";
import * as prefetchSelectors from "./selectors";
import {
  checkPrefetchEpic,
  prefetchAbilitiesEpic,
  prefetchGamesEpic,
  prefetchRegionsEpic,
  prefetchSuccessEpic,
  prefetchSocialsEpic
} from "./operations";

export { prefetchOPS, prefetchSelectors };

export const prefetchEpic = combineEpics(
  checkPrefetchEpic,
  prefetchRegionsEpic,
  prefetchSuccessEpic,
  prefetchAbilitiesEpic,
  prefetchGamesEpic,
  prefetchSocialsEpic
);

export default reducer;
