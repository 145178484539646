import { combineReducers } from "redux";

import { formatDate } from "redux/utils";
import { LOGOUT, REGISTER_FAILURE, REGISTER_START, REGISTER_SUCCESS } from "redux/state/auth";
import ERROR_SHAPE from "api/error-model";
import * as aTypes from "./types";
import { FETCH_SUMMONER_INFO_FAILURE } from "redux/state/game-stats/types";

/**
 * REDUCERS
 */
const nameInit = "";
const mainGameInit = "";
const lastnameInit = "";
const genderInit = "";
const passwordInit = "";
const repeatPasswordInit = "";
const emailInit = "";
const mindsetUsernameInit = "";
const birthdayInit = "";
const summonerNameInit = "";
const regionInit = "";
const referredIdInit = "";
const termsInit = false;
const imageInit = { filename: "", file: null, type: "" };
const errorInit = ERROR_SHAPE;

const mainGame = (state = mainGameInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_MAIN_GAME:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return nameInit;

    default:
      return state;
  }
};

const name = (state = nameInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_NAME:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return nameInit;

    default:
      return state;
  }
};

const lastname = (state = lastnameInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_LASTNAME:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return lastnameInit;

    default:
      return state;
  }
};

const gender = (state = genderInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_GENDER:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return genderInit;

    default:
      return state;
  }
};

const password = (state = passwordInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_PASSWORD:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return passwordInit;

    default:
      return state;
  }
};

const repeatPassword = (state = repeatPasswordInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_REPEAT_PASSWORD:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return repeatPasswordInit;

    default:
      return state;
  }
};

const email = (state = emailInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_EMAIL:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return emailInit;

    default:
      return state;
  }
};

const eProfileTag = (state = mindsetUsernameInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_MINDSET_USERNAME:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return mindsetUsernameInit;

    default:
      return state;
  }
};

const birthday = (state = formatDate(new Date()), action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_BIRTHDAY:
      return action.payload;
    case aTypes.REGISTER_PLAYER_RESET:
      return "";
    default:
      return state;
  }
};

const summonerName = (state = summonerNameInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_SUMMONER_NAME:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return summonerNameInit;

    default:
      return state;
  }
};

const region = (state = regionInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_REGION:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return regionInit;

    default:
      return state;
  }
};

const profilePicture = (state = imageInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_PICTURE:
      return action.payload && action.payload.name
        ? {
            ...state,
            filename: action.payload.name,
            file: action.payload,
            type: action.payload.type
          }
        : state;

    case aTypes.REGISTER_RESET_PICTURE:
      return { ...imageInit };

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return { ...imageInit };

    default:
      return state;
  }
};

const terms = (state = termsInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_TERMS:
      return action.payload;
    case aTypes.REGISTER_PLAYER_RESET:
      return termsInit;
    default:
      return state;
  }
};

const referredId = (state = regionInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_UPDATE_REFERRED_ID:
      return action.payload;

    case aTypes.REGISTER_PLAYER_RESET:
    case LOGOUT:
      return regionInit;

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case REGISTER_START:
    case REGISTER_SUCCESS:
    case aTypes.REGISTER_UPDATE_EMAIL:
    case aTypes.REGISTER_UPDATE_MINDSET_USERNAME:
    case aTypes.REGISTER_UPDATE_LASTNAME:
    case aTypes.REGISTER_UPDATE_NAME:
    case LOGOUT:
      return { ...errorInit };

    case REGISTER_FAILURE:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  name,
  lastname,
  gender,
  password,
  repeatPassword,
  email,
  eProfileTag,
  birthday,
  mainGame,
  summonerName,
  region,
  profilePicture,
  terms,
  referredId,
  error
});
