const setObjKey = msg => ({ error: true, errorMsg: msg });

export const mapEmailValidationToState = errors => {
  const obj = {
    hasErrors: false,
    email: undefined
  };

  if (errors.email) {
    obj.email = setObjKey(errors.email.email[0]);
    obj.hasErrors = true;
  }

  return obj;
};

export const mapUpdatePasswordValidationToState = errors => {
  const obj = {
    errors: false,
    password: undefined,
    repeatPassword: undefined
  };

  if (errors.password) obj.password = setObjKey(errors.password.password[0]);
  if (errors.repeatPassword) obj.repeatPassword = setObjKey(errors.repeatPassword.confirmPassword[0]);

  if (errors.password || errors.repeatPassword) obj.hasErrors = true;
  return obj;
};
