import { ofType } from "redux-observable";
import { mapTo, map, debounceTime } from "rxjs/operators";
import api from "api";

import * as validation from "redux/state/forms/validation";
import { AUTH_LOGIN_SUCCESS } from "redux/state/auth";
import { getAuthToken } from "redux/state/auth/selectors";
import {
  fetchProfile,
  profileCheck,
  profileCheckFailure,
  profileCheckFinishTests,
  profileCheckSuccess,
  profileCheckVerifyUser,
  profileUnlockUser,
  profileUpdateUserSocialNetwork,
  profileValidateImageFailure,
  profileValidateImageStart,
  profileValidateImageSuccess,
  updateProfile
} from "./actions";
import { mapUserProfileToState, mapProfileImageToState } from "./utils";
import {
  getProfileCompletedStudies,
  getProfileImageForm,
  getProfileIsActive,
  getProfileIsVerified,
  getProfileUserId,
  getUserSocialNetworks
} from "redux/state/profile/selectors";
import { PROFILE_FORM_VALIDATE_SUCCESS } from "redux/state/forms/profile";
import {
  FETCH_PROFILE_SUCCESS,
  PROFILE_CHECK_SUCCESS,
  PROFILE_TRIGGER_UNLOCK,
  PROFILE_UNLOCK_USER_SUCCESS,
  PROFILE_UPDATE_EMAIL,
  PROFILE_UPDATE_IMAGE,
  PROFILE_UPDATE_USER_SOCIAL_NETWORK,
  PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME,
  PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY,
  PROFILE_UPDATE_USERNAME,
  PROFILE_VALIDATE_IMAGE_SUCCESS,
  UPDATE_PROFILE_SUCCESS
} from "./types";

import {
  profileRequestPutImage,
  profileUpdateEmail,
  profileUpdateGender,
  profileUpdatePassword,
  profileUpdateRegion,
  profileUpdateSummonerName,
  profileUpdateUsername
} from "./actions";
import { validateProfileForm } from "redux/state/forms/profile/operations";
import {
  PROFILE_FORM_VALIDATE_EMAIL_SUCCESS,
  PROFILE_FORM_VALIDATE_USERNAME_SUCCESS
} from "redux/state/forms/profile/types";
import { HOME_PAGE_LOADED } from "redux/state/ui/pages/home";
import {
  mapProfileSocialNetworksToState,
  mapProfileSocialNetworkToState,
  mapUpdateProfileToState
} from "redux/state/profile/utils";
import { profileFetchSocialNetworks } from "redux/state/profile/actions";
import { ACCOUNT_PAGE_LOADED } from "redux/state/ui/pages/account/types";
import { getSocialNetworksById } from "redux/state/social-networks/selectors";

const _fetchProfile = () => async (dispatch, getState) => {
  dispatch(fetchProfile(api.user.fetchProfile(getAuthToken(getState())), mapUserProfileToState));
};

export const submitUpdateProfile = data => (dispatch, getState) => {
  dispatch(updateProfile(api.user.updateProfile(data, getAuthToken(getState())), mapUpdateProfileToState, data));
};

const _checkProfile = () => (dispatch, getState) => {
  dispatch(profileCheck());
  const state = getState();
  const isVerified = getProfileIsVerified(state);
  const isActive = getProfileIsActive(state);
  const completedStudies = getProfileCompletedStudies(state);

  if (isActive) {
    if (completedStudies) {
      dispatch(profileCheckSuccess());
    } else {
      dispatch(profileCheckFinishTests());
    }
  } else {
    if (isVerified) {
      dispatch(profileCheckFailure());
    } else {
      dispatch(profileCheckVerifyUser());
    }
  }
};

/**
 * This action creator is in charge of updating the user image
 */
const _profileRequestPutUpdateImage = () => async (dispatch, getState) => {
  const state = getState();
  const profileImageForm = getProfileImageForm(state);
  const token = getAuthToken(state);
  const userId = getProfileUserId(state);
  dispatch(
    profileRequestPutImage(api.auth.updateProfileImage(profileImageForm.file, userId, token), mapProfileImageToState)
  );
};

const _unlockUser = () => (dispatch, getState) => {
  dispatch(profileUnlockUser(api.user.unlockUser(getAuthToken(getState()))));
};

const _validateProfileImage = () => (dispatch, getState) => {
  dispatch(profileValidateImageStart());
  const profileImageErrors = validation.validateImage(getProfileImageForm(getState()));
  if (profileImageErrors) {
    dispatch(profileValidateImageFailure());
  } else {
    dispatch(profileValidateImageSuccess());
  }
};

/**
 * Social Networks
 */
const _fetchProfileSocials = () => (dispatch, getState) => {
  const state = getState();
  const user_id = getProfileUserId(state);
  const token = getAuthToken(state);

  dispatch(profileFetchSocialNetworks(api.socials.getByUserId(user_id, token), mapProfileSocialNetworksToState));
};

const _profileUpdateUserSocialNetwork = payload => (dispatch, getState) => {
  const state = getState();
  const userSocialNetworks = getUserSocialNetworks(state);
  const socialNetworksById = getSocialNetworksById(state);
  const token = getAuthToken(state);

  // get keys and state values
  const keys = Object.keys(payload);

  // if userSocialNetwork already exist
  if (Object.keys(userSocialNetworks).includes(payload.socialNetworkName)) {
    // just update
    dispatch(
      profileUpdateUserSocialNetwork(
        api.socials.updateUserSocialNetwork(
          {
            user_social_network_id: userSocialNetworks[payload.socialNetworkName].userSocialNetworkId,
            [keys.includes("name") ? "name" : "is_visible"]: keys.includes("name") ? payload.name : payload.visibility
          },
          token
        ),
        mapProfileSocialNetworkToState
      )
    );
  } else {
    // otherwise create
    dispatch(
      profileUpdateUserSocialNetwork(
        api.socials.updateUserSocialNetwork(
          {
            social_network_id: socialNetworksById[payload.socialNetworkName].id,
            name: payload.name,
            is_visible: true
          },
          token
        ),
        mapProfileSocialNetworkToState
      )
    );
  }
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/

// UPDATE PROFILE FLOW
export const startUpdateProfileEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_UPDATE_EMAIL, PROFILE_UPDATE_USERNAME),
    map(action => validateProfileForm(action.type))
  );

export const updateProfileEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_FORM_VALIDATE_SUCCESS, PROFILE_FORM_VALIDATE_EMAIL_SUCCESS, PROFILE_FORM_VALIDATE_USERNAME_SUCCESS),
    map(action => submitUpdateProfile(action.payload))
  );

// FETCH PROFILE FLOW
export const fetchProfileEpic = action$ =>
  action$.pipe(
    ofType(
      AUTH_LOGIN_SUCCESS,
      HOME_PAGE_LOADED,
      PROFILE_UNLOCK_USER_SUCCESS,
      ACCOUNT_PAGE_LOADED
    ),
    debounceTime(100),
    mapTo(_fetchProfile())
  );

export const validateProfileEpic = action$ => action$.pipe(ofType(FETCH_PROFILE_SUCCESS), mapTo(_checkProfile()));

export const unlockProfileComparisonEpic = action$ =>
  action$.pipe(ofType(PROFILE_TRIGGER_UNLOCK), mapTo(_unlockUser()));

export const profileValidateImageEpic = action$ =>
  action$.pipe(ofType(PROFILE_UPDATE_IMAGE), mapTo(_validateProfileImage()));

export const profileRequestPutUpdateImageEpic = action$ =>
  action$.pipe(ofType(PROFILE_VALIDATE_IMAGE_SUCCESS), mapTo(_profileRequestPutUpdateImage()));

// USER SOCIAL NETWORKS
export const profileFetchUserSocialNetworksEpic = action$ =>
  action$.pipe(ofType(PROFILE_CHECK_SUCCESS), mapTo(_fetchProfileSocials()));

export const profileUpdateUserSocialNetworkNameEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME, PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY),
    map(action => _profileUpdateUserSocialNetwork(action.payload))
  );

// EXPORT ACTIONS
export {
  profileUpdateEmail,
  profileUpdateGender,
  profileUpdatePassword,
  profileUpdateRegion,
  profileUpdateSummonerName,
  profileUpdateUsername
};
