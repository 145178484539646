export const spiderLabels = {
  xl: {
    width: 70,
    distance: 35
  },
  lg: {
    width: 60,
    distance: 30
  },
  md: {
    width: 60,
    distance: 30
  },
  sm: {
    width: 60,
    distance: 30
  },
  xs: {
    width: 45,
    distance: 23
  }
};

const basicProps = {
  gridShape: "circular",
  dotSize: 5,
  fillOpacity: 0.5,
  animate: true
};

const spiderPropsWithKeys = {
  xl: {
    width: 650,
    height: 500,
    margin: { top: -40, right: 140, bottom: 0, left: 140 },
    ...basicProps
  },
  lg: {
    width: 600,
    height: 500,
    margin: { top: -80, right: 140, bottom: 0, left: 140 },
    ...basicProps
  },
  md: {
    width: 510,
    height: 400,
    margin: { top: -10, right: 110, bottom: 0, left: 110 },
    ...basicProps
  },
  sm: {
    width: 450,
    height: 350,
    margin: { top: -30, right: 110, bottom: 0, left: 110 },
    ...basicProps
  },
  xs: {
    width: 290,
    height: 290,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    ...basicProps
  }
};

const spiderPropsWithoutKeys = {
  xl: {
    width: 500,
    height: 500,
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    ...basicProps
  },
  lg: {
    width: 500,
    height: 500,
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    ...basicProps
  },
  md: {
    width: 450,
    height: 400,
    margin: { top: 15, right: 15, bottom: 15, left: 15 },
    ...basicProps
  },
  sm: {
    width: 450,
    height: 400,
    margin: { top: 15, right: 15, bottom: 0, left: 15 },
    ...basicProps
  },
  xs: {
    width: 290,
    height: 290,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    ...basicProps
  }
};

export const getSpiderProps = withKeys => (withKeys ? spiderPropsWithKeys : spiderPropsWithoutKeys);
