export const profileOptions = {
  PROFILE: "profile",
  ACCOUNT: "account"
};

export const PROFILE_CHECKS = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  VERIFY_USER: "VERIFY_USER",
  FINISH_TESTS: "FINISH_TESTS",
  NOT_CHECKED: "NOT_CHECKED"
};

export const PREFETCH = {
  ABILITIES: "ABILITIES",
  GAMES: "GAMES",
  REGIONS: "REGIONS",
  SOCIALS: "SOCIALS"
};

export const LOADING = {
  INITIAL: { initial: true, started: false, success: false, failure: false },
  STARTED: { initial: false, started: true, success: false, failure: false },
  SUCCESS: { initial: false, started: false, success: true, failure: false },
  FAILURE: { initial: false, started: false, success: false, failure: true }
};


export const DRAWER_WIDTH = 220;
