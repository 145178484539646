import { bad_format, bad_length, not_equal, not_present, must_accept } from "redux/errors";
import validate from "validate.js";
import { formatDate } from "redux/utils";

validate.extend(validate.validators.datetime, {
  parse: (value, options) => new Date(value),
  format: (value, options) => formatDate(value)
});

// CONSTRAINTS
const nameConstraints = {
  name: {
    presence: { message: `^${not_present}` },
    format: { pattern: /^[a-zA-Z]+$/, message: `^${bad_format}` },
    length: { minimum: 3, message: `^${bad_length}` }
  }
};

const lastnameContraints = {
  lastname: {
    presence: { message: `^${not_present}` },
    format: { pattern: /^[a-zA-Z]+$/, message: `^${bad_format}` },
    length: { minimum: 3, message: `^${bad_length}` }
  }
};

const mainGameConstraints = {
  mainGame: {
    presence: { message: `^${not_present}`, allowEmpty: false }
  }
};

const emailConstraints = {
  email: {
    email: { message: `^${bad_format}` },
    presence: { message: `^${not_present}` }
  }
};

const passwordConstraints = {
  password: {
    presence: { message: `^${not_present}` },
    length: { minimum: 8, message: `^${bad_length}` },
    equality: { attribute: "confirmPassword", message: `^${not_equal}` }
  }
};

const confirmPasswordConstraints = {
  confirmPassword: {
    presence: { message: `^${not_present}` },
    length: { minimum: 8, message: `^${bad_length}` },
    equality: { attribute: "password", message: `^${not_equal}` }
  }
};

const mindsetConstraints = {
  mindsetUsername: {
    presence: { message: `^${not_present}` },
    length: { minimum: 3, message: `^${bad_length}` }
  }
};

const birthdayConstraints = {
  birthday: {
    presence: { message: `^${not_present}` },
    datetime: {
      latest: new Date().setFullYear(new Date().getFullYear() - 18),
      message: `^${bad_format}`
    }
  }
};

const genderConstraint = {
  gender: {
    presence: { message: `^${not_present}`, allowEmpty: false }
  }
};

const regionConstraints = {
  region: {
    presence: { message: `^${not_present}`, allowEmpty: false }
  }
};

const summonerNameConnstraints = {
  summonerName: {
    presence: { message: `^${not_present}` },
    length: { minimum: 3, message: `^${bad_length}` }
  }
};

// VALIDATION
export const validateRegisterName = name => validate({ name }, nameConstraints);
export const validateRegisterLastname = lastname => validate({ lastname }, lastnameContraints);
export const validateRegisterEmail = email => validate({ email }, emailConstraints);
export const validateRegisterPassword = (password, confirmPassword) =>
  validate({ password, confirmPassword }, passwordConstraints);
export const validateRegisterConfirmPassword = (password, confirmPassword) =>
  validate({ password, confirmPassword }, confirmPasswordConstraints);
export const validateRegisterMindsetUsername = mindsetUsername => validate({ mindsetUsername }, mindsetConstraints);
export const validateRegisterBirthDay = birthday => validate({ birthday }, birthdayConstraints);
// export const validateRegisterBirthDay = birthday => null;
export const validateRegisterGender = gender => validate({ gender }, genderConstraint);
export const validateRegisterSummonerName = summonerName => validate({ summonerName }, summonerNameConnstraints);
export const validateRegisterRegion = region => validate({ region }, regionConstraints);
export const validateMainGame = mainGame => validate({ mainGame }, mainGameConstraints);
export const validateRegisterTerms = terms => {
  if (terms !== true) {
    return {terms: [must_accept]};
  }
  else {
    return undefined
  }
};
export const validateImage = imageState => {
  const errors = { profileImage: [] };
  if (imageState) {
    if (imageState.file) {
      if (imageState.file.size > 5 * 1024 * 1024)
        errors.profileImage.push("La imagen no puede exceder los 5 Megabytes");
      if (!["image/png", "image/jpg", "image/jpeg"].includes(imageState.file.type))
        errors.profileImage.push("Imagen debe ser formato png, jpg o jpeg");
      if (!imageState.file.name || imageState.file.name === "")
        errors.profileImage.push("La imagen debe tener un nombre");
      return errors.profileImage.length === 0 ? undefined : errors;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
