import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FormHelperText, useTheme } from "@material-ui/core";
import GreyBootstrapInput from "app/components/bootstrap-input/GrayBootstrapInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  font: {
    fontSize: 14
  }
}));

const GrayInput = ({
  fullWidth,
  error,
  disabled,
  label,
  value,
  onChange,
  className,
  type,
  helperText,
  onFocus,
  onBlur,
  ...other
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <FormControl fullWidth={fullWidth} disabled={disabled} className={className} error={error} {...other}>
      <InputLabel
        className={classes.font}
        style={{ color: theme.palette.grey["300"] }}
        variant={"outlined"}
        htmlFor="demo-customized-select-native"
      >
        {label}
      </InputLabel>
      <GreyBootstrapInput
        {...other}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        error={error}
        value={value}
        onChange={onChange}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

GrayInput.propTypes = {
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  type: PropTypes.oneOf(["email", "text", "password"])
};

GrayInput.defaultProps = {
  fullWidth: false,
  disabled: false,
  error: false,
  type: "text"
};

export default GrayInput;
