export const ABILITIES = `abilities`;

export const INDEX_TEXTS = `view.index`;
export const INDEX_ERR = `error.index`;

export const HOME_TEXTS = `view.home`;
export const HOME_ERR = `error.home`;
export const HOME_DOWNLOAD = `${HOME_TEXTS}.download`;
export const HOME_EPROFILE_RESULTS = `${HOME_TEXTS}.eProfileResultsSection`;
export const HOME_SPIDER = `${HOME_TEXTS}.spiderSection`;
export const HOME_SPIDER_AVATAR_INFORMATION = `${HOME_SPIDER}.avatarInformation`;
export const HOME_SPIDER_DISPLAY = `${HOME_SPIDER}.spiderDisplay`;
export const HOME_SPIDER_DISPLAY_ACTION = `${HOME_SPIDER_DISPLAY}.callToAction`;
export const HOME_SPIDER_FORM = `${HOME_SPIDER}.form`;
export const HOME_SPIDER_FORM_VALIDATION = `${HOME_SPIDER_FORM}.validation`;

export const REGISTER_TEXTS = `view.register`;
export const REGISTER_FORM = `${REGISTER_TEXTS}.registerForm`;
export const REGISTER_CONFIRM = `${REGISTER_TEXTS}.confirm`;
export const REGISTER_ERR = "error.register";
export const REGISTER_VALIDATION_ERR = "validation";

export const PASSWORD_RESET = `view.password-reset`;
export const PASSWORD_RESET_ERR = `error.password-reset`

export const LOGIN_TEXTS = `view.login`;
export const LOGIN_ERR = "error.login";
export const LOGIN_VALIDATION_ERR = "error.login.validation";

export const RESULTS_TEXTS = `view.results`;
export const RESULTS_ERR = `error.results`;

export const PROFILE_TEXTS = `view.profile`;
export const PROFILE_OPTIONS = `${PROFILE_TEXTS}.optionsList`;
export const PROFILE_UPDATE_FORM = `${PROFILE_TEXTS}.profileUpdate`;

export const FORMS = `view.forms`;
export const FORMS_EMAIL = `${FORMS}.email`;
export const FORMS_USERNAME = `${FORMS}.username`;
export const FORMS_PASSWORD = `${FORMS}.password`;
