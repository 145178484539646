import reducer from "./reducer";

import { fetchRegionsEpic } from "./operations";

import * as gameOperations from "./operations";
import * as gameSelectors from "./selectors";
import { combineEpics } from "redux-observable";

export { gameOperations, gameSelectors };

export const regionsEpic = combineEpics(fetchRegionsEpic);

export default reducer;