const components = state => state.ui.components;

/**
 * SELECTORS
 */
export const getLolHandlerPageState = state => components(state).lolhandler;
export const getResultsPublicProfile = state => components(state).publicProfile;


export const getNavbarMenuIsOpen = state => components(state).navbarMenu.open;
export const getNavbarMenuIsPermanent = state => components(state).navbarMenu.permanent;
