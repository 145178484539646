import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(4),
    // background: "rgba(255, 255, 255, .09)"
    background: "linear-gradient(to right, rgba(90,121,222, .5) 0%,rgba(106,37,138, .5) 37%, rgba(183,8,141, .5) 100%)"
  },
  container: {
    padding: theme.spacing(6)
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    letterSpacing: ".1em"
  },
  subtitle: {
    textAlign: "center",
    marginBottom: theme.spacing(4)
  }
}));

const AuthFormContainer = ({ className, padding, title, subtitle, children, ...other }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.card, className)} {...other}>
      <Grid component={"section"} container className={clsx(classes.container, padding)}>
        {/* TITLE */}
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {subtitle && subtitle !== "" && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.subtitle}>
              {subtitle}
            </Typography>
          </Grid>
        )}
        {children}
      </Grid>
    </div>
  );
};

AuthFormContainer.propTypes = {};

export default AuthFormContainer;
