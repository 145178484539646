import createAsyncReducer from "redux/lib/async-reducer";
import { FETCH_PUBLIC_PROFILE } from "./types";
import { combineReducers } from "redux";
import {
  STUDY_FETCH_BY_USER_ID_SUCCESS,
  STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS
} from "redux/state/studies/types";
import { LOGOUT } from "redux/state/auth";

const profileInitState = {
  userId: "",
  gender: "",
  age: 0,
  profileImage: "",
  studies: [],
  completedStudies: false,
  eProfileUsername: "",
  isVerified: false,
  isActive: false,
  isPublicProfile: false,
  isComparable: false,
  mainGame: {
    game_id: "",
    region_id: "",
    game_username: "",
    wins: 0
  },
  score: { position: "", total: "", game_id: "" }
};

const eProfileResultsInit = {
  stats: {
    bestStat: { value: "", title: "", name: "", ability_id: "" },
    worstStat: { value: "", title: "", name: "", ability_id: "" },
    ranking: { value: "", title: "" }
  }
};

const profile = createAsyncReducer(FETCH_PUBLIC_PROFILE, { initState: profileInitState, withLogout: false }, [
  {
    type: STUDY_FETCH_BY_USER_ID_SUCCESS,
    handler: (state, action) => {
      const data = action.payload;
      const key = Object.keys(data)[0];
      return state.studies.indexOf(key) === -1
        ? {
            ...state,
            studies: state.studies.concat(Object.keys(data)[0])
          }
        : state;
    }
  }
]);

const eProfileResults = (state = eProfileResultsInit, action) => {
  switch (action.type) {
    case STUDY_FETCH_BY_USER_ID_SUCCESS:
      const data = action.payload;
      const keys = Object.keys(data);
      const results = data[keys].results;
      const maxValue = results.reduce(
        (maxVal, current) => {
          return current.value >= maxVal.value ? current : maxVal;
        },
        { value: 0 }
      );
      const worstValue = results.reduce(
        (maxVal, current) => {
          return current.value <= maxVal.value ? current : maxVal;
        },
        { value: 100 }
      );

      return {
        ...state,
        stats: { ...state.stats, bestStat: { ...maxValue }, worstStat: { ...worstValue } }
      };

    case LOGOUT:
      return eProfileResultsInit;

    default:
      return state;
  }
};

export default combineReducers({ profile, eProfileResults });
