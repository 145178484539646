import React from "react";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";

const PasswordResetFormError = props => {
  return (
    <AuthFormContainer
      title={"El link está vencido"}
      subtitle={"El link para recuperar contraseña dura una hora. Por favor, vuelve a solicitar la contraseña."}
    />
  );
};

export default PasswordResetFormError;
