import * as aTypes from "./types";

export const prefetchCheckStatus = () => ({ type: aTypes.PREFETCH_CHECK_STATUS });
export const prefetchStatusLoaded = () => ({ type: aTypes.PREFETCH_STATUS_LOADED });
export const prefetchStatusNotLoaded = () => ({ type: aTypes.PREFETCH_STATUS_NOT_LOADED });

export const prefetchAbilities = () => ({ type: aTypes.PREFETCH_ABILITIES });
export const prefetchGames = () => ({ type: aTypes.PREFETCH_GAMES });
export const prefetchRegions = () => ({ type: aTypes.PREFETCH_REGIONS });
export const prefetchSocials = () => ({ type: aTypes.PREFETCH_SOCIALS });

// ABILITIES
export const prefetchUpdateNotLoaded = () => ({ type: aTypes.PREFETCH_UPDATE_STATUS_NOT_LOADED });
export const prefetchUpdateLoaded = () => ({ type: aTypes.PREFETCH_UPDATE_STATUS_LOADED });
export const prefetchUpdateLoading = () => ({ type: aTypes.PREFETCH_UPDATE_STATUS_LOADING });
export const prefetchUpdateError = () => ({ type: aTypes.PREFETCH_UPDATE_STATUS_ERROR });
