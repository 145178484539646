import { combineReducers } from "redux";
import {
  SESSION_SAVE_HOME_RESULTS_TOUR_FINISHED,
  SESSION_SAVE_USER,
  SESSION_SAVE_USER_IN_HOME_RESULTS
} from "redux/state/sessions/types";

const pagesShape = { inHomeResults: false };
const milestonesShapes = { homeResultsTourFinished: false };
const sessionShape = { pages: pagesShape, milestones: milestonesShapes };

const setNewState = (state, sessionID, overridenProp) => ({
  ...state,
  [sessionID]: { ...state[sessionID], ...overridenProp }
});

const byId = (state = {}, action) => {
  switch (action.type) {
    case SESSION_SAVE_USER:
      return state[action.payload] ? state : { ...state, [action.payload]: sessionShape };

    case SESSION_SAVE_USER_IN_HOME_RESULTS:
      return state[action.payload] ? setNewState(state, action.payload, { pages: { inHomeResults: true } }) : state;

    case SESSION_SAVE_HOME_RESULTS_TOUR_FINISHED:
      return state[action.payload]
        ? setNewState(state, action.payload, { milestones: { homeResultsTourFinished: true } })
        : state;

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case SESSION_SAVE_USER:
      return state.includes(action.payload) ? state : [...state, action.payload];

    default:
      return state;
  }
};

export default combineReducers({ byId, allIds });
