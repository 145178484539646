import { combineReducers } from "redux";
import { LOGOUT, REGISTER_FAILURE } from "redux/state/auth/types";
import {
  REGISTER_UPDATE_BIRTHDAY,
  REGISTER_UPDATE_EMAIL,
  REGISTER_UPDATE_GENDER,
  REGISTER_UPDATE_LASTNAME,
  REGISTER_UPDATE_MINDSET_USERNAME,
  REGISTER_UPDATE_NAME,
  REGISTER_UPDATE_PASSWORD,
  REGISTER_UPDATE_PICTURE,
  REGISTER_UPDATE_REFERRED_ID,
  REGISTER_UPDATE_REGION,
  REGISTER_UPDATE_REPEAT_PASSWORD,
  REGISTER_UPDATE_SUMMONER_NAME,
  REGISTER_UPDATE_TERMS,
  REGISTER_VALIDATE_FORM_FAILURE,
  REGISTER_VALIDATE_FORM_START,
  REGISTER_VALIDATE_FORM_SUCCESS
} from "redux/state/forms/register/types";
import { mapValidationToStateErrors } from "redux/state/forms/register/utils";
import { FETCH_SUMMONER_INFO_FAILURE } from "redux/state/game-stats/types";
import { not_found, already_exists } from "redux/errors";

/**
 * SELECTORS
 */
export const getLoadingValidation = state => state.forms.registerForm.validate.loadingValidation;

// Errors
export const getPlayerErrors = state => state.forms.registerForm.validate.errors;

/**
 * REDUCERS
 */
const baseErrorState = { error: false, errorMsg: "" };
const errorInit = {
  name: { ...baseErrorState },
  lastname: { ...baseErrorState },
  gender: { ...baseErrorState },
  password: { ...baseErrorState },
  repeatPassword: { ...baseErrorState },
  email: { ...baseErrorState },
  mindsetUsername: { ...baseErrorState },
  birthday: { ...baseErrorState },
  summonerName: { ...baseErrorState },
  region: { ...baseErrorState },
  profileImage: { ...baseErrorState },
  mainGame: { ...baseErrorState },
  terms: { ...baseErrorState },
  referredId: { ...baseErrorState },
  hasErrors: false
};

const loadingValidation = (state = false, action) => {
  switch (action.type) {
    case REGISTER_VALIDATE_FORM_FAILURE:
    case REGISTER_VALIDATE_FORM_SUCCESS:
      return false;

    case REGISTER_VALIDATE_FORM_START:
      return true;

    default:
      return state;
  }
};

const errors = (state = errorInit, action) => {
  switch (action.type) {
    case REGISTER_VALIDATE_FORM_FAILURE:
      const errors = mapValidationToStateErrors(action.payload);

      return {
        ...state,
        hasErrors: errors.hasErrors ? errors.hasErrors : false,
        name: errors.name ? { ...errors.name } : { ...baseErrorState },
        lastname: errors.lastname ? { ...errors.lastname } : { ...baseErrorState },
        password: errors.password ? { ...errors.password } : { ...baseErrorState },
        repeatPassword: errors.confirmPassword ? { ...errors.confirmPassword } : { ...baseErrorState },
        mindsetUsername: errors.mindsetUsername ? { ...errors.mindsetUsername } : { ...baseErrorState },
        email: errors.email ? { ...errors.email } : { ...baseErrorState },
        birthday: errors.birthday ? { ...errors.birthday } : { ...baseErrorState },
        gender: errors.gender ? { ...errors.gender } : { ...baseErrorState },
        summonerName: errors.summonerName ? { ...errors.summonerName } : { ...baseErrorState },
        region: errors.region ? { ...errors.region } : { ...baseErrorState },
        profileImage: errors.profileImage ? { ...errors.profileImage } : { ...baseErrorState },
        mainGame: errors.mainGame ? { ...errors.mainGame } : { ...baseErrorState },
        terms: errors.terms ? { ...errors.terms } : { ...baseErrorState },
        referredId: errors.referredId ? { ...errors.referredId } : { ...baseErrorState }
      };
    case REGISTER_VALIDATE_FORM_SUCCESS:
      return errorInit;

    case REGISTER_UPDATE_NAME:
      return state.name.error ? { ...state, name: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_LASTNAME:
      return state.lastname.error ? { ...state, lastname: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_PASSWORD:
      return state.password.error ? { ...state, password: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_REPEAT_PASSWORD:
      return state.repeatPassword.error ? { ...state, repeatPassword: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_MINDSET_USERNAME:
      return state.mindsetUsername.error ? { ...state, mindsetUsername: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_EMAIL:
      return state.email.error ? { ...state, email: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_BIRTHDAY:
      return state.birthday.error ? { ...state, birthday: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_GENDER:
      return state.gender.error ? { ...state, gender: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_SUMMONER_NAME:
      return state.summonerName.error ? { ...state, summonerName: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_REGION:
      return state.region.error ? { ...state, region: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_PICTURE:
      return state.profileImage.error ? { ...state, profileImage: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_TERMS:
      return state.terms.error ? { ...state, terms: { ...baseErrorState } } : state;

    case REGISTER_UPDATE_REFERRED_ID:
      return state.referredId.error ? { ...state, referredId: { ...baseErrorState } } : state;

    case FETCH_SUMMONER_INFO_FAILURE:
      return action.payload.error && action.payload.status === 404
        ? { ...state, summonerName: { error: true, errorMsg: not_found } }
        : state;

    case REGISTER_FAILURE:
      return action.payload.data && action.payload.data.errors ? { ...state, ...action.payload.data.errors } : state;

    case LOGOUT:
      return { ...errorInit };

    default:
      return state;
  }
};

export default combineReducers({
  errors,
  loadingValidation
});
