import React, { Fragment } from "react";
import { useScrollTrigger } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../../../redux/state/auth/operations";
import routes from "../../routes";
import { useTranslation } from "react-i18next";
import { getAuthAuthenticated } from "../../../redux/state/auth/selectors";
import { profileSelectors } from "redux/state/profile";
import { navbarMenuPermanentFalse, navbarMenuPermanentTrue, toggleNavMenu } from "redux/state/ui/components/operations";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getNavbarMenuIsPermanent } from "redux/state/ui/components/selectors";
import { getProfileUsername, getProfileImage, getProfileCheck } from "redux/state/profile/selectors";
import { APPLICATION_BASE_URL } from "config/settings";
import { homeMyProfileSelected } from "redux/state/ui/pages/home/actions";

import { PROFILE_CHECKS } from "config/constants";
import NavbarView from "app/components/navbar/NavbarView";
import { getUnreadNotificationsLength } from "redux/state/notifications/selectors";

const Navbar = props => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // elements for avatar and notifications
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationEl, setNotificationEl] = React.useState(null);

  // Open states for avatar and notifications
  const open = Boolean(anchorEl);
  const isNotificationOpen = Boolean(notificationEl);

  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const name = useSelector(state => profileSelectors.getProfileName(state));
  const username = useSelector(state => getProfileUsername(state));
  const authenticated = useSelector(state => getAuthAuthenticated(state));
  const navbarPermanent = useSelector(state => getNavbarMenuIsPermanent(state));
  const profileImage = useSelector(state => getProfileImage(state));
  const profileChecks = useSelector(state => getProfileCheck(state));
  const unreadNotifications = useSelector(state => getUnreadNotificationsLength(state));

  const trigger = useScrollTrigger({ threshold: 120, disableHysteresis: true });

  const isNotRootUrl = () =>
    window.location.pathname !== APPLICATION_BASE_URL && window.location.pathname !== APPLICATION_BASE_URL + "/";

  const isRootUrl = () =>
    window.location.pathname === APPLICATION_BASE_URL || window.location.pathname === APPLICATION_BASE_URL + "/";

  const isRootAndScrolled = () => isRootUrl() && trigger;
  const isRootAndNotScrolled = () => isRootUrl() && !trigger;

  React.useEffect(() => {
    if (isLarge) {
      dispatch(navbarMenuPermanentTrue());
    } else {
      dispatch(navbarMenuPermanentFalse());
    }
  }, [isLarge]);

  const handleLogout = () => dispatch(authLogout());

  const handleProfileMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const toggleMenu = () => {
    dispatch(toggleNavMenu());
  };

  const setHomeResults = () => {
    if (window.location.pathname === routes.home.resource && profileChecks === PROFILE_CHECKS.SUCCESS) {
      dispatch(homeMyProfileSelected());
    }
  };

  const handleNotificationElClick = event => {
    setNotificationEl(event.currentTarget);
  };

  const handleNotificationElClose = () => {
    setNotificationEl(null);
  };

  return (
    <NavbarView
      t={t}
      notificationEl={notificationEl}
      isNotificationOpen={isNotificationOpen}
      unreadNotifications={unreadNotifications}
      handleNotificationElClick={handleNotificationElClick}
      handleNotificationElClose={handleNotificationElClose}
      anchorEl={anchorEl}
      name={name}
      username={username}
      navbarPermanent={navbarPermanent}
      profileImage={profileImage}
      open={open}
      authenticated={authenticated}
      isRootUrl={isRootUrl}
      isNotRootUrl={isNotRootUrl}
      isRootAndNotScrolled={isRootAndNotScrolled}
      isRootAndScrolled={isRootAndScrolled}
      handleLogout={handleLogout}
      handleProfileMenu={handleProfileMenu}
      handleCloseProfileMenu={handleCloseProfileMenu}
      setHomeResults={setHomeResults}
      toggleMenu={toggleMenu}
    />
  );
};

export default Navbar;
