import { combineReducers } from "redux";
import loginReducer from "./login";
import registerReducer from "./register";
import userComparisonReducer from "./user-comparison";
import profileUpdateReducer from "./profile";
import passwordResetReducer from "./password-reset";

import { userComparisonFormEpic } from "./user-comparison";
import { combineEpics } from "redux-observable";

export const formEpics = combineEpics(userComparisonFormEpic);

export default combineReducers({
  loginForm: loginReducer,
  registerForm: registerReducer,
  userComparison: userComparisonReducer,
  profileForm: profileUpdateReducer,
  passwordReset: passwordResetReducer
});
