import React from "react";
import PropTypes from "prop-types";
import routes from "../../routes";
import { Redirect } from "react-router-dom";

/**
 * This component is abstraction of the 'redirect' behaviour of a public component when authenticated.
 * It isolates that functionality and applies the 'single responsibility' principle.
 * It's only job is to redirect to a specific route and all the components who make use of it
 * will do the same.
 * @param location
 * @param redirectTo
 */
const RedirectAuthenticated = ({ location, redirectTo }) => {
  return <Redirect to={{ pathname: redirectTo || routes.home.route, state: { from: location } }} />;
};

RedirectAuthenticated.propTypes = {
  location: PropTypes.object.isRequired,
  redirectTo: PropTypes.string
};

export default RedirectAuthenticated;
