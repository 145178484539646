import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Container, useMediaQuery, useTheme } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";

const withStyles = makeStyles(theme => ({
  appBar: {
    background: theme.palette.gradients.darkBlue,
    zIndex: 1
  },
  tabs: {
    paddingTop: theme.spacing(1),
    color: "white"
  },
  tab: {
    color: "white",
    textTransform: "none",
    fontSize: 16
  },
  tabFontColor: {
    opacity: 1
  },
  indicator: {
    // width: "160px !important",
    background: theme.palette.gradients.darkToLight,
    height: "4px",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      background: theme.palette.gradients.darkToLight
    }
  },
  center: {
    margin: "auto"
  }
}));

const AccountNavigation = props => {
  const classes = withStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    let index = 0;
    switch (history.location.pathname) {
      case `${routes.account.resource}profile`:
        index = 0;
        break;

      case `${routes.account.resource}games`:
        index = 1;
        break;

      case `${routes.account.resource}socials`:
        index = 2;
        break;

      case `${routes.account.resource}subscription`:
        index = 3;
        break;

      case `${routes.account.resource}notifications`:
        index = 4;
        break;

      case `${routes.account.resource}about`:
        index = 5;
        break;
    }
    setValue(index);
    // DO NOT REMOVE: this event is necesary due to a bug with material ui library
    window.dispatchEvent(new CustomEvent("resize"));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function setTabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const tabs = [
    { to: `${routes.account.resource}profile`, disabled: false, label: "Mi Perfil" },
    { to: `${routes.account.resource}games`, disabled: true, label: "Mis Juegos" },
    { to: `${routes.account.resource}socials`, disabled: false, label: "Redes Sociales" },
    { to: `${routes.account.resource}subscription`, disabled: true, label: "Suscripción" },
    { to: `${routes.account.resource}notifications`, disabled: true, label: "Notificaciones" },
    { to: `${routes.account.resource}about`, disabled: true, label: "Acerca de" }
  ];

  return (
    <div>
      <AppBar className={classes.appBar} position={"relative"}>
        <Container maxWidth={"lg"}>
          <Tabs
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
            onChange={handleChange}
            variant={md ? "fullWidth" : "scrollable"}
            value={value}
            aria-label="simple tabs example"
          >
            {tabs.map((tab, index) => (
              <Tab
                component={RouterLink}
                to={tab.to}
                disabled={tab.disabled}
                className={classes.tab}
                key={tab.label}
                label={tab.label}
                classes={{ root: tab.disabled ? null : classes.tabFontColor }}
                {...setTabProps(index)}
              />
            ))}
          </Tabs>
        </Container>
      </AppBar>
    </div>
  );
};

AccountNavigation.propTypes = {};

export default AccountNavigation;
