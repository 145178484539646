import { createSelector } from "reselect";
import { getProfileUserId } from "redux/state/profile/selectors";
const sessions = state => state.sessions;

export const getSessions = state => sessions(state);
export const getSessionsById = state => getSessions(state).byId;
export const getAllSessionIds = state => getSessions(state).allIds;

export const isCurrentUserNewInHomeResults = createSelector(
  [getProfileUserId, getSessionsById],
  (userId, sessionsById) =>
    userId && userId !== "" && Object.keys(sessionsById).includes(userId)
      ? sessionsById[userId].pages.inHomeResults
      : false
);

export const hasCurrentUserFinishedHomeTour = createSelector(
  [getProfileUserId, getSessionsById],
  (userId, sessionsById) =>
    userId && userId !== "" && Object.keys(sessionsById).includes(userId)
      ? sessionsById[userId].milestones.homeResultsTourFinished
      : false
);
