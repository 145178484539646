import { combineEpics } from "redux-observable";
import reducer from "./reducer";
import { searchTopRanksAutocompleteEpic, fetchStudySearchTermEpic } from "./operations";

import * as studySearchOPS from "./operations";
import * as studySearchSelectors from "./selectors";

export { studySearchOPS, studySearchSelectors };

export const studySearchEpic = combineEpics(searchTopRanksAutocompleteEpic, fetchStudySearchTermEpic);

export default reducer;
