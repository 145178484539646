import reducer from "./reducer";

import * as homeUiOPS from "./operations";
import * as homeUiSelectors from "./selectors";
import { HOME_PAGE_LOADED } from "./types";
import { combineEpics } from "redux-observable";
import { homeComponentsLoadedEpic } from "./operations";

export { homeUiOPS, homeUiSelectors, HOME_PAGE_LOADED };

export const homePageEpic = combineEpics(homeComponentsLoadedEpic)

export default reducer;
