import React from "react";
import PropTypes from "prop-types";

const WhiteLink = ({ href, text, underline }) => {
  return (
    <a style={{ color: "white" }} target="_blank" href={href}>
      {text}
    </a>
  );
};

WhiteLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default WhiteLink;
