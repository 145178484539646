import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as publicProfileOPS from "./operations";
import * as publicProfileSelectors from "./selectors";

import { fetchPublicProfileEpic, validatePublicProfileEpic } from "./operations";

export { publicProfileOPS, publicProfileSelectors };

export const publicProfileEpic = combineEpics(fetchPublicProfileEpic, validatePublicProfileEpic);

export default reducer;
