import * as aTypes from "./types";

export const geoIPCheckStatus = () => ({ type: aTypes.GEOIP_CHECK_STATUS });
export const geoIPStatusLoaded = () => ({ type: aTypes.GEOIP_STATUS_LOADED });
export const geoIPStatusNotLoaded = () => ({ type: aTypes.GEOIP_STATUS_NOT_LOADED });

export const fetchGeoIP = (promise, transform) => ({
  type: aTypes.FETCH_GEOIP,
  payload: promise,
  meta: { transform }
});

export const geoIPUpdateNotLoaded = () => ({ type: aTypes.GEOIP_UPDATE_STATUS_NOT_LOADED });
export const geoIPUpdateLoaded = () => ({ type: aTypes.GEOIP_UPDATE_STATUS_LOADED });
export const geoIPUpdateLoading = () => ({ type: aTypes.GEOIP_UPDATE_STATUS_LOADING });
export const geoIPUpdateError = () => ({ type: aTypes.GEOIP_UPDATE_STATUS_ERROR });
