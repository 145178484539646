import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { paper } from "app/pages/home/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  paper: {
    ...paper(theme),
    padding: theme.spacing(1),
    height: "120px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4)
    },
    color: theme.palette.whiteText
  },
  smallBorder: {
    borderRightColor: theme.palette.whiteText,
    borderRightStyle: "solid",
    borderRightWidth: "2px",
    margin: theme.spacing(1, 0)
  },
  largeBorder: {
    borderRightColor: theme.palette.text.primary,
    borderRightStyle: "solid",
    borderRightWidth: "2px"
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.players.user.main
  },
  name: {
    fontStyle: "italic",
    fontWeight: "bold"
  },
  bold: {
    fontWeight: "bold"
  }
}));

const AbilityCard = ({ ranking, title, description, value, index, len, isPublic }) => {
  const classes = useStyles({ isPublic });
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const renderLargeBorder = () => {
    if (lg) return true;
  };

  const renderSmallBorder = () => {
    if (sm && index !== 2) return true;
  };

  return (
    <Grid
      item
      lg={4}
      xs={12}
      md={4}
      sm={4}
      className={clsx(classes.box, {
        // [classes.largeBorder]: renderLargeBorder(),
        [classes.smallBorder]: renderSmallBorder()
      })}
    >
      <div className={classes.paper}>
        <Typography className={classes.title} variant={"h5"}>
          {`${value}${typeof value === "number" ? "%" : ""}`}
        </Typography>
        <Typography className={classes.name} variant={"body2"}>{`${title}${!ranking ? ":" : ""}`}</Typography>
        {!ranking && (
          <Typography className={classes.bold} variant={"body2"}>
            {description}
          </Typography>
        )}
      </div>
    </Grid>
  );
};

AbilityCard.propTypes = {
  ranking: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPublic: PropTypes.bool
};

AbilityCard.defaultProps = {
  ranking: false,
  title: "Card title",
  description: "Card description",
  value: 28,
  isPublic: false
};

export default AbilityCard;
