import api from "api";
import { fetchGeoIP, geoIPUpdateLoaded, geoIPUpdateNotLoaded } from "redux/state/geoip/actions";
import { ofType } from "redux-observable";
import { filter, map, mapTo } from "rxjs/operators";
import { APP_LOADED } from "redux/state/ui/pages/app";
import { mapGeoIpResponseToState } from "./utils";
import { getGeoIPFetchStatus } from "redux/state/geoip/selectors";
import { GEOIP_FETCH_STATUS } from "redux/state/geoip/reducer";
import { FETCH_GEOIP_SUCCESS, GEOIP_UPDATE_STATUS_NOT_LOADED } from "redux/state/geoip/types";
import { GEOIP_ACTIVATED } from "config/settings";

export const checkGeoIpStatus = () => (dispatch, getState) => {
  const state = getState();
  const status = getGeoIPFetchStatus(state);

  // Check if geoip module is active
  if (GEOIP_ACTIVATED) {
    if (status === GEOIP_FETCH_STATUS.LOADED) {
      dispatch(geoIPUpdateLoaded());
    } else {
      dispatch(geoIPUpdateNotLoaded());
    }
  }
};

export const fetchLocation = () => {
  return fetchGeoIP(api.geoIp.getIpLocation(), mapGeoIpResponseToState);
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const checkGeoIPStatusEpic = action$ =>
  action$.pipe(
    ofType(APP_LOADED),
    map(action => checkGeoIpStatus())
  );
export const fetchGeoIPEpic = action$ =>
  action$.pipe(
    ofType(GEOIP_UPDATE_STATUS_NOT_LOADED),
    map(action => fetchLocation())
  );
export const fetchGeoIPSuccessEpic = action$ =>
  action$.pipe(
    ofType(FETCH_GEOIP_SUCCESS),
    map(action => geoIPUpdateLoaded())
  );
