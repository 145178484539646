import { startAsyncAction, resultAsyncAction } from "redux/lib/async-actions";
import isPromise from "is-promise";

const promiseMiddleware = store => next => async action => {
  if (action === undefined) {
    console.error("promiseMiddleware () => Action can't be undefined");
    throw "Action can't be undefined";
  }
  if ("payload" in action && isPromise(action.payload)) {
    const startAction = startAsyncAction(action);
    next(startAction);
    next(await resultAsyncAction(startAction));
  }
  return next(action);
};

export default promiseMiddleware;
