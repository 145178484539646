import {
  userComparisonFormUpdateUserGame,
  userComparisonFormUpdateUserNickname,
  userComparisonFormUpdateUserRegion,
  userComparisonFormUpdateComparedGame,
  userComparisonFormUpdateComparedNickname,
  userComparisonFormUpdateComparedRegion
} from "./actions";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile";
import { getProfileMainGame } from "redux/state/profile/selectors";

// export const fetchUserComparison = () => async (dispatch, getState) => {
//   // start dispatch
//   dispatch(actions.userComparisonFetchStart());
//
//   try {
//     const state = getState();
//
//     const res = await api.user.getByIds(authSelectors.getAuthToken(state));
//
//     if (res.status === 200 || res.status === 201) {
//       // transform response into a user state
//       const transform = res.data.top_users.reduce((obj, user, landing_showcase) => {
//         obj[user.user_id] = user;
//         return obj;
//       }, {});
//
//       dispatch(actions.userComparisonFetchSuccess(transform));
//     } else {
//       dispatch(actions.userComparisonFetchFailure("Wrong status"));
//     }
//   } catch (e) {
//     dispatch(actions.userComparisonFetchFailure(e.message));
//   }
// };

// export const compareUser = name => async (dispatch, getState) => {
//   const state = getState();
//   dispatch(actions.userComparisonSelect(name));
//
//   try {
//     const names = getUserComparisonNames(state);
//     const userIndex = names.indexOf(name);
//     const userId = getUserComparisonAllIds(state)[userIndex];
//
//     const res = await api.study.getByUserId(userId, authSelectors.getAuthToken(state));
//
//     if (res.status === 200 || res.status === 201) {
//       const transformedRes = mapStudyResponseToState(res);
//       // On success
//       dispatch(fetchStudyByUserIdSuccess(transformedRes));
//     } else {
//       // On error
//       dispatch(fetchStudyByUserIdFailure(res));
//     }
//   } catch (e) {
//     dispatch(fetchStudyByUserIdFailure(e.message));
//   }
// };
const _setCompareFormStateFromProfile = () => (dispatch, getState) => {
  const state = getState();
  const mainGame = getProfileMainGame(state);
  dispatch(userComparisonFormUpdateUserGame(mainGame.game_id));
  dispatch(userComparisonFormUpdateUserRegion(mainGame.region_id));
  dispatch(userComparisonFormUpdateUserNickname(mainGame.game_username));
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const setCompareFormStateFromProfileEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_CHECK_SUCCESS),
    map(action => _setCompareFormStateFromProfile())
  );

export {
  userComparisonFormUpdateUserNickname,
  userComparisonFormUpdateUserGame,
  userComparisonFormUpdateUserRegion,
  userComparisonFormUpdateComparedNickname,
  userComparisonFormUpdateComparedGame,
  userComparisonFormUpdateComparedRegion
};
