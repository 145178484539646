import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CustomSpider from "app/components/spider-graph/CustomSpider";
import SpiderCallToAction
  from "app/pages/home/compare/spider-section/spider-call-to-action/SpiderCallToAction";

const SpiderDisplayView = ({ graphKeys, graphData, md }) => {
  return (
    <Grid container>
      <Grid xs={12} data-tut="reactour__spider">
        <CustomSpider graphKeys={graphKeys} graphData={graphData} />
      </Grid>
      {md && <SpiderCallToAction />}
    </Grid>
  );
};

SpiderDisplayView.propTypes = {
  graphKeys: PropTypes.array.isRequired,
  graphData: PropTypes.array.isRequired,
  md: PropTypes.bool.isRequired
};

export default SpiderDisplayView;
