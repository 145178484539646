import * as aTypes from "./types";

export const fetchNotifications = (promise, transform) => ({
  type: aTypes.FETCH_NOTIFICATIONS,
  payload: promise,
  meta: { transform }
});

export const updateIsReadNotification = promise => ({
  type: aTypes.NOTIFICATIONS_UPDATE_IS_READ,
  payload: promise
});
