import React from "react";
import PropTypes from "prop-types";
import HomePageTemplate from "app/components/profile-page-template/HomePageTemplate";
import Navbar from "app/components/navbar/Navbar";
import { Container } from "@material-ui/core";
import DrawerHeader from "app/components/drawer-header/DrawerHeader";
import AccountNavigation from "app/pages/account/account-navigation/AccountNavigation";
import Profile from "app/pages/account/profile/Profile";
import Socials from "app/pages/account/socials/Socials";
import { useDispatch } from "react-redux";
import { accountPageLoaded } from "redux/state/ui/pages/account/actions";

const Account = ({ history, match, location }) => {
  const dispatch = useDispatch();
  const params = match.params;

  React.useEffect(() => {
    dispatch(accountPageLoaded());
  }, []);

  const renderSubRoute = () => {
    switch (params.subRoute) {
      case "socials":
        return <Socials />;

      case "profile":
      default:
        return <Profile />;
    }
  };
  return (
    <HomePageTemplate>
      <Navbar />
      <DrawerHeader />
      <AccountNavigation />
      <Container maxWidth={"lg"}>{renderSubRoute()}</Container>
    </HomePageTemplate>
  );
};

Account.propTypes = {};

export default Account;
