const publicProfile = state => state.publicProfile;
const publicProfileError = state => publicProfile(state).error;
const publicProfileResults = state => publicProfile(state).eProfileResults;

export const getPublicProfile = state => publicProfile(state).profile;
export const getPublicProfileUsername = state => getPublicProfile(state).eProfileUsername;
export const getPublicProfileUserID = state => getPublicProfile(state).userId;
export const getPublicProfileLoading = state => getPublicProfile(state).loading;
export const getPublicProfileStudies = state => getPublicProfile(state).studies;
export const getPublicProfileImage = state => getPublicProfile(state).profileImage;
export const getPublicProfileIsVerified = state => getPublicProfile(state).isVerified;
export const getPublicProfileIsActive = state => getPublicProfile(state).isActive;
export const getPublicProfileHasCompletedStudies = state => getPublicProfile(state).completedStudies;
export const getPublicProfileMainGame = state => getPublicProfile(state).mainGame;
export const getPublicProfileMainGameID = state => getPublicProfileMainGame(state).game_id;
export const getPublicProfileMainGameWins = state => getPublicProfileMainGame(state).wins;


export const getPublicProfileScore = state => getPublicProfile(state).score;
export const getPublicProfileScorePosition = state => getPublicProfileScore(state).position;
export const getPublicProfileScoreTotal = state => getPublicProfileScore(state).total;
export const getPublicProfileScoreGameID = state => getPublicProfileScore(state).game_id;

export const getPublicProfileResults = state => publicProfileResults(state);
export const getPublicProfileResultStats = state => getPublicProfileResults(state).stats;

export const getPublicProfileError = state => getPublicProfile(state).error;
export const getPublicProfileErrorIsError = state => getPublicProfileError(state).error;
