import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getStudiesForComparison } from "redux/state/studies/selectors";
import SpiderDisplayView from "app/pages/home/compare/spider-section/spider-display/SpiderDisplayView";
import { useTranslation } from "react-i18next";
import { HOME_SPIDER_DISPLAY_ACTION } from "app/i18n/selectors";
import useTheme from "@material-ui/core/styles/useTheme";
import { useMediaQuery } from "@material-ui/core";

const SpiderDisplay = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const graphData = useSelector(state => getStudiesForComparison(state));
  const graphKeys = Object.keys(graphData[0] || []).filter(key => key !== "name");

  const state = { graphKeys, graphData, md };
  return <SpiderDisplayView {...state} />;
};

SpiderDisplay.propTypes = {};

export default SpiderDisplay;
