import React from "react";

import { useDispatch, useSelector } from "react-redux";
import RegisterFormView from "./RegisterFormView";

import { getLoadingValidation, getPlayerErrors } from "redux/state/forms/register/validationReducer";
import CustomLoader from "../../../components/custom-loader/CustomLoader";
import { getGameStatsLoading } from "redux/state/game-stats/selectors";
import {
  updateRegisterBirthday,
  updateRegisterEmail,
  updateRegisterGender,
  updateRegisterLastname,
  updateRegisterMainGame,
  updateRegisterMindsetUsername,
  updateRegisterName,
  updateRegisterPassword,
  updateRegisterPicture, updateRegisterReferredId,
  updateRegisterRepeatPassword,
  updateRegisterResetPicture,
  updateRegisterrRegion,
  updateRegisterSummonerName,
  updateRegisterTerms
} from "redux/state/forms/register/actions";
import {
  getRegisterBirthDay,
  getRegisterConfirmPassword,
  getRegisterEmail,
  getRegisterFormMainGame,
  getRegisterLastname,
  getRegisterFormeProfileTag,
  getRegisterName,
  getRegisterPassword,
  getRegisterProfileImage,
  getRegisterRegion,
  getRegisterGender,
  getRegisterSummonerName,
  getRegisterTerms, getRegisterFormReferredId
} from "redux/state/forms/register/selectors";
import { toggleRegisterLolSelected } from "redux/state/ui/pages/register/actions";
import { getRegisterLolSelected } from "redux/state/ui/pages/register/selectors";
import { getAllLolRegionIdsByGameLoL, getGameNamesWithIds } from "redux/state/games/selectors";
import { getRegionsFormattedByGame } from "redux/state/regions/selectors";
import { authStartRegistrationProcess } from "redux/state/auth/actions";

const RegisterForm = props => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateRegisterResetPicture());
  }, []);

  // CONSUME SELECTORS
  const password = useSelector(state => getRegisterPassword(state));
  const repeatPassword = useSelector(state => getRegisterConfirmPassword(state));
  const lastname = useSelector(state => getRegisterLastname(state));
  const name = useSelector(state => getRegisterName(state));
  const email = useSelector(state => getRegisterEmail(state));
  const terms = useSelector(state => getRegisterTerms(state));
  const mindsetUsername = useSelector(state => getRegisterFormeProfileTag(state));
  const birthday = useSelector(state => getRegisterBirthDay(state));
  const gender = useSelector(state => getRegisterGender(state));
  const lolSelected = useSelector(state => getRegisterLolSelected(state));
  const loadingValidation = useSelector(state => getLoadingValidation(state));
  const profilePicture = useSelector(state => getRegisterProfileImage(state));
  const errors = useSelector(state => getPlayerErrors(state));
  const loadingGameStats = useSelector(state => getGameStatsLoading(state));
  const regions = useSelector(state => getRegionsFormattedByGame(state));
  const games = useSelector(state => getGameNamesWithIds(state));
  const mainGame = useSelector(state => getRegisterFormMainGame(state));
  const referredId = useSelector(state => getRegisterFormReferredId(state));

  const summonerName = useSelector(state => getRegisterSummonerName(state));
  const region = useSelector(state => getRegisterRegion(state));

  // DISPATCHES
  const getValue = event => event.target.value;
  const handleChangePicture = file => dispatch(updateRegisterPicture(file));
  const startRegistration = () => dispatch(authStartRegistrationProcess());
  const toggleLolSelect = () => dispatch(toggleRegisterLolSelected());
  const udateMindsetUsername = e => dispatch(updateRegisterMindsetUsername(getValue(e)));
  const updateBirthday = e => dispatch(updateRegisterBirthday(e));
  const updateEmail = e => dispatch(updateRegisterEmail(getValue(e)));
  const updateGender = e => dispatch(updateRegisterGender(getValue(e)));
  const updateLastname = e => dispatch(updateRegisterLastname(getValue(e)));
  const updateMainGame = e => dispatch(updateRegisterMainGame(getValue(e)));
  const updateName = e => dispatch(updateRegisterName(getValue(e)));
  const updatePassword = e => dispatch(updateRegisterPassword(getValue(e)));
  const updateRegion = e => dispatch(updateRegisterrRegion(getValue(e)));
  const updateReferred = e => dispatch(updateRegisterReferredId(getValue(e)));
  const updateRepeatPassword = e => dispatch(updateRegisterRepeatPassword(getValue(e)));
  const updateSummonerName = e => dispatch(updateRegisterSummonerName(getValue(e)));
  const updateTerms = e => dispatch(updateRegisterTerms(e.target.checked));
  // const validateAndFetchInfo = () => dispatch(validateFormAndFetchLolInfo());
  return loadingGameStats.isLoading ? (
    <CustomLoader absolute={true} />
  ) : (
    <RegisterFormView
      referredId={referredId}
      regions={regions}
      region={region}
      mainGame={mainGame}
      games={games}
      summonerName={summonerName}
      loadingValidation={loadingValidation}
      name={name}
      lastname={lastname}
      password={password}
      repeatPassword={repeatPassword}
      email={email}
      mindsetUsername={mindsetUsername}
      birthday={birthday}
      gender={gender}
      terms={terms}
      profilePicture={profilePicture}
      lolSelected={lolSelected}
      startRegistration={startRegistration}
      updateRegion={updateRegion}
      updateSummonerName={updateSummonerName}
      updateName={updateName}
      updateLastname={updateLastname}
      updateGender={updateGender}
      updatePassword={updatePassword}
      updateReferredId={updateReferred}
      updateRepeatPassword={updateRepeatPassword}
      updateEmail={updateEmail}
      updateBirthday={updateBirthday}
      updateMindsetUsername={udateMindsetUsername}
      updateTerms={updateTerms}
      toggleLolSelect={toggleLolSelect}
      // validateAndFetchInfo={validateAndFetchInfo}
      handleChangePicture={handleChangePicture}
      updateMainGame={updateMainGame}
      errors={errors}
    />
  );
};

export default RegisterForm;
