import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RedirectAuthenticated from "../../components/redirect-authenticated/RedirectAuthenticated";
import { getAuthAuthenticated } from "../../../redux/state/auth/selectors";
import { useTranslation } from "react-i18next";
import { INDEX_TEXTS } from "app/i18n/selectors";
import { checkPrefetch } from "redux/state/prefetch/operations";
import EULandingView from "app/pages/landing/EULandingView";
import homeslide0 from "app/assets/img/landing/profiles/homeslide_0.png";
import homeslide1 from "app/assets/img/landing/profiles/homeslide_1.png";
import homeslide2 from "app/assets/img/landing/profiles/homeslide_2.png";
import homeslide3 from "app/assets/img/landing/profiles/homeslide_3.png";
import homeslide4 from "app/assets/img/landing/profiles/homeslide_4.png";

const EULanding = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(checkPrefetch());
  }, []);

  const texts = {
    title1Text: "Create your gamer profile",
    title2Text: "and share with the best",
    title3Text: t(`${INDEX_TEXTS}.title.part3`),
    initButtonText: t(`${INDEX_TEXTS}.init-button`)
  };

  const carouselData = [
    { img: homeslide0, id: 0 },
    { img: homeslide1, id: 1 },
    { img: homeslide2, id: 2 },
    { img: homeslide3, id: 3 },
    { img: homeslide4, id: 4 }
  ];

  const authenticated = useSelector(state => getAuthAuthenticated(state));

  return authenticated ? (
    <RedirectAuthenticated location={props.location} />
  ) : (
    <EULandingView carouselData={carouselData} {...texts} />
  );
};

export default EULanding;
