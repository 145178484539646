import React from "react";
import PropTypes from "prop-types";
import HomePageTemplate from "app/components/profile-page-template/HomePageTemplate";
import MyProfileFooter from "app/components/my-profile/myprofile-footer/MyProfileFooter";
import MyProfile from "app/components/my-profile/MyProfile";
import DrawerHeader from "app/components/drawer-header/DrawerHeader";

const PublicProfileView = props => {
  return (
    <HomePageTemplate>
      <DrawerHeader />
      <MyProfile isPublic={true} />
      <MyProfileFooter />
    </HomePageTemplate>
  );
};

PublicProfileView.propTypes = {};

export default PublicProfileView;
