import React from "react";

import LoginFormView from "./LoginFormView";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { authOPS } from "redux/state/auth";
import { updateLoginPassword, updateLoginUsername } from "redux/state/forms/login/actions";
import {
  getLoginErrorStatus,
  getLoginIsError,
  getLoginPassword,
  getLoginUsername
} from "redux/state/forms/login/selectors";
import { LOGIN_ERR, LOGIN_TEXTS } from "app/i18n/selectors";

const LoginForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isError = useSelector(state => getLoginIsError(state));
  const status = useSelector(state => getLoginErrorStatus(state));

  const setErrorMsg = () =>
    isError && status !== 0 ? t(`${LOGIN_ERR}.email.${status.toString()}`) : "";

  const updateUsername = e => {
    dispatch(updateLoginUsername(e.target.value));
  };

  const updatePassword = e => {
    dispatch(updateLoginPassword(e.target.value));
  };

  const loginUser = () => {
    dispatch(authOPS.authLogin());
  };

  const fields = [
    {
      // username - email
      value: useSelector(state => getLoginUsername(state)),
      label: t(`${LOGIN_TEXTS}.email.placeholder`),
      helperText: setErrorMsg(),
      type: "text",
      onChange: updateUsername
    },
    {
      // password
      value: useSelector(state => getLoginPassword(state)),
      label: t(`${LOGIN_TEXTS}.password.placeholder`),
      helperText: setErrorMsg(),
      type: "password",
      onChange: updatePassword
    }
  ];

  const texts = {
    loginButtonText: t(`${LOGIN_TEXTS}.login-button`),
    notUserLink: t(`${LOGIN_TEXTS}.not_user_link`),
    registerButton: t(`${LOGIN_TEXTS}.register-button`),
    loginTitle: t(`${LOGIN_TEXTS}.title`),
    loginSubtitle: t(`${LOGIN_TEXTS}.subtitle`),
    passwordReset: t(`${LOGIN_TEXTS}.password-reset`)
  };
  return <LoginFormView {...texts} fields={fields} loginUser={loginUser} error={isError} />;
};

export default LoginForm;
