import {
  userComparisonUpdateComparedGame,
  userComparisonUpdateComparedRegion,
  userComparisonUpdateComparedNickname,
  userComparisonUpdateComparedProfileImage,
} from "./actions";
import { ofType } from "redux-observable";
import { mapTo } from "rxjs/operators";
import { getStudySearchComparedGame } from "redux/state/autocomplete/study-search/selectors";
import { STUDY_SET_SELECT_COMPARED_STUDY_SUCCESS } from "redux/state/studies/types";

const _setUserComparisonState = () => (dispatch, getState) => {
  const state = getState();
  // TODO this has to be UserComparisonMainGame
  const comparedGame = getStudySearchComparedGame(state);
  dispatch(userComparisonUpdateComparedGame(comparedGame.game_id));
  // TODO set region if game_id has regions
  dispatch(userComparisonUpdateComparedRegion(""));
  dispatch(userComparisonUpdateComparedNickname(comparedGame.username));
  dispatch(userComparisonUpdateComparedProfileImage(comparedGame.profile_image));
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const setUserComparisonEpic = (action$) =>
  action$.pipe(ofType(STUDY_SET_SELECT_COMPARED_STUDY_SUCCESS), mapTo(_setUserComparisonState()));

export {
  userComparisonUpdateComparedNickname,
  userComparisonUpdateComparedGame,
  userComparisonUpdateComparedRegion,
  userComparisonUpdateComparedProfileImage,
};
