import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BootstrapPurpleInput from "app/components/bootstrap-input/BootstrapPurpleInput";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FormHelperText } from "@material-ui/core";
import AutocomSearchStudyItem from "app/pages/home/compare/spider-section/player-information/player-search-form/autocom-search-study-item/AutocomSearchStudyItem";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  label: {
    top: "20%",
    fontSize: 14,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    }
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  select: {
    "&:focus": {
      // Show that it's not an text input
      borderRadius: 0, // Reset Chrome style,
      backgroundColor: theme.palette.primary.main_dark5
    }
  },
  formControl: {
    position: "relative"
  },
  autocompleteContainer: {
    position: "relative",
    width: "100%",
    zIndex: 10
  },
  autocomplete: {
    display: "block",
    position: "absolute",
    overflowY: "scroll",
    tabIndex: 0,
    // bottom: -20,
    left: 0,
    width: "100%",
    height: "200px",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px",
    backgroundColor: theme.palette.primary.main2_dark,
    verticalAlign: "top"
  },
  listItem: {
    backgroundColor: theme.palette.primary.main2_dark,
    cursor: "pointer"
  },
  listItemHover: {
    backgroundColor: theme.palette.primary.main2_light
  }
}));

const CustomInputView = ({
  inputRef,
  handleKeyEvent,
  setIsFocusTrue,
  setIsFocusFalse,
  value,
  handleChange,
  isFocus,
  renderList,
  handleHover,
  setUnHover,
  setSelected,
  items,
  hover,
  label,
  disabled,
  byIdIdName,
  byIdValueName,
  validation,
  helperText,
  errorMsg
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const renderListItem = item => (
    <div
      id={items.byId[item][byIdIdName]}
      className={clsx(classes.listItem, {
        [classes.listItemHover]: items.byId[item][byIdIdName] === renderList[hover]
      })}
      onMouseEnter={handleHover}
      onMouseLeave={setUnHover}
      onClick={setSelected}
      key={items.byId[item][byIdIdName]}
    >
      {items.byId[item][byIdValueName]}
    </div>
  );

  return (
    <FormControl style={{outline: "none"}} fullWidth disabled={disabled} className={classes.formControl}>
      <InputLabel
        style={{ color: theme.palette.grey["300"] }}
        className={classes.label}
        variant={"outlined"}
        htmlFor="demo-customized-select-native"
      >
        {label}
      </InputLabel>
      <BootstrapPurpleInput
        // error={validation.error}
        inputRef={inputRef}
        onKeyDown={handleKeyEvent}
        onFocus={setIsFocusTrue}
        onBlur={setIsFocusFalse}
        value={value}
        onChange={handleChange}
      />
      {isFocus && (
        <div className={classes.autocompleteContainer}>
          <div className={classes.autocomplete}>
            {renderList.map(id => (
              <AutocomSearchStudyItem
                byIdIdName={byIdIdName}
                byIdValueName={byIdValueName}
                handleHover={handleHover}
                hover={hover}
                item={id}
                items={items}
                renderList={renderList}
                setSelected={setSelected}
                setUnHover={setUnHover}
              />
            ))}
          </div>
        </div>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

CustomInputView.propTypes = {};

export default CustomInputView;
