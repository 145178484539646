import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  getPublicProfileMainGameWins,
  getPublicProfileUsername,
  getPublicProfileImage
} from "redux/state/public-profile/selectors";
import { useSelector } from "react-redux";
import { getPublicProfileGameName, getUserProfileGameName } from "redux/state/games/selectors";
import AvatarInformation from "app/components/avatar-information/AvatarInformation";
import { getProfileImage, getProfileMainGameWins, getProfileUsername } from "redux/state/profile/selectors";

const useStyles = makeStyles(theme => ({
  root: {
    flex: "1 1 100%",
    // backgroundColor: "steelblue"
    [theme.breakpoints.up("sm")]: {
      flex: "1",
      maxWidth: "230px"
    },
    [theme.breakpoints.up("md")]: {
      flex: "1 1 40%",
      maxWidth: "330px"
    },
    [theme.breakpoints.up("lg")]: {
      flex: "1 1 40%",
      maxWidth: "330px"
    },
    [theme.breakpoints.up("xl")]: {
      flex: "1 1 40%",
      maxWidth: "430px"
    }

  },
  profileContainer: {
    width: "60%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      paddingTop: theme.spacing(2)
    },
    [theme.breakpoints.up("md")]: {
      width: "80%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%"
    }
  }
}));

const ProfileSection = ({ isPublic }) => {
  const classes = useStyles();

  const avatarState = {
    mainGame: useSelector(state => (isPublic ? getPublicProfileGameName(state) : getUserProfileGameName(state))),
    avatarName: useSelector(state => (isPublic ? getPublicProfileUsername(state) : getProfileUsername(state))),
    wins: useSelector(state => (isPublic ? getPublicProfileMainGameWins(state) : getProfileMainGameWins(state))),
    winsText: "win",
    imgUrl: useSelector(state => (isPublic ? getPublicProfileImage(state) : getProfileImage(state)))
  };

  return (
    <div className={classes.root}>
      <div className={classes.profileContainer}>
        <AvatarInformation isPublic={isPublic} isUser avatarInfoState={avatarState} />
      </div>
    </div>
  );
};

ProfileSection.propTypes = {
  isPublic: PropTypes.bool
};

export default ProfileSection;
