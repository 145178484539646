import reducer from "redux/state/auth/reducer";

import * as authOPS from "./operations";
import * as authSelectors from "./selectors";
import { authEpic } from "./operations";

import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS
} from "./types";

export {
  authOPS,
  authSelectors,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  LOGOUT,
  authEpic,
  REGISTER_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS
};

export default reducer;
