import Register from "../pages/register/Register";
import Login from "../pages/login/Login";
import Download from "../pages/download/Download";
import TestPage from "../pages/test-page/TestPage";
import Survey from "../pages/survey/Survey";
import Landing from "app/pages/landing/Landing";
import NotFound from "../pages/not-found/NotFound";
import SurveyBackup from "../pages/survey-backup/SurveyBackup";
import Home from "../pages/home/Home";
import PasswordReset from "../pages/password-reset/PasswordReset";
import PasswordUpdate from "app/pages/password-reset/password-update/PasswordUpdate";
import PasswordResetForm from "app/pages/password-reset/password-reset-form/PasswordResetForm";
import TermsAndConditions from "app/pages/terms-conditions/TermsAndConditions";
import Privacy from "app/pages/privacy/Privacy";
import PublicProfile from "app/pages/public-profile/PublicProfile";
import Payment from "app/pages/payment/Payment";
import Loading from "app/pages/loading/Loading";
import EULanding from "app/pages/landing/EULanding";
import Account from "app/pages/account/Account";

const scope = { private: "PRIVATE", public: "PUBLIC" };

const routes = {
  index: {
    route: "/",
    component: Landing,
    scope: scope.public
  },
  euIndex: {
    route: "/",
    component: EULanding,
    scope: scope.public
  },
  home: {
    route: "/landing",
    resource: "/landing",
    component: Home,
    scope: scope.public
  },
  login: {
    route: "/login",
    component: Login,
    scope: scope.public
  },
  register: {
    route: "/register",
    component: Register,
    scope: scope.public
  },
  download: {
    route: "/download",
    component: Download,
    scope: scope.private
  },
  survey: {
    route: "/survey/:token/:studyId",
    component: Survey,
    scope: scope.private
  },
  notFound: {
    route: "*",
    component: NotFound,
    scope: scope.public
  },
  surveyBackup: {
    route: "/survey/finish",
    component: SurveyBackup,
    scope: scope.private
  },
  passwordReset: {
    route: "/password-reset",
    component: PasswordReset,
    scope: scope.private
  },
  passwordUpdate: {
    route: "/password-reset/:token",
    component: PasswordUpdate,
    scope: scope.public
  },
  passwordResetForm: {
    route: "/password-reset/form",
    component: PasswordResetForm,
    scope: scope.public
  },
  termsAndConditions: {
    route: "/terms-conditions",
    component: TermsAndConditions,
    scope: scope.public
  },
  privacy: {
    route: "/privacy",
    component: Privacy,
    scope: scope.public
  },
  publicProfile: {
    route: "/profile/:pathUsername",
    resource: "/profile/",
    component: PublicProfile,
    scope: scope.public
  },
  payment: {
    route: "/payment",
    resource: "/payment/",
    component: Payment,
    scope: scope.public
  },
  account: {
    route: "/account/:subRoute",
    resource: "/account/",
    component: Account,
    scope: scope.private
  },
  loading: {
    route: "/",
    resource: "/",
    component: Loading,
    scope: scope.public
  },
  testPage: {
    route: "/test",
    component: TestPage,
    scope: scope.private
  }
};

export default routes;
