import * as aTypes from "./types";

/**
 * Init STATE
 */
const initUrlErrorState = {
  error: false,
  validated: false
};

/**
 * REDUCERS
 */
const urlError = (state = initUrlErrorState, action) => {
  switch (action.type) {
    case aTypes.RESULTS_URL_FORMAT_START:
      return { ...state, validated: false };

    case aTypes.RESULTS_URL_FORMAT_SUCCESS:
      return { ...state, error: false, validated: true };

    case aTypes.RESULTS_URL_FORMAT_FAILURE:
      return { ...state, error: true, validated: true };

    default:
      return state;
  }
};

export default urlError;
