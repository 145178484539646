import thunk from "redux-thunk";
import loggerMiddleware from "./loggerMiddleware";
import promiseMiddleware from "./promiseMiddleware";
import authMiddleware from "./authMiddleware";

const middlewares = [];

// Logger
if (process.env.NODE_ENV === "development") {
  middlewares.push(loggerMiddleware);
}

middlewares.push(thunk);
middlewares.push(promiseMiddleware);
middlewares.push(authMiddleware);

export default middlewares;
