import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as userComparisonOPS from "./operations";
import * as userComparisonSelectors from "./selectors";
import { setUserComparisonEpic } from "./operations";

export { userComparisonOPS, userComparisonSelectors };

export const userComparisonEpic = combineEpics(setUserComparisonEpic);

export default reducer;
