export const AUTOCOMPLETE_FETCH_STUDY_SEARCH = "AUTOCOMPLETE_FETCH_STUDY_SEARCH";
export const AUTOCOMPLETE_FETCH_STUDY_SEARCH_START = "AUTOCOMPLETE_FETCH_STUDY_SEARCH_START";
export const AUTOCOMPLETE_FETCH_STUDY_SEARCH_SUCCESS = "AUTOCOMPLETE_FETCH_STUDY_SEARCH_SUCCESS";
export const AUTOCOMPLETE_FETCH_STUDY_SEARCH_FAILURE = "AUTOCOMPLETE_FETCH_STUDY_SEARCH_FAILURE";

export const AUTOCOM_FETCH_TOP_RANKS = "AUTOCOM_FETCH_TOP_RANKS";
export const AUTOCOM_FETCH_TOP_RANKS_START = "AUTOCOM_FETCH_TOP_RANKS_START";
export const AUTOCOM_FETCH_TOP_RANKS_SUCCESS = "AUTOCOM_FETCH_TOP_RANKS_SUCCESS";
export const AUTOCOM_FETCH_TOP_RANKS_FAILURE = "AUTOCOM_FETCH_TOP_RANKS_FAILURE";

export const AUTOCOM_FETCH_BY_TERM = "AUTOCOM_FETCH_BY_TERM";
export const AUTOCOM_FETCH_BY_TERM_START = "AUTOCOM_FETCH_BY_TERM_START";
export const AUTOCOM_FETCH_BY_TERM_SUCCESS = "AUTOCOM_FETCH_BY_TERM_SUCCESS";
export const AUTOCOM_FETCH_BY_TERM_FAILURE = "AUTOCOM_FETCH_BY_TERM_FAILURE";

export const AUTOCOM_STUDY_SEARCH_USER_SELECT = "AUTOCOM_STUDY_SEARCH_USER_SELECT";
export const AUTOCOM_STUDY_SEARCH_USER_SELECT_SUCCESS = "AUTOCOM_STUDY_SEARCH_USER_SELECT_SUCCESS";
export const AUTOCOM_STUDY_SEARCH_USER_RESET = "AUTOCOM_STUDY_SEARCH_USER_RESET";
export const AUTOCOM_STUDY_SEARCH_COMPARE_RESET = "AUTOCOM_STUDY_SEARCH_COMPARE_RESET";
export const AUTOCOM_STUDY_SEARCH_COMPARE_SELECT = "AUTOCOM_STUDY_SEARCH_COMPARE_SELECT";
export const AUTOCOM_STUDY_SEARCH_COMPARE_SELECT_SUCCESS = "AUTOCOM_STUDY_SEARCH_COMPARE_SELECT_SUCCESS";
