import React from "react";
import CognitiveAbilitiesView from "app/pages/home/cognitive-abilities/CognitiveAbilitiesView";
import abilities from "./abilitiesTexts";

const CognitiveAbilities = props => {
  const state = {
    title: "Funciones Cognitivas/",
    subtitle: "Funciones Cognitivas y Videojuegos/",
    subtitleDescription1:
      "Diversos estudios han evidenciado diferencias entre videojugadores y personas que no juegan videojuegos que se observan en variadas áreas de la cognición, como la memoria de trabajo, la velocidad de procesamiento, ladistinción figura-fondo, tiempos de reacción y atención visoespacial (Granic, Lobel, y Engels 2014;Eichenbaum, Bavelier, y Green 2014; Powers y cols. 2013; Pasinski, Hannon, y Snyder 2016) . Es importante\nnotar que estas diferencias se explican producto de la interacción entre la práctica de jugar videojuegos y laplasticidad cognitiva (Kühn y cols. 2014; Toril, Reales, y Ballesteros 2014; Hartanto, Toh, y Yang 2016)."
  };
  return <CognitiveAbilitiesView {...state} abilities={abilities} />;
};

CognitiveAbilities.propTypes = {};

export default CognitiveAbilities;
