import axios from "axios";
import { setAuthHeader } from "api/utils";
import * as URLS from "api/urls";
import request from "api/request";
import { set } from "react-ga";

const TIMEOUT = 15 * 1000;

export default {
  games: {
    /**
     * This method retrieves all supported Games from the server.
     */
    fetchAll: () => request(URLS.GAMES),
    lol: {
      regions: {
        /**
         * This method retrieves all LoL regions
         */
        fetchAll: () => request(URLS.REGIONS_ALL)
      },
      summoners: {
        /**
         * This method consults the LoL api for summoner information. Wins, losses and tier.
         * @param region {string}
         * @param summonerName {string}
         */
        fetchSummonerByName: (region, summonerName) => request(URLS.SUMMONER_BY_NAME(region, summonerName))
      }
    }
  },
  auth: {
    /**
     * This method retrieves an auth_token to work as an access token
     * @param user {string}
     * @param pass {string}
     */
    login: (user, pass) => request(URLS.LOGIN, "POST", {}, null, { username: user, password: pass }),
    /**
     * This compare a player with the backend api and returns an auth_token to work as an access token
     * @param email {string}
     * @param sex {string}
     * @param age {number}
     * @param password {string}
     * @param additional {object}
     * @param username {string}
     * @param main_game {string}
     * @param region_id {string}
     * @param summoner_name {string}
     * @param referral_code {string}
     */
    register: (email, sex, age, password, additional, username, main_game, region_id, summoner_name, referral_code) =>
      request(URLS.REGISTER, "POST", {
        age,
        email,
        password,
        sex,
        additional,
        username,
        main_game,
        region_id,
        summoner_name,
        referral_code
      }),

    updateProfileImage: (image, user_id, token) => {
      let data = new FormData();
      data.append("image", image);
      data.append("user_id", user_id);
      return request(URLS.UPLOAD_IMAGE, "PUT", data, {
        ...setAuthHeader(token),
        "Content-Type": "multipart/form-data"
      });
    },
    /**
     * This method validates a token and send a study id to start a survey
     * @param token
     * @param study_id
     */
    validateToken: (token, study_id) => request(URLS.VALIDATE_TOKEN, "POST", { token, study_id }),

    /**
     * This method send an email address to the backend. It will send an email with
     * the link to reset the password
     * @param email
     */
    passwordReset: email => request(URLS.PASSWORD_RESET, "POST", { email }),

    /**
     * This method send a token to the backend to validate the permission to update password
     * @param token
     */
    passwordResetValidateToken: token => request(URLS.PASSWORD_RESET_VALIDATE_TOKEN, "POST", { token }),
    /**
     * This method send a token and a password with a PUT method to update user password based on token information
     * @param password
     * @param token
     */
    updatePassword: (password, token) => request(URLS.PASSWORD_RESET_UPDATE_PASSWORD, "PUT", { password, token })
  },
  user: {
    fetchProfile: token => {
      return request(URLS.PROFILE, "GET", {}, { ...setAuthHeader(token) });
    },
    fetchPublicProfile: username => {
      return request(URLS.PUBLIC_PROFILE(username));
    },
    updateProfile: (data, token) => {
      return request(URLS.PROFILE, "PUT", { ...data }, setAuthHeader(token));
    },
    unlockUser: token => {
      return request(URLS.PROFILE_UNLOCK, "PUT", {}, setAuthHeader(token));
    }
  },
  study: {
    /**
     * This method returns a study compare by passing an id.
     * @param {string} studyId
     * @param {string} token
     */
    getById: (studyId, token) => {
      return request(URLS.STUDY_BY_ID(studyId), "GET", {}, { ...setAuthHeader(token) });
    },

    getByUserId: userId => {
      return request(URLS.STUDY_BY_USER_ID(userId));
    },

    getUserResults: token => {
      return request(URLS.STUDY_BY_CURRENT_USER, "GET", {}, { ...setAuthHeader(token) });
    },

    /**
     * This method returns a list of study compare by passing a list of study ids separated by semicolons
     * @param {string} studyIds  ex: "uuid1;uuid2;uuid3"
     * @param {string} token
     */
    getByIds: (studyIds, token) => {
      return request(URLS.STUDY_BY_USER_IDS(studyIds), "GET", {}, { ...setAuthHeader(token) });
    }
  },
  score: {
    fetchTopRanksAutocomplete: (scoreType, token) =>
      request(URLS.SEARCH_TOP_RANKS(scoreType), "GET", {}, { ...setAuthHeader(token) }),

    fetchPlayersWithTerm: (scoreType, token, term) => {
      return request(URLS.SEARCH_BY_TERM(scoreType, term), "GET", {}, { ...setAuthHeader(token) });
    }
  },
  cognitiveAbilities: {
    fetchAll: token => request(URLS.ABILITIES_ALL, "GET", {}, { ...setAuthHeader(token) })
  },
  notifications: {
    fetchAll: (userId, token) => {
      return request(URLS.NOTIFICATIONS_BY_USER(userId), "GET", {}, { ...setAuthHeader(token) });
    },
    updateIsRead: (notificationId, token, userId) => {
      return request(URLS.NOTIFICATIONS_IS_READ(userId, notificationId), "PUT", {}, { ...setAuthHeader(token) });
    }
  },
  geoIp: {
    getIpLocation: () => request(URLS.GEO_IP)
  },
  socials: {
    fetchAll: () => {
      return request(URLS.SOCIALS);
    },
    getByUserId: (userId, token) => {
      return request(URLS.SOCIALS_BY_USER_ID(userId), "GET", {}, { ...setAuthHeader(token) });
    },
    updateUserSocialNetwork: (data, token) => {
      return request(URLS.SOCIALS_BY_USER, "PUT", data, { ...setAuthHeader(token) });
    }
  }
};
