import api from "api";
import { transformLolStateToJson } from "api/utils";
import * as actions from "./actions";
import { authPasswordResetUpdatePassword, authPasswordResetValidateToken } from "./actions";
import * as utils from "./utils";
import { getGameStatsByIdByGameLol } from "redux/state/game-stats/selectors";
import { getGameBySelected } from "redux/state/games/selectors";
import { getLoginPassword, getLoginUsername } from "redux/state/forms/login/selectors";
import { registerFormSelectors } from "redux/state/forms/register";
import {
  AUTH_LOGIN,
  AUTH_LOGIN_START,
  LOGOUT,
  PASSWORD_RESET_TRIGGER_SEND_MAIL,
  PASSWORD_RESET_TRIGGER_UPDATE_PASSWORD,
  PASSWORD_RESET_VALIDATE_TOKEN_TRIGGER,
  START_REGISTRATION_PROCESS
} from "redux/state/auth/types";
import { combineEpics, ofType } from "redux-observable";
import { debounceTime, map, mapTo } from "rxjs/operators";
import { validateRegisterForm } from "redux/state/forms/register/operations";
import { REGISTER_VALIDATE_FORM_SUCCESS } from "redux/state/forms/register/types";
import { fetchSummonerInfo } from "redux/state/game-stats/operations";
import { FETCH_SUMMONER_INFO_CONTINUE, FETCH_SUMMONER_INFO_SUCCESS } from "redux/state/game-stats/types";
import {
  validatePasswordResetEmailForm,
  validatePasswordResetUpdatePasswordForm
} from "redux/state/forms/password-reset/operations";
import { passwordResetFormEmail, passwordResetFormPassword } from "redux/state/forms/password-reset/selectors";
import {
  PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS,
  PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS
} from "redux/state/forms/password-reset/types";
import { getAuthPasswordResetTokenValidationToken } from "redux/state/auth/selectors";
import { PASSWORD_RESET_FORM_PAGE_LOADED } from "redux/state/ui/pages/password-reset/types";
import { authPasswordResetUpdatePasswordReset } from "redux/state/auth/actions";

/**
 * Action creator to init the login process
 */
export const authLogin = () => async (dispatch, getState) => {
  const state = getState();
  const username = getLoginUsername(state);
  const password = getLoginPassword(state);

  dispatch(actions.authLogin(api.auth.login(username, password), utils.mapLoginResToState));
};

/**
 * This action creator starts a registration process.
 * 1. Gets all the variables from the state name, email, sex, age, password, additional.
 * If success it updates the profile image.
 */
export const authRegister = () => async (dispatch, getState) => {
  const state = getState();

  const email = registerFormSelectors.getRegisterEmail(state);
  const gender = registerFormSelectors.getRegisterGender(state);
  const age = new Date().getFullYear() - new Date(registerFormSelectors.getRegisterBirthDay(state)).getFullYear();
  const password = registerFormSelectors.getRegisterPassword(state);
  const gameStats = getGameStatsByIdByGameLol(state);
  const lolGame = getGameBySelected(state);
  const additional = transformLolStateToJson(lolGame, gameStats);
  const username = registerFormSelectors.getRegisterFormeProfileTag(state);
  const mainGame = registerFormSelectors.getRegisterFormMainGame(state);
  const region = registerFormSelectors.getRegisterRegion(state);
  const summonerName = registerFormSelectors.getRegisterSummonerName(state);
  const referredId = registerFormSelectors.getRegisterFormReferredId(state);

  const sex = utils.transformGenderState(gender);

  dispatch(
    actions.authRegister(
      api.auth.register(email, sex, age, password, additional, username, mainGame, region, summonerName, referredId),
      utils.mapLoginResToState
    )
  );
};

/**
 * This action creator is in charge of validating the token url token from standalone
 * @param {string} token
 * @param {string} studyId
 */
export const authValidateToken = (token, studyId) => {
  return actions.authValidateToken(api.auth.validateToken(token, studyId), utils.mapValidateToState);
};

/**
 * Logout action creator
 */
export const authLogout = () => ({ type: LOGOUT });

/**
 * This action creator is in charge of updating the user image
 * @param {string} user_id
 * @param {string} token
 * @deprecated
 */
export const authUpdateImage = (user_id, token) => async (dispatch, getState) => {
  // Start dispatching
  dispatch(actions.authRegisterUpdateProfileImageStart());

  const state = getState();
  const profileImage = registerFormSelectors.getRegisterProfileImage(state);

  try {
    const res = await api.auth.updateProfileImage(profileImage.file, user_id, token);

    if (res.status === 201 || res.status === 200) {
      dispatch(actions.authRegisterUpdateProfileImageSuccess());
    } else {
      dispatch(actions.authRegisterUpdateProfileImageFailure("Error al subir la imagen"));
    }
  } catch (e) {
    dispatch(actions.authRegisterUpdateProfileImageFailure(e.message));
  }
};

export const authPasswordResetSendEmail = () => (dispatch, getState) => {
  const email = passwordResetFormEmail(getState());
  dispatch(actions.authPasswordResetSendMail(api.auth.passwordReset(email)));
};

export const _authPasswordResetValidateToken = token => (dispatch, getState) => {
  dispatch(authPasswordResetValidateToken(api.auth.passwordResetValidateToken(token)));
};

export const _authPasswordResetValidateTokenFromStore = () => (dispatch, getState) => {
  const token = getAuthPasswordResetTokenValidationToken(getState());
  dispatch(authPasswordResetValidateToken(api.auth.passwordResetValidateToken(token)));
};

const _authPasswordResetUpdatePassword = () => (dispatch, getState) => {
  const state = getState();
  const password = passwordResetFormPassword(state);
  const token = getAuthPasswordResetTokenValidationToken(state);

  dispatch(authPasswordResetUpdatePassword(api.auth.updatePassword(password, token)));
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
// export const registrationEpic = action$ =>
//   action$.pipe(ofType(START_REGISTRATION_PROCESS), mapTo(validateRegisterForm()));

// REGISTRATION
export const triggerRegistrationEpic = action$ =>
  action$.pipe(ofType(START_REGISTRATION_PROCESS), mapTo(validateRegisterForm()));

export const registerValidationSuccessEpic = action$ =>
  action$.pipe(ofType(REGISTER_VALIDATE_FORM_SUCCESS), mapTo(fetchSummonerInfo()));

export const startRegister = action$ =>
  action$.pipe(ofType(FETCH_SUMMONER_INFO_SUCCESS, FETCH_SUMMONER_INFO_CONTINUE), mapTo(authRegister()));

// PASSWORD RESET SEND EMAIL
export const triggerResetPasswordEpic = action$ =>
  action$.pipe(ofType(PASSWORD_RESET_TRIGGER_SEND_MAIL), mapTo(validatePasswordResetEmailForm()));

export const passwordResetEmailValidationSuccessEpic = action$ =>
  action$.pipe(
    ofType(PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS),
    debounceTime(500),
    mapTo(authPasswordResetSendEmail())
  );

export const passwordResetStartTokenValidationEpic = action$ =>
  action$.pipe(
    ofType(PASSWORD_RESET_VALIDATE_TOKEN_TRIGGER),
    map(action => _authPasswordResetValidateToken(action.payload))
  );

export const passwordResetStartTokenValidationFromStoreEpic = action$ =>
  action$.pipe(ofType(PASSWORD_RESET_FORM_PAGE_LOADED), mapTo(_authPasswordResetValidateTokenFromStore()));

// PASSWORD RESET UPDATE PASSWORD
export const triggerPasswordResetUpdatePasswordEpic = action$ =>
  action$.pipe(
    ofType(PASSWORD_RESET_TRIGGER_UPDATE_PASSWORD),
    debounceTime(500),
    mapTo(validatePasswordResetUpdatePasswordForm())
  );

export const passwordResetUpdatePasswordValidationSuccessEpic = action$ =>
  action$.pipe(
    ofType(PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS),
    mapTo(_authPasswordResetUpdatePassword())
  );

export const passwordResetUpdatePasswordResetEpic = action$ =>
  action$.pipe(
    ofType(PASSWORD_RESET_VALIDATE_TOKEN_TRIGGER, AUTH_LOGIN_START, PASSWORD_RESET_TRIGGER_SEND_MAIL),
    mapTo(authPasswordResetUpdatePasswordReset())
  );

export const authEpic = combineEpics(
  triggerRegistrationEpic,
  registerValidationSuccessEpic,
  startRegister,
  triggerResetPasswordEpic,
  passwordResetEmailValidationSuccessEpic,
  passwordResetStartTokenValidationEpic,
  passwordResetStartTokenValidationFromStoreEpic,
  triggerPasswordResetUpdatePasswordEpic,
  passwordResetUpdatePasswordValidationSuccessEpic,
  passwordResetUpdatePasswordResetEpic
);
