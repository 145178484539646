import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";
import { combineReducers } from "redux";
import { mapUpdatePasswordValidationToState } from "./utils";

const baseErrorState = { error: false, errorMsg: "" };
const errorInit = { password: { ...baseErrorState }, repeatPassword: { ...baseErrorState }, hasErrors: false };

const loadingValidation = (state = false, action) => {
  switch (action.type) {
    case aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_FAILURE:
    case aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS:
      return false;

    case aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START:
      return true;

    default:
      return state;
  }
};

const errors = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_FAILURE:
      const errors = mapUpdatePasswordValidationToState(action.payload);

      return {
        ...state,
        hasErrors: errors.hasErrors ? errors.hasErrors : false,
        password: errors.password ? { ...errors.password } : { ...baseErrorState },
        repeatPassword: errors.repeatPassword ? { ...errors.repeatPassword } : { ...baseErrorState }
      };

    case aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS:
    case aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START:
    case aTypes.UPDATE_PASSWORD_RESET_PASSWORD:
    case LOGOUT:
    case aTypes.UPDATE_PASSWORD_RESET_REPEAT_PASSWORD:
      return errorInit;

    default:
      return state;
  }
};

export default combineReducers({ errors, loadingValidation });
