import React, { Fragment, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { APPLICATION_BASE_URL, DEVELOP_EU_LANDING, GEOIP_ACTIVATED } from "../config/settings";
import routes from "./routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import PrivateRoute from "./components/private-route/PrivateRoute";
import CustomLoader from "./components/custom-loader/CustomLoader";

import ReactGA from "react-ga";
import withTracker from "../analytics/withTracker";
import { handleClientStateChange } from "react-helmet/es/HelmetUtils";
import { isDevelopment } from "config/utils";
import { useDispatch, useSelector } from "react-redux";
import { appLoaded } from "redux/state/ui/pages/app";
import { getGeoIPFetchStatus, getGeoIPLocationIpStatus, isContinentEU } from "redux/state/geoip/selectors";
import { GEOIP_FETCH_STATUS, GEOIP_STATUS } from "redux/state/geoip/reducer";

// Initialize Google Analitycs
const initAnalytics = () => {
  // ReactGA.initialize("UA-154743496-1");
  ReactGA.initialize("UA-161763983-1");
};

const App = props => {
  const {
    login,
    register,
    download,
    survey,
    index,
    testPage,
    notFound,
    surveyBackup,
    home,
    passwordReset,
    passwordUpdate,
    passwordResetForm,
    termsAndConditions,
    privacy,
    publicProfile,
    payment,
    loading,
    euIndex,
    account
  } = routes;
  const dispatch = useDispatch();
  const geoIPFetchStatus = useSelector(state => getGeoIPFetchStatus(state));
  const ipStatus = useSelector(state => getGeoIPLocationIpStatus(state));
  const isEU = useSelector(state => isContinentEU(state));
  React.useEffect(() => {
    dispatch(appLoaded());
  }, []);

  const euRoutes = () => <Route exact path={euIndex.route} component={withTracker(euIndex.component)} />;

  const notFoundRoute = () => <Route exact path={euIndex.route} component={withTracker(euIndex.component)} />;

  const allRoutes = () => {
    return (
      <Fragment>
        <PrivateRoute exact path={home.route} component={withTracker(home.component)} />
        <PrivateRoute exact path={surveyBackup.route} component={withTracker(surveyBackup.component)} />
        <Route exact path={privacy.route} component={withTracker(privacy.component)} />
        <Route exact path={publicProfile.route} component={withTracker(publicProfile.component)} />
        <Route exact path={termsAndConditions.route} component={withTracker(termsAndConditions.component)} />
        <Route exact path={testPage.route} component={withTracker(testPage.component)} />
        <Route exact path={passwordResetForm.route} component={withTracker(passwordResetForm.component)} />
        <Route exact path={passwordUpdate.route} component={withTracker(passwordUpdate.component)} />
        <Route exact path={passwordReset.route} component={withTracker(passwordReset.component)} />
        <Route exact path={register.route} component={withTracker(register.component)} />
        <Route exact path={login.route} component={withTracker(login.component)} />
        <Route exact path={survey.route} component={withTracker(survey.component)} />
        <PrivateRoute exact path={download.route} component={withTracker(download.component)} />
        <PrivateRoute exact path={account.route} component={withTracker(account.component)} />
        <Route exact path={index.route} component={withTracker(index.component)} />
      </Fragment>
    );
  };

  const renderRoutes = () => {
    if (GEOIP_ACTIVATED) {
      switch (geoIPFetchStatus) {
        case GEOIP_FETCH_STATUS.NOT_LOADED:
        case GEOIP_FETCH_STATUS.LOADING:
          return <Route path={loading.route} component={withTracker(loading.component)} />;

        case GEOIP_FETCH_STATUS.LOADED:
          switch (ipStatus) {
            case GEOIP_STATUS.SUCCESS:
              return isEU ? euRoutes() : allRoutes();

            case GEOIP_STATUS.DEVELOP:
              return DEVELOP_EU_LANDING ? euRoutes() : allRoutes();

            default:
              return notFoundRoute();
          }

        case GEOIP_FETCH_STATUS.ERROR:
        default:
          return notFoundRoute();
      }
    }
    return DEVELOP_EU_LANDING ? euRoutes() : allRoutes();
  };

  return (
    <Suspense fallback={<loading.component />}>
      <Router basename={APPLICATION_BASE_URL}>
        {initAnalytics()}
        <CssBaseline />
        <Switch>
          {renderRoutes()}
          {isDevelopment() && <Route exact path={payment.route} component={withTracker(payment.component)} />}
          {notFoundRoute()}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
