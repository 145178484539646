import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";
import { combineReducers } from "redux";
import ERROR_SHAPE from "api/error-model";
import {
  STUDY_FETCH_BY_USER_ID_SUCCESS,
  STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS,
  STUDY_FETCH_TOP_RANKS_SUCCESS
} from "redux/state/studies/types";
import { getUserFromStudy, getUserStudyFromStudyRequest } from "redux/state/userStudy/utils";

const byIdInit = {};
const allIdsInit = [];
const loadingInit = { isLoading: false, loaded: false };
const errorInit = ERROR_SHAPE;

const byId = (state = byIdInit, action) => {
  switch (action.type) {
    // case aTypes.AUTOCOM_FETCH_TOP_RANKS_START:
    // case aTypes.AUTOCOM_FETCH_TOP_RANKS_FAILURE:
    //   return state;

    // case aTypes.AUTOCOM_FETCH_TOP_RANKS_SUCCESS:
    //   const key = Object.keys(action.payload)[0];
    //   return { ...state, ...action.payload[key] };
    case STUDY_FETCH_TOP_RANKS_SUCCESS:
    case STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
    case STUDY_FETCH_BY_USER_ID_SUCCESS:
      const users = getUserStudyFromStudyRequest(action);
      return { ...state, ...users };

    case LOGOUT:
      return { ...byIdInit };

    default:
      return state;
  }
};

const allIds = (state = allIdsInit, action) => {
  switch (action.type) {
    // case aTypes.AUTOCOM_FETCH_TOP_RANKS_START:
    // case aTypes.AUTOCOM_FETCH_TOP_RANKS_FAILURE:
    //   return state;

    // case aTypes.AUTOCOM_FETCH_TOP_RANKS_SUCCESS:
    //   const key = Object.keys(action.payload)[0];
    //   const data = action.payload[key];
    //   const keys = Object.keys(data);
    //   return state.indexOf(keys[0]) === -1 ? [...state, ...keys] : state;

    case STUDY_FETCH_TOP_RANKS_SUCCESS:
    case STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
    case STUDY_FETCH_BY_USER_ID_SUCCESS:
      const users = getUserStudyFromStudyRequest(action);
      return [...new Set([...state, ...Object.keys(users)])];

    case LOGOUT:
      return [...allIdsInit];

    default:
      return state;
  }
};

const loading = (state = loadingInit, action) => {
  switch (action.type) {
    // case aTypes.FETCH_COGNITIVE_ABILITIES_START:
    //   return { ...state, isLoading: true };
    //
    // case aTypes.FETCH_COGNITIVE_ABILITIES_FAILURE:
    //   return { ...state, isLoading: false, loaded: false };
    //
    // case aTypes.FETCH_COGNITIVE_ABILITIES_SUCCESS:
    //   return { isLoading: false, loaded: true };

    case LOGOUT:
      return { ...loadingInit };

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    // case aTypes.FETCH_COGNITIVE_ABILITIES_FAILURE:
    //   return { ...state, ...action.payload, error: true };
    //
    // case aTypes.FETCH_COGNITIVE_ABILITIES_START:
    // case aTypes.FETCH_COGNITIVE_ABILITIES_SUCCESS:
    //   return { ...errorInit };

    default:
      return state;
  }
};

export default combineReducers({ allIds, byId, loading, error });
