// LOLHANDLER
import {
  LOLHANDLER_UPDATE_PAGE,
  NAVBAR_MENU_CLOSE,
  NAVBAR_MENU_OPEN,
  NAVBAR_MENU_PERMANENT_FALSE,
  NAVBAR_MENU_PERMANENT_TRUE,
  NAVBAR_MENU_TOGGLE
} from "redux/state/ui/components/types";

export const updateLolHandlerPage = () => ({ type: LOLHANDLER_UPDATE_PAGE });

// NAVBAR
export const closeNavMenu = () => ({ type: NAVBAR_MENU_CLOSE });
export const openNavMenu = () => ({ type: NAVBAR_MENU_OPEN });
export const toggleNavMenu = () => ({ type: NAVBAR_MENU_TOGGLE });
export const navbarMenuPermanentTrue = () => ({ type: NAVBAR_MENU_PERMANENT_TRUE });
export const navbarMenuPermanentFalse = () => ({ type: NAVBAR_MENU_PERMANENT_FALSE });
