import { createStore, applyMiddleware, compose } from "redux";
import { loadState, saveState } from "redux/store/localStorage";
import { createEpicMiddleware } from "redux-observable";

import reducers, { rootEpic } from "redux/state";
import middlewares from "./middlewares";

const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux observable
const observableMiddleware = createEpicMiddleware();

/**
 * Creates the store
 */
const store = createStore(
  reducers,
  persistedState, // optional
  composeEnhancers(applyMiddleware(...middlewares, observableMiddleware))
);

observableMiddleware.run(rootEpic);

/**
 * Save pieces of state in local storage
 */
store.subscribe(() => {
  saveState({
    entities: store.getState().entities,
    auth: store.getState().auth,
    forms: store.getState().forms,
    profile: store.getState().profile,
    prefetch: store.getState().prefetch,
    autocomplete: store.getState().autocomplete,
    sessions: store.getState().sessions,
    geoip: store.getState().geoip
  });
});

export default store;
