import React from "react";
import PropTypes from "prop-types";
import * as typeformEmbed from "@typeform/embed";
import routes from "../../routes";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TYPEFORM_URL } from "../../../config/settings";

const useStyles = makeStyles(theme => ({
  widget: {
    width: "100vw",
    height: "100vh",
    position: "relative",
  }
}));

const SurveyView = props => {
  const classes = useStyles();
  React.useEffect(() => {
    const el = document.getElementById("typeform-wrapper");
    typeformEmbed.makeWidget(el, `${TYPEFORM_URL}${props.email}`, {
      opacity: 0,
      hideHeaders: true,
      hideFooter: true,
      onSubmit: () => props.history.push(props.onSubmit)
    });
  }, []);
  return <div id="typeform-wrapper" className={classes.widget} />;
};

SurveyView.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.string.isRequired
};

export default SurveyView;
