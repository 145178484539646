import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ABILITIES } from "app/i18n/selectors";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  verticalSpacing: {
    padding: theme.spacing(3)
  },
  // TABLE
  tableContainer: {
    overflowX: "scroll",
    [theme.breakpoints.up("sm")]: {
      overflowX: "hidden"
    }
  },
  table: {
    minWidth: "650px",
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px"
    }
  },
  tableRow: {
    display: "flex"
    // flexWrap: "nowrap"
  },
  tableHeader: {
    display: "flex",
    padding: theme.spacing(1, 1, 1, 2),
    alignItems: "center",
    background: "linear-gradient(90deg, rgba(26,20,77,1) 0%, rgba(17,12,62,1) 100%)",
    flex: "0 0 200px",
    [theme.breakpoints.up("md")]: {
      flex: "0 0 230px"
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 250px",
      padding: theme.spacing(2)
    }
  },
  tableContent: {
    padding: theme.spacing(1, 1, 1, 1),
    backgroundColor: "transparent",
    flex: "1 0 auto",
    borderTop: "2px",
    borderLeft: "2px",
    borderBottom: "0px",
    borderStyle: "solid",
    borderColor: "#533bd5",
    borderRightColor: "#533bd5",
    borderRight: "2px",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2)
    }
  },
  tableValue: {
    marginLeft: "auto",
    fontSize: "16px",
    flex: "0 0 45px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      flex: "0 0 52px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      flex: "0 0 65px"
    }
  },
  tableDescription: {
    display: "flex",
    alignItems: "center"
  },
  blueBorderBottom: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#533bd5"
  },
  topRank: {
    color: theme.palette.players.user.main
  }
}));

const AbilitiesTableSectionView = ({ results }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.root}>
      <div className={classes.verticalSpacing} />
      <Grid item xs={12} className={classes.tableContainer}>
        <div className={classes.table}>
          {results.map((result, index) => (
            <div className={classes.tableRow}>
              <div className={classes.tableHeader}>{t(`${ABILITIES}.${result.ability_id}.name`)}</div>
              <div className={clsx(classes.tableContent, { [classes.blueBorderBottom]: results.length - 1 === index })}>
                <div className={classes.tableDescription}>{t(`${ABILITIES}.${result.ability_id}.description`)}</div>
                <div className={clsx(classes.tableValue, { [classes.topRank]: index === 0 })}>{result.value}%</div>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

AbilitiesTableSectionView.propTypes = {};

export default AbilitiesTableSectionView;
