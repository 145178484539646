import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";
import ERROR_SHAPE from "api/error-model";
import { combineReducers } from "redux";

const errorInit = ERROR_SHAPE;
const loadingInit = { isLoading: false, loaded: false };
const byIdInit = {};
const allIdsInit = [];

/**
 * Reducers
 */
const byId = (state = byIdInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_COGNITIVE_ABILITIES_START:
    case aTypes.FETCH_COGNITIVE_ABILITIES_FAILURE:
      return state;

    case aTypes.FETCH_COGNITIVE_ABILITIES_SUCCESS:
      const data = action.payload;
      // return state[Object.keys(data)[0]] ? state : { ...state, ...data };
      return { ...state, ...data };
    //
    // case LOGOUT:
    //   return { ...byIdInit };

    default:
      return state;
  }
};

const allIds = (state = allIdsInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_COGNITIVE_ABILITIES_START:
    case aTypes.FETCH_COGNITIVE_ABILITIES_FAILURE:
      return state;

    case aTypes.FETCH_COGNITIVE_ABILITIES_SUCCESS:
      const data = action.payload;
      const keys = Object.keys(data);
      return state.indexOf(keys[0]) === -1 ? [...state, ...keys] : state;
    //
    // case LOGOUT:
    //   return [...allIdsInit];

    default:
      return state;
  }
};

const loading = (state = loadingInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_COGNITIVE_ABILITIES_START:
      return { ...state, isLoading: true };

    case aTypes.FETCH_COGNITIVE_ABILITIES_FAILURE:
      return { ...state, isLoading: false, loaded: false };

    case aTypes.FETCH_COGNITIVE_ABILITIES_SUCCESS:
      return { isLoading: false, loaded: true };

    case LOGOUT:
      return { ...loadingInit };

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_COGNITIVE_ABILITIES_FAILURE:
      return { ...state, ...action.payload, error: true };

    case aTypes.FETCH_COGNITIVE_ABILITIES_START:
    case aTypes.FETCH_COGNITIVE_ABILITIES_SUCCESS:
      return { ...errorInit };

    default:
      return state;
  }
};

export default combineReducers({ byId, allIds, loading, error });
