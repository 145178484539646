import React from "react";
import { makeStyles } from "@material-ui/core";
import Navbar from "../../components/navbar/Navbar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import Sidebar from "app/components/sidebar/Sidebar";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "relative",
    flexDirection: "column"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  grow: {
    flexGrow: 1,
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: "flex"
  },
  container: {
    padding: theme.spacing(2),
    flex: 1,
    textAlign: "center",
    width: "100%",
    marginBottom: theme.spacing(10)
  },
  cardContent: {
    textAlign: "center",
    width: "100%",
    margin: "auto"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  downloadButton: {
    marginTop: theme.spacing(3)
  }
}));

const DownloadView = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Navbar />
      <div className={classes.drawerHeader} />
      <main className={classes.content}>
        <Container maxWidth={"lg"} className={classes.grow}>
          <Card className={classes.grow}>
            <CardContent className={classes.cardContent}>
              <Grid container component={"section"} className={classes.container}>
                <Grid item xs={12}>
                  <Typography variant={"h4"} className={classes.title}>
                    {t("view.download.title")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h5"} className={classes.subtitle}>
                    {t("view.download.subtitle")}
                  </Typography>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*  <Button*/}
                {/*    disabled*/}
                {/*    className={classes.downloadButton}*/}
                {/*    color={"primary"}*/}
                {/*    variant={"contained"}*/}
                {/*    startIcon={<CloudDownload />}*/}
                {/*  >*/}
                {/*    {t("view.download.download_button")}*/}
                {/*  </Button>*/}
                {/*</Grid>*/}
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </main>
    </div>
  );
};

export default DownloadView;
