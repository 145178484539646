export const PREFETCH_CHECK_STATUS = "PREFETCH_CHECK_STATUS";

export const PREFETCH_STATUS_LOADED = "PREFETCH_STATUS_LOADED";
export const PREFETCH_STATUS_NOT_LOADED = "PREFETCH_STATUS_NOT_LOADED";

export const PREFETCH_ABILITIES = "PREFETCH_ABILITIES";
export const PREFETCH_GAMES = "PREFETCH_GAMES";
export const PREFETCH_REGIONS = "PREFETCH_REGIONS";
export const PREFETCH_SOCIALS = "PREFETCH_SOCIALS";

export const PREFETCH_UPDATE_STATUS_LOADED = "PREFETCH_UPDATE_STATUS_LOADED";
export const PREFETCH_UPDATE_STATUS_LOADING = "PREFETCH_UPDATE_STATUS_LOADING";
export const PREFETCH_UPDATE_STATUS_NOT_LOADED = "PREFETCH_UPDATE_STATUS_NOT_LOADED";
export const PREFETCH_UPDATE_STATUS_ERROR = "PREFETCH_UPDATE_STATUS_ERROR";
