import React from "react";
import PropTypes from "prop-types";
import { Radar } from "ejs-radar";
import { makeStyles } from "@material-ui/core/styles";
import spiderBackground from "app/assets/js/background";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getSpiderProps } from "./spiderProps";
import LabelComponent from "app/components/spider-graph/LabelComponent";
import { useTranslation } from "react-i18next";
import { ABILITIES } from "app/i18n/selectors";
import LabelComponentEmpty
  from "app/components/spider-graph/LabelComponentEmpty";

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    // backgroundColor: "#333",
    margin: "0 auto",
    textAlign: "center"
  },
  center: {
    margin: "0 auto"
  }
}));

const CustomSpider = ({ graphKeys, graphData, withKeys }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const spiderProps = getSpiderProps(withKeys);

  const spiderSizes = () => {
    if (xl) return spiderProps.xl;
    if (lg) return spiderProps.lg;
    if (md) return spiderProps.md;
    if (sm) return spiderProps.sm;
    return spiderProps.xs;
  };

  const data = graphData ? graphData.map(axis => ({ ...axis, ["name"]: t(`${ABILITIES}.${axis.name}.name`) })) : [];

  const commonProperties = {
    ...spiderSizes(),
    indexBy: "name"
  };
  const classes = useStyles();
  const spiderTheme = {
    textColor: "white",
    fontSize: lg ? 12 : 11,
    fontFamily: "Exo",
    tooltip: {
      container: {
        background: "black"
      }
    },
    grid: {
      line: {
        // stroke: "rgba(0,212,255,.3)",
        stroke: "rgba(217, 230, 237, .5)",
        strokeWidth: 1
        // strokeDasharray: "4 4"
      }
    },
    crosshair: {
      line: {
        stroke: "rgba(0,212,255,1)",
        strokeWidth: 3,
        // strokeOpacity: 0.75,
        strokeDasharray: "0"
      },
      staticLine: {
        // stroke: "rgba(0,212,255,.3)",
        stroke: "rgba(217, 230, 237, .5)",
        strokeWidth: 1,
        // strokeOpacity: 0.5,
        strokeDasharray: "0"
      }
    }
  };

  return (
    <div className={classes.container}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="someGradientId" gradientTransform="rotate(20)">
            <stop offset="0%" stopColor="rgba(0,212,255,1)" />
            <stop offset="100%" stopColor="rgba(2,0,36,1)" />
          </linearGradient>
        </defs>
      </svg>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="gradientTwo" gradientTransform="rotate(20)">
            <stop offset="0%" stopColor="rgba(255,0,236,1)" />
            <stop offset="100%" stopColor="rgba(56,1,62,1)" />
          </linearGradient>
        </defs>
      </svg>

      {withKeys && sm ? (
        <Radar
          data={data}
          keys={graphKeys}
          // backgroundComponent={custom}
          className={classes.center}
          gridLevels={5}
          theme={spiderTheme}
          displayInnerGrid={true}
          tooltipFormat={value => `${value}%`}
          colors={["rgba(255, 83, 118, .8)", "rgba(0,212,255,.6)"]}
          {...commonProperties}
          gridLabel={LabelComponent}
          // isInteractive={false}
        />
      ) : (
        <Radar
          data={data}
          keys={graphKeys}
          // backgroundComponent={custom}
          className={classes.center}
          gridLevels={5}
          theme={spiderTheme}
          displayInnerGrid={true}
          tooltipFormat={value => `${value}%`}
          colors={["rgba(255, 83, 118, .8)", "rgba(0,212,255,.6)"]}
          {...commonProperties}
          gridLabel={LabelComponentEmpty}
          // isInteractive={false}
        />
      )}
    </div>
  );
};

CustomSpider.propTypes = {
  graphData: PropTypes.array.isRequired,
  graphKeys: PropTypes.array.isRequired,
  withKeys: PropTypes.bool
};

CustomSpider.defaultProps = {
  withKeys: false
};

export default CustomSpider;
