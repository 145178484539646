import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import logo from "app/assets/svg/logo-eprofile.svg";
import { useHistory } from "react-router-dom";
import routes from "app/routes";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#102341"
  },
  footer: {
    display: "flex",
    height: "100px",
    justifyContent: "center",
    alignItems: "center"
  },
  logo: {
    cursor: "pointer",
    width: "120px",
    [theme.breakpoints.up("md")]: {
      width: "150px"
    }
  }
}));

const MyProfileFooter = props => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickOnLogo = () => history.push(routes.index.route);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <footer className={classes.footer}>
          <img onClick={handleClickOnLogo} src={logo} className={classes.logo} />
        </footer>
      </Grid>
    </Grid>
  );
};

MyProfileFooter.propTypes = {};

export default MyProfileFooter;
