import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import GetAppIcon from "@material-ui/icons/GetApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { useDispatch, useSelector } from "react-redux";
import { getNavbarMenuIsOpen, getNavbarMenuIsPermanent } from "redux/state/ui/components/selectors";
import { closeNavMenu } from "redux/state/ui/components/operations";
import { getProfileCheck } from "redux/state/profile/selectors";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { DRAWER_WIDTH, PROFILE_CHECKS } from "config/constants";
import CompareIcon from "@material-ui/icons/Compare";
import {
  homeAbilitiesSelected,
  homeCognitiveProfileSelected,
  homeDownloadSelected
} from "redux/state/ui/pages/home/operations";
import { getUiHomeComponentsSelected } from "redux/state/ui/pages/home/selectors";
import { homeMyProfileSelected } from "redux/state/ui/pages/home/actions";

const drawerWidth = DRAWER_WIDTH;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: theme.palette.sidebar.backgroundGradient,
    color: theme.palette.getContrastText(theme.palette.primary.main1_light)
  },
  toolbar: theme.mixins.toolbar,
  itemText: {
    fontWeight: "bold"
  },
  selected: {
    background: theme.palette.home.pinkGradientRight
  },
  iconRoot: {
    margin: 0,
    padding: 0,
    minWidth: 35
  },
  listItem: {
    padding: theme.spacing(1)
  }
}));

const Sidebar = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navbarPermanent = useSelector(state => getNavbarMenuIsPermanent(state));
  const navbarOpen = useSelector(state => getNavbarMenuIsOpen(state));
  const profileCheck = useSelector(state => getProfileCheck(state));

  const selectCognitiveProfile = () => {
    dispatch(homeCognitiveProfileSelected());
    handleClose();
  };
  const selectAbilities = () => {
    dispatch(homeAbilitiesSelected());
    handleClose();
  };
  const selectDownload = () => {
    dispatch(homeDownloadSelected());
    handleClose();
  };
  const selectMyProfile = () => {
    dispatch(homeMyProfileSelected());
    handleClose();
  };

  const selected = useSelector(state => getUiHomeComponentsSelected(state));

  const handleClose = () => {
    dispatch(closeNavMenu());
  };

  const renderState = [
    { text: "Mi eProfile", icon: <AccountCircleRoundedIcon />, action: selectMyProfile, order: 1 },
    { text: "Compárate", icon: <CompareArrowsIcon />, action: selectCognitiveProfile, order: 2 },
    { text: "Prueba de habilidades", icon: <GetAppIcon />, action: selectDownload, order: 3 },
    { text: "Info y neurociencia", icon: <SettingsIcon />, action: selectAbilities, order: 4 }
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant={navbarPermanent ? "permanent" : "temporary"}
      open={navbarOpen}
      onClose={handleClose}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />
      <List>
        {profileCheck === PROFILE_CHECKS.SUCCESS
          ? renderState.map((obj, index) => (
              <ListItem
                onClick={obj.action}
                selected={selected === obj.order}
                classes={{ selected: classes.selected, root: classes.listItem }}
                button
                key={obj.text}
              >
                <ListItemIcon classes={{ root: classes.iconRoot }}>{obj.icon}</ListItemIcon>
                <ListItemText classes={{ primary: classes.itemText }} primary={obj.text} />
              </ListItem>
            ))
          : ["Descarga"].map((text, index) => (
              <ListItem selected={index === 0} classes={{ selected: classes.selected }} button key={text}>
                <ListItemIcon classes={{ root: classes.iconRoot }}>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.itemText }} primary={text} />
              </ListItem>
            ))}
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
