import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RegisterView from "app/pages/register/RegisterView";
import RedirectAuthenticated from "../../components/redirect-authenticated/RedirectAuthenticated";
import routes from "../../routes";
import { getAuthAuthenticated, getAuthRegister } from "../../../redux/state/auth/selectors";
import { getRegisterFragmentList, getRegisterFragmentSelected } from "redux/state/ui/pages/register/selectors";
import { _fetchRegions } from "redux/state/regions/operations";
import { checkPrefetch } from "redux/state/prefetch/operations";
import { registerPageLoaded } from "redux/state/ui/pages/register/actions";

const Register = props => {
  const fragment = useSelector(state => getRegisterFragmentSelected(state));
  const fragmentList = useSelector(state => getRegisterFragmentList(state));
  const authenticated = useSelector(state => getAuthAuthenticated(state));
  const registerState = useSelector(state => getAuthRegister(state));
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(registerPageLoaded());
  }, []);

  return authenticated ? (
    <RedirectAuthenticated redirectTo={routes.download.route} location={props.location} />
  ) : (
    <RegisterView registerState={registerState} fragment={fragment} fragmentList={fragmentList} />
  );
};

export default Register;
