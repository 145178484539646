import { ofType } from "redux-observable";
import * as actions from "./actions";
import { mapTo } from "rxjs/operators";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile";
import { getProfileUserId } from "redux/state/profile/selectors";
import { HOME_COMPONENTS_LOADED } from "redux/state/ui/pages/home/types";
import { SESSION_FINISH_HOME_RESULTS_TOUR } from "redux/state/sessions/types";

const _saveUser = () => (dispatch, getState) => {
  const userId = getProfileUserId(getState());
  dispatch(actions.sessionSaveUser(userId));
};

const _saveUserInHomeResults = () => (dispatch, getState) => {
  const userId = getProfileUserId(getState());
  dispatch(actions.sessionSaveUserInHomeResults(userId));
};

const _saveHomeResultsTourFinished = () => (dispatch, getState) => {
  const userId = getProfileUserId(getState());
  dispatch(actions.sessionSaveHomeResultsTourFinished(userId));
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const saveUserEpic = action$ => action$.pipe(ofType(PROFILE_CHECK_SUCCESS), mapTo(_saveUser()));

export const saveUserInHomeResultsEpic = action$ =>
  action$.pipe(ofType(HOME_COMPONENTS_LOADED), mapTo(_saveUserInHomeResults()));

export const homeResultsTourFinishedEpic = action$ =>
  action$.pipe(ofType(SESSION_FINISH_HOME_RESULTS_TOUR), mapTo(_saveHomeResultsTourFinished()));
