import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AvatarInformation from "app/components/avatar-information/AvatarInformation";
import PlayerSearchForm from "app/pages/home/compare/spider-section/player-information/player-search-form/PlayerSearchForm";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import { useMediaQuery } from "@material-ui/core";
import {
  autocompleteProps,
  playerSearchFormProps,
  userInfoProps
} from "app/pages/home/compare/spider-section/spiderProps";

const withStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "relative"
  },
  [theme.breakpoints.up("sm")]: {
    paper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  [theme.breakpoints.up("md")]: {
    paper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  spacing: {
    marginBottom: theme.spacing(2)
  }
}));

const PlayerInformation = ({ disabled, isUser, formState, avatarInfoState, autocompleteItems, reactour }) => {
  const classes = withStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid container className={classes.paper} data-tut={reactour}>
      <AvatarInformation isUser={isUser} avatarInfoState={avatarInfoState} disabled={disabled}/>

      {!xs && <div className={classes.spacing} />}

      {!xs && (
        <PlayerSearchForm
          autocompleteItems={autocompleteItems}
          formState={formState}
          isUser={isUser}
          disabled={disabled}
        />
      )}
    </Grid>
  );
};

PlayerInformation.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isUser: PropTypes.bool,
  formState: PropTypes.shape(playerSearchFormProps),
  avatarInfoState: PropTypes.shape(userInfoProps),
  autocompleteItems: PropTypes.shape(autocompleteProps),
  reactour: PropTypes.string
};

PlayerInformation.defaultProps = {
  disabled: false,
  isUser: false,
  reactour: ""
};

export default PlayerInformation;
