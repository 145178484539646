import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import GrayInput from "app/components/CustomInput/GrayInput";
import LightRedButton from "app/components/custom-button/LighRedButton";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  passwordResetFormEmail,
  passwordResetEmailFormValidateEmailErrors,
} from "redux/state/forms/password-reset/selectors";
import { updatePasswordResetEmail } from "redux/state/forms/password-reset/actions";
import { useTranslation } from "react-i18next";
import { REGISTER_FORM, REGISTER_VALIDATION_ERR } from "app/i18n/selectors";
import { authPasswordResetTriggerSendMail } from "redux/state/auth/actions";

const useStyles = makeStyles(theme => ({
  group: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(5),
    textAlign: "center"
  },
  sendButton: {
    marginTop: theme.spacing(5),
    borderRadius: 0
  }
}));

const PasswordResetMailForm = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const email = useSelector(state => passwordResetFormEmail(state));
  const emailErrors = useSelector(state => passwordResetEmailFormValidateEmailErrors(state));

  const emailLabel = t(`${REGISTER_FORM}.email.placeholder_password_reset`);
  const emailHint = t(`${REGISTER_FORM}.email.hint`);

  const setErrorMsg = () =>
    emailErrors.error ? t(`${REGISTER_VALIDATION_ERR}.email.${emailErrors.errorMsg}`) : emailHint;

  const changeEmail = e => {
    dispatch(updatePasswordResetEmail(e.target.value));
  };

  const onCLickSend = () => {
    dispatch(authPasswordResetTriggerSendMail());
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      dispatch(authPasswordResetTriggerSendMail());
    }
  };

  return (
    <Grid item xs={12} sm={12} className={classes.group}>
      <GrayInput
        error={emailErrors.error}
        variant={"outlined"}
        label={emailLabel}
        fullWidth
        helperText={setErrorMsg()}
        value={email}
        onKeyPress={handleKeyPress}
        onChange={changeEmail}
      />
      <LightRedButton onClick={onCLickSend} className={classes.sendButton}>
        Enviar
      </LightRedButton>
    </Grid>
  );
};

PasswordResetMailForm.propTypes = {};

export default PasswordResetMailForm;
