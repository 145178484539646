import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import LightGreenButton from "app/components/custom-button/LightGreenButton";
import PlayerSearchForm from "app/pages/home/compare/spider-section/player-information/player-search-form/PlayerSearchForm";
import CustomInputAutocomplete from "app/components/CustomInput/CustomInputAutocomplete";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";

const withStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  mdFlex: {
    flexDirection: "column",
    alignItems: "center"
  },
  xsSpace: {
    height: theme.spacing(2)
  },
  againButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      textAlign: "center"
    }
  }
}));

const SpiderCallToActionView = ({ repeatText, sm, xs, autocompleteItems, formState }) => {
  const classes = withStyles();
  return (
    <Grid xs={12} className={clsx(classes.buttonContainer, { [classes.mdFlex]: sm || xs })}>
      {/* ONLY ON MOBILE */}
      {/*{xs && (*/}
      {/*  <Grid container>*/}
      {/*    <Grid item xs={12} style={{padding: "20px 0px"}}>*/}
      {/*      <Button*/}
      {/*        fullWidth*/}
      {/*        className={classes.profileButton}*/}
      {/*        variant={"contained"}*/}
      {/*        color={"secondary"}*/}
      {/*        component={RouterLink}*/}
      {/*        to={`${routes.publicProfile.resource}`}*/}
      {/*      >*/}
      {/*        eProfile Público*/}
      {/*      </Button>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*)}*/}
      {xs && (
        <PlayerSearchForm autocompleteItems={autocompleteItems} formState={formState} isUser={false} disabled={false} />
      )}

      {/*<div className={clsx(classes.space, { [classes.xsSpace]: xs })} />*/}
      {/*<Button fullWidth={xs} className={classes.button} variant={"contained"} color={"secondary"}>*/}
      {/*  {unblockText}*/}
      {/*</Button>*/}

      {/*{!xs && (*/}
      <Grid container>
        <Grid item xs={12} className={classes.againButton}>
          <LightGreenButton fullWidth={xs} disabled variant={"contained"}>
            {repeatText}
          </LightGreenButton>
        </Grid>
      </Grid>
      {/*)}*/}
    </Grid>
  );
};

SpiderCallToActionView.propTypes = {
  unblockText: PropTypes.string,
  repeatText: PropTypes.string,
  compareText: PropTypes.string
};

SpiderCallToActionView.defaultProps = {
  unblockText: "Unblock",
  repeatText: "Repeat",
  compareText: "Compare"
};

export default SpiderCallToActionView;
