import React, { useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { lighten, makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CustomSelect from "app/components/custom-select/CustomSelect";
import CustomInputAutocomplete from "app/components/CustomInput/CustomInputAutocomplete";
import { autocompleteProps } from "app/pages/home/compare/spider-section/spiderProps";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  opacity: {
    opacity: 0
  },
  form: {
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  button: {
    background: theme.palette.home.blueGradientRight,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
      opacity: 0.7
    },
    "&:disabled": {
      background: lighten(theme.palette.home.lightBlue, 0.25)
    }
  }
}));

const PlayerSearchFormView = ({
  disabled,
  games,
  regions,
  selectNickname,
  handleChangeNickname,
  byIdIdName,
  byIdValueName,
  isUser,
  autocompleteItems
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const variant = useMediaQuery(theme.breakpoints.down("xs")) ? "body2" : "h6";

  const isDisabled = isUser || disabled;

  return (
    <Grid container className={clsx(classes.container, { [classes.opacity]: isUser })}>
      <Grid xs={12} className={classes.container}>
        <Typography variant={variant} className={classes.title}>
          {"Selecciona oponente"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomInputAutocomplete
          fullWidth
          variant={"outlined"}
          value={selectNickname}
          autocompleteItems={autocompleteItems}
          change={handleChangeNickname}
          byIdIdName={byIdIdName}
          byIdValueName={byIdValueName}
          // data={games}
          disabled={isDisabled}
          label={"Escribe nombre o juego"}
          helperText={'Ejemplo: "Conifeliz", "LoL"'}
        />
      </Grid>
    </Grid>
  );
};

PlayerSearchFormView.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleChangeNickname: PropTypes.func.isRequired,
  autocompleteItems: PropTypes.shape(autocompleteProps)
};

PlayerSearchFormView.defaultProps = {
  disabled: false
};

export default PlayerSearchFormView;
