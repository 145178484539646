import React from "react";
import PropTypes from "prop-types";
import MyProfileView from "app/components/my-profile/MyProfileView";

const MyProfile = ({ isPublic }) => {
  return <MyProfileView isPublic={isPublic} />;
};

MyProfile.propTypes = {
  isPublic: PropTypes.bool
};

MyProfile.defaultProps = {
  isPublic: false
};

export default MyProfile;
