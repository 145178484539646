import React from "react";
import { withStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

const BootstrapPurpleInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    // zIndex: -10,
    position: "relative",
    // backgroundColor: theme.palette.primary.main2_light,
    backgroundColor: "transparent",
    color: theme.palette.getContrastText(theme.palette.primary.main2_light),
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.home.lightBlue,
    fontSize: 16,
    fontWeight: 600,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#7363DD",
      boxShadow: "0 0 0 0.07rem #7363DD"
    }
  }
}))(InputBase);

export default BootstrapPurpleInput;
