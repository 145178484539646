import { combineReducers } from "redux";
import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";

/**
 * Init States
 */
// const byIdInit = {};
// const allIdsInit = [];
// const loadingInit = { isLoading: false, loaded: false };
// const valueInit = "";
const initState = "";

const userGame = (state = initState, action) => {
  switch (action.type) {
    case aTypes.COMPARISON_UPDATE_USER_GAME:
      return action.payload;

    case aTypes.COMPARISON_RESET_USER_GAME:
    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

const userRegion = (state = initState, action) => {
  switch (action.type) {
    case aTypes.COMPARISON_UPDATE_USER_REGION:
      return action.payload;

    case aTypes.COMPARISON_RESET_USER_REGION:
    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

const userNickname = (state = initState, action) => {
  switch (action.type) {
    case aTypes.COMPARISON_UPDATE_USER_NICKNAME:
      return action.payload;

    case aTypes.COMPARISON_RESET_USER_NICKNAME:
    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

const comparedGame = (state = initState, action) => {
  switch (action.type) {
    case aTypes.COMPARISON_UPDATE_COMPARED_GAME:
      return action.payload;

    case aTypes.COMPARISON_RESET_COMPARED_GAME:
    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

const comparedRegion = (state = initState, action) => {
  switch (action.type) {
    case aTypes.COMPARISON_UPDATE_COMPARED_REGION:
      return action.payload;

    case aTypes.COMPARISON_RESET_COMPARED_REGION:
    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

const comparedNickname = (state = initState, action) => {
  switch (action.type) {
    case aTypes.COMPARISON_UPDATE_COMPARED_NICKNAME:
      return action.payload;

    case aTypes.COMPARISON_RESET_COMPARED_NICKNAME:
    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default combineReducers({
  userGame,
  userRegion,
  userNickname,
  comparedGame,
  comparedRegion,
  comparedNickname
});
