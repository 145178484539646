import * as aTypes from "./types";
import { combineReducers } from "redux";
import { LOGOUT } from "redux/state/auth";

const userComparisonInit = { region: "", game: "", nickname: "", profileImage: null };

const compare = (state = userComparisonInit, action) => {
  switch (action.type) {
    case aTypes.USER_COMPARISON_UPDATE_COMPARED_GAME:
      return { ...state, game: action.payload };

    case aTypes.USER_COMPARISON_UPDATE_COMPARED_REGION:
      return { ...state, region: action.payload };

    case aTypes.USER_COMPARISON_UPDATE_COMPARED_NICKNAME:
      return { ...state, nickname: action.payload };

    case aTypes.USER_COMPARISON_UPDATE_COMPARED_PROFILE_IMAGE:
      return { ...state, profileImage: action.payload };

    case LOGOUT:
      return { ...userComparisonInit };
    default:
      return state;
  }
};

export default combineReducers({ compare });
