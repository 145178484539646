import React from "react";
import PropTypes from "prop-types";
import CustomLoader from "app/components/custom-loader/CustomLoader";

import HomePageTemplate from "app/components/profile-page-template/HomePageTemplate";

const Loading = props => {
  return (
    <HomePageTemplate>
      <CustomLoader absolute={true} />
    </HomePageTemplate>
  );
};

Loading.propTypes = {};

export default Loading;
