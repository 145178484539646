import { LOADING } from "config/constants";
import { LOGOUT } from "redux/state/auth";
import ERROR_SHAPE from "api/error-model";

/**
 * This function creates a reducer with basic structure for async calls.
 * It follows the convention: ACTION_TYPE_START
 *                            ACTION_TYPE_SUCCESS
 *                            ACTION_TYPE_FAILURE
 * Also contains a loading state and a LOGOUT response.
 *
 * By default LOGOUT resets the state to its initial shape, but it can be customized
 * by passing the option withLogout: false into the configuration object
 *
 * It can receive an initial state by passing initState object or array.
 *
 * Additionally it can receive an array of objects with the structure {type, handler(state, action)}
 * to add additional behaviour to control the state.
 *
 * @param {string} actionType
 * @param {Object|Array} initState
 * @param {boolean} withLogout
 * @param {Array.<{type: string, handler: function(state, action)}>} extraActions
 * @returns {function(...[*]=)}
 */
const createAsyncReducer = (actionType, { initState = {}, withLogout = true }, extraActions = []) => {
  const startAction = `${actionType}_START`;
  const successAction = `${actionType}_SUCCESS`;
  const failureAction = `${actionType}_FAILURE`;

  return (state = { ...initState, loading: LOADING.INITIAL, error: ERROR_SHAPE }, action) => {
    if (action.type === startAction) {
      return {
        ...state,
        loading: LOADING.STARTED,
        error: ERROR_SHAPE
        // [property]: {
        //   ...state[property]
        // }
      };
    } else if (action.type === successAction) {
      return {
        ...state,
        ...action.payload,
        loading: LOADING.SUCCESS,
        error: ERROR_SHAPE
        // [property]: {
        //   ...state[property],
        //
        // }
      };
    } else if (action.type === failureAction) {
      return {
        ...state,
        loading: LOADING.FAILURE,
        error: action.payload
        // [property]: {
        //   ...state[property]
        // }
      };
    } else if (action.type === LOGOUT && withLogout) {
      return {
        ...{ ...initState, loading: LOADING.INITIAL, error: ERROR_SHAPE }
        // [property]: {
        //   ...state[property]
        // }
      };
    } else if (extraActions.length >= 1) {
      let newState = null;
      extraActions.forEach(extraAction => {
        if (action.type === extraAction.type) {
          newState = extraAction.handler(state, action);
        }
      });
      if (newState) {
        return newState;
      }
    }

    return state;
  };
};

export default createAsyncReducer;
