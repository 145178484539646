export const paper = theme => ({
  // backgroundColor: "transparent",
  color: theme.palette.getContrastText(theme.palette.primary.main1_light),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  padding: theme.spacing(4)
});

export const sectionContainer = theme => ({
  paddingTop: theme.spacing(2)
});

export const title = theme => ({
  title: {
    fontWeight: "bold",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4)
    }
  },
  titleBackground: {
    color: theme.palette.getContrastText(theme.palette.primary.main3_light)
  }
});
