import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import PropTypes from "prop-types";
import GreyBootstrapInput
  from "app/components/bootstrap-input/GrayBootstrapInput";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  label: {
    fontSize: 14
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  select: {
    "&:focus": {
      // Show that it's not an text input
      borderRadius: 0, // Reset Chrome style,
      backgroundColor: theme.palette.primary.main_dark5
    }
  }
}));

const WhiteSelect = ({ disabled, label, data, onChange, value, helperText, fullWidth, error }) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth={fullWidth} disabled={disabled} error={error}>
      <InputLabel
        className={classes.label}
        classes={{focused: classes.labelFocused}}
        style={{color: "white"}}
        variant={"outlined"}
        htmlFor="demo-customized-select-native"
      >
        {label}
      </InputLabel>
      <NativeSelect
        error={error}
        value={value}
        classes={{ icon: classes.icon, select: classes.select }}
        id="demo-customized-select-native"
        input={<GreyBootstrapInput />}
        onChange={onChange}
      >
        <option value="" />
        {data.map(value => (
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        ))}
      </NativeSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

WhiteSelect.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }))
    .isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string
};

WhiteSelect.defaultProps = {
  label: "Custom Select",
  disabled: false,
  fullWidth: false
};

export default WhiteSelect;
