import { API_URL, AUTH_SERVER } from "config/settings";

// GAMES
export const GAMES = `${API_URL}/games`;
export const REGIONS_ALL = `${AUTH_SERVER}/games/regions`;

// SUMMONER NAME
export const SUMMONER_BY_NAME = (region, summonerName) => `${AUTH_SERVER}/games/lol/${region}/summoner/${summonerName}`;

// AUTH
export const LOGIN = `${AUTH_SERVER}/user/login`;
export const REGISTER = `${AUTH_SERVER}/user/create`;
export const VALIDATE_TOKEN = `${AUTH_SERVER}/user/token/validate`;
export const PASSWORD_RESET = `${AUTH_SERVER}/user/password-reset`;
export const PASSWORD_RESET_VALIDATE_TOKEN = `${AUTH_SERVER}/user/password-reset/validate`;
export const PASSWORD_RESET_UPDATE_PASSWORD = `${AUTH_SERVER}/user/password-reset`;

// AUTH USER
export const UPLOAD_IMAGE = `${AUTH_SERVER}/user/profile-image`;
export const PROFILE = `${AUTH_SERVER}/user/profile`;
export const PUBLIC_PROFILE = username => `${AUTH_SERVER}/public/profile/${username}`;
export const PROFILE_UNLOCK = `${AUTH_SERVER}/user/profile/unlock`;
// STUDY
export const STUDY_BY_ID = studyId => `${API_URL}/study/results/${studyId}`;

export const STUDY_BY_USER_IDS = userIds => `${API_URL}/study/results/user/${userIds}`;

export const STUDY_BY_USER_ID = userId => `${API_URL}/study/user/${userId}`;
export const STUDY_BY_CURRENT_USER = `${API_URL}/study/results`;

// SCORE
export const SEARCH_TOP_RANKS = scoreType => `${API_URL}/ranks/${scoreType}`;
export const SEARCH_TOP_RANKS_BY_GAME = (scoreType, gameId) => `${API_URL}/ranks/${scoreType}/game/${gameId}`;
export const SEARCH_BY_TERM = (scoreType, term) => `${API_URL}/ranks/${scoreType}/${term}`;

// COGNITIVE_ABILITIES
export const ABILITIES_ALL = `${API_URL}/cognitive-abilities`;

// NOTIFICATIONS_BY_USER
export const NOTIFICATIONS_BY_USER = userId => `${API_URL}/notifications/user/${userId}`;
export const NOTIFICATIONS_IS_READ = (userId, notificationId) =>
  `${API_URL}/notifications/${notificationId}/user/${userId}/read`;

// GEOIP
export const GEO_IP = `${API_URL}/geo`;

// SOCIALS
export const SOCIALS = `${API_URL}/socials`;
export const SOCIALS_BY_USER_ID = user_id => `${API_URL}/socials/user/${user_id}`;
export const SOCIALS_BY_USER = `${SOCIALS}/user`
