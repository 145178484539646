import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Navbar from "app/components/navbar/Navbar";
import Grid from "@material-ui/core/Grid";

import Sidebar from "app/components/sidebar/Sidebar";
import Compare from "app/pages/home/compare/Compare";
import { PROFILE_CHECKS } from "config/constants";
import DownloadLogged from "app/pages/home/download-logged/DownloadLogged";
import VerifyUser from "app/pages/home/verify-user/VerifyUser";
import HomeError from "app/pages/home/home-error/HomeError";
import CognitiveAbilities from "app/pages/home/cognitive-abilities/CognitiveAbilities";
import MyProfile from "app/components/my-profile/MyProfile";
import MyProfilePage from "app/pages/home/my-profile-page/MyProfilePage";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    overflow: "hidden"
  },
  content: {
    position: "relative",
    height: "100vh",
    flexGrow: 1,
    // padding: theme.spacing(4),
    overflowX: "hidden",
    overflowY: "auto"
  },
  toolbar: theme.mixins.toolbar,
  containerSpacing: {
    // marginTop: theme.spacing(2)
  },
  background: {
    zIndex: -10,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100vh",
    backgroundImage: theme.palette.home.backgroundGradient
  }
}));

const HomeView = ({ selected, profileCheck }) => {
  const classes = useStyles();
  const renderState = [
    <HomeError />,
    <MyProfilePage isPublic={false} />,
    <Compare />,
    <DownloadLogged checkProfile={profileCheck} />,
    <CognitiveAbilities />
  ];

  return (
    <div className={classes.root}>
      <Navbar />
      <Sidebar />
      <div className={classes.background} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* Content */}
        <Grid container className={classes.overflow}>
          {renderState[selected]}
          {/*<Compare />,*/}
        </Grid>
      </main>
    </div>
  );
};

HomeView.propTypes = {
  titleText: PropTypes.string.isRequired
};

export default HomeView;
