import createAsyncReducer from "redux/lib/async-reducer";
import {
  FETCH_GEOIP,
  GEOIP_UPDATE_STATUS_ERROR,
  GEOIP_UPDATE_STATUS_LOADED,
  GEOIP_UPDATE_STATUS_LOADING,
  GEOIP_UPDATE_STATUS_NOT_LOADED
} from "redux/state/geoip/types";
import { combineReducers } from "redux";
import { GEOIP_ACTIVATED } from "config/settings";

const locationInit = { continentCode: "US", ipStatus: "" };

export const GEOIP_FETCH_STATUS = {
  NOT_LOADED: "NOT_LOADED",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
  INACTIVE: "INACTIVE"
};
export const GEOIP_STATUS = {
  DEVELOP: "DEVELOP",
  NO_SUBSCRIPTION: "NO_SUBSCRIPTION",
  NOT_VALID: "NOT_VALID",
  IP_INVALID: "IP_INVALID",
  IP_NOT_FOUND: "IP_NOT_FOUND",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};
const statusInit = GEOIP_ACTIVATED ? GEOIP_FETCH_STATUS.NOT_LOADED : GEOIP_FETCH_STATUS.INACTIVE;

const location = createAsyncReducer(FETCH_GEOIP, { initState: locationInit, withLogout: false });

const status = (state = statusInit, action) => {
  switch (action.type) {
    case GEOIP_UPDATE_STATUS_NOT_LOADED:
      return GEOIP_FETCH_STATUS.NOT_LOADED;

    case GEOIP_UPDATE_STATUS_LOADED:
      return GEOIP_FETCH_STATUS.LOADED;

    case GEOIP_UPDATE_STATUS_LOADING:
      return GEOIP_FETCH_STATUS.LOADING;

    case GEOIP_UPDATE_STATUS_ERROR:
      return GEOIP_FETCH_STATUS.ERROR;

    default:
      return state;
  }
};

export default combineReducers({ location, status });
