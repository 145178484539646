export const FETCH_PUBLIC_PROFILE = "FETCH_PUBLIC_PROFILE";
export const FETCH_PUBLIC_PROFILE_START = "FETCH_PUBLIC_PROFILE_START";
export const FETCH_PUBLIC_PROFILE_SUCCESS = "FETCH_PUBLIC_PROFILE_SUCCESS";
export const FETCH_PUBLIC_PROFILE_FAILURE = "FETCH_PUBLIC_PROFILE_FAILURE";

export const PUBLIC_PROFILE_CHECK = "PUBLIC_PROFILE_CHECK";
export const PUBLIC_PROFILE_CHECK_START = "PUBLIC_PROFILE_CHECK_START";
export const PUBLIC_PROFILE_CHECK_VERIFY_USER = "PUBLIC_PROFILE_CHECK_VERIFY_USER";
export const PUBLIC_PROFILE_CHECK_FINISH_TESTS = "PUBLIC_PROFILE_CHECK_FINISH_TESTS";
export const PUBLIC_PROFILE_CHECK_FAILURE = "PUBLIC_PROFILE_CHECK_FAILURE";
export const PUBLIC_PROFILE_CHECK_SUCCESS = "PUBLIC_PROFILE_CHECK_SUCCESS";