import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";

const TwitchIcon = ({ color, ...other }) => {
  return (
    <SvgIcon {...other} viewBox="0 0 50 50" color={color}>
      <path d="M14.79,4.88,7.5,12.05V37.88h9v7.24L24,37.88h5.38L42.5,25V4.88ZM39.5,23l-5.75,5.87H27.57L22.5,34V28.88h-6v-22h23Z" />
      <rect x="32.5" y="12.88" width="3" height="9" />
      <rect x="24.5" y="12.88" width="3" height="9" />
    </SvgIcon>
  );
};

TwitchIcon.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "inherit", "disabled"])
};

TwitchIcon.defaultProps = {
  color: "inherit"
};

export default TwitchIcon;
