import { combineReducers } from "redux";
import { LOGOUT } from "redux/state/auth/types";
import * as aTypes from "./types";
import { REGISTER_TOGGLE_LOLSELECTED } from "redux/state/ui/pages/register/types";
import ERROR_SHAPE from "api/error-model";
import { mapLolInfoToState } from "redux/state/game-stats/utils";

/**
 * INITIAL STATE
 */
const byIdInit = {};
const allIdsInit = [];
const loadingInit = { isLoading: false, loaded: false };
const errorInit = ERROR_SHAPE;

const byId = (state = byIdInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_SUMMONER_INFO_SUCCESS:
      const payload = mapLolInfoToState(state, action);
      return {
        ...state,
        [payload.summoner.account_info.accountId]: payload
      };

    case aTypes.FETCH_SUMMONER_INFO_FAILURE:
    case aTypes.FETCH_SUMMONER_INFO_START:
    case LOGOUT:
    case REGISTER_TOGGLE_LOLSELECTED:
      return { ...byIdInit };

    default:
      return state;
  }
  /**
   * REDUCERS
   */
};

const allIds = (state = allIdsInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_SUMMONER_INFO_SUCCESS:
      const payload = mapLolInfoToState(state, action);
      return [...new Set([...state, payload.summoner.account_info.accountId])];

    case aTypes.FETCH_SUMMONER_INFO_FAILURE:
    case aTypes.FETCH_SUMMONER_INFO_START:
    case LOGOUT:
    case REGISTER_TOGGLE_LOLSELECTED:
      return [...allIdsInit];

    default:
      return state;
  }
};

const loading = (state = loadingInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_SUMMONER_INFO_START:
      return { ...state, isLoading: true };

    case aTypes.FETCH_SUMMONER_INFO_SUCCESS:
    case aTypes.FETCH_SUMMONER_INFO_FAILURE:
      return { isLoading: false, loaded: true };

    case LOGOUT:
      return { ...loadingInit };

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_SUMMONER_INFO_FAILURE:
      return { ...state, ...action.payload, error: true };

    case aTypes.FETCH_SUMMONER_INFO_START:
    case aTypes.FETCH_SUMMONER_INFO_SUCCESS:
    case REGISTER_TOGGLE_LOLSELECTED:
      return { ...errorInit };

    default:
      return state;
  }
};

/**
 * EXPORT DEFAULT
 */
export default combineReducers({ byId, allIds, loading, error });
