import * as actions from "./actions";
import api from "api";
import { authSelectors } from "redux/state/auth";
import { mapStudyResponseToState, mapStudyTopRanksToState } from "redux/state/studies/utils";
import { ofType } from "redux-observable";
import { mapTo, mergeMap, map, concatMap } from "rxjs/operators";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile";
import {
  AUTOCOM_FETCH_BY_TERM_SUCCESS,
  AUTOCOM_FETCH_TOP_RANKS_SUCCESS,
  AUTOCOM_STUDY_SEARCH_COMPARE_SELECT_SUCCESS
} from "redux/state/autocomplete/study-search/types";
import { getStudySeachAutocomComparedSelected } from "redux/state/autocomplete/study-search/selectors";
import { setSelectedCompareStudyByAutocomplete } from "./actions";
import { setSelectedCompareStudyByAutocompleteSuccess } from "./actions";
import { of } from "rxjs";
import { getStudyIdByAutocompleteComparedSelected } from "redux/state/userStudy/selectors";
import { validateStudyExistenceStart } from "./actions";
import { validateStudyExistenceSuccess } from "./actions";
import { validateStudyExistenceFailure } from "./actions";
import {
  STUDY_FETCH_BY_USER_ID_SUCCESS,
  STUDY_VALIDATE_STUDY_EXISTENCE_FAILURE,
  STUDY_VALIDATE_STUDY_EXISTENCE_SUCCESS
} from "redux/state/studies/types";
import { bad_format, not_found } from "redux/errors";
import routes from "app/routes";
import { getPublicProfile, getPublicProfileUserID } from "redux/state/public-profile/selectors";
import { PUBLIC_PROFILE_CHECK_SUCCESS } from "redux/state/public-profile/types";

/**
 * This action creator fetches the study compare of the current user.
 * @private
 */
const _fetchUserStudy = () => async (dispatch, getState) => {
  if (window.location.pathname.includes(routes.home.resource))
    dispatch(
      actions.fetchUserStudy(api.study.getUserResults(authSelectors.getAuthToken(getState())), mapStudyResponseToState)
    );
  if (window.location.pathname.includes(routes.publicProfile.resource)) {
    const publicUserID = getPublicProfileUserID(getState());
    dispatch(actions.fetchStudyByUserId(api.study.getByUserId(publicUserID), mapStudyResponseToState));
  }
};

/**
 * This action creator fetches the study of a certain user.
 * @private
 */
const _fetchStudyByAutocompSelectedComparedId = () => async (dispatch, getState) => {
  const state = getState();
  const autocompleteComparedSelectedId = getStudySeachAutocomComparedSelected(state);

  if (autocompleteComparedSelectedId !== "")
    dispatch(
      actions.fetchStudyByUserId(api.study.getByUserId(autocompleteComparedSelectedId), mapStudyResponseToState)
    );
};

const _fetchTopRankStudies = asd => async (dispatch, getState) => {
  const game = Object.keys(asd)[0];
  let userIds = [];
  if (game && game !== "") {
    userIds = Object.keys(asd[game]);
  }
  dispatch(
    actions.fetchTopRankStudies(
      api.study.getByIds(userIds.join(";"), authSelectors.getAuthToken(getState())),
      mapStudyTopRanksToState
    )
  );
};

const _setSelectComparedStudy = () => (dispatch, getState) => {
  const state = getState();
  const comparedSelected = getStudyIdByAutocompleteComparedSelected(state);
  dispatch(setSelectedCompareStudyByAutocomplete(comparedSelected.study_id));
  dispatch(setSelectedCompareStudyByAutocompleteSuccess());
};

const _setSelectUserStudy = () => (dispatch, getState) => {
  const state = getState();
  const comparedSelected = getStudySeachAutocomComparedSelected(state);
  dispatch(setSelectedCompareStudyByAutocomplete(comparedSelected));
  dispatch(setSelectedCompareStudyByAutocompleteSuccess());
};

const validateStudy = () => (dispatch, getState) => {
  const state = getState();
  dispatch(validateStudyExistenceStart());
  const autocompleteComparedSelected = getStudySeachAutocomComparedSelected(state);
  const comparedStudySelected = getStudyIdByAutocompleteComparedSelected(state);
  if (autocompleteComparedSelected && autocompleteComparedSelected !== "") {
    if (comparedStudySelected && typeof comparedStudySelected == "object" && "study_id" in comparedStudySelected) {
      dispatch(validateStudyExistenceSuccess());
    } else {
      dispatch(validateStudyExistenceFailure(bad_format));
    }
  } else {
    dispatch(validateStudyExistenceFailure(not_found));
  }
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/

/**
 * This epic start the process of fetching the compare of the current user by dispatching a
 * _fetchUserStudy action. It gets triggered by a PROFILE_CHECK_SUCCESS
 * @returns {*}
 */
export const fetchUserStudyEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_CHECK_SUCCESS, PUBLIC_PROFILE_CHECK_SUCCESS),
    concatMap(a => of(_fetchUserStudy()))
  );

export const fetchTopRanksStudyEpic = action$ =>
  action$.pipe(
    ofType(AUTOCOM_FETCH_TOP_RANKS_SUCCESS),
    map(action => _fetchTopRankStudies(action.payload))
  );

// TODO: CHANGE VALIDATION FROM APLICAR BUTTON
export const validateStudyExistanceEpic = action$ =>
  action$.pipe(
    ofType(AUTOCOM_STUDY_SEARCH_COMPARE_SELECT_SUCCESS, AUTOCOM_FETCH_BY_TERM_SUCCESS, STUDY_FETCH_BY_USER_ID_SUCCESS),
    mapTo(validateStudy())
  );

export const setStudyComparedSelectedEpic = action$ =>
  action$.pipe(ofType(STUDY_VALIDATE_STUDY_EXISTENCE_SUCCESS), mapTo(_setSelectComparedStudy()));

export const studyValidationFailureEpic = action$ =>
  action$.pipe(ofType(STUDY_VALIDATE_STUDY_EXISTENCE_FAILURE), mapTo(_fetchStudyByAutocompSelectedComparedId()));
