import { createSelector } from "reselect";

const geoIP = state => state.geoip;

export const getGeoIPFetchStatus = state => geoIP(state).status;
export const getGeoIPLocation = state => geoIP(state).location;
export const getGeoIPLocationCode = state => getGeoIPLocation(state).continentCode;
export const getGeoIPLocationIpStatus = state => getGeoIPLocation(state).ipStatus;
const continentEU = () => "EU";
export const isContinentEU = createSelector([getGeoIPLocationCode, continentEU], (code, euCode) => code === euCode);
