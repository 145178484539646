import { combineReducers } from "redux";
import {
  PASSWORD_RESET_VIEW_CLOSE_TOAST,
  PASSWORD_RESET_VIEW_OPEN_TOAST
} from "redux/state/ui/pages/password-reset/types";
import { LOGOUT } from "redux/state/auth";
import { PASSWORD_RESET_SEND_EMAIL_START } from "redux/state/auth/types";

const passwordResetInit = false;

const displayToast = (state = passwordResetInit, action) => {
  switch (action.type) {
    case PASSWORD_RESET_VIEW_OPEN_TOAST:
      return true;

    case PASSWORD_RESET_VIEW_CLOSE_TOAST:
    case PASSWORD_RESET_SEND_EMAIL_START:
    case LOGOUT:
      return false;

    default:
      return state;
  }
};

export default combineReducers({ displayToast });
