import {
  passwordResetStartEmailFormValidation,
  updatePasswordResetEmail,
  passwordResetEmailFormValidationSuccess,
  passwordResetEmailFormValidationFailure,
  passwordResetStartUpdatePasswordFormValidation,
  passwordResetUpdatePasswordFormValidationFailure,
  passwordResetUpdatePasswordFormValidationSuccess
} from "./actions";
import {
  passwordResetFormEmail,
  passwordResetFormPassword,
  passwordResetFormRepeatPassword
} from "redux/state/forms/password-reset/selectors";
import {
  validateRegisterConfirmPassword,
  validateRegisterEmail,
  validateRegisterPassword
} from "redux/state/forms/validation";

export const validatePasswordResetEmailForm = () => (dispatch, getState) => {
  dispatch(passwordResetStartEmailFormValidation());
  const state = getState();
  const email = passwordResetFormEmail(state);

  const errors = {
    email: validateRegisterEmail(email)
  };

  if (errors.email) {
    dispatch(passwordResetEmailFormValidationFailure(errors));
  } else {
    dispatch(passwordResetEmailFormValidationSuccess());
  }
};

export const validatePasswordResetUpdatePasswordForm = () => (dispatch, getState) => {
  dispatch(passwordResetStartUpdatePasswordFormValidation());
  const state = getState();
  const password = passwordResetFormPassword(state);
  const repeatPassword = passwordResetFormRepeatPassword(state);

  const errors = {
    password: validateRegisterPassword(password, repeatPassword),
    repeatPassword: validateRegisterConfirmPassword(password, repeatPassword)
  };

  if (errors.password || errors.repeatPassword) {
    dispatch(passwordResetUpdatePasswordFormValidationFailure(errors));
  } else {
    dispatch(passwordResetUpdatePasswordFormValidationSuccess());
  }
};

export { updatePasswordResetEmail };
