import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";

const DiscordIcon = ({ color, ...other }) => {
  return (
    <SvgIcon {...other} viewBox="0 0 50 50" color={color}>
      <path d="M38.88,4.28H11.12A4.25,4.25,0,0,0,6.87,8.55v28a4.25,4.25,0,0,0,4.25,4.27h23.5L33.52,37l2.65,2.47,2.51,2.32,4.45,3.93V8.55A4.25,4.25,0,0,0,38.88,4.28Zm-8,27.06s-.75-.89-1.37-1.68a6.53,6.53,0,0,0,3.75-2.46,12,12,0,0,1-2.38,1.22,14.34,14.34,0,0,1-3,.89,14.6,14.6,0,0,1-5.37,0,17.68,17.68,0,0,1-3-.89A12.73,12.73,0,0,1,18,27.69l-.18-.1-.09-.06c-.37-.21-.58-.35-.58-.35a6.49,6.49,0,0,0,3.63,2.44c-.62.79-1.39,1.72-1.39,1.72A7.5,7.5,0,0,1,13,28.19a27.71,27.71,0,0,1,3-12.08,10.24,10.24,0,0,1,5.82-2.18l.21.25a14,14,0,0,0-5.45,2.72s.45-.25,1.22-.6A15.41,15.41,0,0,1,22.51,15a2.2,2.2,0,0,1,.36,0A16.94,16.94,0,0,1,33.29,16.9a13.71,13.71,0,0,0-5.16-2.63l.29-.34a10.24,10.24,0,0,1,5.82,2.18,27.71,27.71,0,0,1,3,12.08A7.56,7.56,0,0,1,30.89,31.34Zm-9.64-9.68A2.31,2.31,0,1,0,23.36,24a2.21,2.21,0,0,0-2.11-2.3m7.56,0A2.31,2.31,0,1,0,30.93,24a2.21,2.21,0,0,0-2.12-2.3" />
    </SvgIcon>
  );
};

DiscordIcon.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "inherit", "disabled"])
};

DiscordIcon.defaultProps = {
  color: "inherit"
};

export default DiscordIcon;
