import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.home.backgroundGradient,
    width: "100vw",
    height: "100vh",
    display: "flex"
  }
}));

const FullPageTemplate = ({ children, ...other }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

FullPageTemplate.propTypes = {};

export default FullPageTemplate;
