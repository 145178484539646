import { ga } from "react-ga";

export const mapGamesToState = ({ data }) => {
  let games = data.games;
  return games.reduce((obj, game) => {
    obj[game.game_id] = game.name === "Other" ? {...game, name: "Otro"} : game
    return obj;
  }, {});
};


export const sortObjectByName = (games) => {
  const keys = Object.keys(games);
  const sortable = []
  keys.forEach(key => {
    sortable.push([games[key].game_id, games[key].name])
  })
  sortable.sort((a, b) =>{
    return a[1] < b[1] ? -1 : b[1] > a[1] ? 1 : 0
  })
  return sortable.map(arr => arr[0])
}
