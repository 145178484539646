import * as aTypes from "./types";

// LOGIN
export const authLogin = (promise, transform) => ({
  type: aTypes.AUTH_LOGIN,
  payload: promise,
  meta: { transform }
});

// REGISTER
export const authRegister = (promise, transform) => ({
  type: aTypes.REGISTER,
  payload: promise,
  meta: { transform }
});

export const authStartRegistrationProcess = () => ({ type: aTypes.START_REGISTRATION_PROCESS });

// RESET PASSWORD
export const authPasswordResetTriggerSendMail = () => ({ type: aTypes.PASSWORD_RESET_TRIGGER_SEND_MAIL });
export const authPasswordResetSendMail = promise => ({
  type: aTypes.PASSWORD_RESET_SEND_EMAIL,
  payload: promise
});

export const authPasswordResetTriggerUpdatePassword = () => ({ type: aTypes.PASSWORD_RESET_TRIGGER_UPDATE_PASSWORD });
export const authPasswordResetUpdatePasswordReset = () => ({ type: aTypes.PASSWORD_RESET_UPDATE_PASSWORD_RESET });
export const authPasswordResetUpdatePassword = promise => ({
  type: aTypes.PASSWORD_RESET_UPDATE_PASSWORD,
  payload: promise
});

export const authPasswordResetValidateTokenTrigger = token => ({
  type: aTypes.PASSWORD_RESET_VALIDATE_TOKEN_TRIGGER,
  payload: token
});

export const authPasswordResetValidateToken = promise => ({
  type: aTypes.PASSWORD_RESET_VALIDATE_TOKEN,
  payload: promise
});

// Validate
export const authValidateToken = (promise, transform) => ({
  type: aTypes.VALIDATE_TOKEN,
  payload: promise,
  meta: { transform }
});

// UPDATE PROFILE IMAGE
export const authRegisterUpdateProfileImageStart = () => ({
  type: aTypes.REGISTER_UPDATE_PROFILE_IMAGE_START
});
export const authRegisterUpdateProfileImageSuccess = () => ({
  type: aTypes.REGISTER_UPDATE_PROFILE_IMAGE_SUCCESS
});
export const authRegisterUpdateProfileImageFailure = error => ({
  type: aTypes.REGISTER_UPDATE_PROFILE_IMAGE_FAILURE,
  error: true,
  payload: error
});
