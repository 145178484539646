import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LabelIcon from "@material-ui/icons/Label";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Container, useMediaQuery } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import SvgRay from "app/assets/svg/ray-01.svg";
import SvgBrain from "app/assets/svg/cerebro-01.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    fontWeight: "bold"
  },
  paragraph: {
    marginBottom: theme.spacing(4)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  iconGroup: {
    display: "flex",
    marginBottom: theme.spacing(4)
  },
  icon: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  iconContainer: {
    flex: "0 0 30px"
  },
  iconCenterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(3)
  },
  iconCenter: {
    width: "80px",
    height: "80px"
  },
  visibleTextContainer: {
    padding: theme.spacing(2, 2, 0, 2)
  },
  abilityContainer: {
    padding: theme.spacing(0)
  },
  card: {
    backgroundColor: theme.palette.home.cardBackground,
    marginBottom: theme.spacing(4)
  },
  abilityTitle: {
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  abilityDescription: {
    fontSize: "1em",
    marginBottom: theme.spacing(2)
  },
  expansion: {
    backgroundColor: theme.palette.home.cardExpansionBackground
  },
  expansionTitleContainer: {
    padding: theme.spacing(2)
  },
  expansionTitle: {
    fontSize: "1.1em",
    fontWeight: "bold"
  },
  expansionDetails: {
    display: "block",
    padding: theme.spacing(2)
  },
  [theme.breakpoints.up("sm")]: {
    iconContainer: {
      flex: "0 0 40px",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    }
  },
  [theme.breakpoints.up("md")]: {
    iconContainer: {
      flex: "0 0 60px",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    abilityContainer: {
      padding: theme.spacing(2)
    },
    visibleTextContainer: {
      padding: theme.spacing(4)
    },
    iconCenter: {
      width: "120px",
      height: "120px"
    }
  },
  [theme.breakpoints.up("lg")]: {
    title: {
      fontSize: "1.8em",
      padding: theme.spacing(0, 4)
    },
    iconGroup: {
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(6)
    },
    iconCenter: {
      width: "150px",
      height: "150px"
    },
    description: {
      fontSize: "1.2em"
    },
    abilityContainer: {
      padding: theme.spacing(2, 4)
    },
    abilityTitle: {
      fontSize: "1.5em"
    },
    abilityDescription: {
      fontSize: "1.1em",
      marginBottom: theme.spacing(4)
    },
    expansionTitleContainer: {
      padding: theme.spacing(2, 4)
    },
    expansionTitle: {
      fontSize: "1.4em"
    },
    expansionDetails: {
      padding: theme.spacing(0, 4, 4, 4)
    }
  },
  [theme.breakpoints.up("xl")]: {
    abilityContainer: {
      padding: theme.spacing(2, 8)
    },
    abilityTitle: {
      fontSize: "1.6em",
      marginBottom: theme.spacing(2)
    },
    abilityDescription: {
      fontSize: "1.1em",
      marginBottom: theme.spacing(4)
    },
    expansionTitle: {
      fontSize: "1.5em"
    }
  }
}));

const CognitiveAbilitiesView = ({ title, subtitle, abilities }) => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const getCurrentQuery = () => {
    if (xl) return "xl";
    if (lg) return "lg";
    if (md) return "md";
    return "";
  };
  const smHeight = {
    md: 250,
    lg: 300,
    xl: 300
  };
  const mdHeight = {
    md: 300,
    lg: 340,
    xl: 340
  };
  const lgHeight = {
    md: 380,
    lg: 380,
    xl: 380
  };

  const sizes = {
    sm: { value: 0, height: smHeight[getCurrentQuery()] },
    md: { value: 1, height: mdHeight[getCurrentQuery()] },
    lg: { value: 2, height: lgHeight[getCurrentQuery()] }
  };

  const setSize = (currentIndex, abilities) => {
    const isLastInRow = (currentIndex + 1) % 2 === 0;

    if (!isLastInRow && abilities.length - 1 === currentIndex) {
      return sizes[abilities[currentIndex].size]
    }

    return isLastInRow
      ? sizes[abilities[currentIndex].size].value > sizes[abilities[currentIndex - 1].size].value
        ? sizes[abilities[currentIndex].size]
        : sizes[abilities[currentIndex - 1].size]
      : sizes[abilities[currentIndex].size].value > sizes[abilities[currentIndex + 1].size].value
      ? sizes[abilities[currentIndex].size]
      : sizes[abilities[currentIndex + 1].size];
  };
  return (
    <Container maxWidth={"lg"}>
    <Grid item component={"section"} xs={12} className={classes.container}>
      {/* Title */}
      <Typography variant={"h6"} className={classes.title}>
        {title}
      </Typography>

      <Grid item xs={12} className={classes.iconGroup}>
        <div className={classes.iconContainer}>
          <img src={SvgRay} className={classes.icon} />
        </div>
        <Typography className={classes.description} color={"textPrimary"} variant={"body1"}>
          Las funciones cognitivas son procesos mentales que nos permiten hacer infinidad de cosas, por ejemplo, ahora
          mismo mientras lees, estás utilizando varias de ellas, como atención, memoria, procesamiento de significados y
          procesamiento cognitivo en paralelo en caso que además estés escuchando música. Las capacidades cognitivas
          emergen de la habilidad que tiene nuestro cerebro para coordinar distintas redes neuronales especializadas y
          asi funcionar y trabajar con la información que adquirimos de nuestro ambiente.
        </Typography>
      </Grid>

      {/* Subtitle */}
      <Typography variant={"h6"} className={classes.title}>
        {subtitle}
      </Typography>

      <Grid item xs={12} className={classes.iconGroup}>
        <div className={classes.iconContainer}>
          <img src={SvgRay} className={classes.icon} />
        </div>
        <Typography className={classes.description} color={"textPrimary"} variant={"body1"}>
          Diversos estudios han evidenciado diferencias entre videojugadores y personas que no juegan videojuegos que se
          observan en variadas áreas de la cognición, como la memoria de trabajo, la velocidad de procesamiento, la
          distinción figura-fondo, tiempos de reacción y atención visoespacial. Es importante notar que estas
          diferencias se explican producto de la interacción entre la práctica de jugar videojuegos y la plasticidad
          cognitiva. Es decir, los videojugadores tienen mejores resultados en las mediciones anteriores producto de que
          han jugado videojuegos. Es esta acción repetida en el tiempo la que les ha ayudado a entrenar y desarrollar
          aquellas habilidades que son necesarias para jugar videojuegos. Esto es análogo al deporte y al desarrollo de
          los músculos: un velocista, por ejemplo, va a desarrollar más fuerza en los músculos de las piernas puesto que
          son justamente aquellos los que debe entrenar para desempeñarse mejor en la actividad objetivo de correr; por
          el contrario, un lanzador de jabalina debe desarrollar más los músculos de los brazos para desempeñarse en la
          actividad objetivo.
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.iconGroup}>
        <div className={classes.iconContainer}>
          <img src={SvgRay} className={classes.icon} />
        </div>
        {/*<LabelIcon className={classes.icon} />*/}
        <Typography className={classes.description} color={"textPrimary"} variant={"body1"}>
          Las demandas cognitivas de los videojuegos pueden ser muy distintas dependiendo de su género. Videojuegos de
          acción, por ejemplo, generalmente requieren de respuestas rápidas por parte del jugador dado que la naturaleza
          misma de dicha actividad es, en esencia, la acción; los videojuegos de puzles, por otro lado, posiblemente
          requieran de respuestas rápidas del jugador, pero esta no constituye la destreza principal requerida por el
          jugador, si no que es mas fuerte la influencia de otras habilidades, como la memoria de trabajo y la velocidad
          de procesamiento. Además, si bien este ultimo genero a veces requieren respuestas aceleradas, es poco probable
          que requieran una coordinación visomotora tan compleja, flexible y consistentemente alta como se requiere en
          los juegos de acción.
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.iconCenterContainer}>
        <img src={SvgBrain} className={classes.iconCenter} />
      </Grid>

      <Grid container>
        {abilities.map((ability, index) => (
          <Grid item md={6} xs={12} key={ability.name} className={classes.abilityContainer}>
            <div className={classes.card}>
              <div
                style={{ height: setSize(index, abilities).height ? `${setSize(index, abilities).height}px` : "" }}
                className={classes.visibleTextContainer}
              >
                <Typography className={classes.abilityTitle}>{ability.name}/</Typography>
                <Typography className={classes.abilityDescription}>{ability.description}</Typography>
              </div>
              <ExpansionPanel className={classes.expansion}>
                <ExpansionPanelSummary
                  className={classes.expansionTitleContainer}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.expansionTitle}>{ability.extras.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionDetails}>
                  {ability.extras.paragraphs.map((paragraph, index) => (
                    <Typography className={classes.abilityDescription} key={index}>
                      {paragraph}
                    </Typography>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
    </Container>
  );
};

CognitiveAbilitiesView.propTypes = {
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleDescription1: PropTypes.string.isRequired,
  subtitleDescription2: PropTypes.string.isRequired,
  subtitleDescription3: PropTypes.string.isRequired
};

export default CognitiveAbilitiesView;
