import React from "react";
import PropTypes from "prop-types";
import Navbar from "app/components/navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import fondo_color from "app/assets/img/fondo_color.png";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "relative",
    backgroundImage: `url(${fondo_color})`,
    backgroundSize: "cover"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    paddingBottom: theme.spacing(2)
  },
  headGradient: {
    backgroundImage: "linear-gradient(to right, #ED6292 0%,#B2013F 11%,#5A42EC 100%)",
    width: "100%",
    height: "10px"
  }
}));

const AuthPageTemplate = ({ className, headGradient = true, children, ...other }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...other}>
      <Navbar />
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        {headGradient && <div className={classes.headGradient} />}
        {children}
      </main>
    </div>
  );
};

AuthPageTemplate.propTypes = {};

export default AuthPageTemplate;
