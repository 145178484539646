import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

// Reducers
import uiReducer from "./ui";
import formsReducer from "./forms";
import authReducer from "./auth";
import profileReducer from "./profile";
import regionReducer from "./regions";
import autocompleteReducer from "./autocomplete";
import userStudyReducer from "./userStudy";
import prefetchReducer from "./prefetch";
import gamesReducer from "./games";
import gameStatsReducer from "./game-stats";
import studiesReducer from "./studies";
import cognitiveAbilitiesReducer from "./cognitive-abilities";
import userComparisonReducer from "./user-comparison";
import publicProfileReducer from "./public-profile";
import sessionsReducer from "./sessions";
import notificationsReducer from "./notifications";
import geoipReducer from "./geoip";
import socialNetworksReducer from "./social-networks";

// Epics
import { authEpic } from "./auth";
import { autocompleteEpic } from "./autocomplete";
import { fetchCognitiveAbilitiesEpic } from "./cognitive-abilities";
import { formEpics } from "./forms";
import { gamesEpic } from "./games";
import { prefetchEpic } from "./prefetch";
import { profileEpic } from "./profile";
import { regionsEpic } from "./regions";
import { studiesEpic } from "./studies";
import { userComparisonEpic } from "./user-comparison";
import { viewEpic } from "./ui";
import { publicProfileEpic } from "./public-profile";
import { sessionsEpic } from "./sessions";
import { notificationsEpic } from "./notifications";
import { geoipEpic } from "./geoip";
import { socialNetworksEpic } from "./social-networks";

export const rootEpic = combineEpics(
  authEpic,
  autocompleteEpic,
  fetchCognitiveAbilitiesEpic,
  formEpics,
  gamesEpic,
  geoipEpic,
  notificationsEpic,
  prefetchEpic,
  profileEpic,
  publicProfileEpic,
  regionsEpic,
  sessionsEpic,
  socialNetworksEpic,
  studiesEpic,
  userComparisonEpic,
  viewEpic
);

const entitiesReducer = combineReducers({
  cognitiveAbilities: cognitiveAbilitiesReducer,
  games: gamesReducer,
  gameStats: gameStatsReducer,
  regions: regionReducer,
  studies: studiesReducer,
  userComparison: userComparisonReducer,
  userStudy: userStudyReducer,
  notifications: notificationsReducer,
  socialNetworks: socialNetworksReducer
});

// EXPORTED REDUCER
export default combineReducers({
  auth: authReducer,
  autocomplete: autocompleteReducer,
  entities: entitiesReducer,
  forms: formsReducer,
  geoip: geoipReducer,
  prefetch: prefetchReducer,
  profile: profileReducer,
  publicProfile: publicProfileReducer,
  sessions: sessionsReducer,
  ui: uiReducer,
  userComparison: userComparisonReducer
});
