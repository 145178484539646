import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getPublicProfileGraphState, getUserGraphState } from "redux/state/studies/selectors";
import CustomSpider from "app/components/spider-graph/CustomSpider";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    flex: "1 1 100%",
    // backgroundColor: "slateblue",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(0),
      flex: "1"
    },
    [theme.breakpoints.up("md")]: {
      flex: "1 1 60%"
    }
  },
  spiderContainer: {
    width: "100%",
    margin: "auto"
  }
}));

const SpiderSection = ({ isPublic }) => {
  const classes = useStyles();
  const graphData = useSelector(state => (isPublic ? getPublicProfileGraphState(state) : getUserGraphState(state)));
  const graphKeys = Object.keys(graphData && graphData[0] || []).filter(key => key !== "name");

  return (
    <div className={classes.root}>
      <div className={classes.spiderContainer}>
        <CustomSpider withKeys graphData={graphData} graphKeys={graphKeys} />
      </div>
    </div>
  );
};

SpiderSection.propTypes = {};

export default SpiderSection;
