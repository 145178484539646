import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "@material-ui/core/styles/styled";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import BootstrapPurpleInput from "app/components/bootstrap-input/BootstrapPurpleInput";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { setSingleValidationSchema } from "config/validation";
import NativeSelect from "@material-ui/core/NativeSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { resetUpdateProfile } from "redux/state/profile/actions";

const withStyles = makeStyles(theme => ({
  container: {
    width: "100%"
  },
  wrapper: {
    display: "flex",
    width: "100%"
  }
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1, 0)
}));

const FormLabelContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: "0 0 120px",
  [theme.breakpoints.up("md")]: {
    flex: "0 0 180px"
  }
}));

const FormInputContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  flex: 1,
  alignItems: "center"
}));

const FormEditContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0 60px",
  position: "relative"
}));

const FormSubmitLoading = styled(({ children, ...other }) => (
  <CircularProgress {...other} children={children} color={"secondary"} size={24} />
))({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -2
});

const FormFont = styled(({ children, ...other }) => <Typography component={"p"} {...other} children={children} />)(
  ({ theme }) => ({
    fontSize: 14,
    letterSpacing: 0.9,
    [theme.breakpoints.up("md")]: {
      fontSize: 16
    }
  })
);

const FormContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column"
}));

const Form = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%"
}));

const ErrorMessage = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  paddingRight: "60px",
  justifyContent: "flex-end"
}));

const FormButton = styled(({ children, ...other }) => (
  <Button {...other} children={children} color={"secondary"} variant={"text"} />
))(({ theme }) => ({
  textTransform: "none",
  padding: theme.spacing(0, 0, 0, 0),
  paddingTop: "1px",
  margin: 0,
  minWidth: 0,
  marginLeft: "auto"
}));

const FormRow = ({
  label,
  value,
  editableButtonText,
  notEditableButtonText,
  onSave,
  name,
  type,
  withForm,
  data,
  submitState
}) => {
  const classes = withStyles();
  const [isEditable, setEditable] = React.useState(false);
  const [labelHeight, setHeight] = React.useState(36);
  const dispatch = useDispatch();
  const matchForm = name === submitState.field;
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      [name]: ""
    },
    onSubmit: values => {
      if (onSave) onSave(values);
      formik.resetForm();
    },
    validationSchema: setSingleValidationSchema(t, name)
  });

  React.useEffect(() => {
    if (isEditable && matchForm) dispatch(resetUpdateProfile());
  }, [isEditable]);

  // Submitting from submit state
  React.useEffect(() => {
    if (matchForm && submitState.loading.submitting) {
      formik.setSubmitting(true);
      return;
    }
    if (submitState.loading.submitted) {
      formik.setSubmitting(false);
      if (matchForm && submitState.error.isError) {
        let message = "Ha ocurrido un error, inténtalo nuevamente.";
        switch (submitState.error.code) {
          case 409:
            message = "El usuario ingresado ya existe";
            break;

          case 404:
          case 500:
            break;
        }
        formik.setFieldError(name, message);
        return;
      }
      handleCancel();
    }
  }, [submitState.loading]);

  const handleEdit = () => {
    setEditable(true);
  };

  const handleSave = () => {
    if (!formik.errors[name]) {
      formik.submitForm();
    }
  };

  const handleClick = () => {
    if (isEditable) {
      handleSave();
    } else {
      handleEdit();
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      // formik.submitForm();
      handleClick();
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setEditable(false);
  };

  return (
    <Container>
      <ClickAwayListener onClickAway={handleCancel}>
        <Collapse
          enter={true}
          exit={true}
          classes={{ container: classes.container, wrapper: classes.wrapper, wrapperInner: classes.wrapper }}
          in={isEditable}
          collapsedHeight={labelHeight}
        >
          <FormContainer>
            <Form>
              <FormLabelContainer>
                <FormFont>{label}</FormFont>
              </FormLabelContainer>
              <FormInputContainer>
                {isEditable && withForm ? (
                  <Fade in={isEditable} timeout={200}>
                    {type === "select" ? (
                      <NativeSelect
                        name={name}
                        type={type}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                        fullWidth
                        input={<BootstrapPurpleInput />}
                      >
                        <option value="" />
                        {data.map(item => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </NativeSelect>
                    ) : (
                      <BootstrapPurpleInput
                        name={name}
                        onKeyPress={handleKeyPress}
                        type={type}
                        value={formik.values[name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                        size={"small"}
                        variant={"outlined"}
                        fullWidth
                      />
                    )}
                  </Fade>
                ) : (
                  <FormFont>{value}</FormFont>
                )}
              </FormInputContainer>
              <FormEditContainer>
                {matchForm && submitState.loading.submitting ? (
                  <FormSubmitLoading />
                ) : (
                  <FormButton disabled={!withForm || submitState.loading.submitting} onClick={handleClick}>
                    {isEditable ? editableButtonText : notEditableButtonText}
                  </FormButton>
                )}
              </FormEditContainer>
            </Form>

            {withForm && (
              <ErrorMessage>
                {(formik.touched[name] || submitState.loading.submitted) && formik.errors[name] && (
                  <Typography color={"error"} variant={"caption"}>
                    {formik.errors[name]}
                  </Typography>
                )}
              </ErrorMessage>
            )}
          </FormContainer>
        </Collapse>
      </ClickAwayListener>
    </Container>
  );
};

FormRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email", "password", "select"]),
  onSave: PropTypes.func,
  editableButtonText: PropTypes.string,
  notEditableButtonText: PropTypes.string,
  withForm: PropTypes.bool
};

FormRow.defaultProps = {
  editableButtonText: "guardar",
  notEditableButtonText: "editar",
  name: "username",
  type: "text",
  withForm: true
};

export default FormRow;
