import React, { useEffect } from "react";

import LoginView from "./LoginView";
import { useDispatch, useSelector } from "react-redux";
import RedirectAuthenticated from "../../components/redirect-authenticated/RedirectAuthenticated";
import { getAuthAuthenticated, getAuthLoading } from "../../../redux/state/auth/selectors";
import { loginUiOPS } from "redux/state/ui/pages/login";

const Login = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginUiOPS.loginPageLoaded());
  }, []);

  const loading = useSelector(state => getAuthLoading(state));
  const authenticated = useSelector(state => getAuthAuthenticated(state));

  return authenticated ? <RedirectAuthenticated location={props.location} /> : <LoginView loading={loading} />;
};

export default Login;
