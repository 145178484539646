import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import EprofileResultsSection from "app/pages/home/compare/eprofile-results/EprofileResultsSection";
import SpiderSection from "app/pages/home/compare/spider-section/SpiderSection";
import Tour from "reactour";
import { useDispatch, useSelector } from "react-redux";
import { getUIHomeAreComponentsLoaded } from "redux/state/ui/pages/home/selectors";
import useTheme from "@material-ui/core/styles/useTheme";
import { hasCurrentUserFinishedHomeTour } from "redux/state/sessions/selectors";
import { sessionFinishHomeResultsTour } from "redux/state/sessions/actions";
import { Container, useMediaQuery } from "@material-ui/core";

const steps = [
  // {
  //   selector: '[data-tut="reactour__results"]',
  //   content:
  //     "¡Felicidades por terminar todas las pruebas! Estos son tus puntajes cognitivos. Mira cuál es " +
  //     "tu mejor habilidad, en qué puedes mejorar y cuál es tu lugar en el ranking.",
  //   style: {
  //     color: "black"
  //   },
  //   stepInteraction: false
  // },
  {
    selector: '[data-tut="reactour__user-profile"]',
    content: "Este es tu perfil, ¡Personalizalo con tu imagen!",
    style: {
      color: "black"
    },
    stepInteraction: true
  },
  {
    selector: '[data-tut="reactour__spider"]',
    content: "Revisa tus resultados según cada habilidad cognitiva. ",
    style: {
      color: "black"
    }
  },
  {
    selector: '[data-tut="reactour__compare"]',
    content: "Desbloquea para conocer los resultados de otros jugadores y compara tus habilidades cognitivas.",
    style: {
      color: "black"
    },
    stepInteraction: false
  },
  {
    selector: '[data-tut="reactour__public-profile"]',
    content: "Haz clic y accede a tu eProfile público. ¡Comparte tus resultados donde sea!",
    style: {
      color: "black"
    },
  }
];

const Compare = props => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(true);
  const [step, setStep] = React.useState(0);
  const componentsLoaded = useSelector(state => getUIHomeAreComponentsLoaded(state));
  const tourFinished = useSelector(state => hasCurrentUserFinishedHomeTour(state));
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const closeTour = () => {
    if (step === steps.length - 1) {
      dispatch(sessionFinishHomeResultsTour());
      setIsOpen(false);
    }
  };

  const handleStepChange = index => {
    setStep(index);
  };
  return (
    <Container disableGutters maxWidth={"lg"}>
      {/* Stats section */}
      {/*<Grid item component={"section"} xs={12}>*/}
      {/*  <EprofileResultsSection />*/}
      {/*</Grid>*/}
      <br/>
      {/* Spider section */}
      <Grid item component={"section"} xs={12}>
        <SpiderSection />
      </Grid>

      {/* TOUR */}
      {componentsLoaded && !tourFinished && (
        <Tour
          getCurrentStep={handleStepChange}
          rounded={5}
          accentColor={theme.palette.players.user.main}
          steps={steps}
          isOpen={isOpen}
          onRequestClose={closeTour}
        />
      )}
    </Container>
  );
};

Compare.propTypes = {};

export default Compare;
