import * as aTypes from "./types";
import acFactory from "ejs-acfactory";

// REGISTER
export const setRegisterFragmentNext = () => ({ type: aTypes.REGISTER_FRAGMENT_NEXT });
export const setRegisterFragmentBack = () => ({ type: aTypes.REGISTER_FRAGMENT_BACK });
export const setRegisterFragmentReset = () => ({ type: aTypes.REGISTER_FRAGMENT_RESET });
export const toggleRegisterLolSelected = () => ({ type: aTypes.REGISTER_TOGGLE_LOLSELECTED });

export const registerPageLoaded = acFactory(aTypes.REGISTER_PAGE_LOADED);
