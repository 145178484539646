import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import NavbarButtonGroup from "app/components/navbar/NavbarButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Typography from "@material-ui/core/Typography";
import Divider from "app/components/divider/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import { MEDIA_URL } from "config/settings";
import avatar from "app/assets/img/profile.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";
import AppBar from "@material-ui/core/AppBar/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { logoExceptions } from "app/components/navbar/utils";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import logo from "app/assets/svg/logo-eprofile.svg";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NotificationList from "app/components/notification-list/NotificationList";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ListItemText } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  logo: {
    width: "120px"
  },
  avatar: {
    background: theme.palette.getContrastText(theme.palette.primary.light)
  },
  avatarIcon: {
    padding: theme.spacing(1)
  },
  appBar: {
    backgroundColor: theme.palette.appbar.main
  },
  navbarBackground: {
    backgroundColor: "transparent",
    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard
    })
  },
  navbarBackgroundOnScroll: {
    backgroundColor: "rgba(0,0,0, .2)",
    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard
    })
  },
  menu: {
    background: theme.palette.gradients.darkBlue
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  menuItemTitle: {
    paddingBottom: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  listItemIcon: {
    minWidth: "32px"
  },
  hide: {
    display: "none"
  },
  username: {
    padding: theme.spacing(0, 0, 0, 1),
    fontWeight: "bold"
  },
  verticalDivider: {
    backgroundColor: "white",
    borderColor: "white"
  },
  expandIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer"
  },
  menuItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: "14px"
  },
  logoContainer: {
    margin: !logoExceptions.includes(window.location.pathname) ? "auto" : "0",
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      margin: "0"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(28)
    }
  }
}));

const NavbarView = ({
  t,
  anchorEl,
  name,
  username,
  navbarPermanent,
  profileImage,
  open,
  authenticated,
  isRootUrl,
  isNotRootUrl,
  isRootAndNotScrolled,
  isRootAndScrolled,
  handleLogout,
  handleProfileMenu,
  handleCloseProfileMenu,
  setHomeResults,
  toggleMenu,
  notificationEl,
  unreadNotifications,
  isNotificationOpen,
  handleNotificationElClick,
  handleNotificationElClose
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const renderLogo = () =>
    isNotRootUrl() && (
      <Box
        className={classes.logoContainer}
        component={RouterLink}
        to={authenticated ? routes.home.route : routes.index.route}
        onClick={setHomeResults}
      >
        <img src={logo} className={classes.logo} />
      </Box>
    );

  const renderMenu = () => {
    const pathname = window.location.pathname;
    return (
      isNotRootUrl() &&
      !logoExceptions.includes(pathname) && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleMenu}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: navbarPermanent
          })}
        >
          <MenuIcon />
        </IconButton>
      )
    );
  };

  return (
    <AppBar
      elevation={isRootUrl() ? 0 : 3}
      color={"primary"}
      className={clsx(classes.appBar, {
        [classes.navbarBackground]: isRootAndNotScrolled(),
        [classes.navbarBackgroundOnScroll]: isRootAndScrolled()
      })}
    >
      <Toolbar className={classes.container}>
        {/* MENU */}
        {renderMenu()}

        {/* LOGO */}
        {renderLogo()}
        {authenticated ? (
          <NavbarButtonGroup>
            {/* NOTIFICATIONS_BY_USER */}
            <IconButton aria-controls="notification-menu" onClick={handleNotificationElClick} color="inherit">
              <Badge badgeContent={unreadNotifications} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <NotificationList
              handleNotificationElClose={handleNotificationElClose}
              isNotificationOpen={isNotificationOpen}
              notificationEl={notificationEl}
            />

            {/* USERNAME */}
            {!xs && (
              <>
                <div>
                  <Typography className={classes.username} color={"textPrimary"} variant={"body1"}>
                    {username}
                  </Typography>
                </div>
                <Divider
                  color={theme.palette.text.primary}
                  width={"2px"}
                  height={"20px"}
                  marginLeft={2}
                  marginRight={1}
                />
                <ExpandMoreIcon onClick={handleProfileMenu} className={classes.expandIcon} />
              </>
            )}

            {/* AVATAR */}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              className={classes.avatarIcon}
              onClick={handleProfileMenu}
            >
              <Avatar src={profileImage ? MEDIA_URL + profileImage : avatar} className={classes.avatar}>
                {name && name !== "" ? name.split(" ")[0][0] + name.split(" ")[1][0] : "A"}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              classes={{
                paper: classes.menu
              }}
              transformOrigin={{ horizontal: "center", vertical: xs ? -105 : -90 }}
              open={open}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem disableGutters style={{ opacity: 1 }} className={classes.menuItemTitle} disabled>
                <Typography style={{ color: "white" }} variant={"subtitle1"}>
                  <b>{username}</b>
                </Typography>
              </MenuItem>
              <MenuItem className={classes.menuItem} component={RouterLink} to={`${routes.account.resource}profile`}>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <AccountCircleIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>{"Cuenta"}</ListItemText>
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={handleLogout}>
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <ExitToAppIcon fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>{t("view.navbar.logout_button")}</ListItemText>
              </MenuItem>
            </Menu>
          </NavbarButtonGroup>
        ) : (
          <NavbarButtonGroup>
            <Button
              component={RouterLink}
              to={routes.login.route}
              color={window.location.pathname === routes.login.route ? "secondary" : "inherit"}
            >
              {t("view.navbar.login_button")}
            </Button>
            <Button
              component={RouterLink}
              to={routes.register.route}
              color={window.location.pathname === routes.register.route ? "secondary" : "inherit"}
            >
              {t("view.navbar.register_button")}
            </Button>
          </NavbarButtonGroup>
        )}
      </Toolbar>
    </AppBar>
  );
};

NavbarView.propTypes = {};

export default NavbarView;
