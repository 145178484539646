import * as schemas from "./schemas";

export const setSingleValidationSchema = (t, name) => {
  switch (name) {
    case "email":
      return schemas.emailSchema(t);

    case "password":
      return schemas.passwordSchema(t);

    case "username":
      return schemas.usernameSchema(t);

    case "gender":
      return schemas.genderSchema(t);

    case "birthday":
      return schemas.birthdaySchema(t);

    default:
      return schemas.defaultSchema(t, name);
  }
};
