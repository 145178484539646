export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const PROFILE_UPDATE_EMAIL = "PROFILE_UPDATE_EMAIL";
export const PROFILE_UPDATE_USERNAME = "PROFILE_UPDATE_USERNAME";
export const PROFILE_UPDATE_GENDER = "PROFILE_UPDATE_GENDER";
export const PROFILE_UPDATE_SUMMONER_NAME = "PROFILE_UPDATE_SUMMONER_NAME";
export const PROFILE_UPDATE_REGION = "PROFILE_UPDATE_REGION";
export const PROFILE_UPDATE_PASSWORD = "PROFILE_UPDATE_PASSWORD";
export const PROFILE_UPDATE_IMAGE = "PROFILE_UPDATE_IMAGE";

export const PROFILE_CHECK = "PROFILE_CHECK";
export const PROFILE_TRIGGER_UNLOCK = "PROFILE_TRIGGER_UNLOCK";
export const PROFILE_CHECK_START = "PROFILE_CHECK_START";
export const PROFILE_CHECK_VERIFY_USER = "PROFILE_CHECK_VERIFY_USER";
export const PROFILE_CHECK_FINISH_TESTS = "PROFILE_CHECK_FINISH_TESTS";
export const PROFILE_CHECK_FAILURE = "PROFILE_CHECK_FAILURE";
export const PROFILE_CHECK_SUCCESS = "PROFILE_CHECK_SUCCESS";
export const PROFILE_VALIDATE_IMAGE_START = "PROFILE_VALIDATE_IMAGE_START";
export const PROFILE_VALIDATE_IMAGE_SUCCESS = "PROFILE_VALIDATE_IMAGE_SUCCESS";
export const PROFILE_VALIDATE_IMAGE_FAILURE = "PROFILE_VALIDATE_IMAGE_FAILURE";

export const PROFILE_UNLOCK_USER = "PROFILE_UNLOCK_USER";
export const PROFILE_UNLOCK_USER_START = "PROFILE_UNLOCK_USER_START";
export const PROFILE_UNLOCK_USER_SUCCESS = "PROFILE_UNLOCK_USER_SUCCESS";
export const PROFILE_UNLOCK_USER_FAILURE = "PROFILE_UNLOCK_USER_FAILURE";

export const PROFILE_REQUEST_PUT_IMAGE = "PROFILE_REQUEST_PUT_IMAGE";
export const PROFILE_REQUEST_PUT_IMAGE_START = "PROFILE_REQUEST_PUT_IMAGE_START";
export const PROFILE_REQUEST_PUT_IMAGE_SUCCESS = "PROFILE_REQUEST_PUT_IMAGE_SUCCESS";
export const PROFILE_REQUEST_PUT_IMAGE_FAILURE = "PROFILE_REQUEST_PUT_IMAGE_FAILURE";

export const PROFILE_FETCH_SOCIAL_NETWORKS = "PROFILE_FETCH_SOCIAL_NETWORKS";
export const PROFILE_FETCH_SOCIAL_NETWORKS_START = "PROFILE_FETCH_SOCIAL_NETWORKS_START";
export const PROFILE_FETCH_SOCIAL_NETWORKS_SUCCESS = "PROFILE_FETCH_SOCIAL_NETWORKS_SUCCESS";
export const PROFILE_FETCH_SOCIAL_NETWORKS_FAILURE = "PROFILE_FETCH_SOCIAL_NETWORKS_FAILURE";

export const PROFILE_UPDATE_USER_SOCIAL_NETWORK = "PROFILE_UPDATE_USER_SOCIAL_NETWORK";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_START = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_START";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_SUCCESS = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_SUCCESS";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_FAILURE = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_FAILURE";

export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME_START = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME_START";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME_SUCCESS = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME_SUCCESS";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME_FAILURE = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME_FAILURE";

export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY = "PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY_START =
  "PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY_START";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY_SUCCESS =
  "PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY_SUCCESS";
export const PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY_FAILURE =
  "PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY_FAILURE";
