export const FETCH_GEOIP = "FETCH_GEOIP";
export const FETCH_GEOIP_START = "FETCH_GEOIP_START";
export const FETCH_GEOIP_SUCCESS = "FETCH_GEOIP_SUCCESS";
export const FETCH_GEOIP_FAILURE = "FETCH_GEOIP_FAILURE";


export const GEOIP_CHECK_STATUS = "GEOIP_CHECK_STATUS"
export const GEOIP_STATUS_LOADED = "GEOIP_STATUS_LOADED"
export const GEOIP_STATUS_NOT_LOADED = "GEOIP_STATUS_NOT_LOADED"

export const GEOIP_UPDATE_STATUS_LOADED = "GEOIP_UPDATE_STATUS_LOADED";
export const GEOIP_UPDATE_STATUS_LOADING = "GEOIP_UPDATE_STATUS_LOADING";
export const GEOIP_UPDATE_STATUS_NOT_LOADED = "GEOIP_UPDATE_STATUS_NOT_LOADED";
export const GEOIP_UPDATE_STATUS_ERROR = "GEOIP_UPDATE_STATUS_ERROR";
