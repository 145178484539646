import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

// Reducers
import results from "./results";
import register from "./register";
import profile from "./profile";
import home from "./home";
import passwordReset from "./password-reset";

// epics
import { passwordResetViewEpic } from "./password-reset";
import { homePageEpic } from "./home";

export const pagesEpic = combineEpics(passwordResetViewEpic, homePageEpic);
export default combineReducers({ results, register, profile, home, passwordReset });
