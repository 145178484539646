import {
  FETCH_PUBLIC_PROFILE,
  PUBLIC_PROFILE_CHECK,
  PUBLIC_PROFILE_CHECK_START,
  PUBLIC_PROFILE_CHECK_VERIFY_USER,
  PUBLIC_PROFILE_CHECK_FINISH_TESTS,
  PUBLIC_PROFILE_CHECK_FAILURE,
  PUBLIC_PROFILE_CHECK_SUCCESS
} from "./types";

export const fetchPublicProfile = (promise, transform) => ({
  type: FETCH_PUBLIC_PROFILE,
  payload: promise,
  meta: { transform }
});

export const publicProfileCheck = () => ({ type: PUBLIC_PROFILE_CHECK });
export const publicProfileCheckStart = () => ({ type: PUBLIC_PROFILE_CHECK_START });
export const publicProfileCheckVerifyUser = () => ({ type: PUBLIC_PROFILE_CHECK_VERIFY_USER });
export const publicProfileCheckFinishTests = () => ({ type: PUBLIC_PROFILE_CHECK_FINISH_TESTS });
export const publicProfileCheckFailure = () => ({ type: PUBLIC_PROFILE_CHECK_FAILURE });
export const publicProfileCheckSuccess = () => ({ type: PUBLIC_PROFILE_CHECK_SUCCESS });
