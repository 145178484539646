// REGISTER
export const REGISTER_PLAYER_RESET = "REGISTER_PLAYER_RESET";
export const REGISTER_RESET_PICTURE = "REGISTER_RESET_PICTURE";
export const REGISTER_UPDATE_BIRTHDAY = "REGISTER_UPDATE_BIRTHDAY";
export const REGISTER_UPDATE_EMAIL = "REGISTER_UPDATE_EMAIL";
export const REGISTER_UPDATE_REFERRED_ID = "REGISTER_UPDATE_REFERRED_ID";
export const REGISTER_UPDATE_GENDER = "REGISTER_UPDATE_GENDER";
export const REGISTER_UPDATE_LASTNAME = "REGISTER_UPDATE_LASTNAME";
export const REGISTER_UPDATE_MAIN_GAME = "REGISTER_UPDATE_MAIN_GAME";
export const REGISTER_UPDATE_MINDSET_USERNAME = "REGISTER_UPDATE_MINDSET_USERNAME";
export const REGISTER_UPDATE_NAME = "REGISTER_UPDATE_NAME";
export const REGISTER_UPDATE_PASSWORD = "REGISTER_UPDATE_PASSWORD";
export const REGISTER_UPDATE_PICTURE = "REGISTER_UPDATE_PICTURE";
export const REGISTER_UPDATE_REGION = "REGISTER_UPDATE_REGION";
export const REGISTER_UPDATE_REPEAT_PASSWORD = "REGISTER_UPDATE_REPEAT_PASSWORD";
export const REGISTER_UPDATE_SUMMONER_NAME = "REGISTER_UPDATE_SUMMONER_NAME";
export const REGISTER_UPDATE_TERMS = "REGISTER_UPDATE_TERMS";
export const REGISTER_VALIDATE_FORM_FAILURE = "REGISTER_VALIDATE_FORM_FAILURE";
export const REGISTER_VALIDATE_FORM_START = "REGISTER_VALIDATE_FORM_START";
export const REGISTER_VALIDATE_FORM_SUCCESS = "REGISTER_VALIDATE_FORM_SUCCESS";
