import acFactory from "ejs-acfactory";
import * as aTypes from "./types";

export const homePageLoaded = acFactory(aTypes.HOME_PAGE_LOADED);
export const homeDownloadSelected = acFactory(aTypes.HOME_DOWNLOAD_SELECTED);
export const homeAbilitiesSelected = acFactory(aTypes.HOME_ABILITIES_SELECTED);
export const homeCognitiveProfileSelected = acFactory(aTypes.HOME_COGNITIVE_PROFILE_SELECTED);
export const homeMyProfileSelected = acFactory(aTypes.HOME_MY_PROFILE_SELECTED)

export const homeComponentsLoaded = acFactory(aTypes.HOME_COMPONENTS_LOADED)
