import React, { useEffect } from "react";

import HomeView from "app/pages/home/HomeView";
import { useTranslation } from "react-i18next";
import { HOME_TEXTS } from "app/i18n/selectors";
import { useDispatch, useSelector } from "react-redux";
import { profileSelectors } from "redux/state/profile";
import { getProfileCheck } from "redux/state/profile/selectors";
import { homeUiOPS } from "redux/state/ui/pages/home";
import { getUiHomeComponentsSelected } from "redux/state/ui/pages/home/selectors";

const Home = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(homeUiOPS.homePageLoaded());
  }, []);

  const { t } = useTranslation();
  const homeState = {
    profileCheck: useSelector(state => getProfileCheck(state)),
    selected: useSelector(state => getUiHomeComponentsSelected(state))
  };

  const texts = {
    titleText: t(`${HOME_TEXTS}.title`)
  };

  return <HomeView {...homeState} {...texts} />;
};

export default Home;
