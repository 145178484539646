import { profileOptions } from "config/constants";
import { combineReducers } from "redux";
import { PROFILE_SELECT_OPTION } from "redux/state/ui/pages/profile/types";
import { mapIndexToOption } from "redux/state/ui/pages/profile/utils";

const optionsListInit = [profileOptions.PROFILE, profileOptions.ACCOUNT];

const optionsList = (state = optionsListInit, action) => {
  return state;
};

const selected = (state = "", action) => {
  switch (action) {
    case PROFILE_SELECT_OPTION:
      return mapIndexToOption(action.payload, optionsListInit);

    default:
      return state;
  }
};

export default combineReducers({ optionsList, selected });
