import axios from "axios";
import ResponseCreator from "api/ResponseCreator";
import { AJAX_REQUEST_TIMEOUT } from "config/settings";

const TIMEOUT = AJAX_REQUEST_TIMEOUT;
const responseCreator = new ResponseCreator();

const request = (url, method = "GET", data = {}, headers, auth) => {
  const config = {
    url,
    method,
    data,
    headers: responseCreator.createHeaders(headers),
    timeout: TIMEOUT
  };

  if (auth) {
    if (typeof auth !== "object") throw Error("auth must be an object");
    if (auth !== {}) config["auth"] = responseCreator.setBasicAuth(auth);
  }

  return axios(config).then(
    res => responseCreator.createResponse(res),
    error => responseCreator.createErrorResponse(error)
  );
};

export default request;
