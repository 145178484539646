import React from "react";
import PropTypes from "prop-types";
import FullPageTemplate from "app/components/full-page-template/FullPageTemplate";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import useTheme from "@material-ui/core/styles/useTheme";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import TermsTexts from "app/pages/terms-conditions/TermsTexts";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  // DRAWER
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "transparent"
    // border: 0
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  // MAIN
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 8, 8, 8),
    overflowY: "auto",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  title: {
    fontWeight: "bold"
  }
}));

const TermsAndConditions = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <FullPageTemplate>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}></div>
        <List>
          <ListItem button selected={true} component={RouterLink} to={routes.termsAndConditions.route}>
            {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
            <ListItemText primaryTypographyProps={{ className: classes.title }} primary={"Términos de servicio"} />
          </ListItem>
          <ListItem button selected={false} component={RouterLink} to={routes.privacy.route}>
            {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
            <ListItemText primary={"Política de privacidad"} />
          </ListItem>
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        <Typography variant={"h4"} className={classes.title}>
          TÉRMINOS Y CONDICIONES DE USO “eProfile”
        </Typography>
        <br />
        <Typography variant={"caption"}>Última actualización: 27 de marzo de 2020</Typography>
        <br />

        <TermsTexts />
      </main>
    </FullPageTemplate>
  );
};

TermsAndConditions.propTypes = {};

export default TermsAndConditions;
