import { log } from "redux/lib/loggers";

export const mapTopRanksToState = ({ data }) => {
  log("studySearchTopRanks", data);
  const ranks = data.top_ranks.reduce((obj, rank) => {
    obj[rank.user_id] = rank;
    return obj;
  }, {});
  return { [data.game_id]: ranks };
};
