import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import WhiteLink from "app/components/white-link/WhiteLink";

const URL_ROOT = "";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  italic: {
    fontStyle: "italic"
  }
}));

const TermsTexts = props => {
  const classes = useStyles();
  return (
    <Fragment>
      <Typography paragraph className={classes.title}>
        Generalidades
      </Typography>
      <Typography paragraph>
        Este documento de Términos y Condiciones regula el acceso y uso del sistema eProfile de SoluNova SpA, en
        adelante SoluNova, junto con cualquier información, texto, video u otro material comunicado en cualquiera de los
        componentes del sistema eProfile y será aplicable a aquellas personas que, siendo o no usuarios del sistema
        eProfile, deseen utilizar cualquiera de los componentes del sistema, en adelante indistintamente “usuario”. Si
        no estás de acuerdo con estos Términos y Condiciones de Uso, deberás abstenerte de utilizar el sistema eProfile
        de SoluNova.
      </Typography>
      <Typography paragraph>
        SoluNova es el proveedor del sistema eProfile. Se trata de un sistema que contiene pruebas cognitivas
        desarrolladas en conjunto con el Instituto Milenio de Neurociencia Biomédica de la Universidad de Chile. Las
        pruebas cognitivas están destinadas a que el usuario evalúe diferentes aptitudes que le permitan obtener
        orientaciones respecto a sus fortalezas y debilidades de manera de identificar de mejor forma su desempeño en
        los diferentes tipos de juegos de video. Las pruebas evalúan:
      </Typography>
      <Typography>
        <b>Velocidad de procesamiento</b>: Mide tu capacidad para procesar rápidamente estímulos simples.
      </Typography>
      <Typography>
        <b>Atención sostenida</b>: Mide tu capacidad de poner atención de forma continúa sin distraerte.
      </Typography>
      <Typography>
        <b>Atención dividida</b>: Mide tu capacidad de poner atención entre diferentes estímulos de manera eficiente.
      </Typography>
      <Typography>
        <b>Alternancia de tareas</b>: Mide tu capacidad de poner atención a más de una tarea y desempeñarte bien en
        ellas.
      </Typography>
      <Typography>
        <b>Visoespacial</b>: Mide tu capacidad de procesar información espacial en objetos de 2 o 3 dimensiones.
      </Typography>
      <Typography>
        <b>Memoria de trabajo</b>: Mide tu capacidad para mantener y operar la información en tu memoria a corto plazo
      </Typography>
      <Typography>
        <b>Razonamiento fluido</b>: Mide tu habilidad de identificar y predecir patrones y secuencias en nuevas tareas.
      </Typography>
      <Typography>
        <b>Ceguera atencional</b>: Mide el costo temporal en tus recursos atencionales entre que estás en una tarea y
        cambias tu atención a otra.
      </Typography>
      <br />
      <Typography paragraph>
        Estos Términos y Condiciones son un contrato vinculante para las partes y regula el uso de todos los elementos
        que componen el sistema eProfile.
      </Typography>
      <Typography paragraph>
        Estos Términos y Condiciones se entenderán incorporados en cada una de las actividades que realices utilizando
        el sistema eProfile. Por lo tanto, deberás leer, entender y aceptar las condiciones establecidas en estos
        Términos y Condiciones y en la Política de Tratamiento de Datos Personales, así como en los demás documentos
        incorporados a los mismos por referencia en forma previa a tu inscripción como usuario del sistema eProfile.
      </Typography>
      <Typography paragraph>
        Quien quiera acceder y/o usar el sistema eProfile podrá hacerlo siempre que se sujete a estos Términos y
        Condiciones, junto con todas las demás políticas y principios que rigen a eProfile y que son incorporados a los
        presentes por referencia.
      </Typography>
      <Typography paragraph>
        La mera visita al sitio web de <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} /> no impone ningún tipo de
        obligación, salvo que exprese de forma inequívoca su voluntad de contratar con la empresa para acceder a los
        Servicios, en la forma indicada en estos Términos y Condiciones.
      </Typography>
      <Typography paragraph className={classes.title}>
        Aceptación de Condiciones
      </Typography>
      <Typography paragraph>
        Si no aceptas estos Términos y Condiciones, deberás abstenerte de usar cualquiera de los componentes del sistema
        eProfile. Para aceptar las Condiciones debes hacer clic donde la plataforma Web (
        <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} />) o la aplicación eProfile, ofrezcan esta opción en la
        interfaz de usuario.
      </Typography>
      <Typography paragraph>
        Para utilizar el sistema eProfile deberás crear una Cuenta, momento desde el cual pasarás a ser un usuario
        registrado.
      </Typography>
      <Typography paragraph>
        Para tener un conocimiento adecuado de este contrato y mayor claridad respecto de tus obligaciones y derechos,
        te recomendamos leer estos Términos y Condiciones, imprimirlos o guardar una copia de ellos en la unidad de
        disco local para tu información.
      </Typography>
      <Typography paragraph className={classes.title}>
        Capacidad
      </Typography>
      <Typography paragraph>
        Los Servicios sólo estarán disponibles para personas que tengan 14 años o la edad mínima requerida en tu país
        para tener autorización para usar eProfile. No podrán usar eProfile los usuarios que hayan sido suspendidos
        temporalmente o inhabilitados definitivamente por SoluNova.
      </Typography>
      <Typography paragraph>
        Los Servicios sólo estarán disponibles para personas que tengan 14 años o la edad mínima requerida en tu país
        para tener autorización para usar eProfile. No podrán usar eProfile los usuarios que hayan sido suspendidos
        temporalmente o inhabilitados definitivamente por SoluNova.
      </Typography>
      <Typography paragraph>
        En el caso de menores de edad, estos Términos y Condiciones deberán ser aceptados por tu padre/madre o por tu
        tutor legal, tanto para el registro como para el uso de los Servicios de eProfile.
      </Typography>
      <Typography paragraph>
        Nos tomamos muy en serio la edad y capacidad de las personas que pueden tener una cuenta en eProfile, por lo que
        si tu amigo o familiar no tiene la edad suficiente o está incapacitado mental o físicamente de forma
        irreversible para conservar su cuenta en <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} />, podemos
        ayudarte a eliminarla. Para ello envía una solicitud a{" "}
        <WhiteLink href="info@eprofile.gg" text={"info@eprofile.gg"} /> y nosotros revisaremos la información que nos
        proporciones y te informaremos acerca de lo que se puede hacer.
      </Typography>
      <Typography paragraph className={classes.title}>
        Idioma de las condiciones
      </Typography>
      <Typography paragraph>
        El idioma que regirá tu relación con SoluNova será el español y cualquier traducción que se proporcione es
        únicamente a título informativo.
      </Typography>
      <Typography paragraph>
        En el caso que exista alguna incoherencia entre la versión en español y cualquier versión traducida, prevalecerá
        la versión en español.
      </Typography>
      <Typography paragraph className={classes.title}>
        Comunicaciones
      </Typography>
      <Typography paragraph>
        Para realizar cualquier presentación, consulta o reclamo con ocasión del sistema eProfile puedes comunicarte al
        correo electrónico <WhiteLink href="info@eprofile.gg" text={"info@eprofile.gg"} /> .
      </Typography>
      <Typography paragraph>
        El domicilio de SoluNova es Almirante Churruca 3130, Comuna de Santiago, Santiago de Chile.
      </Typography>
      <Typography paragraph className={classes.title}>
        Modificación y Término de las condiciones
      </Typography>
      <Typography paragraph>
        Estos Términos y Condiciones podrán ser modificados por SoluNova cuando lo considere oportuno haciendo pública
        la versión más actualizada en{" "}
        <WhiteLink href={`${URL_ROOT}/terms-conditions`} text={"www.eprofile.gg/terminos"} />. Si eres un usuario
        registrado y la modificación es considerada como esencial por SoluNova, te notificaremos al correo electrónico
        asociado a tu cuenta, de lo contrario únicamente podrás tomar conocimiento de las modificaciones a través de la
        revisión de los Términos y Condiciones de Uso en la dirección web indicada.
      </Typography>
      <Typography paragraph>
        En caso de que no estés de acuerdo con las modificaciones efectuadas, puedes solicitar la eliminación de tu
        Cuenta, momento desde el cual deberás desinstalar la aplicación eProfile y abstenerte de usar cualquiera de los
        componentes del sistema eProfile.
      </Typography>
      <Typography paragraph>Las Condiciones seguirán vigentes hasta que tú o SoluNova decidan resolverlas.</Typography>
      <Typography paragraph>
        En caso que decidas poner término al contrato, deberás notificar a SoluNova en cualquier momento a través de la
        opción Cerrar Cuenta disponible dentro de tu perfil en la plataforma web eProfile. Si SoluNova recibe una
        solicitud de esta naturaleza, tu Cuenta será eliminada, sin embargo, seguirá rigiéndose por estos Términos y
        Condiciones en todo lo que sea aplicable a los usuarios.
      </Typography>
      <Typography paragraph>SoluNova podrá resolver su contrato contigo en cualquier momento si:</Typography>
      <Typography>
        a) Has incumplido alguna de las disposiciones de estos Términos y Condiciones o has actuado de algún modo que
        demuestre que no tienes intención de cumplir tales disposiciones o que no puedes cumplirlas.
      </Typography>
      <Typography>b) Provocas riesgo o dejas expuesto legalmente a SoluNova.</Typography>
      <Typography>c) Así se lo exige a SoluNova la ley o una resolución judicial.</Typography>
      <Typography>
        d) La provisión de los Servicios ha dejado de ser comercialmente viable desde el punto de vista de SoluNova.
      </Typography>
      <br />
      <Typography paragraph>
        En caso que SoluNova tome conocimiento de publicaciones ofensivas, falsas, contrarias a la ley o a estos Término
        y Condiciones podrá, además, dar de baja una cuenta en forma temporal o definitiva.
      </Typography>
      <Typography paragraph className={classes.title}>
        Sistema eProfile
      </Typography>
      <Typography paragraph>
        El sistema eProfile se encuentra formado por la plataforma Web eProfile accesible desde{" "}
        <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} /> y por la aplicación eProfile que los usuarios registrados
        deberán instalar en su computador para realizar las pruebas cognitivas.
      </Typography>
      <Typography paragraph>
        El sistema eProfile está destinado a que los usuarios realicen pruebas cognitivas para identificar sus
        diferentes aptitudes y así obtener orientaciones respecto a sus fortalezas y debilidades de manera de
        identificar de mejor forma su desempeño en los diferentes tipos de juegos de video. Junto a ello, cuando hayan
        optado expresamente por compartir sus resultados con el resto de la comunidad de eProfile podrán compararse con
        los demás jugadores en función de las aptitudes, fortalezas y debilidades detectadas. Asimismo, los usuarios
        pueden compartir sus resultados, individuales y comparados, con personas ajenas a la comunidad eProfile cuando
        lo hagan a través de las redes sociales que se encuentran integradas al sistema.
      </Typography>
      <Typography paragraph className={classes.title}>
        Plataforma Web
      </Typography>
      <Typography paragraph>
        La plataforma Web eProfile es un componente del sistema que tiene como principal función permitir a los usuarios
        registrarse creando una Cuenta, y a los usuarios registrados visualizar los resultados de sus pruebas
        cognitivas, así como información relacionada con el desempeño en videojuegos que el jugador haya elegido
        compartir, y así obtener orientaciones respecto a sus fortalezas y debilidades de manera de comprender de mejor
        forma su desempeño en los diferentes juegos de video.
      </Typography>
      <Typography paragraph title={classes.title}>
        Aplicación eProfile
      </Typography>
      <Typography paragraph>
        La aplicación eProfile es un componente del sistema que tiene como principal función permitir a los usuarios
        registrados efectuar las diferentes pruebas cognitivas que permitan identificar sus diferentes aptitudes.
      </Typography>
      <Typography paragraph>
        La aplicación eProfile debe ser descargada desde <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} /> e
        instalada localmente en el computador del usuario, sólo así podrá realizar las pruebas cognitivas.
      </Typography>
      <Typography paragraph>
        El conjunto de pruebas cognitivas sólo puede realizarse una vez al mes, dado que lo que se busca con ellas es
        identificar las aptitudes y no la capacidad de los usuarios registrados de memorizarlas o entrenarse para ellas.
        Sólo así, es posible obtener resultados que sean verdaderamente representativos de las destrezas del usuario y
        así poder proporcionarle orientaciones respecto a sus fortalezas y debilidades de desempeño en los diferentes
        tipos de juegos de video.
      </Typography>
      <Typography paragraph className={classes.title}>
        Requisitos para el uso del sistema eProfile
      </Typography>
      <Typography paragraph>
        El sistema eProfile está disponible sólo para los usuarios que hayan creado una Cuenta en{" "}
        <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} />. El acceso y utilización del sistema eProfile requiere
        que el usuario disponga de acceso a Internet, ya sea por un plan, bolsa de datos, conexión Wi-Fi u otro medio
        disponible. Requiere de dispositivos compatibles y determinados softwares; se podrían requerir actualizaciones
        periódicas. Los Servicios del sistema eProfile podrían verse afectados por el rendimiento de estos factores.
        Para obtener información más detallada puede acceder a{" "}
        <WhiteLink href={`${URL_ROOT}/info`} text={"www.eprofile.gg/info"} /> o haciendo clic acá.
      </Typography>
      <Typography paragraph>
        Para asegurar un buen funcionamiento de los componentes del sistema eProfile asegúrate de utilizar los
        siguientes sistemas operativos, dispositivos y navegadores.
      </Typography>
      <Typography className={classes.italic}>Sistema Operativo para aplicación eProfile</Typography>
      <Typography paragraph>
        La aplicación eProfile está diseñada para ser instalada y ejecutada únicamente en el sistema operativo Windows
        10, versión 64 bits, de Microsoft.
      </Typography>
      <Typography className={classes.italic}>Navegadores plataforma Web</Typography>
      <Typography paragraph>
        <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} /> puede ser accedido tanto desde computadores de escritorio
        como dispositivos móviles que cuenten con navegadores web. Los siguientes navegadores se ajustan bien a los
        estándares web y son soportados activamente por sus respectivos fabricantes:
      </Typography>
      <Typography>a) Chrome Escritorio 57+</Typography>
      <Typography>b) Firefox 50+</Typography>
      <Typography>c) Safari Escritorio 10+</Typography>
      <Typography>d) Safari iOS 6.0+</Typography>
      <Typography>e) Chrome Android 57+</Typography>
      <br />
      <Typography className={classes.italic}>Velocidad de conexión</Typography>
      <Typography>
        Para asegurar una buena experiencia usuaria se requiere una velocidad mínima de acceso a Internet de 4 Megabits
        por segundo (500 KB/s).
      </Typography>
      <br />
      <Typography paragraph className={classes.title}>
        Uso de los Servicios.
      </Typography>
      <Typography paragraph>
        Para utilizar el sistema eProfile tienes que crear una Cuenta de usuario. Para ello deberás entregar la
        siguiente información: tag eProfile, juego principal y tu tag en ese juego, fotografía personal o una imagen que
        te represente, correo electrónico, contraseña, sexo, fecha de nacimiento, región y cualquier otra información
        indicada como requerida. Esta Cuenta te permitirá operar tanto en{" "}
        <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} /> como en la aplicación eProfile.
      </Typography>
      <Typography paragraph>
        Una vez creada la Cuenta, recibirás un correo electrónico con un enlace para verificar tu dirección de correo.
        Solo una vez realizada esta verificación tu Cuenta quedará activada y te permitirá el uso de los Servicios del
        sistema eProfile. Reconoces que eProfile utilizará esta dirección de correo electrónico como método principal de
        comunicación contigo.
      </Typography>
      <Typography paragraph>
        Una vez creada tu Cuenta de usuario tendrás acceso a la misma mediante el ingreso del correo registrado, o tu
        tag eProfile, y tu contraseña. Será tu deber mantener la confidencialidad de tu contraseña.
      </Typography>
      <Typography paragraph>
        La Cuenta es personal, única e intransferible y está prohibido que inscribas o poseas más de una Cuenta. En caso
        de que eProfile detecte más de una Cuenta con datos coincidentes o relacionados, podrá cancelar, suspender e
        incluso inhabilitar dichas Cuentas.
      </Typography>
      <Typography paragraph>
        Serás responsable de todas las operaciones realizadas en tu Cuenta ya que el acceso a ésta se encuentra
        restringido al ingreso y uso de tu contraseña, de tu exclusivo conocimiento.
      </Typography>
      <Typography paragraph>
        Por este acto te comprometes a notificar a eProfile en forma inmediata y por el medio más adecuado, cualquier
        uso no autorizado de tu Cuenta, así como el ingreso hecho por terceros no autorizados.
      </Typography>
      <Typography paragraph>La venta, cesión o transferencia de tu Cuenta de usuario está prohibida.</Typography>
      <Typography paragraph>
        Serás responsable del uso del sistema eProfile, los Servicios contratados, y de cualquier información que
        entregues a eProfile para su tratamiento.
      </Typography>
      <Typography paragraph>Serás, además, especialmente responsable de:</Typography>
      <Typography>
        a) Proporcionar, mantener y actualizar tu información personal completa, auténtica, precisa y actual, según lo
        solicitado en los procesos de registro (Datos de Registro) del sistema eProfile.
      </Typography>
      <Typography>b) Usar el Sistema eProfile de acuerdo con los fines establecidos en estas Condiciones.</Typography>
      <Typography>c) No suplantar a ninguna persona o entidad.</Typography>
      <Typography>
        d) No falsificar tu identidad o afiliación con ninguna persona o entidad, incluido el uso del nombre de usuario,
        contraseña u otra información de la Cuenta de otra persona.
      </Typography>
      <Typography>
        e) No realizar ningún tipo de actividad que ponga en riesgo la prestación del Servicio por parte de SoluNova.
      </Typography>
      <Typography>f) No entregar tu contraseña de eProfile a terceros.</Typography>
      <Typography>
        g) Notificar de forma inmediata en info@eprofile.gg cualquier uso no autorizado o infracción de seguridad de la
        que tengas conocimiento, en el que el sistema eProfile o SoluNova esté relacionado.
      </Typography>
      <Typography>
        h) No realizar accesos no autorizados, ni reproducir, duplicar, copiar, vender, comercializar ni revender los
        Servicios de eProfile para ningún fin.
      </Typography>
      <br />
      <Typography paragraph className={classes.title}>
        Tarifas eProfile.
      </Typography>
      <Typography paragraph>Todos nuestros Servicios son gratuitos, excepto:</Typography>
      <Typography>a) La funcionalidad de compararse con otros usuarios de la comunidad eProfile.</Typography>
      <Typography>b) Las sucesivas pruebas cognitivas que se realicen con posterioridad a la primera vez.</Typography>
      <br />
      <Typography paragraph>
        Tú eres responsable por las tarifas e impuestos asociados al uso de nuestros Servicios pagados. Sin embargo,
        debes tener presente que podemos rechazar o cancelar pedidos. Asimismo, que no otorgamos reembolsos por nuestros
        Servicios, a menos que lo exija la ley.
      </Typography>
      <Typography paragraph>
        Las tarifas asociadas a nuestros Servicios pagados se encuentran permanentemente disponibles en{" "}
        <WhiteLink href={`${URL_ROOT}/prices`} text={"www.eprofile.gg/prices"} />.
      </Typography>
      <Typography paragraph className={classes.title}>
        Provisión de los Servicios del sistema eProfile.
      </Typography>
      <Typography paragraph>
        El sistema eProfile es operado y proporcionado por SoluNova y sus proveedores de Servicios de hosting,
        conectividad y otros.
      </Typography>
      <Typography paragraph>
        Los Servicios que SoluNova proporciona están siempre evolucionando, por lo tanto, la forma y naturaleza de éstos
        podrá cambiar eventualmente sin previo aviso, aun cuando se informe dicha situación a través del sitio Web.
      </Typography>
      <Typography paragraph>
        Los Servicios pueden incluir anuncios referidos al contenido o información de los Servicios, preguntas hechas a
        través de dichos Servicios u otra información. El tipo y extensión de la publicidad de SoluNova en los Servicios
        están sujetos a cambio. El usuario acepta que SoluNova y terceras partes emplacen publicidad en los Servicios.
      </Typography>
      <Typography paragraph>
        La información que el sistema eProfile recolecta directamente de los usuarios no se revisa y el contenido de
        ésta es responsabilidad exclusiva de quien la informa. Asimismo, la información derivada del uso del sistema
        eProfile de parte del usuario no es eliminada de los sistemas de SoluNova y transcurridos 3 meses desde la fecha
        en que terminó la relación entre el usuario y SoluNova, ésta es disociada definitivamente, de manera que no se
        pueda lograr una vinculación de la información con el titular de ésta.
      </Typography>
      <Typography paragraph>
        Si tiene alguna duda sobre estas Condiciones, por favor póngase en contacto, usando el formulario de contacto,
        disponible en <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} />, o escribiendo a info@eprofile.gg.
      </Typography>
      <Typography paragraph className={classes.title}>
        Privacidad.
      </Typography>

      <Typography paragraph>
        La privacidad de sus usuarios es muy importante para SoluNova. Para obtener información acerca de la Política de
        Tratamiento de Datos Personales, puede consultar en{" "}
        <WhiteLink href={`${URL_ROOT}/privacy`} text={"www.eprofile.gg/privacy"} />. Esta Política explica cómo SoluNova
        trata su información y cómo protege su privacidad cuando usa el sistema eProfile. Debe considerar y entender
        que, a través del uso de los Servicios, usted da su consentimiento a las diferentes formas de tratamiento de sus
        datos personales y de la información derivada del uso de eProfile de conformidad con los límites establecidos en
        la Política de Tratamiento de Datos Personales.
      </Typography>

      <Typography paragraph className={classes.title}>
        Seguridad.
      </Typography>

      <Typography paragraph>
        eProfile adoptará las medidas necesarias y prudentes para resguardar la seguridad de los datos y clave secreta,
        así como sistemas de encriptación de información, certificados de seguridad u otros que la empresa estime
        pertinente. En caso de detectarse cambios en la información que haya registrado en el sitio, nuestros ejecutivos
        podrán contactarte por vía telefónica o correo electrónico, con el fin de corroborar sus datos e intentar evitar
        posibles fraudes.
      </Typography>

      <Typography paragraph>
        Le recomendamos que use contraseñas "seguras" (contraseñas que utilizan la combinación de letras mayúsculas y
        minúsculas, números y símbolos) en su Cuenta. SoluNova no será responsable de ninguna pérdida o daño derivados
        del incumplimiento de los requisitos anteriores.
      </Typography>

      <Typography paragraph className={classes.title}>
        Derechos sobre la información.
      </Typography>

      <Typography paragraph>
        SoluNova trata la Información como si se tratara de información confidencial, de manera que la información que
        recibe no será divulgada.
      </Typography>

      <Typography paragraph>
        Sin perjuicio de lo anterior, los sistemas de eProfile y el uso de los Servicios hecho por los distintos
        usuarios, genera información con la cual SoluNova podrá desarrollar estudios e informes a partir de la
        información recopilada. Para ello la información del usuario será anonimizada de manera que no pueda asociarse
        dicha información a una persona identificada o identificable.
      </Typography>

      <Typography paragraph>
        Cuando eliminas contenido del sistema, éste deja de estar disponible para tí o para terceras personas, sin
        embargo, este no es borrado de nuestros servidores, sino que es disociado definitivamente, de manera que no se
        pueda lograr una vinculación de la información con el titular de ésta, pudiendo en el futuro ser utilizado con
        fines científicos o estadísticos.
      </Typography>

      <Typography paragraph className={classes.title}>
        Propiedad intelectual.
      </Typography>

      <Typography paragraph>
        Todos los contenidos incluidos en este sitio, como textos, material gráfico, logotipos, íconos de botones,
        códigos fuente, imágenes, audio clips, descargas digitales y compilaciones de datos, encabezados de páginas,
        frases publicitarias, textos escritos y nombres de Servicios incluidos en este sitio son marcas comerciales,
        creaciones o imágenes comerciales de propiedad de SoluNova en Chile y en otros países o de sus proveedores de
        contenidos, y están protegidos por las leyes chilenas e internacionales sobre Propiedad Intelectual e Industrial
        según corresponda.
      </Typography>

      <Typography paragraph>
        Dichas marcas, creaciones e imágenes comerciales no se pueden usar en relación a ningún producto o servicio que
        pueda causar confusión entre los clientes y en ninguna forma que desprestigie o desacredite a SoluNova. Las
        demás marcas comerciales que no sean de propiedad de SoluNova y que aparezcan en este sitio pertenecen a sus
        respectivos dueños.
      </Typography>

      <Typography paragraph>
        Todos los derechos no expresamente otorgados en estos Términos y Condiciones son reservados por SoluNova o sus
        cesionarios, proveedores, editores, titulares de derechos u otros proveedores de contenidos. Ningún producto,
        imagen o sonido puede ser reproducido, duplicado, copiado, vendido, revendido, visitado o explotado para ningún
        fin, en todo o en parte, sin el consentimiento escrito previo de SoluNova. No se puede enmarcar o utilizar
        técnicas de enmarcación para encerrar alguna marca comercial, logotipo u otra información registrada o patentada
        (incluyendo imágenes, texto, disposición de páginas, o formulario) de SoluNova, sin que conste por escrito
        nuestro consentimiento previo. Tampoco se puede usar meta etiquetas ni ningún otro “texto oculto” que use el
        nombre o marcas comerciales de SoluNova, sin autorización escrita previa de esta empresa. Se prohíbe hacer un
        uso indebido de este sitio o de estas marcas, licencias o patentes. Lo anterior, sin perjuicio de las
        excepciones expresamente señaladas en la ley.
      </Typography>

      <Typography paragraph className={classes.title}>
        Licencia.
      </Typography>

      <Typography paragraph>
        El sistema eProfile es licenciado y no vendido. La licencia para usar el sistema eProfile, está sujeta a tu
        aceptación previa de estos Términos y Condiciones de Uso.
      </Typography>

      <Typography paragraph>
        Esta licencia es concedida en forma gratuita para el uso de los componentes del Sistema eProfile y se limita a
        una licencia no transferible para usarlo en dispositivos compatibles que poseas o controles. Esta licencia no
        permite usar el sistema eProfile en otros dispositivos que no sean de tu propiedad o control.
      </Typography>

      <Typography paragraph>
        No puedes distribuir ni poner el sistema eProfile disponible en una red donde pueda ser utilizado por múltiples
        dispositivos al mismo tiempo. No puedes alquilar, arrendar, ceder, prestar, vender, transferir, redistribuir o
        sublicenciar el sistema eProfile. No puedes copiar (salvo lo expresamente permitido por esta licencia),
        descompilar, realizar ingeniería inversa, desmontar, intentar descifrar el código fuente, modificar o crear
        trabajos derivados del sistema eProfile licenciado, las actualizaciones, o cualquier parte de la misma (excepto
        y únicamente en la medida en que cualquier restricción anterior esté prohibida por la ley aplicable). Cualquier
        intento de llevar a cabo las conductas anteriores, se entenderá como una violación de los derechos de SoluNova.
      </Typography>

      <Typography paragraph>
        Los términos de esta licencia se aplican a cualquier actualización proporcionada por SoluNova, a menos que dicha
        actualización esté acompañada por una licencia independiente, en cuyo caso los términos de dicha licencia
        prevalecerán.
      </Typography>

      <Typography paragraph>
        La licencia estará vigente hasta que le den término tú o SoluNova. Los derechos otorgados por esta licencia
        terminarán automáticamente y sin previo aviso por parte de SoluNova, en caso que no des cumplimiento a
        cualquiera de los términos de la presente Licencia. A la terminación de la Licencia, deberás cesar el uso del
        sistema licenciado y destruir todas las copias, totales o parciales, del mismo.
      </Typography>

      <Typography paragraph className={classes.title}>
        Responsabilidad
      </Typography>

      <Typography paragraph>
        SoluNova no emite declaración o garantía alguna respecto a la exactitud, fiabilidad, integridad o vigencia del
        contenido de los sitios de SoluNova. Todo uso de los sitios o aplicaciones de SoluNova corre por cuenta y riesgo
        del usuario.
      </Typography>

      <Typography paragraph>
        SoluNova no garantiza el acceso y uso continuado o ininterrumpido de su sitio y aplicaciones. El sistema puede
        eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra
        circunstancia ajena y no imputable a SoluNova. Sin embargo, siempre procurará mantener los Servicios en un
        uptime adecuado a los requerimientos de sus usuarios y, en caso de producirse alguna falla, reestablecer el
        Servicio con la mayor celeridad posible sin que por ello pueda imputarse algún tipo de responsabilidad. SoluNova
        no será responsable por ningún error u omisión de contenidos en su sitio web o aplicaciones salvo que se trate
        de culpa grave o dolo.
      </Typography>

      <Typography paragraph>
        SoluNova, sus directores, empleados, afiliados, agentes, contratistas, gerentes o licenciantes, no serán
        responsables por ningún daño derivado del uso del sistema eProfile, salvo que provenga de culpa grave o dolo.
        SoluNova realizará esfuerzos razonables para proteger la información en los diferentes componentes del sistema
        eProfile, sin ser por ello responsable ante ti por pérdida o responsabilidad relacionada a tu información, salvo
        culpa grave o dolo.
      </Typography>

      <Typography paragraph>
        SoluNova no garantiza que su sitio Web no sufrirá interrupciones, que no presentará ningún error ni que esta Web
        quedará exenta de virus u otros componentes peligrosos. Tampoco responderá de los perjuicios ocasionados al
        Cliente, provenientes del uso inadecuado de las tecnologías, dispositivos y accesorios puestos a disposición de
        éste, cualquiera sea la forma en la cual éstos se utilicen inadecuadamente. SoluNova no responderá de los daños
        producidos por el uso indebido y de mala fe de los Usuarios o terceros. Así como tampoco de la información de
        SoluNova, eProfile u otros Servicios de SoluNova que se encuentre en sitios distintos a{" "}
        <WhiteLink href={URL_ROOT} text="www.eprofile.gg" /> o su aplicación.
      </Typography>

      <Typography paragraph>
        Estas limitaciones de la responsabilidad se aplicarán independientemente de si se le hayan notificado tales
        posibilidades de pérdidas a SoluNova o de si SoluNova debiese haber sido consciente de dicha posibilidad.
      </Typography>

      <Typography paragraph>
        En todo caso, la responsabilidad de SoluNova, contractual, extracontractual o legal, con los Usuarios no
        excederá del precio efectivamente pagado por el usuario en contraprestación por el Servicio, sin perjuicio de lo
        que determinen los Tribunales de Justicia.
      </Typography>

      <Typography paragraph className={classes.title}>
        Anexos
      </Typography>

      <Typography paragraph>Forman parte integrante de estos Términos y Condiciones los siguientes anexos:</Typography>
      {/*<Typography>*/}
      {/*  a) Tarifas, accesible en <WhiteLink href={`${URL_ROOT}/prices`} text="www.eprofile.gg/prices" />.*/}
      {/*</Typography>*/}
      <Typography>
        b) Política de Tratamiento de Datos Personales, accesible desde{" "}
        <WhiteLink href={`${URL_ROOT}/privacy`} text="www.eprofile.gg/privacy" /> o haciendo clic acá.
      </Typography>
      <br />
      <Typography paragraph className={classes.title}>
        Cambios de propiedad.
      </Typography>

      <Typography paragraph>
        Por este acto usted declara y acepta que la propiedad o control de eProfile podría cambiar en cualquier momento
        por cualquiera de las formas que la legislación permite.
      </Typography>

      <Typography paragraph className={classes.title}>
        Legislación aplicable y solución de controversias.
      </Typography>

      <Typography paragraph>
        Los presentes términos y condiciones se rigen por las leyes de la República de Chile. Cualquier controversia o
        conflicto derivado de la utilización del sistema eProfile, sus Términos y Condiciones, Tarifas y las Políticas
        de Tratamiento de Datos Personales, su validez, interpretación, alcance o cumplimiento, será sometida a las
        leyes aplicables de la República de Chile. Ya sea en forma de reclamación o litigio tendrá lugar únicamente en
        Santiago de Chile, y el usuario acepta dicha legislación, jurisdicción y lugar de los tribunales, renunciando
        con ello a cualquier objeción en cuanto a inconveniencia de foro.
      </Typography>

      <Typography paragraph className={classes.title}>
        Definiciones.
      </Typography>

      <Typography>
        <b>Dato Personal</b>: Cualquier información referente a una persona determinada o determinable.
      </Typography>

      <Typography>
        <b>eProfile</b>: Servicio de software que permite a sus usuarios llevar a cabo tests cognitivos, visualizar sus
        resultados y la relación entre estos y su desempeño en videojuegos.
      </Typography>

      <Typography>
        <b>SoluNova</b>: Es la empresa que ha diseñado, desarrolla y opera el sistema eProfile.
      </Typography>

      <Typography>
        <b>Términos y Condiciones</b>: Este instrumento.
      </Typography>
      <Typography>
        <b>Usuario</b>: Cualquier persona que accede a <WhiteLink href={URL_ROOT} text="www.eprofile.gg" />
      </Typography>
      <Typography>
        <b>Usuario Registrado</b>: Aquellos usuarios que han creado una Cuenta y que pueden hacer uso de la totalidad o
        parte de los Servicios provistos por eProfile.
      </Typography>
    </Fragment>
  );
};

TermsTexts.propTypes = {};

export default TermsTexts;
