import * as aTypes from "./types";

export const userComparisonUpdateComparedGame = gameId => ({
  type: aTypes.USER_COMPARISON_UPDATE_COMPARED_GAME,
  payload: gameId
});

export const userComparisonUpdateComparedRegion = region => ({
  type: aTypes.USER_COMPARISON_UPDATE_COMPARED_REGION,
  payload: region
});

export const userComparisonUpdateComparedNickname = nickname => ({
  type: aTypes.USER_COMPARISON_UPDATE_COMPARED_NICKNAME,
  payload: nickname
});
export const userComparisonUpdateComparedProfileImage = profileImage => ({
  type: aTypes.USER_COMPARISON_UPDATE_COMPARED_PROFILE_IMAGE,
  payload: profileImage
});
