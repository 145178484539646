import React from "react";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const LightBlueButton = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.home.lightGreen,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: darken(theme.palette.home.lightGreen, 0.2)
    }
  }
}))(Button);

export default LightBlueButton;
