import { fetchCognitiveAbilities } from "./actions";
import api from "api";
import { getAuthToken } from "redux/state/auth/selectors";
import { ofType } from "redux-observable";
import { AUTH_LOGIN_SUCCESS, REGISTER_SUCCESS } from "redux/state/auth";
import { mapTo } from "rxjs/operators";
import { mapResponseAbilitiesToState } from "redux/state/cognitive-abilities/utils";
import { LOGIN_PAGE_LOADED } from "redux/state/ui/pages/login";
import { PREFETCH_ABILITIES } from "redux/state/prefetch/types";

const _fetchAbilities = () => async (dispatch, getState) => {
  dispatch(
    fetchCognitiveAbilities(
      api.cognitiveAbilities.fetchAll(getAuthToken(getState())),
      mapResponseAbilitiesToState
    )
  );
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const fetchCognitiveAbilitiesEpic = action$ =>
  action$.pipe(
    ofType(PREFETCH_ABILITIES),
    mapTo(_fetchAbilities())
  );
