import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export default createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#503DD5",
      main1_light: "#320064",
      main2_light: "#433380",
      main3_light: "#661690",
      main4_light: "#c1add5",
      main1_dark: "#0f0027",
      main2_dark: "#120740",
      second: "#E61E46",
      second2: "#ED6292"
    },
    whiteText: "#c6ddff",
    players: {
      user: {
        main: "#FF5376"

      },
      compare: {
        main: "#00D4FF"
      }
    },
    gradients: {
      lightToDark: "linear-gradient(to right, #ED6292 0%,#B2013F 11%,#5A42EC 100%)",
      darkToLight: "linear-gradient(to right, #5a42ec 2%,#b2013f 84%,#ed6292 100%)",
      darkBlue: "linear-gradient(90deg, rgba(26,20,77,1) 0%, rgba(17,12,62,1) 100%)"
    },
    home: {
      backgroundGradient: "radial-gradient(ellipse at 70% 40%, #4a3ac3 0%,#333183 76%)",
      pinkGradient: "linear-gradient(to bottom, #646dd6 0%,#ff5e6e 79%)",
      pinkGradientRight: "linear-gradient(45deg, #646dd6 0%,#ff5e6e 79%)",
      blueGradient: "linear-gradient(to bottom, #3b349b 0%,#00f8ff 80%)",
      blueGradientRight: "linear-gradient(45deg, #00f8ff 14%,#3b349b 98%)",
      lightBlue: "#646DD6",
      lightGreen: "#02D5AA",
      cardBackground: "#5A50E0",
      cardExpansionBackground: "rgb(50, 45, 128)",
    },
    secondary: {
      main: "#FF5376",
      second: "#320064"
    },
    tertiary: {
      main: "#E61E46"
    },
    appbar: {
      main: "#503DD5"
    },
    sidebar: {
      backgroundGradient: "linear-gradient(to bottom, #1c093d 1%,#401a66 99%)"
    },
    title: {
      background: "#661690"
    }
  },
  typography: {
    fontFamily: [
      "Exo",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    button: {
      fontWeight: "bold"
    }
  }
});
