import { darken } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const LightRedButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.tertiary.main),
    backgroundColor: theme.palette.tertiary.main,
    // borderColor: theme.palette.text.primary,
    padding: theme.spacing(1, 4),
    "&:hover": {
      backgroundColor: darken(theme.palette.tertiary.main, 0.3)
    }
  }
}))(Button);

export default LightRedButton;
