import * as aTypes from "./types";

export const updatePasswordResetEmail = email => ({ type: aTypes.UPDATE_PASSWORD_RESET_EMAIL, payload: email });
export const updatePasswordResetPassword = password => ({
  type: aTypes.UPDATE_PASSWORD_RESET_PASSWORD,
  payload: password
});

export const updatePasswordResetRepeatPassword = password => ({
  type: aTypes.UPDATE_PASSWORD_RESET_REPEAT_PASSWORD,
  payload: password
});

export const passwordResetStartEmailFormValidation = () => ({
  type: aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_START
});
export const passwordResetEmailFormValidationSuccess = () => ({
  type: aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS
});
export const passwordResetEmailFormValidationFailure = errors => ({
  type: aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_FAILURE,
  payload: errors
});


export const passwordResetStartUpdatePasswordFormValidation = () => ({
  type: aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START
});
export const passwordResetUpdatePasswordFormValidationSuccess = () => ({
  type: aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS
});
export const passwordResetUpdatePasswordFormValidationFailure = errors => ({
  type: aTypes.PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_FAILURE,
  payload: errors
});
