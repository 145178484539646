const loginForm = state => state.forms.loginForm.credentials;
const loginFormError = state => state.forms.loginForm.error;

/**
 * SELECTORS
 */
export const getLoginUsername = state => loginForm(state).username;
export const getLoginPassword = state => loginForm(state).password;
export const getLoginIsError = state => loginFormError(state).error;
export const getLoginErrorStatusText = state => loginFormError(state).statusText;
export const getLoginErrorMsg = state => loginFormError(state).message;
export const getLoginErrorStatus = state => loginFormError(state).status;
