import * as authTypes from "redux/state/auth/types";
import * as aTypes from "./types";
import * as utils from "./utils";
import { STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS } from "redux/state/studies/types";
import { combineReducers } from "redux";
import { LOGOUT } from "redux/state/auth/types";
import { PROFILE_CHECKS } from "config/constants";
import ERROR_SHAPE from "api/error-model";

const userInitState = {
  userId: "",
  gender: "",
  profileImage: "",
  age: 0,
  studies: [],
  completedStudies: false,
  eProfileUsername: "",
  isVerified: false,
  isActive: false,
  isPublicProfile: false,
  isComparable: false,
  mainGame: {
    game_id: "",
    region_id: "",
    game_username: "",
    wins: 0
  },
  score: { position: "", total: "", game_id: "" }
};

const eProfileResultsInit = {
  stats: {
    bestStat: { value: "", title: "", name: "", ability_id: "" },
    worstStat: { value: "", title: "", name: "", ability_id: "" },
    ranking: { value: "", title: "" }
  }
};

const submitShape = {
  value: "",
  submitting: false,
  submitted: false,
  error: ERROR_SHAPE
};
const loadingInit = { isLoading: false, loaded: false };

const updateProfileInit = { value: "", field: "", loading: loadingInit, error: ERROR_SHAPE };

const errorInit = ERROR_SHAPE;
const validationInit = PROFILE_CHECKS.NOT_CHECKED;
const imageInit = { filename: "", file: null, type: "" };
const userSocialNetworksInit = {
  byId: {},
  allIds: [],
  loading: { isLoading: false, loaded: false, fetched: false },
  error: ERROR_SHAPE,
  socialNetworks: []
};

const user = (state = userInitState, action) => {
  switch (action.type) {
    case authTypes.AUTH_LOGIN_FAILURE:
    case authTypes.REGISTER_FAILURE:
    case authTypes.LOGOUT:
      return { ...state, ...userInitState };

    case authTypes.AUTH_LOGIN_SUCCESS:
    case authTypes.REGISTER_SUCCESS:
    case aTypes.FETCH_PROFILE_SUCCESS:
    case aTypes.PROFILE_REQUEST_PUT_IMAGE_SUCCESS:
    case authTypes.VALIDATE_TOKEN_SUCCESS:
    case aTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, ...action.payload };

    case STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
      const data = action.payload;
      const key = Object.keys(data)[0];
      return state.studies.indexOf(key) === -1
        ? {
            ...state,
            studies: state.studies.concat(Object.keys(data)[0])
          }
        : state;

    default:
      return state;
  }
};

const loading = (state = loadingInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_PROFILE_START:
      return { ...state, isLoading: true };

    case aTypes.FETCH_PROFILE_FAILURE:
      return { ...state, isLoading: false, loaded: false };

    case aTypes.FETCH_PROFILE_SUCCESS:
      return { isLoading: false, loaded: true };

    case LOGOUT:
      return { ...loadingInit };

    default:
      return state;
  }
};

const image = (state = imageInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_UPDATE_IMAGE:
      return action.payload && action.payload.name
        ? {
            ...state,
            filename: action.payload.name,
            file: action.payload,
            type: action.payload.type
          }
        : state;
    case aTypes.PROFILE_REQUEST_PUT_IMAGE_FAILURE:
    case LOGOUT:
      return { ...imageInit };

    default:
      return state;
  }
};

const profileCheck = (state = validationInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_CHECK_SUCCESS:
      return PROFILE_CHECKS.SUCCESS;

    case aTypes.PROFILE_CHECK_VERIFY_USER:
      return PROFILE_CHECKS.VERIFY_USER;

    case aTypes.PROFILE_CHECK_FINISH_TESTS:
      return PROFILE_CHECKS.FINISH_TESTS;

    case aTypes.PROFILE_CHECK_FAILURE:
      return PROFILE_CHECKS.FAILURE;

    case authTypes.LOGOUT:
      return PROFILE_CHECKS.NOT_CHECKED;

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_PROFILE_FAILURE:
      return { ...state, ...action.payload, error: true };

    case aTypes.FETCH_PROFILE_START:
    case aTypes.FETCH_PROFILE_SUCCESS:
      return { ...errorInit };
    default:
      return state;
  }
};

const eProfileResults = (state = eProfileResultsInit, action) => {
  switch (action.type) {
    case STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
      const data = action.payload;
      const keys = Object.keys(data);
      const results = data[keys].results;
      const maxValue = results.reduce(
        (maxVal, current) => {
          return current.value >= maxVal.value ? current : maxVal;
        },
        { value: 0 }
      );
      const worstValue = results.reduce(
        (maxVal, current) => {
          return current.value <= maxVal.value ? current : maxVal;
        },
        { value: 100 }
      );

      return {
        ...state,
        stats: { ...state.stats, bestStat: { ...maxValue }, worstStat: { ...worstValue } }
      };

    case LOGOUT:
      return eProfileResultsInit;

    default:
      return state;
  }
};

const updateProfile = (state = updateProfileInit, action) => {
  switch (action.type) {
    case aTypes.UPDATE_PROFILE_START:
      const data = action.sourceMeta.data;
      const paramKey = Object.keys(data)[0];

      return state.loading.isLoading
        ? state
        : {
            ...state,
            loading: {
              isLoading: true,
              loaded: false
            },
            error: ERROR_SHAPE,
            value: data[paramKey],
            field: paramKey
          };

    case aTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        value: "",
        loading: {
          isLoading: false,
          loaded: true
        },
        error: ERROR_SHAPE,
        field: ""
      };

    case aTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: {
          isLoading: false,
          loaded: true
        },
        error: action.payload
      };

    case aTypes.UPDATE_PROFILE_RESET:
      return updateProfileInit;

    default:
      return state;
  }
};

const userSocialNetworks = (state = userSocialNetworksInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_FETCH_SOCIAL_NETWORKS_START:
      return { ...state, loading: { ...state.loading, isLoading: true, loaded: false }, error: errorInit };

    case aTypes.PROFILE_FETCH_SOCIAL_NETWORKS_FAILURE:
      return { ...state, loading: { isLoading: false, loaded: false, fetched: false }, error: action.payload };

    case aTypes.PROFILE_FETCH_SOCIAL_NETWORKS_SUCCESS:
      return {
        ...state,
        loading: { isLoading: false, loaded: true, fetched: true },
        error: errorInit,
        allIds: [...new Set(state.allIds.concat(Object.keys(action.payload)))],
        byId: { ...state.byId, ...action.payload },
        socialNetworks: utils.getSocialNetworks(action.payload)
      };

    case aTypes.PROFILE_UPDATE_USER_SOCIAL_NETWORK_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.payload },
        allIds: [...new Set(state.allIds.concat(Object.keys(action.payload)))],
        socialNetworks: { ...state.socialNetworks, ...utils.getSocialNetworks(action.payload) }
      };

    case LOGOUT:
      return userSocialNetworksInit;

    default:
      return state;
  }
};

export default combineReducers({
  user,
  loading,
  profileCheck,
  error,
  eProfileResults,
  image,
  updateProfile,
  userSocialNetworks
});
