import React, { useRef } from "react";
import PropTypes from "prop-types";
import PlayerSearchFormView from "app/pages/home/compare/spider-section/player-information/player-search-form/PlayerSearchFormView";
import {
  autocompleteProps,
  playerSearchFormProps
} from "app/pages/home/compare/spider-section/spiderProps";

const PlayerSearchForm = ({ disabled, isUser, formState, autocompleteItems }) => {
  return (
    <PlayerSearchFormView autocompleteItems={autocompleteItems} isUser={isUser} {...formState} disabled={disabled} />
  );
};

PlayerSearchForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isUser: PropTypes.bool,
  formState: PropTypes.shape(playerSearchFormProps),
  autocompleteItems: PropTypes.shape(autocompleteProps)
};

PlayerSearchForm.defaultProps = {
  disabled: false,
  isUser: false
};

export default PlayerSearchForm;
