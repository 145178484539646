import pagesReducer from "./pages";
import componentsReducer from "./components";
import { combineReducers } from "redux";

import { pagesEpic } from "./pages";
import { combineEpics } from "redux-observable";

export const viewEpic = combineEpics(pagesEpic);

export default combineReducers({
  pages: pagesReducer,
  components: componentsReducer
});
