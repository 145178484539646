import React from "react";
import PropTypes from "prop-types";
import MyProfileSpiderSectionView from "app/components/my-profile/myprofile-spider-section/MyProfileSpiderSectionView";

const MyProfileSpiderSection = ({ isPublic }) => {
  return <MyProfileSpiderSectionView isPublic={isPublic} />;
};

MyProfileSpiderSection.propTypes = {};

export default MyProfileSpiderSection;
