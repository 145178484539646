import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { darken, makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { REGISTER_FORM, REGISTER_VALIDATION_ERR } from "../../../i18n/selectors";
import { useTranslation } from "react-i18next";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import GrayInput from "app/components/CustomInput/GrayInput";
import Grid from "@material-ui/core/Grid";
import routes from "app/routes";
import WhiteLink from "app/components/white-link/WhiteLink";
import WhiteSelect from "app/components/custom-select/WhiteSelect";

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  container: {
    padding: theme.spacing(4)
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between"
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    fontStyle: "italic",
    letterSpacing: ".1em"
  },
  subtitle: {
    textAlign: "center",
    marginBottom: theme.spacing(4)
  },
  card: {
    marginTop: theme.spacing(4),
    // background: "rgba(255, 255, 255, .09)"
    background: "linear-gradient(to right, rgba(90,121,222, .5) 0%,rgba(106,37,138, .5) 37%, rgba(183,8,141, .5) 100%)"
  },
  group: {
    padding: theme.spacing(2)
  },
  groupVerticalSpacing: {
    paddingBottom: theme.spacing(2)
  },
  center: {
    textAlign: "center",
    paddingBottom: theme.spacing(4)
  },
  radioText: {
    fontSize: "14px",
    color: "rgba(255, 255, 255, .6)",
    userSelect: "none"
  },
  registerButton: {
    borderRadius: 0,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.getContrastText(theme.palette.tertiary.main),
    "&:hover": {
      backgroundColor: darken(theme.palette.tertiary.main, 0.3)
    }
  },
  termsHelper: {
    marginTop: "0px"
  }
}));

const RegisterFormView = props => {
  const classes = useStyles();
  const { errors } = props;
  const { t } = useTranslation();

  return (
    <AuthFormContainer title={t(`${REGISTER_FORM}.title`)} subtitle={t(`${REGISTER_FORM}.subtitle`)}>
      {/* EMAIL / USERNAME */}
      <Grid item xs={12} sm={6} className={classes.group}>
        <GrayInput
          label={t(`${REGISTER_FORM}.email.placeholder`)}
          error={errors.email.error}
          helperText={
            errors.email.error
              ? t(`${REGISTER_VALIDATION_ERR}.email.${errors.email.errorMsg}`)
              : t(`${REGISTER_FORM}.email.hint`)
          }
          fullWidth={true}
          type={"email"}
          value={props.email}
          onChange={props.updateEmail}
        />
        <div className={classes.groupVerticalSpacing} />
        <GrayInput
          id="eprofile-username"
          label={t(`${REGISTER_FORM}.eprofileUsername.placeholder`)}
          error={errors.mindsetUsername.error}
          helperText={
            errors.mindsetUsername.error
              ? t(`${REGISTER_VALIDATION_ERR}.eprofileUsername.${errors.mindsetUsername.errorMsg}`)
              : t(`${REGISTER_FORM}.eprofileUsername.hint`)
          }
          fullWidth={true}
          value={props.mindsetUsername}
          onChange={props.updateMindsetUsername}
        />
      </Grid>

      {/* PASSWORD / CONFIRM PASSWORD */}
      <Grid item xs={12} sm={6} className={classes.group}>
        <GrayInput
          id="password"
          error={errors.password.error}
          helperText={
            errors.password.error
              ? t(`${REGISTER_VALIDATION_ERR}.password.${errors.password.errorMsg}`)
              : t(`${REGISTER_FORM}.password.hint`)
          }
          type={"password"}
          label={t(`${REGISTER_FORM}.password.placeholder`)}
          fullWidth={true}
          value={props.password}
          onChange={props.updatePassword}
        />
        <div className={classes.groupVerticalSpacing} />
        <GrayInput
          id="repeat-password"
          error={errors.repeatPassword.error}
          helperText={
            errors.repeatPassword.error
              ? t(`${REGISTER_VALIDATION_ERR}.repeatPassword.${errors.repeatPassword.errorMsg}`)
              : t(`${REGISTER_FORM}.repeatPassword.hint`)
          }
          type={"password"}
          label={t(`${REGISTER_FORM}.repeatPassword.placeholder`)}
          fullWidth={true}
          value={props.repeatPassword}
          onChange={props.updateRepeatPassword}
        />
      </Grid>

      {/* BIRTHDAY / GENDER */}
      <Grid item xs={12} sm={6} className={classes.group}>
        <WhiteSelect
          fullWidth={true}
          onChange={props.updateGender}
          data={[
            { id: "Masculino", name: "Masculino" },
            { id: "Femenino", name: "Femenino" },
            { id: "Otro", name: "Otro" }
          ]}
          error={errors.gender.error}
          value={props.gender}
          label={t(`${REGISTER_FORM}.gender.placeholder`)}
          helperText={errors.gender.error ? t(`${REGISTER_VALIDATION_ERR}.gender.${errors.gender.errorMsg}`) : ""}
        />

        <div className={classes.groupVerticalSpacing} />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            TextFieldComponent={GrayInput}
            id="player-birthday"
            disableFuture
            error={errors.birthday.error}
            helperText={
              errors.birthday.error ? t(`${REGISTER_VALIDATION_ERR}.birthday.${errors.birthday.errorMsg}`) : ""
            }
            color={"secondary"}
            fullWidth={true}
            inputVariant="outlined"
            openTo="year"
            format="yyyy/MM/dd"
            label={t(`${REGISTER_FORM}.birthday.placeholder`)}
            views={["year", "month", "date"]}
            value={props.birthday}
            onChange={props.updateBirthday}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.groupVerticalSpacing} />
        <GrayInput
          id="referred_id"
          helperText={t(`${REGISTER_FORM}.referred_id.hint`)}
          type={"text"}
          label={t(`${REGISTER_FORM}.referred_id.placeholder`)}
          fullWidth={true}
          value={props.referredId}
          onChange={props.updateReferredId}
        />
      </Grid>

      {/* MAIN GAME / SUMMONER NAME */}
      <Grid item xs={12} sm={6} className={classes.group}>
        <WhiteSelect
          value={props.mainGame}
          error={errors.mainGame.error}
          onChange={props.updateMainGame}
          fullWidth={true}
          data={props.games}
          label={t(`${REGISTER_FORM}.mainGame.placeholder`)}
          helperText={errors.mainGame.error ? t(`${REGISTER_VALIDATION_ERR}.mainGame.${errors.mainGame.errorMsg}`) : ""}
        />

        {props.regions.length > 0 && (
          <Fragment>
            <div className={classes.groupVerticalSpacing} />
            <WhiteSelect
              value={props.region}
              onChange={props.updateRegion}
              error={errors.region.error}
              fullWidth={true}
              data={props.regions}
              label={t(`${REGISTER_FORM}.region.placeholder`)}
              helperText={
                errors.region.error
                  ? t(`${REGISTER_VALIDATION_ERR}.region.${errors.region.errorMsg}`)
                  : t(`${REGISTER_FORM}.region.hint`)
              }
            />

            <div className={classes.groupVerticalSpacing} />
            <GrayInput
              id="summoner-name"
              error={errors.summonerName.error}
              helperText={
                errors.summonerName.error
                  ? t(`${REGISTER_VALIDATION_ERR}.summonerName.${errors.summonerName.errorMsg}`)
                  : t(`${REGISTER_FORM}.summonerName.hint`)
              }
              fullWidth={true}
              label={t(`${REGISTER_FORM}.summonerName.placeholder`)}
              value={props.summonerName}
              onChange={props.updateSummonerName}
            />
          </Fragment>
        )}
      </Grid>

      {/* CALL TO ACTION */}
      <Grid item xs={12} className={classes.center}>
        <br />
        <FormControl component={"fieldset"} error={errors.terms.error}>
          <FormControlLabel
            control={<Checkbox checked={props.terms} onChange={props.updateTerms} color={"default"} />}
            label={
              <span>
                Acepto los <WhiteLink href={routes.termsAndConditions.route} text={"Términos y Condiciones "} />
                y la <WhiteLink href={routes.privacy.route} text={"Política de Privacidad"} />
              </span>
            }
            classes={{ label: classes.radioText }}
          />
          <FormHelperText className={classes.termsHelper}>
            {errors.terms.error ? t(`${REGISTER_VALIDATION_ERR}.terms.${errors.terms.errorMsg}`) : ""}
          </FormHelperText>
        </FormControl>
        <br />
        <Button onClick={props.startRegistration} className={classes.registerButton} variant={"contained"}>
          Registrarme
        </Button>
      </Grid>

      {/* GROUP SPACING */}
    </AuthFormContainer>
  );
};

RegisterFormView.propTypes = {
  loadingValidation: PropTypes.bool,
  terms: PropTypes.bool,
  name: PropTypes.string,
  lastname: PropTypes.string,
  password: PropTypes.string,
  profilePicture: PropTypes.object,
  repeatPassword: PropTypes.string,
  email: PropTypes.string,
  mindsetUsername: PropTypes.string,
  birthday: PropTypes.string,
  lolSelected: PropTypes.bool,
  gender: PropTypes.string,
  updateName: PropTypes.func,
  updateLastname: PropTypes.func,
  updateGender: PropTypes.func,
  updatePassword: PropTypes.func,
  updateRepeatPassword: PropTypes.func,
  updateEmail: PropTypes.func,
  updateBirthday: PropTypes.func,
  updateMindsetUsername: PropTypes.func,
  updateTerms: PropTypes.func,
  toggleLolSelect: PropTypes.func,
  // validateAndFetchInfo: PropTypes.func,
  handleChangePicture: PropTypes.func
};

export default RegisterFormView;
