import * as actions from "./actions";
import api from "api";
import { ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { LOGIN_PAGE_LOADED } from "redux/state/ui/pages/login";
import { mapGamesToState } from "./utils";
import { PREFETCH_GAMES } from "redux/state/prefetch/types";

const _fetchGames = () => {
  return actions.fetchGames(api.games.fetchAll(), mapGamesToState);
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const fetchGamesEpic = action$ =>
  action$.pipe(
    ofType(PREFETCH_GAMES),
    map(action => _fetchGames())
  );
