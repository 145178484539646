import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import WhiteLink from "app/components/white-link/WhiteLink";

const URL_ROOT = "";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  italic: {
    fontStyle: "italic"
  }
}));

const PrivacyTexts = props => {
  const classes = useStyles();
  return (
    <Fragment>
      <br />
      <Typography paragraph>
        eProfile, sistema de propiedad de SoluNova SpA, en adelante SoluNova, informa esta Política de Tratamiento de
        Datos Personales con el fin de que nuestros usuarios se encuentren debidamente informados de nuestras políticas
        y procedimientos relativos al tratamiento de datos personales, en especial en lo que se refiere a la recogida,
        uso y comunicación de la información entregada por los usuarios a eProfile.
      </Typography>
      <Typography paragraph>
        eProfile declara como principio fundamental que cumple con la normativa vigente respecto a la protección de los
        datos, especialmente con la normativa chilena sobre protección de la vida privada (Ley 19.628).
      </Typography>

      <Typography paragraph>
        Sírvase leer cuidadosamente nuestra Política de Tratamiento de Datos, para comprender cómo eProfile trata sus
        datos personales. Si no está de acuerdo con nuestra Política deberá abstenerse de utilizar nuestros servicios.
        Si ya ha adquirido servicios sometidos a esta política, por favor interrumpa la instalación del sistema
        eProfile, suspenda el uso de dichos servicios si ya lo ha hecho y comuníquese con eProfile mediante los datos de
        contacto que se detallan más adelante.
      </Typography>
      <Typography paragraph>
        Si tiene preguntas o comentarios sobre esta Política de Tratamiento de Datos, por favor póngase en contacto con
        nosotros a través del correo electrónico info@eprofile.gg.
      </Typography>

      <Typography paragraph className={classes.title}>
        Responsabilidad
      </Typography>

      <Typography paragraph>
        De conformidad con lo dispuesto en el artículo 2 letra n) de la Ley 19.628 el responsable del registro o banco
        de datos, es la persona jurídica a quien compete las decisiones relacionadas con el tratamiento de los datos de
        carácter personal.
      </Typography>
      <Typography paragraph>
        A los efectos de la presente Política el responsable del tratamiento de los Datos Personales es SoluNova SpA, el
        que puede ser contactado al correo electrónico: info@eprofile.gg o en la calle Almirante Churruca 3130, Comuna
        de Santiago, Santiago de Chile.
      </Typography>

      <Typography paragraph className={classes.title}>
        Recogida y uso de la información
      </Typography>

      <Typography paragraph>
        Recolectamos y usamos su información para la correcta operación de nuestros Servicios y mejorarlos con el
        tiempo. Asimismo, usamos los datos para dar soporte a nuestros usuarios y clientes.
      </Typography>
      <Typography paragraph>
        Los datos recolectados son objeto de tratamiento informatizado, por lo que, para el supuesto en el que puedan
        ser utilizados por eProfile con una finalidad comercial, de personalización y actividades propias de su objeto;
        eProfile queda expresamente autorizado, por el usuario, para el tratamiento, almacenamiento de datos y estudios
        de marketing con el objetivo de personalizar sus ofertas al perfil individual del usuario. Los datos serán
        almacenados durante el período que sea necesario de acuerdo a la finalidad para la cual fueron recolectados:
        correcta operación de nuestros Servicios y dar soporte a nuestros usuarios y clientes. Una vez transcurrido ese
        tiempo, los datos serán anonimizados.
      </Typography>

      <Typography paragraph className={classes.title}>
        Datos que la plataforma eProfile recolecta:
      </Typography>
      <Typography paragraph>
        <b>Información personal de los usuarios</b>: La información personal a la que eProfile tendrá acceso corresponde
        a:
      </Typography>
      <Typography>a) Tag eProfile.</Typography>
      <Typography>b) Tag name del usuario en sus juegos de interés</Typography>
      <Typography>c) Fotografía personal.</Typography>
      <Typography>d) Correo electrónico.</Typography>
      <Typography>e) Sexo.</Typography>
      <Typography>f) Fecha de nacimiento.</Typography>
      <Typography>g) Región.</Typography>
      <Typography>
        h) Información de la performance en videojuegos, sea que la proporciones directamente o la obtengamos de
        aplicaciones de juego en que participes y con quienes nos encontremos integrados..
      </Typography>
      <Typography>
        i) Información que pueda afectar el resultado de las pruebas tests cognitivos, como por ejemplo si has consumido
        psicotrópicos previo a la realización de las pruebas.
      </Typography>
      <br />
      <Typography paragraph>
        <b>Datos de navegación</b>: Cuando el usuario utiliza eProfile los servidores graban automáticamente la
        información en relación con el uso por parte del usuario. Estos datos de registro pueden incluir información
        como:
      </Typography>
      <Typography>a) La dirección IP</Typography>
      <Typography>b) Tipo de navegador</Typography>
      <Typography>c) Dominios que el usuario visita</Typography>
      <Typography>d) Páginas en que navega</Typography>
      <Typography>e) Productos que revisa</Typography>
      <Typography>f) Hora en que usa eProfile</Typography>
      <Typography>g) Términos de búsqueda</Typography>
      <br />
      <Typography paragraph>
        <b>Cookies</b>: El acceso a <WhiteLink href={URL_ROOT} text={"www.eprofile.gg"} />, puede implicar la
        utilización de cookies. Una cookie es un pequeño archivo de información que se transfiere al disco duro del
        computador para que el servidor recuerde cierta información que, posteriormente, y únicamente el servidor que la
        implementó leerá. Gracias a las cookies, eProfile puede identificar a los navegadores de los usuarios.
      </Typography>

      <Typography paragraph>
        eProfile informa al usuario que, en aras de su protección personal ante esta clase de archivos Cookie, tiene la
        posibilidad de configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la
        instalación de cookies en su disco duro. Recomendamos la consulta de las instrucciones y manuales de su
        navegador para ampliar ésta información.
      </Typography>
      <Typography paragraph className={classes.title}>
        ¿Qué tipo de cookies utilizamos?
      </Typography>
      <Typography paragraph className={classes.title}>
        1. Cookies técnicas.
      </Typography>

      <Typography paragraph>
        Son aquéllas que permiten al usuario la navegación a través de un dispositivo y la utilización de las diferentes
        opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos,
        identificar la sesión, acceder a subsitios de acceso restringido, recordar los elementos que integran un pedido,
        realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento,
        utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido
        o compartir contenidos a través de redes sociales.
      </Typography>

      <Typography paragraph className={classes.title}>
        2. Cookies de análisis.
      </Typography>

      <Typography paragraph>
        Son aquéllas que permiten a eProfile, el seguimiento y análisis del comportamiento de los usuarios de los sitios
        web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición
        de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de
        los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del
        análisis de los datos de uso que hacen los usuarios del servicio.
      </Typography>

      <Typography paragraph className={classes.title}>
        3. Publicitarias.
      </Typography>

      <Typography paragraph>
        Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que se
        encuentren en nuestra página web, aplicación o plataforma.
      </Typography>

      <Typography paragraph className={classes.title}>
        4. De publicidad comportamental.
      </Typography>

      <Typography paragraph>
        Son aquéllas que, además de permitir la gestión, de la forma más eficaz posible, de los espacios publicitarios
        de nuestra página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies
        almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus
        hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del
        mismo.
      </Typography>

      <Typography paragraph className={classes.title}>
        Finalidades y bases legales para el tratamiento.
      </Typography>
      <Typography>
        a) Datos de contacto, al consentir en forma expresa y escrita al momento de crear su cuenta de usuario
        registrado.
      </Typography>
      <Typography>
        b) Datos de pruebas cognitivas, al consentir en forma expresa y escrita al momento de crear su cuenta de usuario
        registrado.
      </Typography>
      <Typography>
        c) Comunicaciones comerciales personalizadas, al consentir en que sean tratados sus datos personales para el
        envío de comunicaciones comerciales relativas a productos de eProfile por cualquier medio incluido el
        telefónico, postal y correo electrónico, SMS, medio de comunicación equivalente, o aviso durante la navegación.
      </Typography>
      <br />
      <Typography paragraph className={classes.title}>
        Limitación en el tratamiento de datos
      </Typography>
      <Typography paragraph>
        eProfile limita el tratamiento de datos personales al mínimo necesario para cumplir el propósito para el cual se
        recolectaron estos datos y mientras se mantenga la finalidad que motivó el tratamiento de los datos personales.
      </Typography>
      <Typography paragraph>
        eProfile usa los datos para generar valor agregado a sus usuarios a partir del comportamiento de éstos, siendo
        parte importante de la propuesta de valor de eProfile. Sólo así, es posible que los usuarios registrados se
        comparen con otros usuarios y puedan compartir sus resultados.
      </Typography>
      <Typography paragraph>
        El procesamiento permite personalizar el perfil del usuario registrado de acuerdo a sus juegos de video de
        interés. Asimismo, permite entregar al usuario registrado el resultado de las pruebas cognitivas realizadas por
        él, junto con resultados de otros usuarios con los que el usuario se quiera comparar, siempre que estos hayan
        autorizado a ser comparados. Finalmente, permite realizar calibración estadística de los resultados, y análisis
        estadísticos para entender la relación entre habilidades cognitivas y desempeño en videojuegos.
      </Typography>
      <Typography paragraph className={classes.title}>
        Intercambio y difusión de información
      </Typography>
      <Typography>eProfile no comparte tu información con terceros.</Typography>
      <Typography>
        <b>Ley y perjuicios</b>: eProfile puede revelar información del usuario si creyera que es razonablemente
        necesario para hacer cumplir la ley, normativa o requerimiento judicial o administrativo, para proteger la
        seguridad de cualquier persona, tratar fraudes, problemas técnicos o de seguridad o proteger los derechos o
        propiedad de eProfile.
      </Typography>
      <Typography>
        <b>Seguridad de los datos</b>: Todos los datos que maneja eProfile se encuentran enviados a sus bases de datos
        mediante protocolos encriptados y seguros (HTTPS), los cuales, una vez almacenados se protegen mediante
        firewalls que sólo permiten el acceso a los datos desde los servidores de eProfile mediante un usuario y
        contraseña privada.
      </Typography>
      <Typography>
        Los datos no son almacenados en forma encriptada. Las contraseñas de los usuarios son almacenadas por medio de
        un algoritmo de hash con un sistema salt
      </Typography>
      <Typography>
        <b>Información no privada o no personal</b>: eProfile puede compartir o divulgar información con fines
        comerciales, de manera agregada, con fines estadísticos o de cualquier forma que no permita que el usuario sea
        identificado o identificable.
      </Typography>
      <br />
      <Typography paragraph className={classes.title}>
        Exactitud
      </Typography>
      <Typography paragraph>
        Al entregar información a eProfile, puedes asegurarte que la información personal que tenemos de ti sea exacta y
        esté actualizada. Tú suministras la información y, si ésta cambia, simplemente puedes actualizar tu perfil.
      </Typography>
      <Typography paragraph>
        eProfile no trata información que no sea proporcionada directamente por los usuarios y con el consentimiento,
        informado, expreso, inequívoco y escrito del titular de los datos o aquella que a solicitud del interesado se
        obtenga de los órganos de la administración del Estado o de organismos privados de interés público para la
        realización de un trámite o prestación de un servicio.
      </Typography>
      <Typography paragraph className={classes.title}>
        Modificar información personal
      </Typography>
      <Typography paragraph>Los usuarios pueden modificar la información personal que hayan proporcionado.</Typography>
      <Typography paragraph className={classes.title}>
        Cambios en esta política
      </Typography>
      <Typography paragraph>
        Esta Política de Protección de Datos Personales podrá ser modificada por SoluNova cuando lo considere oportuno.
        La versión más actualizada estará siempre disponible en la página principal{" "}
        <WhiteLink href={`${URL_ROOT}/privacy`} text={"www.eprofile.gg/privacy"} />. Si la modificación es considerada
        esencial por eProfile se lo notificaremos al correo electrónico registrado en eProfile.
      </Typography>
    </Fragment>
  );
};

PrivacyTexts.propTypes = {};

export default PrivacyTexts;
