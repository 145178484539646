import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { sectionContainer, paper, title } from "app/pages/home/styles";
import AbilityCard from "app/pages/home/compare/eprofile-results/ability-card/AbilityCard";
import ico_sin_fondo from "app/assets/svg/ico_sin fondo.svg";
import { useHistory } from "react-router-dom";
import routes from "app/routes";
import Button from "@material-ui/core/Button";
import RouterLink from "app/components/router-link/RouterLink";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    ...sectionContainer(theme),
    color: theme.palette.whiteText
  },

  paper: {
    ...paper(theme),
    paddingLeft: theme.spacing(2),
    height: "120px",
    width: "100%"
  },
  // title: {
  //   paddingTop: theme.spacing(6),
  //   paddingBottom: theme.spacing(2),
  //   paddingLeft: theme.spacing(2)
  // },
  ...title(theme),
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  abilitiesContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  shareButton: {
    backgroundColor: theme.palette.home.lightBlue,
    borderColor: theme.palette.text.primary,
    borderWidth: "1px",
    borderStyle: "solid",
    color: theme.palette.text.primary
  },
  publicTitleContainer: {
    display: "flex",
    // alignContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4)
    }
  },
  publicTitle: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: theme.palette.players.user.main,
    // padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem"
    }
  },
  smallPublicTitle: {
    fontSize: "16px"
  },
  logoIcon: {
    width: "3.5rem",
    cursor: "pointer",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: "4.5rem",
      paddingLeft: theme.spacing(1)
    },
    [theme.breakpoints.up("lg")]: {
      width: "5rem"
    }
  }
}));

const EprofileResultsSectionView = ({
  title,
  shareButton,
  results,
  components,
  isPublic,
  username,
  isHomeMyProfileSelected
}) => {
  const classes = useStyles();
  const componentsLen = components.length || 0;
  const history = useHistory();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleClickLink = () => history.push(routes.index.route);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {isPublic || isHomeMyProfileSelected ? (
          <div className={classes.publicTitleContainer}>
            <img onClick={handleClickLink} className={classes.logoIcon} src={ico_sin_fondo} />
            <Typography variant={"h4"} className={classes.publicTitle}>
              {title} {isPublic && <span className={classes.smallPublicTitle}>(perfil público)</span>}
            </Typography>
          </div>
        ) : (
          <Typography variant={"h6"} className={classes.title}>
            {`/ ${title}`}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} className={classes.abilitiesContainer}>
        <Grid container data-tut="reactour__results">
          {components.map((key, index) => {
            return (
              <AbilityCard
                key={key}
                index={index}
                len={componentsLen}
                ranking={results[key].id === "ranking"}
                value={results[key].value}
                description={results[key].description}
                title={results[key].title}
                isPublic={isPublic}
              />
            );
          })}

          {/*<Grid item lg={3} xs={12} md={6} sm={6} className={classes.buttonContainer}>*/}
          {/*  <LightBlueButton disabled startIcon={<ShareIcon />} variant={"contained"}>*/}
          {/*    {shareButton}*/}
          {/*  </LightBlueButton>*/}
          {/*</Grid>*/}
          {xs && !isPublic && (
            <Grid container>
              <Grid item xs={12} style={{ padding: "0px 8px 20px" }}>
                <Button
                  data-tut={"reactour__public-profile"}
                  fullWidth
                  className={classes.profileButton}
                  variant={"contained"}
                  color={"secondary"}
                  component={RouterLink}
                  to={`${routes.publicProfile.resource}${username}`}
                >
                  eProfile Público
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

EprofileResultsSectionView.propTypes = {
  title: PropTypes.string.isRequired,
  shareButton: PropTypes.string.isRequired,
  isPublic: PropTypes.bool
  // stats: PropTypes.object.isRequired,
  // components: PropTypes.array.isRequired
};

EprofileResultsSectionView.defaultProps = {
  title: "Title",
  shareButton: "Share",
  isPublic: false
};

export default EprofileResultsSectionView;
