// AUTH
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_START = "AUTH_LOGIN_START";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";

export const REGISTER = "REGISTER";
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const REGISTER_UPDATE_PROFILE_IMAGE_START = "REGISTER_UPDATE_PROFILE_IMAGE_START";
export const REGISTER_UPDATE_PROFILE_IMAGE_SUCCESS = "REGISTER_UPDATE_PROFILE_IMAGE_SUCCESS";
export const REGISTER_UPDATE_PROFILE_IMAGE_FAILURE = "REGISTER_UPDATE_PROFILE_IMAGE_FAILURE";

export const LOGOUT = "LOGOUT";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_START = "VALIDATE_TOKEN_START";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";

export const START_REGISTRATION_PROCESS = "START_REGISTRATION_PROCESS";
export const PASSWORD_RESET_TRIGGER_SEND_MAIL = "PASSWORD_RESET_TRIGGER_SEND_MAIL";
export const PASSWORD_RESET_SEND_EMAIL = "PASSWORD_RESET_SEND_EMAIL";
export const PASSWORD_RESET_SEND_EMAIL_START = "PASSWORD_RESET_SEND_EMAIL_START";
export const PASSWORD_RESET_SEND_EMAIL_SUCCESS = "PASSWORD_RESET_SEND_EMAIL_SUCCESS";
export const PASSWORD_RESET_SEND_EMAIL_FAILURE = "PASSWORD_RESET_SEND_EMAIL_FAILURE";

export const PASSWORD_RESET_VALIDATE_TOKEN = "PASSWORD_RESET_VALIDATE_TOKEN";
export const PASSWORD_RESET_VALIDATE_TOKEN_TRIGGER = "PASSWORD_RESET_VALIDATE_TOKEN_TRIGGER";
export const PASSWORD_RESET_VALIDATE_TOKEN_START = "PASSWORD_RESET_VALIDATE_TOKEN_START";
export const PASSWORD_RESET_VALIDATE_TOKEN_SUCCESS = "PASSWORD_RESET_VALIDATE_TOKEN_SUCCESS";
export const PASSWORD_RESET_VALIDATE_TOKEN_FAILURE = "PASSWORD_RESET_VALIDATE_TOKEN_FAILURE";

// PASSWORD RESET UPDATE PASSWORD
export const PASSWORD_RESET_TRIGGER_UPDATE_PASSWORD = "PASSWORD_RESET_TRIGGER_UPDATE_PASSWORD";
export const PASSWORD_RESET_UPDATE_PASSWORD = "PASSWORD_RESET_UPDATE_PASSWORD";
export const PASSWORD_RESET_UPDATE_PASSWORD_START = "PASSWORD_RESET_UPDATE_PASSWORD_START";
export const PASSWORD_RESET_UPDATE_PASSWORD_SUCCESS = "PASSWORD_RESET_UPDATE_PASSWORD_SUCCESS";
export const PASSWORD_RESET_UPDATE_PASSWORD_FAILURE = "PASSWORD_RESET_UPDATE_PASSWORD_FAILURE";
export const PASSWORD_RESET_UPDATE_PASSWORD_RESET = "PASSWORD_RESET_UPDATE_PASSWORD_RESET";
