import {
  prefetchUpdateError,
  prefetchCheckStatus,
  prefetchUpdateLoaded,
  prefetchUpdateLoading,
  prefetchUpdateNotLoaded,
  prefetchStatusLoaded,
  prefetchStatusNotLoaded,
  prefetchAbilities,
  prefetchGames,
  prefetchRegions
} from "./actions";
import { getPrefetchStatus } from "redux/state/prefetch/selectors";
import { PREFETCH_STATUS } from "redux/state/prefetch/reducer";
import { ofType } from "redux-observable";
import { LOGIN_PAGE_LOADED } from "redux/state/ui/pages/login";
import { mapTo } from "rxjs/operators";
import { PREFETCH_STATUS_NOT_LOADED } from "redux/state/prefetch/types";
import { GAMES_FETCH_ALL_SUCCESS } from "redux/state/games/types";
import { HOME_PAGE_LOADED } from "redux/state/ui/pages/home";
import { REGISTER_PAGE_LOADED } from "redux/state/ui/pages/register/types";
import { REGIONS_FETCH_ALL_SUCCESS } from "redux/state/regions/types";
import { PUBLIC_PROFILE_PAGE_LOADED } from "redux/state/ui/pages/public-profile/types";
import { prefetchSocials } from "redux/state/prefetch/actions";
import { ACCOUNT_PAGE_LOADED } from "redux/state/ui/pages/account/types";

export const checkPrefetch = () => (dispatch, getState) => {
  const state = getState();
  const status = getPrefetchStatus(state);

  if (status === PREFETCH_STATUS.VERSION) {
    dispatch(prefetchStatusLoaded());
  } else {
    dispatch(prefetchStatusNotLoaded());
  }
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const checkPrefetchEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_PAGE_LOADED, HOME_PAGE_LOADED, REGISTER_PAGE_LOADED, PUBLIC_PROFILE_PAGE_LOADED, ACCOUNT_PAGE_LOADED),
    mapTo(checkPrefetch())
  );

export const prefetchAbilitiesEpic = action$ =>
  action$.pipe(ofType(PREFETCH_STATUS_NOT_LOADED), mapTo(prefetchAbilities()));

export const prefetchGamesEpic = action$ => action$.pipe(ofType(PREFETCH_STATUS_NOT_LOADED), mapTo(prefetchGames()));

export const prefetchRegionsEpic = action$ => action$.pipe(ofType(GAMES_FETCH_ALL_SUCCESS), mapTo(prefetchRegions()));

export const prefetchSocialsEpic = action$ => action$.pipe(ofType(REGIONS_FETCH_ALL_SUCCESS), mapTo(prefetchSocials()));

export const prefetchSuccessEpic = action$ =>
  action$.pipe(ofType(REGIONS_FETCH_ALL_SUCCESS), mapTo(prefetchUpdateLoaded()));

export {
  prefetchUpdateNotLoaded,
  prefetchUpdateLoading,
  prefetchUpdateLoaded,
  prefetchCheckStatus,
  prefetchUpdateError
};
