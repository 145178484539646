import acFactory from "ejs-acfactory";
import * as aTypes from "./types";

export const autocomStudySearchFetchTopRanks = acFactory(aTypes.AUTOCOM_FETCH_TOP_RANKS);
export const autocomStudySearchByTerm = acFactory(aTypes.AUTOCOM_FETCH_BY_TERM);

export const _autocomStudySearchUserSelect = value => ({
  type: aTypes.AUTOCOM_STUDY_SEARCH_USER_SELECT,
  payload: value
});

export const _autocomStudySearchCompareSelect = value => ({
  type: aTypes.AUTOCOM_STUDY_SEARCH_COMPARE_SELECT,
  payload: value
});

export const autocomStudySearchCompareSelectSuccess = () => ({
  type: aTypes.AUTOCOM_STUDY_SEARCH_COMPARE_SELECT_SUCCESS
});

export const autocomStudySearchUserSelectSuccess = () => ({
  type: aTypes.AUTOCOM_STUDY_SEARCH_USER_SELECT_SUCCESS
});

export const autocomStudySearchUserReset = acFactory(aTypes.AUTOCOM_STUDY_SEARCH_USER_RESET);
export const autocomStudySearchCompareReset = acFactory(aTypes.AUTOCOM_STUDY_SEARCH_COMPARE_RESET);
