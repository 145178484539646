import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PlayerInformation from "app/pages/home/compare/spider-section/player-information/PlayerInformation";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { sectionContainer, title } from "app/pages/home/styles";
import { Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SpiderDisplay from "app/pages/home/compare/spider-section/spider-display/SpiderDisplay";
import SpiderCallToAction from "app/pages/home/compare/spider-section/spider-call-to-action/SpiderCallToAction";
import InfoIcon from "@material-ui/icons/Info";
import {
  autocompleteProps,
  playerSearchFormProps,
  userInfoProps
} from "app/pages/home/compare/spider-section/spiderProps";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.whiteText,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(20)
    },
    [theme.breakpoints.up("xl")]: {
      paddingBottom: theme.spacing(0)
    }
  },
  container: {
    ...sectionContainer(theme)
  },
  titleContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  ...title(theme),
  innerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  [theme.breakpoints.up("md")]: {
    innerContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  graph: {
    flex: "1 1 100%",
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 100%"
    },
    [theme.breakpoints.up("md")]: {
      flex: 1
    }
  },
  playerInfo: {
    flex: "1 1 80px",
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 150px",
      // maxWidth: "100px"
    },
    [theme.breakpoints.up("md")]: {
      flex: ".1 0 200px"
    },
    [theme.breakpoints.up("lg")]: {
      flex: ".08 0 200px"
    }
  },
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  tooltipContainer: {
    display: "inline-flex",
    position: "relative",
    flexShrink: 0,
    verticalAlign: "middle",
    paddingBottom: theme.spacing(1)
  },
  tooltipIcon: {
    display: "inline-block"
  },
  tooltipLink: {
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer"
  }
}));

const SpiderSectionView = ({
  title,
  compareDisabled,
  userFormState,
  comparedFormState,
  userInfoState,
  autocompleteUserItems,
  autocompleteComparedItems,
  compareInfoState,
  changeToAbilitiesPage,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const variant = useMediaQuery(theme.breakpoints.down("xs")) ? "body2" : "h6";
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} className={classes.container}>
        <Typography component={"span"} variant={variant} className={classes.title}>
          {`/ ${title} `}
        </Typography>

        <span className={classes.tooltipContainer}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            interactive
            arrow
            title={
              <Fragment>
                <Typography variant={"caption"}>
                  Si quieres más información de qué se trata cada habilidad{" "}
                  <span onClick={changeToAbilitiesPage} className={classes.tooltipLink}>
                    ingresa acá
                  </span>
                </Typography>
              </Fragment>
            }
          >
            <InfoIcon className={classes.tooltipIcon} width={"40px"} />
          </Tooltip>
        </span>
      </Grid>

      {/* SPIDER SECTION */}
      <Grid item xs={12} className={classes.sectionContainer}>
        <Grid container justify={"flex-start"} className={classes.innerContainer}>
          {/* Avatar Section */}
          <Box clone order={{ xs: 2, md: 1 }} pr={{ xs: 1, md: 0 }}>
            {/*<Grid item xs={6} sm={4} md={3} lg={2}>*/}
            <div className={classes.playerInfo}>
              <PlayerInformation
                reactour="reactour__user-profile"
                autocompleteItems={autocompleteUserItems}
                avatarInfoState={userInfoState}
                formState={userFormState}
                isUser={true}
              />
            </div>
          </Box>

          <Box clone order={{ xs: 1, md: 2 }}>
            {/* SPIDER GRAPH */}
            {/*<Grid item xs={12} sm={12} md={6} lg={8}>*/}
            <div className={classes.graph}>
              <SpiderDisplay />
            </div>
            {/*</Grid>*/}
          </Box>

          {/*SPIDER CALL TO ACTION SM */}
          {sm && (
            <Box clone order={{ xs: 3, md: 1 }} pr={{ xs: 1, md: 0 }}>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <SpiderCallToAction />
              </Grid>
            </Box>
          )}

          {/* Compare Avatar */}
          <Box clone order={{ xs: 4, md: 3 }} pl={{ xs: 1, md: 0 }}>
            {/*<Grid item xs={6} sm={4} md={3} lg={2}>*/}
            <div className={classes.playerInfo}>
              <PlayerInformation
                reactour={"reactour__compare"}
                autocompleteItems={autocompleteComparedItems}
                avatarInfoState={compareInfoState}
                formState={comparedFormState}
                disabled={compareDisabled}
              />
            </div>
            {/*</Grid>*/}
          </Box>

          {/* SPIDER CALL TO ACTION XS */}
          {xs && (
            <Box clone order={{ xs: 5, md: 1 }} pr={{ xs: 1, md: 0 }}>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <SpiderCallToAction />
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

SpiderSectionView.propTypes = {
  title: PropTypes.string.isRequired,
  userFormState: PropTypes.shape(playerSearchFormProps),
  comparedFormState: PropTypes.shape(playerSearchFormProps),
  userInfoState: PropTypes.shape(userInfoProps),
  compareInfoState: PropTypes.shape(userInfoProps),
  autocompleteUserItems: PropTypes.shape(autocompleteProps),
  autocompleteComparedItems: PropTypes.shape(autocompleteProps)
};

SpiderSectionView.defaultProps = {
  title: "Spider section title"
};

export default SpiderSectionView;
