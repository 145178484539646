import jwtDecode from "jwt-decode";

/**
 * This function returns a formatted gender state
 * @param gender {string}
 * @returns {string}
 */
export const transformGenderState = gender => {
  switch (gender) {
    case "Masculino":
    case "Male":
      return "male";

    case "Femenino":
    case "Female":
      return "female";

    case "Otro":
    case "Other":
      return "other";
  }
};

export const mapLoginResToState = res => ({
  token: res.data.auth_token,
  name: res.data.name,
  userId: jwtDecode(res.data.auth_token).sub
});

export const mapValidateToState = res => ({
  token: res.data.auth_token,
  name: res.data.name,
  userId: jwtDecode(res.data.auth_token).sub,
  email: res.data.email
});



