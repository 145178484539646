import React from "react";
import PropTypes from "prop-types";
import SpiderCallToActionView from "app/pages/home/compare/spider-section/spider-call-to-action/SpiderCallToActionView";
import { HOME_SPIDER_DISPLAY_ACTION, HOME_SPIDER_FORM_VALIDATION } from "app/i18n/selectors";
import { useTranslation } from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudySeachAutocomAllIds,
  getStudySeachAutocomById,
  getStudySeachAutocomComparedSelected,
  getStudySearchFilteredByGenericGameAndTopRanks
} from "redux/state/autocomplete/study-search/selectors";
import { getGameNamesWithIds, getGamesById } from "redux/state/games/selectors";
import {
  autocomStudySearchCompareReset,
  autocomStudySearchCompareSelect
} from "redux/state/autocomplete/study-search/operations";
import { getStudyValidating } from "redux/state/studies/selectors";
import { userComparisonOPS } from "redux/state/forms/user-comparison";
import {
  getUserComparisonFormComparedGame,
  getUserComparisonFormComparedNickname,
  getUserComparisonFormComparedRegion
} from "redux/state/forms/user-comparison/selectors";

const SpiderCallToAction = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const texts = {
    repeatText: t(`${HOME_SPIDER_DISPLAY_ACTION}.repeat`)
  };

  const commonForms = {
    games: useSelector(state => getGameNamesWithIds(state)),
    byIdIdName: "user_id",
    byIdValueName: "username"
  };

  const comparedFormState = {
    handleChangeGame: gameId => dispatch(userComparisonOPS.userComparisonFormUpdateComparedGame(gameId)),
    handleChangeRegion: regionId => dispatch(userComparisonOPS.userComparisonFormUpdateComparedRegion(regionId)),
    handleChangeNickname: nickname => dispatch(userComparisonOPS.userComparisonFormUpdateComparedNickname(nickname)),
    selectedGame: useSelector(state => getUserComparisonFormComparedGame(state)),
    selectRegion: useSelector(state => getUserComparisonFormComparedRegion(state)),
    selectNickname: useSelector(state => getUserComparisonFormComparedNickname(state)),
    ...commonForms
  };

  const autocompleteComparedItems = {
    byId: useSelector(state => getStudySeachAutocomById(state)),
    hasRelation: true,
    relationships: {
      game: {
        id_name: "game_id",
        fields: ["name", "short_name"],
        byId: useSelector(state => getGamesById(state))
      }
    },
    bestPlayers: useSelector(state => getStudySearchFilteredByGenericGameAndTopRanks(state)),
    allIds: useSelector(state => getStudySeachAutocomAllIds(state)),
    selected: useSelector(state => getStudySeachAutocomComparedSelected(state)),
    setAutocompleteSelected: value => dispatch(autocomStudySearchCompareSelect(value)),
    resetSelectedAutocomplete: () => dispatch(autocomStudySearchCompareReset()),
    autocompleteValidation: useSelector(state => getStudyValidating(state)),
    autocompleteValidationText: errorMsg => t(`${HOME_SPIDER_FORM_VALIDATION}.${errorMsg}`)
  };

  const state = { sm, xs };

  return (
    <SpiderCallToActionView
      formState={comparedFormState}
      autocompleteItems={autocompleteComparedItems}
      {...state}
      {...texts}
    />
  );
};

SpiderCallToAction.propTypes = {};

export default SpiderCallToAction;
