export default class ResponseCreator {
  constructor(props) {}

  /**
   * This method returns an abstraction of an api error with the following structure:
   *    {
          error: bool,
          status: integer,
          statusText: string,
          message: string
        }

   * @param error
   * @returns {{statusText: *, error: boolean, message: *, status: *}|{statusText: string, error: boolean, message: string, status: number}}
   */
  createErrorResponse = error => {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.message,
        data: error.response.data
      };
    } else {
      return {
        error: true,
        status: 500,
        statusText: "Internal Server Error",
        message: "Network error",
        data: null
      };
    }
  };

  /**
   * This method returns an abstraction from an axios standard response with the following structure:
   * { data: object, status: integer, statusText: string }
   *
   * @param res
   * @returns {{data: *, statusText: *, status: *}}
   */
  createResponse = res => ({ data: res.data, status: res.status, statusText: res.statusText });

  /**
   * This method is responsible for creating a header structure for ajax calls.
   * It sets:
   *    - Content-type to application/json with charset UTF-8
   *
   * @param custom headers object
   */
  createHeaders = custom => ({ "Content-Type": "application/json; charset=UTF-8", ...custom });

  /**
   * This method returns a basic authentication object to be passed to axios auth config with the following
   * struncture:
   *     { username: string, password: string }
   *
   * @param auth object
   * @returns an object with { username: string, password: string } or empty if no auth is passed
   */
  setBasicAuth = auth => (auth ? { username: auth.username, password: auth.password } : null);
}
