import { combineReducers } from "redux";
import formReducer from "./formReducer";
import emailValidationReducer from "./emailValidationReducer";
import updatePasswordValidationReducer from "./updatePasswordValidationReducer";

const validateReducer = combineReducers({
  emailForm: emailValidationReducer,
  updatePasswordForm: updatePasswordValidationReducer
});

export default combineReducers({ form: formReducer, validate: validateReducer });
