export const getUserStudyFromStudyRequest = ({ payload }) => {
  const keys = Object.keys(payload);
  if (keys.length > 0) {
    return keys.reduce((obj, user, index) => {
      obj[payload[keys[index]].user_id] = {
        user_id: payload[keys[index]].user_id,
        study_id: payload[keys[index]].study_id
      };
      return obj;
    }, {});
  }
  return [];
};

export const getUserFromStudy = ({ payload }) => {
  const keys = Object.keys(payload);
  const res = keys.reduce((obj, user, index) => {
    obj[payload[keys[index]].user_id] = {
      user_id: payload[keys[index]].user_id,
      study_id: payload[keys[index]].study_id
    };
    return obj;
  }, {});
};
