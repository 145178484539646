import * as Yup from "yup";
import { REGISTER_VALIDATION_ERR } from "app/i18n/selectors";

export const emailSchema = t =>
  Yup.object({
    email: Yup.string()
      .email(t(`${REGISTER_VALIDATION_ERR}.email.bad_format`))
      .required(t(`${REGISTER_VALIDATION_ERR}.email.not_present`))
  });

export const passwordSchema = t =>
  Yup.object({
    password: Yup.string()
      .min(8, t(`${REGISTER_VALIDATION_ERR}.password.bad_length`))
      .required(t(`${REGISTER_VALIDATION_ERR}.password.not_present`))
  });

export const usernameSchema = t =>
  Yup.object({
    username: Yup.string()
      .min(3, t(`${REGISTER_VALIDATION_ERR}.eprofileUsername.bad_length`))
      .required(t(`${REGISTER_VALIDATION_ERR}.eprofileUsername.not_present`))
  });

export const genderSchema = t =>
  Yup.object({
    gender: Yup.string().required(t(`${REGISTER_VALIDATION_ERR}.gender.not_present`))
  });

export const birthdaySchema = t =>
  Yup.object({
    birthday: Yup.date()
      .required(t(`${REGISTER_VALIDATION_ERR}.birthday.not_present`))
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        t(`${REGISTER_VALIDATION_ERR}.birthday.bad_format`)
      )
      .typeError(t(`${REGISTER_VALIDATION_ERR}.birthday.type`))
  });

export const defaultSchema = (t, name) =>
  Yup.object({
    [name]: Yup.string()
      .max(50, "Máximo 50 caracteres")
      .required("Requerido!")
  });
