import { allIds } from "app/components/CustomInput/fakeState";
import { createSelector } from "reselect";
import { getStudySeachAutocomComparedSelected } from "redux/state/autocomplete/study-search/selectors";

const userStudy = state => state.entities.userStudy;

export const getUserStudyAllIds = state => userStudy(state).allIds;
export const getUserStudyById = state => userStudy(state).byId;

export const getStudyIdByAutocompleteComparedSelected = createSelector(
  [getUserStudyById, getStudySeachAutocomComparedSelected],
  (userStudies, comparedSelected) => {
    // if (!comparedSelected || comparedSelected === "") {
    //   throw Error("getStudyIdByAutocompleteComparedSelected() => comparedSelected must be defined");
    // }
    // if (!userStudies || Object.keys(userStudies).length === 0) {
    //   throw Error("getStudyIdByAutocompleteComparedSelected() => userStudies must be defined");
    // }
    // console.log("getStudyIdByAutocompleteComparedSelected() => SELECTED", comparedSelected);
    // console.log("getStudyIdByAutocompleteComparedSelected() => STUDY", userStudies);
    return comparedSelected !== "" && userStudies[comparedSelected] ? userStudies[comparedSelected] : null;
  }
);
