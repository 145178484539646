import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { REGISTER_FORM } from "app/i18n/selectors";
import { darken } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(4),
    background:
      "linear-gradient(to right, rgba(90,121,222, .27) 0%,rgba(106,37,138, .27) 37%, rgba(183,8,141, .27) 100%)"
  },
  container: {
    padding: theme.spacing(4)
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    fontWeight: 700,
    fontStyle: "italic",
    letterSpacing: ".1em"
  },
  subtitle: {
    textAlign: "center",
    marginBottom: theme.spacing(4)
  },
  center: {
    textAlign: "center",
    paddingBottom: theme.spacing(4)
  },
  registerButton: {
    borderRadius: 0,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.getContrastText(theme.palette.tertiary.main),
    "&:hover": {
      backgroundColor: darken(theme.palette.tertiary.main, 0.3)
    }
  }
}));

const RegisterSuccess = props => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <Grid component="section" container className={classes.container}>
        {/* TITLE */}
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Registro exitoso
          </Typography>
        </Grid>

        {/* SUBTITLE */}
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.subtitle}>
            Por favor inicia sesión para descargar nuestra aplicación y puedas tomar los tests cognitivos.
          </Typography>
        </Grid>

        {/* CALL TO ACTION */}
        <Grid item xs={12} className={classes.center}>
          <Button
            component={RouterLink}
            to={routes.login.route}
            className={classes.registerButton}
            variant={"contained"}
          >
            Iniciar Sesión
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

RegisterSuccess.propTypes = {};

export default RegisterSuccess;
