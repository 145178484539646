import {
  FETCH_PROFILE,
  PROFILE_CHECK_FAILURE,
  PROFILE_CHECK_FINISH_TESTS,
  PROFILE_CHECK_SUCCESS,
  PROFILE_CHECK_VERIFY_USER,
  PROFILE_CHECK,
  PROFILE_REQUEST_PUT_IMAGE,
  PROFILE_UNLOCK_USER,
  PROFILE_UPDATE_EMAIL,
  PROFILE_UPDATE_GENDER,
  PROFILE_UPDATE_IMAGE,
  PROFILE_UPDATE_PASSWORD,
  PROFILE_UPDATE_REGION,
  PROFILE_UPDATE_SUMMONER_NAME,
  PROFILE_UPDATE_USERNAME,
  PROFILE_VALIDATE_IMAGE_FAILURE,
  PROFILE_VALIDATE_IMAGE_START,
  PROFILE_VALIDATE_IMAGE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_RESET,
  PROFILE_FETCH_SOCIAL_NETWORKS,
  PROFILE_UPDATE_USER_SOCIAL_NETWORK,
  PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME,
  PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY
} from "./types";
import { PROFILE_CHECK_START, PROFILE_TRIGGER_UNLOCK } from "redux/state/profile/types";

export const fetchProfile = (promise, transform) => ({
  type: FETCH_PROFILE,
  payload: promise,
  meta: { transform }
});

export const updateProfile = (promise, transform, data) => ({
  type: UPDATE_PROFILE,
  payload: promise,
  meta: { transform, data }
});

export const resetUpdateProfile = () => ({
  type: UPDATE_PROFILE_RESET
});

export const profileFetchSocialNetworks = (promise, transform) => ({
  type: PROFILE_FETCH_SOCIAL_NETWORKS,
  payload: promise,
  meta: { transform }
});

export const profileUpdateUserSocialNetworkName = (name, socialNetworkName) => ({
  type: PROFILE_UPDATE_USER_SOCIAL_NETWORK_NAME,
  payload: { name, socialNetworkName }
});

export const profileUpdateUserSocialNetworkVisibility = (visibility, socialNetworkName) => ({
  type: PROFILE_UPDATE_USER_SOCIAL_NETWORK_VISIBILITY,
  payload: { visibility, socialNetworkName }
});

export const profileUpdateUserSocialNetwork = (promise, transform) => ({
  type: PROFILE_UPDATE_USER_SOCIAL_NETWORK,
  payload: promise,
  meta: { transform }
});

export const profileUpdateEmail = () => ({ type: PROFILE_UPDATE_EMAIL });
export const profileUpdateUsername = () => ({ type: PROFILE_UPDATE_USERNAME });
export const profileUpdateGender = () => ({ type: PROFILE_UPDATE_GENDER });
export const profileUpdatePassword = () => ({ type: PROFILE_UPDATE_PASSWORD });
export const profileUpdateRegion = () => ({ type: PROFILE_UPDATE_REGION });
export const profileUpdateSummonerName = () => ({ type: PROFILE_UPDATE_SUMMONER_NAME });
export const profileUpdateImage = file => ({
  type: PROFILE_UPDATE_IMAGE,
  payload: file
});

export const profileCheck = () => ({ type: PROFILE_CHECK });
export const profileCheckStart = () => ({ type: PROFILE_CHECK_START });
export const profileCheckVerifyUser = () => ({ type: PROFILE_CHECK_VERIFY_USER });
export const profileCheckFinishTests = () => ({ type: PROFILE_CHECK_FINISH_TESTS });
export const profileCheckFailure = () => ({ type: PROFILE_CHECK_FAILURE });
export const profileCheckSuccess = () => ({ type: PROFILE_CHECK_SUCCESS });
export const profileValidateImageStart = () => ({ type: PROFILE_VALIDATE_IMAGE_START });
export const profileValidateImageFailure = () => ({ type: PROFILE_VALIDATE_IMAGE_FAILURE });
export const profileValidateImageSuccess = () => ({ type: PROFILE_VALIDATE_IMAGE_SUCCESS });

export const triggerUnlockUser = () => ({ type: PROFILE_TRIGGER_UNLOCK });

export const profileUnlockUser = promise => ({
  type: PROFILE_UNLOCK_USER,
  payload: promise
});

// UPDATE PROFILE IMAGE
export const profileRequestPutImage = (promise, transform) => ({
  type: PROFILE_REQUEST_PUT_IMAGE,
  payload: promise,
  meta: { transform }
});
