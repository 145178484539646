import React, { Fragment } from "react";
import PropTypes from "prop-types";
import AuthPageTemplate from "app/components/auth-page-template/AuthPageTemplate";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";
import AuthPageContainer from "app/components/auth-page-container/AuthPageContainer";

import PasswordResetMailForm from "app/pages/password-reset/password-reset-mail-form/PasswordResetMailForm";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Alert from "app/components/alert/Alert";

const useStyles = makeStyles(theme => ({
  padding: theme.spacing(4),
  marginTop: {
    marginTop: theme.spacing(8)
  }
}));

const PasswordResetView = ({
  passwordResetTitle,
  passwordResetSubtitle,
  handleClose,
  isToastOpen,
  toastMsg,
  isToastError
}) => {
  const classes = useStyles();

  return (
    <AuthPageTemplate>
      <AuthPageContainer>
        <Snackbar
          className={classes.marginTop}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isToastOpen}
          autoHideDuration={6 * 1000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} msg={toastMsg} color={"primary"} />
        </Snackbar>
        <AuthFormContainer padding={classes.padding} title={passwordResetTitle} subtitle={passwordResetSubtitle}>
          <PasswordResetMailForm />
        </AuthFormContainer>
      </AuthPageContainer>
    </AuthPageTemplate>
  );
};

PasswordResetView.propTypes = {};

export default PasswordResetView;
