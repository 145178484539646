import React from "react";
import PropTypes from "prop-types";

import EprofileResultsSectionView from "./EprofileResultsSectionView";
import { useTranslation } from "react-i18next";
import { ABILITIES, HOME_EPROFILE_RESULTS } from "app/i18n/selectors";
import { useSelector } from "react-redux";
import { getUIeProfileResultsComponents, getUIHomeIsMyProfileSelected } from "redux/state/ui/pages/home/selectors";
import {
  getProfileResultStats,
  getProfileScorePosition,
  getProfileScoreTotal,
  getProfileUsername
} from "redux/state/profile/selectors";
import {
  getPublicProfileResultStats,
  getPublicProfileScorePosition,
  getPublicProfileScoreTotal,
  getPublicProfileUsername
} from "redux/state/public-profile/selectors";

const EprofileResultsSection = ({ isPublic }) => {
  const { t } = useTranslation();
  const components = useSelector(state => getUIeProfileResultsComponents(state));
  const publicUsername = useSelector(state => getPublicProfileUsername(state));
  const username = useSelector(state => getProfileUsername(state));
  const stats = useSelector(state => (isPublic ? getPublicProfileResultStats(state) : getProfileResultStats(state)));
  const position = useSelector(state =>
    isPublic ? getPublicProfileScorePosition(state) : getProfileScorePosition(state)
  );
  const isHomeMyProfileSelected = useSelector(state => getUIHomeIsMyProfileSelected(state));
  const total = useSelector(state => (isPublic ? getPublicProfileScoreTotal(state) : getProfileScoreTotal(state)));
  const ranking = position && total ? `${position}/${total}` : false;

  const state = {
    results: {
      ...components
        .map(key => ({
          ...stats[key],
          id: key,
          title: t(`${HOME_EPROFILE_RESULTS}.${key}`),
          description: key === "ranking" ? "" : t(`${ABILITIES}.${stats[key].ability_id}.name`)
        }))
        .reduce((obj, current) => {
          obj[current.id] = current;
          return obj;
        }, {})
    }
  };
  // Set value for rankings
  state.results.ranking.value = ranking ? `#${ranking}` : "No hay ranks";

  const texts = {
    title: isPublic ? publicUsername : isHomeMyProfileSelected ? username : t(`${HOME_EPROFILE_RESULTS}.title`),
    shareButton: t(`${HOME_EPROFILE_RESULTS}.shareButton`)
  };

  return (
    <EprofileResultsSectionView
      isHomeMyProfileSelected={isHomeMyProfileSelected}
      username={username}
      isPublic={isPublic}
      {...state}
      components={components}
      {...texts}
    />
  );
};

EprofileResultsSection.propTypes = {
  isPublic: PropTypes.bool
};

EprofileResultsSection.defaultProps = {
  isPublic: false
};

export default EprofileResultsSection;
