import { createSelector } from "reselect";
import { getUserComparisonNickname } from "redux/state/user-comparison/selectors";

import { profileSelectors } from "redux/state/profile";
import {
  getPublicProfileStudies,
  getPublicProfileUsername
} from "redux/state/public-profile/selectors";

const studies = state => state.entities.studies;

/**
 * SELECTORS
 */
export const getStudiesError = state => studies(state).error;
export const getStudiesById = state => studies(state).byId;
export const getStudiesLoading = state => studies(state).loading;
export const getStudyCompareSelectedId = state => studies(state).compareSelected;
export const getStudyUserSelectedId = state => studies(state).userSelected;
export const getStudyValidating = state => studies(state).validation;

export const getUserStudy = createSelector(
  [getStudyUserSelectedId, getStudiesById],
  (userStudyId, studiesById) => studiesById[userStudyId]
);

export const getComparedStudy = createSelector([getStudyCompareSelectedId, getStudiesById], (selectedId, studies) => {
  return studies[selectedId];
});

export const getPublicProfileStudy = createSelector(
  [getPublicProfileStudies, getStudiesById],
  (publicProfileStudy, studies) => studies[publicProfileStudy[0]]
);

export const getPublicProfileStudyResults = createSelector([getPublicProfileStudy], study =>
  study && Object.keys(study).includes("results") && study.results
    ? study.results.sort((a, b) => b.value - a.value)
    : []
);

export const getUserProfileStudyResults = createSelector([getUserStudy], study =>
  study && Object.keys(study).includes("results") && study.results
    ? study.results.sort((a, b) => b.value - a.value)
    : []
);

export const getPublicProfileGraphState = createSelector(
  [getPublicProfileStudy, getPublicProfileUsername],
  (publicProfileStudy, username) =>
    publicProfileStudy && username
      ? publicProfileStudy.results.map(study => ({
          name: study.ability_id,
          [username]: study.value
        }))
      : []
);

/**
 * This Selector returns an array with the graph data from the user authenticated
 * [
 *  {name: "Tenacity", "Emilio Jeldes": 60},
 *  {name: "Reaction Speed", "Emilio Jeldes": 20},
 *  {name: "Inventiveness", "Emilio Jeldes": 80},
 *  {name: "Macro Management", "Emilio Jeldes": 70},
 *  {name: "Micro Management", "Emilio Jeldes": 30},
 *  {name: "Mapping", "Emilio Jeldes": 10},
 *  {name: "Nimbleness", "Emilio Jeldes": 60},
 *  {name: "Recall", "Emilio Jeldes": 60},
 *  {name: "Stamina", "Emilio Jeldes": 40},
 * ]
 */
export const getUserGraphState = createSelector(
  [getUserStudy, profileSelectors.getProfileUsername],
  (userStudies, username) =>
    userStudies && username
      ? userStudies.results.map(study => ({
          name: study.ability_id,
          [username]: study.value
        }))
      : undefined
);
/**
 * This Selector returns an array with the graph data from the compared user
 * [
 *  {name: "Tenacity", "Emilio Jeldes": 60},
 *  {name: "Reaction Speed", "Emilio Jeldes": 20},
 *  {name: "Inventiveness", "Emilio Jeldes": 80},
 *  {name: "Macro Management", "Emilio Jeldes": 70},
 *  {name: "Micro Management", "Emilio Jeldes": 30},
 *  {name: "Mapping", "Emilio Jeldes": 10},
 *  {name: "Nimbleness", "Emilio Jeldes": 60},
 *  {name: "Recall", "Emilio Jeldes": 60},
 *  {name: "Stamina", "Emilio Jeldes": 40},
 * ]
 */
export const getCompareGraphState = createSelector(
  [getComparedStudy, getUserComparisonNickname],
  (compareStudy, compareUser) =>
    compareStudy && compareUser
      ? compareStudy.results.map(study => ({
          name: study.ability_id,
          [compareUser]: study.value
        }))
      : undefined
);
/**
 * This Selector returns an array with the graph final state, using the authenticated user and
 * the compared user
 *
 * [
 *  {name: "Tenacity", "Emilio Jeldes": 60, "Diego Araneda": 20},
 *  {name: "Reaction Speed", "Emilio Jeldes": 20, "Diego Araneda": 40},
 *  {name: "Inventiveness", "Emilio Jeldes": 80, "Diego Araneda": 60},
 *  {name: "Macro Management", "Emilio Jeldes": 70, "Diego Araneda": 100},
 *  {name: "Micro Management", "Emilio Jeldes": 30, "Diego Araneda": 50},
 *  {name: "Mapping", "Emilio Jeldes": 10, "Diego Araneda": 60},
 *  {name: "Nimbleness", "Emilio Jeldes": 60, "Diego Araneda": 80},
 *  {name: "Recall", "Emilio Jeldes": 60, "Diego Araneda": 40},
 *  {name: "Stamina", "Emilio Jeldes": 40, "Diego Araneda": 60},
 * ]
 */
export const getStudiesForComparison = createSelector(
  [getUserGraphState, getCompareGraphState, getUserComparisonNickname],
  (userResults, compareResults, compareUser) => {
    if (userResults) {
      if (compareResults && compareUser) {
        let newList = [];
        userResults.forEach(user => {
          const obj = compareResults.filter(compare => compare.name === user.name)[0];
          if (obj) {
            return newList.push({ ...user, [compareUser]: obj[compareUser] });
          }
        });
        return newList;
      }
      return userResults;
    }
    return [];
  }
);

// export const getStudiesForComparison = createSelector(
//   [getUserGraphState, getCompareGraphState, () => "Asd"],
//   (userResults, compareResults, compareUser) => {
//     if (userResults) {
//       console.log("getStudiesForComparison() => COMPARE_RESULTS___", compareResults);
//       console.log("getStudiesForComparison() => USER_RESULTS___", userResults);
//       console.log("getStudiesForComparison() => COMPARE_RESULTS_KEYS___", Object.keys(compareResults));
//
//       const compareList = userResults.filter(user => user.name in Object.keys(compareResults));
//       console.log("getStudiesForComparison() => COMPARE_LIST___", compareList);
//
//       if (compareResults && compareUser) {
//         return userResults.map((user, landing_showcase) => {
//           const obj = compareResults.filter(compare => compare.name === user.name)[0];
//           console.log("getStudiesForComparison() OBJ_COMPAREUSER =>", obj[compareUser])
//           return { ...user, [compareUser]: obj[compareUser] };
//         });
//       }
//       return userResults;
//     }
//     return [];
//   }
// );
