import React from "react";
import PropTypes from "prop-types";

const LabelComponentEmpty = props => {
  return "";
};

LabelComponentEmpty.propTypes = {};

export default LabelComponentEmpty;
