import React, { Fragment, useState } from "react";
import CustomInputView from "app/components/CustomInput/CustomInputView";
import { useDispatch } from "react-redux";

const CustomInputAutocomplete = ({
  disabled,
  label,
  change,
  isInputEmpty,
  value,
  autocompleteItems,
  byIdValueName,
  byIdIdName,
  helperText,
  hasRelation,
  relationships
}) => {
  const dispatch = useDispatch();
  /**********************************************************
   * STATES
   **********************************************************/
  const [isFocus, setFocus] = useState(false);
  const [renderList, setRenderList] = useState([]);
  const [hover, setHover] = useState(0);
  const inputRef = React.useRef(null);

  const items = autocompleteItems;
  const validation = autocompleteItems.autocompleteValidation || { error: false, errorMsg: "", validating: false };
  const errorMsg = autocompleteItems.autocompleteValidationText;

  /**********************************************************
   * LIFECYCLE METHODS
   **********************************************************/
  // at the beginning
  React.useEffect(() => {
    if (items.bestPlayers) {
      setRenderList(items.bestPlayers);
    }
  }, [items.bestPlayers]);

  // every time isFocus
  React.useEffect(() => {
    setUnHover();
  }, [isFocus]);

  // everytime value and isEmpty
  React.useEffect(() => {
    if (isInputEmpty) {
      setRenderList(items.bestPlayers);
    } else {
      const searchOnRelation = (item, byIdValueName, relations, value) => {
        const relationKeys = Object.keys(relations);
        for (let key of relationKeys) {
          const relationStruct = relations[key];
          const relationItem = relationStruct.byId[item["game_id"]];
          if (relationItem) {
            for (let field of relationStruct.fields) {
              if (relationItem[field].toLowerCase().includes(value.toLowerCase())) return true;
            }
          }
        }
        return false;
      };
      setRenderList(
        items.allIds
          ? items.allIds.filter(
              id =>
                items.byId[id][byIdValueName].toLowerCase().includes(value.toLowerCase()) ||
                searchOnRelation(items.byId[id], byIdValueName, items.relationships, value)
            )
          : []
      );
    }
  }, [value, isInputEmpty]);

  // everytime items.selected
  React.useEffect(() => {
    changeInputValueFromSelectedState(items.selected);
  }, [items.selected]);

  /**********************************************************
   * SETTERS
   **********************************************************/

  // isFocus State
  const setIsFocusFalse = () => {
    setTimeout(() => {
      setFocus(false);
    }, 200);
  };

  const setIsFocusTrue = () => {
    setFocus(true);
  };

  // items.selected state
  const setSelected = e => {
    items.setAutocompleteSelected(e.target.id);
  };

  const setSelectedWithHover = value => {
    items.setAutocompleteSelected(value);
  };

  const clearSelection = () => {
    items.resetSelectedAutocomplete();
  };

  // hover
  const handleHover = e => {
    e.persist(); // necessary for this to work
    setHover(renderList.indexOf(e.target.id));
  };

  const setUnHover = () => {
    if (!isFocus) setHover(0);
  };

  const hoverUp = () => setHover(hover <= 0 ? hover : hover - 1);

  const hoverDown = () => setHover(renderList.length - 1 < hover + 1 ? hover : hover + 1);

  const resetHover = () => setHover(0);

  /**********************************************************
   * Handlers
   **********************************************************/
  // Input change callback
  const handleChange = e => {
    change(e.target.value);
    resetHover();
    if (items.selected !== "") clearSelection();
  };

  const changeInputValueFromSelectedState = value => {
    if (items.selected !== "") change(items.byId[items.selected][byIdValueName]);
  };

  const changeInputFromHover = () => {
    change(items.byId[renderList[hover]][byIdValueName]);
    setSelectedWithHover(items.byId[renderList[hover]][byIdIdName]);
    resetHover();
    setIsFocusFalse();
    inputRef.current.blur();
  };

  const handleKeyEvent = e => {
    switch (e.keyCode) {
      // 38 = arrow up
      case 38:
        hoverUp();
        break;
      // 40 = arrow down
      case 40:
        hoverDown();
        break;
      // 13 = enter
      case 13:
        changeInputFromHover();
        break;
    }
  };

  return (
    <Fragment>
      <CustomInputView
        errorMsg={errorMsg}
        validation={validation}
        byIdIdName={byIdIdName}
        byIdValueName={byIdValueName}
        disabled={disabled}
        label={label}
        renderList={renderList}
        setIsFocusFalse={setIsFocusFalse}
        setIsFocusTrue={setIsFocusTrue}
        setSelected={setSelected}
        setUnHover={setUnHover}
        value={value}
        handleChange={handleChange}
        handleHover={handleHover}
        handleKeyEvent={handleKeyEvent}
        hover={hover}
        inputRef={inputRef}
        isFocus={isFocus}
        helperText={helperText}
        items={items}
      />
    </Fragment>
  );
};

export default CustomInputAutocomplete;
