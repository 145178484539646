import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import EprofileResultsSection from "app/pages/home/compare/eprofile-results/EprofileResultsSection";
import MyProfileSpiderSection from "app/components/my-profile/myprofile-spider-section/MyProfileSpiderSection";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  verticalSpacing: {
    padding: theme.spacing(2)
  }
}));

const MyProfileView = ({ isPublic }) => {
  const classes = useStyles();
  return (
    <Container maxWidth={"lg"}>
      <Grid container>
        <EprofileResultsSection isPublic={isPublic} />
        <div className={classes.verticalSpacing} />
        <MyProfileSpiderSection isPublic={isPublic} />
      </Grid>
     </Container>
  );
};

MyProfileView.propTypes = {};

export default MyProfileView;
