import { combineReducers } from "redux";
import Typography from "@material-ui/core/Typography";
import React from "react";
import * as aTypes from "./types";
import { mapUnreadNotifications } from "redux/state/notifications/utils";

const notificationsInit = {};
const notificationsInitById = [];
const unreadNotificationsInit = [];

const byId = (state = notificationsInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return { ...action.payload };

    case aTypes.NOTIFICATIONS_UPDATE_IS_READ_SUCCESS:
      return {
        ...state,
        [action.payload.data.notification.notification_id]: {
          ...state[action.payload.data.notification.notification_id],
          isRead: true
        }
      };

    default:
      return state;
  }
};

const allIds = (state = notificationsInitById, action) => {
  switch (action.type) {
    case aTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return Object.keys(action.payload);

    default:
      return state;
  }
};

const unreadNotifications = (state = unreadNotificationsInit, action) => {
  switch (action.type) {
    case aTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return mapUnreadNotifications(action.payload);

    case aTypes.NOTIFICATIONS_UPDATE_IS_READ_SUCCESS:
      return state.filter(id => id !== action.payload.data.notification.notification_id);

    default:
      return state;
  }
};

export default combineReducers({ byId, allIds, unreadNotifications });
