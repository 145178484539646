import { combineReducers } from "redux";
import { LOGOUT } from "redux/state/auth/types";
import * as aTypes from "./types"
import { mapEmailValidationToState } from "./utils";

/**
 * SELECTORS
 */
export const getLoadingValidation = state => state.forms.registerForm.validate.loadingValidation;

// Errors
export const getPlayerErrors = state => state.forms.registerForm.validate.errors;

/**
 * REDUCERS
 */
const baseErrorState = { error: false, errorMsg: "" };
const errorInit = { email: { ...baseErrorState }, hasErrors: false };

const loadingValidation = (state = false, action) => {
  switch (action.type) {
    case aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_FAILURE:
    case aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS:
      return false;

    case aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_START:
      return true;

    default:
      return state;
  }
};

const errors = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_FAILURE:
      const errors = mapEmailValidationToState(action.payload);

      return {
        ...state,
        hasErrors: errors.hasErrors ? errors.hasErrors : false,
        email: errors.email ? { ...errors.email } : { ...baseErrorState }
      };
    case aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS:
    case aTypes.PASSWORD_RESET_EMAIL_FORM_VALIDATION_START:
    case aTypes.UPDATE_PASSWORD_RESET_EMAIL:
      return errorInit;

    case LOGOUT:
      return { ...errorInit };

    default:
      return state;
  }
};

export default combineReducers({
  errors,
  loadingValidation
});
