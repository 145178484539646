import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as social_networksOPS from "./operations";
import * as social_networksSelectors from "./selectors";
import { fetchSocialsEpic } from "./operations";

export { social_networksOPS, social_networksSelectors };

export const socialNetworksEpic = combineEpics(fetchSocialsEpic);

export default reducer;
