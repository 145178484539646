import React from "react";
import PropTypes from "prop-types";
import { Link, makeStyles, useMediaQuery } from "@material-ui/core";
import landing_showcase from "app/assets/img/landing/landing_showcase.png";
import landing from "app/assets/img/landing/landing.png";
import landing_mobile from "app/assets/img/landing_mobile.png";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import Navbar from "app/components/navbar/Navbar";
import LightRedButton from "app/components/custom-button/LighRedButton";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";
import vertical_logo from "app/assets/img/logo/vertical_logo.png";
import logo_corfo from "app/assets/img/logo/Logo_Corfo.png";
import logo from "app/assets/svg/logo-eprofile.svg";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LandingHeader from "app/pages/landing/LandingHeader";
import LandingCarousel from "app/pages/landing/landing-carousel/LandingCarousel";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  main: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${landing_showcase})`,
    backgroundPosition: "top center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    color: theme.palette.whiteText
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  image: {
    width: "100%",
    zIndex: "-10"
  },
  titleContainer: {
    position: "absolute",
    left: "5%",
    top: "85%",
    transform: "translateY(-100%)"
  },
  title: {
    fontWeight: "bold"
  },
  gradientSeparator: {
    width: "100%",
    padding: 0,
    margin: 0,
    height: "10px",
    backgroundImage: theme.palette.gradients.lightToDark
  },
  actionButton: {
    borderRadius: 0,
    marginTop: theme.spacing(1)
  },
  logo: {
    zIndex: 10,
    width: "100px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  description: {
    padding: theme.spacing(2, 0)
  },
  paragraph: {
    // display: "block"
    marginBottom: theme.spacing(2),
    letterSpacing: ".1em"
  },
  paragraphs: {
    margin: "auto",
    width: "100%",
    padding: theme.spacing(0, 4)
  },
  abilities: {
    backgroundColor: "rgba(255, 255, 255, .09)",
    padding: theme.spacing(4)
  },
  subtitleContainer: {
    padding: theme.spacing(4, 2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  subtitle: {
    fontSize: "1.1em",
    padding: theme.spacing(0, 2),
    letterSpacing: ".1em",
    fontWeight: "bold"
  },
  separator: {
    backgroundColor: theme.palette.text.primary,
    height: "1px",
    flexGrow: 1
  },
  abilitiesContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  abilityItem: {
    textAlign: "left",
    flex: "1 0 100%",
    marginBottom: theme.spacing(4)
  },
  abilityBorder: {
    padding: theme.spacing(0, 2)
  },
  abilityName: {
    fontWeight: "bold",
    fontSize: ".9em",
    marginBottom: theme.spacing(1)
  },
  abilityDescription: {
    fontSize: ".9em"
  },
  subscribe: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  gradientSeparatorBottom: {
    padding: 0,
    margin: 0,
    backgroundImage: theme.palette.gradients.darkToLight,
    height: "10px"
  },
  contactFooter: {
    // backgroundImage: "linear-gradient(to right, #5a42ec 2%,#b2013f 100%,#ed6292 100%)",
    backgroundImage: "linear-gradient(to right, #683ac1 0%,#aa0a45 100%)",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    textAlign: "left"
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.primary,
    height: "54px",
    background: theme.palette.primary.main1_light
  },
  link: {
    // fontSize: "1rem",
    padding: theme.spacing(2, 1)
  },
  input: {
    flex: "1 0 100%"
  },
  subscribeButton: {
    borderRadius: 0
  },
  iconGroup: {
    paddingTop: theme.spacing(1)
  },
  footerLogo: {
    width: "250px",
    paddingBottom: theme.spacing(2)
  },
  footerTitle: {
    fontWeight: "bold",
    fontSize: ".9em",
    marginBottom: theme.spacing(1)
  },
  footerInfo: {
    fontSize: ".9em",
    fontWeight: "bold"
  },
  footerSeparator: {
    width: "2px",
    backgroundColor: "white",
    margin: theme.spacing(0, 6)
  },
  support: {
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,.2)",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  supportTitle: {
    fontWeight: "bold"
  },
  supportLogo: {
    width: "300px"
  },
  carousel: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(3)
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(11),
      paddingBottom: theme.spacing(5)
    }
  },
  [theme.breakpoints.up("sm")]: {
    image: {
      width: "100%",
      height: "100%"
    },
    contactFooter: {
      flexDirection: "row",
      padding: theme.spacing(6, 6)
    },
    subscribe: {
      justifyContent: "space-around",
      flexWrap: "no-wrap",
      padding: theme.spacing(1, 2, 1, 2)
    },
    input: {
      marginTop: "12px",
      flex: "0 0 50%"
    },
    paragraphs: {
      width: "90%"
    },
    subtitleContainer: {
      padding: theme.spacing(4, 6)
    },
    description: {
      padding: theme.spacing(6, 4)
    },
    subtitle: {
      fontSize: "1.2em",
      padding: theme.spacing(0, 4)
    },
    abilities: {
      padding: theme.spacing(2)
    },
    abilityItem: {
      flex: "1 0 50%"
    }
  },
  [theme.breakpoints.up("md")]: {
    description: {
      padding: theme.spacing(8, 4)
    },
    paragraphs: {
      width: "70%"
    },
    subtitleContainer: {
      padding: theme.spacing(4, 6)
    },
    subtitle: {
      fontSize: "1.5em",
      padding: theme.spacing(0, 4)
    },
    abilities: {
      padding: theme.spacing(2)
    },
    abilityItem: {
      flex: "1 0 20%"
    }
  },
  [theme.breakpoints.up("lg")]: {
    support: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    },
    contactFooter: {
      padding: theme.spacing(8, 10),
      fontSize: "1.2em"
    },
    description: {
      padding: theme.spacing(10, 6)
    },
    paragraph: {
      fontSize: "1.5em",
      letterSpacing: ".1em"
    },
    abilityName: {
      fontWeight: "bold",
      fontSize: "1.2em",
      marginBottom: theme.spacing(1)
    },
    abilityDescription: {
      fontSize: "1.1em"
    }
  }
}));

const LandingView = ({ carouselData, title1Text, title2Text, title3Text, initButtonText }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const calculateTitleVariant = () => {
    if (lg) return "h2";
    if (md) return "h3";
    if (sm) return "h4";
    if (xs) {
      return "h6";
    } else {
      return "h6";
    }
  };

  const abilities = [
    { name: "Velocidad de procesamiento", description: "Procesar rápidamente estímulos simples." },
    { name: "Memoria de trabajo", description: "Mantener y operar con información en tu memoria de corto plazo." },
    { name: "Atención sostenida", description: "Poner atención de forma contínua sin distraerse." },
    { name: "Atención dividida", description: "Poner atención entre diferentes estímulos de manera eficiente." },
    { name: "Ceguera atencional", description: "Mide el costo temporal de cambiar tu atención de una tarea a otra." }
    // { name: "Alternancia de tareas", description: "Poner atención a más de una tarea y desempeñarte bien en ellas." },
    // { name: "Visoespacial", description: "Procesar información espacial en objetos de 2 o 3 dimensiones." },
    // { name: "Razonamiento Fluido", description: "Identificar y predecir patrones y secuencias en nuevas tareas." },
    // { name: "Tolerancia a la frustración", description: "Perseverancia en el intento de solucionar un problema." },
    // { name: "Creatividad", description: "Intentar diferentes soluciones para un mismo problema." }
  ];

  const socials = [
    { icon: <TwitterIcon />, href: "https://twitter.com/eProfilegg" },
    { icon: <InstagramIcon />, href: "https://instagram.com/eprofilegg" }
  ];

  return (
    <div className={classes.container}>
      <LandingHeader />
      <Navbar />
      <header className={classes.headerContainer}>
        <img className={classes.image} src={sm ? landing : landing_mobile} alt="" />
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant={calculateTitleVariant()}>
            {title1Text.toUpperCase()}
          </Typography>
          <Typography className={classes.title} variant={calculateTitleVariant()}>
            {title2Text.toUpperCase()}
          </Typography>
          {/*<Typography className={classes.title} variant={calculateTitleVariant()}>*/}
          {/*  {title3Text.toUpperCase()}*/}
          {/*</Typography>*/}
          <LightRedButton component={RouterLink} to={routes.register.route} className={classes.actionButton}>
            {initButtonText}
          </LightRedButton>
        </div>
      </header>
      <div className={classes.gradientSeparator} />
      <main className={classes.main}>
        <section className={classes.carousel}>
          <LandingCarousel data={carouselData} />
        </section>

        <div className={classes.gradientSeparator} />
        <section className={classes.description}>
          <img className={classes.logo} src={vertical_logo} />
          <div className={classes.paragraphs}>
            <Typography component={"p"} variant={"body1"} className={classes.paragraph}>
              eProfile es un perfil gamer construido a partir de un set de pruebas que miden tus habilidades cognitivas.
              <b>Los resultados de tus pruebas cognitivas pueden ser comparados con jugadores de todo el mundo.</b>
            </Typography>
            <Typography component={"p"} variant={"body1"} className={classes.paragraph}>
              Dependiendo de su género, los videojuegos demandan habilidades cognitivas diferentes. Por ejemplo, los
              juegos FPS (del inglés <i>First Person Shooter</i>) tienden a requerir respuestas rápidas, es decir, una
              alta velocidad de procesamiento y una muy buena habilidad visoespacial.
            </Typography>
            <Typography component={"p"} variant={"body1"} className={classes.paragraph}>
              En los juegos MOBA (del inglés <i>Multiplayer Online Battle Arena</i>), la Velocidad de Procesamiento, la
              alternancia de tareas y la atención sostenida posiblemente sean las habilidades cognitivas más requeridas
              para un mejor desempeño.
            </Typography>
            <Typography component={"p"} variant={"body1"} className={classes.paragraph}>
              Por su parte, los juegos de puzzles pueden tener tiempos de reacción más lentos, pero requieren una mayor
              precisión por parte de los videojugadores.
            </Typography>
            <Typography component={"p"} variant={"body1"} className={classes.paragraph}>
              <b>¿Cuál sería tu habilidad más fuerte?</b>
            </Typography>
          </div>
        </section>

        {/* HABILIDADES COGNITIVAS */}
        <section className={classes.abilities}>
          <div className={classes.subtitleContainer}>
            <div className={classes.separator} />
            <Typography className={classes.subtitle} variant={"h6"}>
              {"Habilidades Cognitivas".toUpperCase()}
            </Typography>
            <div className={classes.separator} />
          </div>
          <div className={classes.abilitiesContainer}>
            {abilities.map((ability, index) => (
              <div className={classes.abilityItem} key={index}>
                <div className={classes.abilityBorder}>
                  <Typography className={classes.abilityName}>{ability.name.toUpperCase()}</Typography>
                  <Typography className={classes.abilityDescription}>{ability.description}</Typography>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/*SUBSCRIBE*/}
        {/*<section className={classes.subscribe}>*/}
        {/*  <WhiteInput className={classes.input} label={"Email"} />*/}
        {/*  <LightRedButton className={classes.subscribeButton}>Subscribete</LightRedButton>*/}
        {/*  {sm && (*/}
        {/*    <div className={classes.iconGroup}>*/}
        {/*      {socials.map((icon, index) => (*/}
        {/*        <IconButton target="_blank" href={icon.href} key={index} size={"small"} className={classes.iconButton}>*/}
        {/*          {icon.icon}*/}
        {/*        </IconButton>*/}
        {/*      ))}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</section>*/}

        {/* SUPPORT */}
        <section className={classes.support}>
          <Typography className={classes.supportTitle}>Nuestro apoyo:</Typography>
          <img className={classes.supportLogo} src={logo_corfo} />
        </section>

        {/*  CONTACT FOOTER */}
        <div className={classes.gradientSeparatorBottom} />
        <footer className={classes.footerContainer}>
          <div className={classes.contactFooter}>
            <img className={classes.footerLogo} src={logo} />
            {sm && <div className={classes.footerSeparator} />}
            <div className={classes.contactContainer}>
              <Typography className={classes.footerTitle}>Contáctanos</Typography>
              <Typography className={classes.footerInfo}>SoluNova SpA</Typography>
              <Typography className={classes.footerInfo}>Almirante Churruca #3130, Santiago, Chile</Typography>
              {/*<Typography className={classes.footerInfo}>+56 2 29473450</Typography>*/}
              <Typography className={classes.footerInfo}>info@eprofile.gg</Typography>
            </div>
          </div>
          <div className={classes.footer}>
            <Link className={classes.link} color={"inherit"} target={"_blank"} href={routes.termsAndConditions.route}>
              Términos y condiciones
            </Link>
            <Link className={classes.link} color={"inherit"} target={"_blank"} href={routes.privacy.route}>
              Política de privacidad
            </Link>
          </div>
        </footer>
      </main>
    </div>
  );
};

LandingView.propType = {
  title1Text: PropTypes.string.isRequired,
  title2Text: PropTypes.string.isRequired,
  title3Text: PropTypes.string.isRequired,
  initButtonText: PropTypes.string.isRequired,
  carouselData: PropTypes.object.isRequired
};

export default LandingView;
