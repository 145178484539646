import { combineReducers } from "redux";
import { LOGOUT } from "redux/state/auth/types";
import * as aTypes from "./types";
import ERROR_SHAPE from "api/error-model";
import { mapStudyResponseToState } from "./utils";
import { STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS } from "./types";
import { not_found } from "redux/errors";

/**
 * Initial State
 */
const loadingInit = { isLoading: false, loaded: true };
const byIdInit = {};
const allIdsInit = [];
const compareSelectedInit = "";
const errorInit = ERROR_SHAPE;
const validationInit = { error: false, errorMsg: "", validating: false, status: 0 };

/**
 * Reducers
 */
const byId = (state = {}, action) => {
  switch (action.type) {
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_START:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_FAILURE:
      // case aTypes.STUDY_FETCH_BY_ID_START:
      // case aTypes.STUDY_FETCH_BY_ID_FAILURE:
      return state;

    // case aTypes.STUDY_FETCH_BY_ID_SUCCESS:
    // case aTypes.STUDY_FETCH_BY_USER_ID_SUCCESS:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
    case aTypes.STUDY_FETCH_BY_USER_ID_SUCCESS:
      const data = action.payload;
      return state[Object.keys(data)[0]] ? state : { ...state, ...data };

    case aTypes.STUDY_FETCH_TOP_RANKS_SUCCESS:
      return { ...state, ...action.payload };

    case LOGOUT:
      return { ...byIdInit };

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    // case aTypes.STUDY_FETCH_BY_ID_START:
    // case aTypes.STUDY_FETCH_BY_ID_FAILURE:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_START:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_FAILURE:
      return state;

    // case aTypes.STUDY_FETCH_BY_ID_SUCCESS:
    // case aTypes.STUDY_FETCH_BY_USER_ID_SUCCESS:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
    case aTypes.STUDY_FETCH_BY_USER_ID_SUCCESS:
      const data = action.payload;
      const keys = Object.keys(data);
      return state.indexOf(keys[0]) === -1 ? [...state, keys[0]] : state;

    case aTypes.STUDY_FETCH_TOP_RANKS_SUCCESS:
      return [...new Set([...state, ...Object.keys(action.payload)])];

    case LOGOUT:
      return [...allIdsInit];

    default:
      return state;
  }
};

const loading = (state = loadingInit, action) => {
  switch (action.type) {
    // case aTypes.STUDY_FETCH_BY_ID_START:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_START:
      return { ...state, isLoading: true };

    // case aTypes.STUDY_FETCH_BY_ID_FAILURE:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_FAILURE:
      return { ...state, isLoading: false, loaded: false };

    // case aTypes.STUDY_FETCH_BY_ID_SUCCESS:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
      return { isLoading: false, loaded: true };

    case LOGOUT:
      return { ...loadingInit };

    default:
      return state;
  }
};

// TODO: VALIDATE THIS AFTER NEW STATE STRUCTURE
const compareSelected = (state = compareSelectedInit, action) => {
  switch (action.type) {
    case aTypes.STUDY_SET_SELECT_COMPARED_STUDY:
      return action.payload;
    case LOGOUT:
      return compareSelectedInit;

    default:
      return state;
  }
};

const userSelected = (state = compareSelectedInit, action) => {
  switch (action.type) {
    case STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
      return Object.keys(action.payload)[0] ? Object.keys(action.payload)[0] : "";

    case aTypes.STUDY_SET_SELECT_USER_STUDY:
      return action.payload;

    case LOGOUT:
      return compareSelectedInit;

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.STUDY_FETCH_BY_ID_FAILURE:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_FAILURE:
    case aTypes.STUDY_FETCH_BY_USER_ID_FAILURE:
      return { ...state, ...action.payload, error: true };

    case aTypes.STUDY_FETCH_BY_ID_START:
    case aTypes.STUDY_FETCH_BY_ID_SUCCESS:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
    case aTypes.STUDY_FETCH_CURRENT_USER_RESULTS_START:
      return { ...errorInit };

    default:
      return state;
  }
};

const validation = (state = validationInit, action) => {
  switch (action.type) {
    case aTypes.STUDY_VALIDATE_STUDY_EXISTENCE_START:
      return { error: false, errorMsg: "", validating: true };

    case aTypes.STUDY_VALIDATE_STUDY_EXISTENCE_FAILURE:
      return { error: true, errorMsg: action.payload, validating: false };

    case aTypes.STUDY_VALIDATE_STUDY_EXISTENCE_SUCCESS:
      return validationInit;

    default:
      return state;
  }
};

export default combineReducers({ byId, allIds, loading, compareSelected, userSelected, error, validation });
