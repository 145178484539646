import * as aTypes from "./types";

export const updateLoginUsername = username => ({
  type: aTypes.LOGIN_UPDATE_USERNAME,
  payload: username
});
export const updateLoginPassword = password => ({
  type: aTypes.LOGIN_UPDATE_PASSWORD,
  payload: password
});
