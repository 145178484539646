import { combineReducers } from "redux";
import * as aTypes from "./types";
import ERROR_SHAPE from "api/error-model";
import { LOGOUT } from "redux/state/auth";
import {
  REGIONS_FETCH_ALL_FAILURE,
  REGIONS_FETCH_ALL_START,
  REGIONS_FETCH_ALL_SUCCESS
} from "redux/state/regions/types";

/**
 * INITIAL STATE
 */
const byIdInit = {};
const allIdsInit = [];
const loadingInit = { isLoading: false, loaded: false, fetched: false };
const errorInit = ERROR_SHAPE;

/**
 * REDUCERS
 */
const byId = (state = byIdInit, action) => {
  switch (action.type) {
    case aTypes.REGIONS_FETCH_ALL_SUCCESS:
      return { ...state, ...action.payload };

    // case LOGOUT:
    //   return byIdInit;
    default:
      return state;
  }
};

const allIds = (state = allIdsInit, action) => {
  switch (action.type) {
    case aTypes.REGIONS_FETCH_ALL_SUCCESS:
      return [...new Set(state.concat(Object.keys(action.payload)))];
    //
    // case LOGOUT:
    //   return allIdsInit;

    default:
      return state;
  }
};

const loading = (state = loadingInit, action) => {
  switch (action.type) {
    case aTypes.REGIONS_FETCH_ALL_START:
      return { ...state, isLoading: true, loaded: false };

    case aTypes.REGIONS_FETCH_ALL_SUCCESS:
      return { ...state, isLoading: false, loaded: true, fetched: true };

    case aTypes.REGIONS_FETCH_ALL_FAILURE:
      return { ...state, isLoading: false, loaded: false, fetched: false };

    // case LOGOUT:
    //   return loadingInit;

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case REGIONS_FETCH_ALL_START:
      return { ...errorInit };

    case REGIONS_FETCH_ALL_SUCCESS:
      return { ...errorInit };

    case REGIONS_FETCH_ALL_FAILURE:
      return action.payload;

    // case LOGOUT:
    //   return errorInit;

    default:
      return state;
  }
};

/**
 * DEFAULT EXPORT
 */
export default combineReducers({ byId, allIds, loading, error });
