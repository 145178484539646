import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";
import { combineReducers } from "redux";
import { PASSWORD_RESET_SEND_EMAIL_SUCCESS } from "redux/state/auth/types";

const emailInit = "";
const passwordInit = "";

const email = (state = emailInit, action) => {
  switch (action.type) {
    case aTypes.UPDATE_PASSWORD_RESET_EMAIL:
      return action.payload;

    case aTypes.PASSWORD_RESET_RESET_EMAIL:
    case PASSWORD_RESET_SEND_EMAIL_SUCCESS:
    case LOGOUT:
      return emailInit;

    default:
      return state;
  }
};

const password = (state = passwordInit, action) => {
  switch (action.type) {
    case aTypes.UPDATE_PASSWORD_RESET_PASSWORD:
      return action.payload;

    case aTypes.PASSWORD_RESET_RESET_PASSWORD:
    case LOGOUT:
      return passwordInit;

    default:
      return state;
  }
};

const repeatPassword = (state = passwordInit, action) => {
  switch (action.type) {
    case aTypes.UPDATE_PASSWORD_RESET_REPEAT_PASSWORD:
      return action.payload;

    case aTypes.PASSWORD_RESET_RESET_PASSWORD:
    case LOGOUT:
      return passwordInit;

    default:
      return state;
  }
};

export default combineReducers({ email, password, repeatPassword });
