import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "@material-ui/core/styles/styled";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import SocialRow from "app/pages/account/socials/social-row/SocialRow";
import { useDispatch, useSelector } from "react-redux";
import { getSocialNetworksAllIds, getSocialNetworksById } from "redux/state/social-networks/selectors";
import { getUserSocialNetworks, getUserSocialNetworksById } from "redux/state/profile/selectors";
import DiscordIcon from "app/components/icon/DiscordIcon";
import TwitchIcon from "app/components/icon/TwitchIcon";
import {
  profileUpdateUserSocialNetworkName,
  profileUpdateUserSocialNetworkVisibility
} from "redux/state/profile/actions";

const SocialsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(6)
  }
}));

const SocialsTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(4, 0)
}));

const SocialsGroup = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "600px"
});

const Text = styled(({ children, ...other }) => <Typography component={"p"} {...other} children={children} />)(
  ({ theme }) => ({
    fontSize: 14,
    letterSpacing: 0.9,
    [theme.breakpoints.up("md")]: {
      fontSize: 16
    }
  })
);

const Socials = props => {
  const socialsAllIds = useSelector(state => getSocialNetworksAllIds(state));
  const socialsById = useSelector(state => getSocialNetworksById(state));
  const userSocialsById = useSelector(state => getUserSocialNetworksById(state));
  const userSocialNetworks = useSelector(state => getUserSocialNetworks(state));
  const dispatch = useDispatch();

  const socialAssets = {
    Discord: { icon: <DiscordIcon /> },
    Twitter: { icon: <TwitterIcon /> },
    Twitch: { icon: <TwitchIcon /> },
    Instagram: { icon: <InstagramIcon /> },
    YouTube: { icon: <YouTubeIcon /> }
  };

  const handleUpdateName = (name, socialNetworkName) => {
    dispatch(profileUpdateUserSocialNetworkName(name, socialNetworkName));
  };

  const handleUpdateVisibility = (visibility, socialNetworkName) => {
    dispatch(profileUpdateUserSocialNetworkVisibility(visibility, socialNetworkName));
  };

  return (
    <SocialsContainer>
      <SocialsTextContainer>
        {/*<Text>Al conectar tus redes sociales automáticamente podrás publicarlas en tu eProfile público</Text>*/}
        <Text>Conecta tus redes sociales</Text>
      </SocialsTextContainer>
      <SocialsGroup>
        {socialsAllIds.map(socialId => (
          <SocialRow
            key={socialId}
            onSave={handleUpdateName}
            onUpdateVisibility={handleUpdateVisibility}
            itemName={socialsById[socialId].name}
            itemValue={
              userSocialNetworks[socialId] ? userSocialsById[userSocialNetworks[socialId].userSocialNetworkId].name : ""
            }
            itemChecked={
              userSocialNetworks[socialId]
                ? userSocialsById[userSocialNetworks[socialId].userSocialNetworkId].is_visible
                : false
            }
            itemLabel={socialsById[socialId].name}
            itemIcon={socialAssets[socialId].icon}
          />
        ))}
      </SocialsGroup>
    </SocialsContainer>
  );
};

export default Socials;
