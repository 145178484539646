import React from "react";
import { Link } from "react-router-dom";

/**
 * This component is a React Router <Link /> representation but using
 * a React reference
 */
const RouterLink = React.forwardRef((props, ref) => {
  return <Link ref={ref} {...props} />;
});

export default RouterLink;
