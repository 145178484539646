import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { BeatLoader } from "react-spinners";
import useTheme from "@material-ui/core/styles/useTheme";

const withStyles = makeStyles(theme => ({
  loaderAbsolute: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  }
}));

const CustomLoader = props => {
  const classes = withStyles();
  const theme = useTheme();
  return (
    <div className={props.absolute ? classes.loaderAbsolute : ""}>
      <BeatLoader color={theme.palette.primary.dark} />
    </div>
  );
};

CustomLoader.propTypes = {
  absolute: PropTypes.bool
};

export default CustomLoader;
