import { createSelector } from "reselect";
/**
 * SELECTORS
 */
const gameStats = state => state.entities.gameStats;
const gameStatsError = state => gameStats(state).error;

export const getGameStatsById = state => gameStats(state).byId;
export const getAllGameStatsIds = state => gameStats(state).allIds;
export const getGameStatsLoading = state => gameStats(state).loading;

export const getGameStatsError = state => gameStatsError(state);
export const getGameStatsHasError = state => gameStatsError(state).error;
export const getGameStatsErrorStatus = state => gameStatsError(state).status;

export const getGameStatsAllIdsByGameLol = createSelector(
  [getGameStatsById, getAllGameStatsIds],
  (gameStatsById, gameStatsList) => {
    return gameStatsList.filter(gameStatId => gameStatsById[gameStatId].game === "1");
  }
);
export const getGameStatsByIdByGameLol = createSelector(
  [getGameStatsById, getGameStatsAllIdsByGameLol],
  (byId, allIds) => allIds.map(id => byId[id])
);
