import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getGameIds, getGamesById } from "redux/state/games/selectors";

const withStyles = makeStyles(theme => ({
  listItem: {
    backgroundColor: theme.palette.primary.main2_dark,
    cursor: "pointer"
  },
  listItemHover: {
    backgroundColor: theme.palette.primary.main2_light
  }
}));

const AutocomSearchStudyItem = ({
  item,
  byIdIdName,
  renderList,
  hover,
  handleHover,
  setUnHover,
  setSelected,
  items,
  byIdValueName
}) => {
  const classes = withStyles();
  const gamesById = useSelector(state => getGamesById(state));

  const getGame = () => {
    return items.relationships.game.byId[items.byId[item]["game_id"]] &&
      Object.keys(items.relationships.game.byId[items.byId[item]["game_id"]]).includes("short_name")
      ? items.relationships.game.byId[items.byId[item]["game_id"]]["short_name"]
      : "";
  };
  return (
    <div
      id={items.byId[item][byIdIdName]}
      className={clsx(classes.listItem, {
        [classes.listItemHover]: items.byId[item][byIdIdName] === renderList[hover]
      })}
      onMouseEnter={handleHover}
      onMouseLeave={setUnHover}
      onClick={setSelected}
      key={items.byId[item][byIdIdName]}
    >
      {items.byId[item][byIdValueName]} - {getGame()}
    </div>
  );
};

AutocomSearchStudyItem.propTypes = {};

export default AutocomSearchStudyItem;
