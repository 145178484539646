import reducer from "./reducers";

import * as studiesOPS from "./operations";
import * as studiesSelectors from "./selectors";

import {
  fetchUserStudyEpic,
  fetchTopRanksStudyEpic,
  validateStudyExistanceEpic,
  setStudyComparedSelectedEpic,
  studyValidationFailureEpic
} from "./operations";
import { combineEpics } from "redux-observable";

export { studiesOPS, studiesSelectors };

export const studiesEpic = combineEpics(
  fetchUserStudyEpic,
  fetchTopRanksStudyEpic,
  validateStudyExistanceEpic,
  setStudyComparedSelectedEpic,
  studyValidationFailureEpic
);

export default reducer;
