const setObjKey = msg => ({ error: true, errorMsg: msg });

export const mapValidationToStateErrors = errors => {
  const obj = {
    errors: false,
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    mindsetUsername: undefined,
    gender: undefined,
    summonerName: undefined,
    region: undefined
  };

  if (errors.email) obj.email = setObjKey(errors.email.email[0]);
  if (errors.password) obj.password = setObjKey(errors.password.password[0]);
  if (errors.confirmPassword)
    obj.confirmPassword = setObjKey(errors.confirmPassword.confirmPassword[0]);
  if (errors.mindsetUsername)
    obj.mindsetUsername = setObjKey(errors.mindsetUsername.mindsetUsername[0]);
  if (errors.gender) obj.gender = setObjKey(errors.gender.gender[0]);
  if (errors.summonerName) obj.summonerName = setObjKey(errors.summonerName.summonerName[0]);
  if (errors.region) obj.region = setObjKey(errors.region.region[0]);

  if (
    errors.email ||
    errors.password ||
    errors.confirmPassword ||
    errors.mindsetUsername ||
    errors.gender ||
    errors.region ||
    errors.summonerName
  )
    obj.hasErrors = true;
  return obj;
};

export const mapEmailValidationToErrorState = errors => {
  let obj = { email: undefined, hasErrors: false };
  if (errors.email) {
    obj.email = setObjKey(errors.email[0]);
    obj.hasErrors = true;
  }
  return obj;
};

export const mapUsernameValidationToErrorState = errors => {
  let obj = { username: undefined, hasErrors: false };
  if (errors.username) {
    obj.username = setObjKey(errors.username[0]);
    obj.hasErrors = true;
  }
  return obj;
};

export const mapGenderValidationToErrorState = errors => {
  let obj = { gender: undefined, hasErrors: false };
  if (errors.gender) {
    obj.gender = setObjKey(errors.gender[0]);
    obj.gender = true;
  }
  return obj;
};
