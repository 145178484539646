// export const SERVER_URL = "http://192.168.10.28";
//export const SERVER_URL = "http://localhost:5000";
//export const SERVER_URL = "http://192.168.10.29";
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";
//export const SERVER_URL = "http://solunova.duckdns.org";
export const API_URL = `${SERVER_URL}/api`;
export const AUTH_SERVER = `${SERVER_URL}/api`;
export const MEDIA_URL = `${SERVER_URL}/media/avatar/`;
export const STANDALONE_DOWNLOAD_URL = process.env.REACT_APP_STANDALONE_URL || SERVER_URL;
export const GEOIP_ACTIVATED =
  process.env.REACT_APP_ACTIVATE_GEO_IP === "false" ? false : process.env.REACT_APP_ACTIVATE_GEO_IP === "true";
export const DEVELOP_EU_LANDING =
  process.env.REACT_APP_EU_LANDING === "false" ? false : process.env.REACT_APP_EU_LANDING === "true";

export const STATE_VERSION = process.env.REACT_APP_STATE_VERSION || "1.0.1";

export const GENERIC_SCORE_TYPE = "00000000-0000-0000-0000-000000000000";
export const GAME_LOL_ID = "a7f6d2f1-4b2f-49ef-b23f-a31ce691b4bc";
export const UUID_VERSION = "v4";

// Typeform
export const TYPEFORM_URL = "https://eprofilegg.typeform.com/to/vRIcsw?email=";

export const APPLICATION_BASE_URL = "/";

// AJAX
export const AJAX_REQUEST_TIMEOUT = 15 * 1000;

// development servers
export const DEVELOPMENT_SERVERS = ["http://localhost:3000", "http://192.168.10.28", "http://192.168.10.29"];

window.LOG_LEVEL = process.env.NODE_ENV !== "production" ? 0 : 3;
// window.LOG_LEVEL =3;
window.DEBUG_SLOW_PAGING = false;
