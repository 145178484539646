import React, { Fragment } from "react";
import PropTypes from "prop-types";

import LoginForm from "./login-form/LoginForm";
import CustomLoader from "../../components/custom-loader/CustomLoader";
import AuthPageTemplate from "app/components/auth-page-template/AuthPageTemplate";
import AuthPageContainer from "app/components/auth-page-container/AuthPageContainer";
import Alert from "app/components/alert/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { getAuthPasswordResetUpdatePasswordStatus } from "redux/state/auth/selectors";
import { makeStyles } from "@material-ui/core/styles";
import { authPasswordResetUpdatePasswordReset } from "redux/state/auth/actions";

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(8)
  }
}));

const LoginView = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const updatePasswordStatus = useSelector(state => getAuthPasswordResetUpdatePasswordStatus(state));

  React.useEffect(() => {
    if (updatePasswordStatus === 200) setOpen(true);
  }, [updatePasswordStatus]);

  const handleClose = () => {
    dispatch(authPasswordResetUpdatePasswordReset());
    setOpen(false);
  };

  return (
    <AuthPageTemplate>
      <AuthPageContainer>
        {props.loading ? (
          <CustomLoader absolute={true} />
        ) : (
          <Fragment>
            <Snackbar
              className={classes.marginTop}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              autoHideDuration={6 * 1000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} msg={"Contraseña modificada correctamente"} color={"primary"} />
            </Snackbar>
            <LoginForm />
          </Fragment>
        )}
      </AuthPageContainer>
    </AuthPageTemplate>
  );
};

LoginView.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default LoginView;
