import React, { Fragment } from "react";
import notFoundIcon from "app/assets/svg/fantasmitaylogo-01.svg";
import { Container, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LightGreenButton from "app/components/custom-button/LightGreenButton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100vw",
    height: "100vh",
    textAlign: "center",
    background: theme.palette.home.backgroundGradient
  },
  image: {
    marginTop: theme.spacing(10),
    width: "70vw",
    [theme.breakpoints.up("md")]: {
      // marginTop: theme.spacing(5),
      width: "50vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    }
  },
  textGroup: {
    textAlign: "left",
    margin: "auto",
    width: "60vw",
    [theme.breakpoints.up("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    }
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: theme.palette.players.user.main,
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "4rem",
    }
  }
}));

const NotFound = props => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickBack = () => {
    history.goBack();
  };
  return (
    <div className={classes.container}>
      <Container maxWidth={"lg"}>
        <img className={classes.image} src={notFoundIcon} />
        <div className={classes.textGroup}>
          <Typography variant={"h5"} className={classes.title}>
            Esta página
          </Typography>
          <Typography variant={"h5"} className={classes.title}>
            no existe
          </Typography>
          <br/>
          <LightGreenButton onClick={handleClickBack}>¡quiero Volver!</LightGreenButton>
        </div>
      </Container>
    </div>
  );
};

NotFound.propTypes = {};

export default NotFound;
