import routes from "app/routes";
import { fetchPublicProfile, publicProfileCheck } from "./actions";
import api from "api";
import { ofType } from "redux-observable";
import { PUBLIC_PROFILE_PAGE_LOADED } from "redux/state/ui/pages/public-profile/types";
import { debounceTime, mapTo } from "rxjs/operators";
import { mapUserProfileToState } from "redux/state/profile/utils";
import { FETCH_PUBLIC_PROFILE_SUCCESS } from "redux/state/public-profile/types";
import {
  getPublicProfileHasCompletedStudies,
  getPublicProfileIsActive,
  getPublicProfileIsVerified
} from "redux/state/public-profile/selectors";
import {
  publicProfileCheckFailure,
  publicProfileCheckFinishTests,
  publicProfileCheckSuccess,
  publicProfileCheckVerifyUser
} from "redux/state/public-profile/actions";

const _fetchPublicProfile = () => async (dispatch, getState) => {
  const usernameInPath = window.location.pathname.split(routes.publicProfile.resource).pop();
  dispatch(fetchPublicProfile(api.user.fetchPublicProfile(usernameInPath), mapUserProfileToState));
};

const _checkPublicProfile = () => (dispatch, getState) => {
  dispatch(publicProfileCheck());
  const state = getState();
  const isVerified = getPublicProfileIsVerified(state);
  const isActive = getPublicProfileIsActive(state);
  const completedStudies = getPublicProfileHasCompletedStudies(state);

  if (isActive) {
    if (completedStudies) {
      dispatch(publicProfileCheckSuccess());
    } else {
      dispatch(publicProfileCheckFinishTests());
    }
  } else {
    if (isVerified) {
      dispatch(publicProfileCheckFailure());
    } else {
      dispatch(publicProfileCheckVerifyUser());
    }
  }
};

// FETCH PUBLIC PROFILE FLOW
export const fetchPublicProfileEpic = action$ =>
  action$.pipe(ofType(PUBLIC_PROFILE_PAGE_LOADED), debounceTime(100), mapTo(_fetchPublicProfile()));

export const validatePublicProfileEpic = action$ =>
  action$.pipe(ofType(FETCH_PUBLIC_PROFILE_SUCCESS), mapTo(_checkPublicProfile()));
