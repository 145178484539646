import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authPasswordResetValidateTokenTrigger } from "redux/state/auth/actions";
import {
  getAuthPasswordResetTokenValidationIsError,
  getAuthPasswordResetTokenValidationStatus,
  getAuthPasswordResetTokenValidationToken
} from "redux/state/auth/selectors";
import routes from "app/routes";
import PasswordUpdateView from "app/pages/password-reset/password-update/PasswordUpdateView";

const PasswordUpdate = props => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const tokenHasError = useSelector(state => getAuthPasswordResetTokenValidationIsError(state));
  const tokenStatus = useSelector(state => getAuthPasswordResetTokenValidationStatus(state));
  const tokenFromStore = useSelector(state => getAuthPasswordResetTokenValidationToken(state));

  React.useEffect(() => {
    dispatch(authPasswordResetValidateTokenTrigger(token));
  }, []);

  // !tokenHasError && tokenStatus === 200 && token !== ""
  return !tokenHasError && tokenStatus === 200 && tokenFromStore !== "" ? (
    <Redirect to={routes.passwordResetForm.route} />
  ) : (
    <PasswordUpdateView tokenHasError={tokenHasError} tokenStatus={tokenStatus} />
  );
};

PasswordUpdate.propTypes = {};

export default PasswordUpdate;
