import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@material-ui/core";
import styled from "@material-ui/core/styles/styled";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import Fade from "@material-ui/core/Fade";
import BootstrapPurpleInput from "app/components/bootstrap-input/BootstrapPurpleInput";
import { useFormik } from "formik";
import FlareIcon from "@material-ui/icons/Flare";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";

const withStyles = makeStyles(theme => ({
  container: {
    width: "100%"
  },
  wrapper: {
    display: "flex",
    width: "100%"
  }
}));

const Text = styled(({ children, ...other }) => <Typography component={"p"} {...other} children={children} />)(
  ({ theme }) => ({
    fontSize: 13,
    letterSpacing: 0.9,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16
    }
  })
);

const SocialRowContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%"
}));

const IconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0 40px",
  alignItems: "center"
}));

const LabelContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flex: "0 0 85px"
}));

const ToggleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end"
}));

const FormInputContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flex: 1,
  alignItems: "center",
  overflowX: "hidden"
}));

const Form = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%"
}));

const FormEditContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0 40px",
  justifyContent: "center",
  position: "relative",
  alignItems: "center",
  marginLeft: "auto"
}));

const ErrorMessage = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  paddingTop: "2px",
  paddingLeft: "120px",
  justifyContent: "flex-start"
}));

const SocialRow = ({ itemName, itemIcon, itemLabel, itemValue, itemChecked, onSave, onUpdateVisibility }) => {
  const classes = withStyles();
  const labelHeight = 36;
  const [isEditable, setEditable] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      [itemName]: ""
    },
    onSubmit: values => {
      if (onSave) onSave(values[itemName], itemName);
      formik.resetForm();
      setEditable(false);
    },
    validationSchema: Yup.object({
      [itemName]: Yup.string().required(`Campo ${itemName} requerido`)
    })
  });

  const handleChecked = () => {
    onUpdateVisibility(!itemChecked, itemName);
  };

  const handleInputEdit = () => {
    setEditable(!isEditable);
    formik.resetForm();
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      formik.submitForm();
    }
  };

  return (
    <Collapse
      enter={true}
      exit={true}
      classes={{ container: classes.container, wrapper: classes.wrapper, wrapperInner: classes.wrapper }}
      in={isEditable}
      collapsedHeight={labelHeight}
    >
      <SocialRowContainer>
        <Form>
          <IconContainer>{itemIcon}</IconContainer>
          <LabelContainer>
            <Text>{itemLabel}</Text>
          </LabelContainer>

          <FormInputContainer>
            {isEditable ? (
              <Fade in={isEditable} timeout={200}>
                <BootstrapPurpleInput
                  name={itemName}
                  type={"text"}
                  value={formik.values[itemName]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyPress={handleKeyPress}
                  autoFocus
                  size={"small"}
                  variant={"outlined"}
                  fullWidth
                />
              </Fade>
            ) : (
              <Text>{itemValue}</Text>
            )}
          </FormInputContainer>

          <FormEditContainer>
            {isEditable ? (
              <CancelIcon style={{ cursor: "pointer" }} onClick={handleInputEdit} fontSize={"small"} />
            ) : (
              <EditIcon style={{ cursor: "pointer" }} onClick={handleInputEdit} fontSize={"small"} />
            )}
          </FormEditContainer>
          <ToggleContainer>
            <Switch name={itemName} onChange={handleChecked} checked={itemChecked} />
          </ToggleContainer>
        </Form>
        <Fade in={formik.touched[itemName] && formik.errors[itemName]} timeout={200}>
          <ErrorMessage>
            {formik.touched[itemName] && formik.errors[itemName] && (
              <Typography color={"error"} variant={"caption"}>
                {formik.errors[itemName]}
              </Typography>
            )}
          </ErrorMessage>
        </Fade>
        {/*<pre>{JSON.stringify({ edited: valueEdited })}</pre>*/}
        {/*<pre>{JSON.stringify(formError)}</pre>*/}
        {/*<pre>{JSON.stringify(formik.values)}</pre>*/}
      </SocialRowContainer>
    </Collapse>
  );
};

SocialRow.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemIcon: PropTypes.node.isRequired,
  itemLabel: PropTypes.string.isRequired,
  itemValue: PropTypes.string.isRequired,
  itemChecked: PropTypes.bool.isRequired,
  onSave: PropTypes.func
};

SocialRow.defaultProps = {
  itemName: "",
  itemIcon: <FlareIcon />,
  itemLabel: "Social",
  itemValue: "",
  itemChecked: false
};

export default SocialRow;
