export const PROFILE_FORM_UPDATE_NAME = "PROFILE_FORM_UPDATE_NAME";
export const PROFILE_FORM_UPDATE_LASTNAME = "PROFILE_FORM_UPDATE_LASTNAME";
export const PROFILE_FORM_UPDATE_EMAIL = "PROFILE_FORM_UPDATE_EMAIL";
export const PROFILE_FORM_UPDATE_EPROFILE_USERNAME = "PROFILE_FORM_UPDATE_EPROFILE_USERNAME";
export const PROFILE_FORM_RESET = "PROFILE_FORM_RESET";

export const PROFILE_FORM_UPDATE = "PROFILE_FORM_UPDATE";
export const PROFILE_FORM_UPDATE_START = "PROFILE_FORM_UPDATE_START";
export const PROFILE_FORM_UPDATE_SUCCESS = "PROFILE_FORM_UPDATE_SUCCESS";
export const PROFILE_FORM_UPDATE_FAILURE = "PROFILE_FORM_UPDATE_FAILURE";

export const PROFILE_FORM_VALIDATE = "PROFILE_FORM_VALIDATE";
export const PROFILE_FORM_VALIDATE_START = "PROFILE_FORM_VALIDATE_START";
export const PROFILE_FORM_VALIDATE_SUCCESS = "PROFILE_FORM_VALIDATE_SUCCESS";
export const PROFILE_FORM_VALIDATE_FAILURE = "PROFILE_FORM_VALIDATE_FAILURE";

export const PROFILE_FORM_VALIDATE_EMAIL_START = "PROFILE_FORM_VALIDATE_EMAIL_START";
export const PROFILE_FORM_VALIDATE_EMAIL_SUCCESS = "PROFILE_FORM_VALIDATE_EMAIL_SUCCESS";
export const PROFILE_FORM_VALIDATE_EMAIL_FAILURE = "PROFILE_FORM_VALIDATE_EMAIL_FAILURE";

export const PROFILE_FORM_VALIDATE_USERNAME_START = "PROFILE_FORM_VALIDATE_USERNAME_START";
export const PROFILE_FORM_VALIDATE_USERNAME_SUCCESS = "PROFILE_FORM_VALIDATE_USERNAME_SUCCESS";
export const PROFILE_FORM_VALIDATE_USERNAME_FAILURE = "PROFILE_FORM_VALIDATE_USERNAME_FAILURE";

export const PROFILE_FORM_VALIDATE_GENDER_START = "PROFILE_FORM_VALIDATE_GENDER_START";
export const PROFILE_FORM_VALIDATE_GENDER_SUCCESS = "PROFILE_FORM_VALIDATE_GENDER_SUCCESS";
export const PROFILE_FORM_VALIDATE_GENDER_FAILURE = "PROFILE_FORM_VALIDATE_GENDER_FAILURE";

export const PROFILE_FORM_VALIDATE_PASSWORD_START = "PROFILE_FORM_VALIDATE_PASSWORD_START";
export const PROFILE_FORM_VALIDATE_PASSWORD_SUCCESS = "PROFILE_FORM_VALIDATE_PASSWORD_SUCCESS";
export const PROFILE_FORM_VALIDATE_PASSWORD_FAILURE = "PROFILE_FORM_VALIDATE_PASSWORD_FAILURE";

export const PROFILE_FORM_VALIDATE_SUMMONER_NAME_START = "PROFILE_FORM_VALIDATE_SUMMONER_NAME_START";
export const PROFILE_FORM_VALIDATE_SUMMONER_NAME_SUCCESS = "PROFILE_FORM_VALIDATE_SUMMONER_NAME_SUCCESS";
export const PROFILE_FORM_VALIDATE_SUMMONER_NAME_FAILURE = "PROFILE_FORM_VALIDATE_SUMMONER_NAME_FAILURE";

export const PROFILE_FORM_VALIDATE_REGION_START = "PROFILE_FORM_VALIDATE_REGION_START";
export const PROFILE_FORM_VALIDATE_REGION_SUCCESS = "PROFILE_FORM_VALIDATE_REGION_SUCCESS";
export const PROFILE_FORM_VALIDATE_REGION_FAILURE = "PROFILE_FORM_VALIDATE_REGION_FAILURE";
