export let PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_FAILURE =
  "PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_FAILURE";
export let PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS =
  "PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_SUCCESS";
export let PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START =
  "PASSWORD_RESET_UPDATE_PASSWORD_FORM_VALIDATION_START";
export const UPDATE_PASSWORD_RESET_EMAIL = "UPDATE_PASSWORD_RESET_EMAIL";
export const UPDATE_PASSWORD_RESET_PASSWORD = "UPDATE_PASSWORD_RESET_PASSWORD";
export const UPDATE_PASSWORD_RESET_REPEAT_PASSWORD = "UPDATE_PASSWORD_RESET_REPEAT_PASSWORD";

export const PASSWORD_RESET_EMAIL_FORM_VALIDATION_START = "PASSWORD_RESET_EMAIL_FORM_VALIDATION_START";
export const PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS = "PASSWORD_RESET_EMAIL_FORM_VALIDATION_SUCCESS";
export const PASSWORD_RESET_EMAIL_FORM_VALIDATION_FAILURE = "PASSWORD_RESET_EMAIL_FORM_VALIDATION_FAILURE";

export const PASSWORD_RESET_RESET_EMAIL = "PASSWORD_RESET_RESET_EMAIL";
export const PASSWORD_RESET_RESET_PASSWORD = "PASSWORD_RESET_RESET_PASSWORD";
