import React from "react";
import { withStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

const GreyBootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    // zIndex: -10,
    position: "relative",
    backgroundColor: "transparent",
    color: theme.palette.getContrastText(theme.palette.primary.main2_light),
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey["500"],
    fontSize: 16,
    fontWeight: 600,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"], {
      duration: theme.transitions.duration.shortest,
      delay: 0
    }),
    "&:focus": {
      outline: "none",
      borderRadius: 4,
      borderColor: theme.palette.text.primary,
      boxShadow: `0 0 0 0.05rem ${theme.palette.text.primary}`
    },
    "&:hover": {
      outline: "none",
      outlineColor: theme.palette.text.primary,
      borderRadius: 4,
      borderColor: theme.palette.text.primary
    }
  }
}))(InputBase);

export default GreyBootstrapInput;
