import { createSelector } from "reselect";
import {
  getUserComparisonGame,
} from "redux/state/user-comparison/selectors";
import { GENERIC_SCORE_TYPE } from "config/settings";

const getAutocomplete = state => state.autocomplete;

export const getStudySearchAutocom = state => getAutocomplete(state).studySearch;
export const getStudySeachAutocomById = state => getStudySearchAutocom(state).byId;
export const getStudySeachAutocomAllIds = state => getStudySearchAutocom(state).allIds;
export const getStudySeachAutocomTopRankFilter = state => getStudySearchAutocom(state).topRankFilter;
export const getStudySeachAutocomGameFilter = state => getStudySearchAutocom(state).gameFilter;

export const getStudySeachAutocomUserSelected = state => getStudySearchAutocom(state).userSelected;
export const getStudySeachAutocomComparedSelected = state => getStudySearchAutocom(state).comparedSelected;

// export const getStudySearchByComparedSelectedUsername = state => getStudySearchByComparedSelected(state).username;

export const getStudySearchFilteredByUserComparisonFormUserGameSelected = createSelector(
  [getUserComparisonGame, getStudySeachAutocomGameFilter],
  (gameSelected, filterByGame) => (gameSelected !== "" && filterByGame[gameSelected] ? filterByGame[gameSelected] : [])
);

export const getStudySearchFilteredByUserComparisonFormUserGameSelectedAndTopRanks = createSelector(
  [getUserComparisonGame, getStudySeachAutocomTopRankFilter],
  (gameSelected, filterByTopRanks) =>
    gameSelected !== "" && filterByTopRanks[gameSelected] ? filterByTopRanks[gameSelected] : []
);

export const getStudySearchFilteredByUserComparisonFormComparedGameSelected = createSelector(
  [getUserComparisonGame, getStudySeachAutocomGameFilter],
  (gameSelected, filterByGame) => (gameSelected !== "" && filterByGame[gameSelected] ? filterByGame[gameSelected] : [])
);

export const getStudySearchFilteredByUserComparisonFormComparedGameSelectedAndTopRanks = createSelector(
  [getUserComparisonGame, getStudySeachAutocomTopRankFilter],
  (gameSelected, filterByTopRanks) =>
    gameSelected !== "" && filterByTopRanks[gameSelected] ? filterByTopRanks[gameSelected] : []
);

export const getStudySearchFilteredByGenericGameAndTopRanks = createSelector(
  [() => GENERIC_SCORE_TYPE, getStudySeachAutocomTopRankFilter],
  (genericGame, filterByTopRanks) =>
    genericGame !== "" && filterByTopRanks[genericGame] ? filterByTopRanks[genericGame] : []
);

export const getStudySearchComparedGame = createSelector(
  [getStudySeachAutocomById, getStudySeachAutocomComparedSelected],
  (byId, selected) => (byId && selected !== "" && byId[selected] ? byId[selected] : null)
);

export const getStudySearchCompareGameUsername = createSelector([getStudySearchComparedGame], game =>
  game && game.username ? game.username : ""
);
export const getStudySearchCompareGameWins = createSelector([getStudySearchComparedGame], game =>
  game && game.wins ? game.wins : 0
);
