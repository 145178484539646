import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedProfileGender,
  getProfileAge,
  getProfileEmail,
  getProfileImage,
  getProfileUpdateErrorCode,
  getProfileUpdateField,
  getProfileUpdateIsError,
  getProfileUpdateIsLoading,
  getProfileUpdateLoaded,
  getProfileUpdateValue,
  getProfileUsername
} from "redux/state/profile/selectors";
import { profileUpdateImage } from "redux/state/profile/actions";
import ProfileView from "app/pages/account/profile/ProfileView";
import { submitUpdateProfile } from "redux/state/profile/operations";

const Profile = props => {
  const userImg = useSelector(state => getProfileImage(state));
  const username = useSelector(state => getProfileUsername(state));
  const dispatch = useDispatch();
  const handleSubmit = values => dispatch(submitUpdateProfile(values));
  const handleChangeImage = file => dispatch(profileUpdateImage(file));
  const submit = {
    submitState: {
      loading: {
        submitting: useSelector(state => getProfileUpdateIsLoading(state)),
        submitted: useSelector(state => getProfileUpdateLoaded(state))
      },
      value: useSelector(state => getProfileUpdateValue(state)),
      field: useSelector(state => getProfileUpdateField(state)),
      error: {
        isError: useSelector(state => getProfileUpdateIsError(state)),
        code: useSelector(state => getProfileUpdateErrorCode(state))
      }
    }
  };
  const formRows = [
    {
      name: "username",
      type: "text",
      label: "Tag o nickname",
      value: username,
      withForm: true
    },
    {
      name: "sex",
      type: "select",
      label: "Sexo de nacimiento",
      value: useSelector(state => getFormattedProfileGender(state)),
      withForm: true,
      data: [
        { value: "other", label: "Otro" },
        { value: "male", label: "Masculino" },
        { value: "female", label: "Femenino" }
      ]
    },
    {
      name: "email",
      type: "email",
      label: "Correo",
      value: useSelector(state => getProfileEmail(state)) || "",
      withForm: false
    },
    { name: "password", type: "password", label: "Contraseña", value: "********", withForm: false },
    {
      name: "birthday",
      type: "text",
      label: "Fecha de nacimiento",
      value: useSelector(state => getProfileAge(state)),
      withForm: false
    }
  ];
  return (
    <ProfileView
      username={username}
      onSave={handleSubmit}
      handleChangeImage={handleChangeImage}
      formRows={formRows}
      userImg={userImg}
      {...submit}
    />
  );
};

Profile.propTypes = {};

export default Profile;
