import { PREFETCH } from "config/constants";
import { combineReducers } from "redux";
import {
  PREFETCH_UPDATE_STATUS_ERROR,
  PREFETCH_UPDATE_STATUS_LOADED,
  PREFETCH_UPDATE_STATUS_LOADING,
  PREFETCH_UPDATE_STATUS_NOT_LOADED
} from "redux/state/prefetch/types";
import { STATE_VERSION } from "config/settings";

export const PREFETCH_STATUS = {
  NOT_LOADED: "NOT_LOADED",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
  VERSION: STATE_VERSION
};

const orderInit = [PREFETCH.ABILITIES, PREFETCH.GAMES, PREFETCH.REGIONS, PREFETCH.SOCIALS];

const order = (state = orderInit, action) => state;

const status = (state = PREFETCH_STATUS.NOT_LOADED, action) => {
  switch (action.type) {
    case PREFETCH_UPDATE_STATUS_NOT_LOADED:
      return PREFETCH_STATUS.NOT_LOADED;

    case PREFETCH_UPDATE_STATUS_LOADED:
      return PREFETCH_STATUS.VERSION;

    case PREFETCH_UPDATE_STATUS_LOADING:
      return PREFETCH_STATUS.LOADING;

    case PREFETCH_UPDATE_STATUS_ERROR:
      return PREFETCH_STATUS.ERROR;

    default:
      return state;
  }
};

export default combineReducers({ order, status });
