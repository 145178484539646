import React from "react";
import { Helmet } from "react-helmet/es/Helmet";
import background from "app/assets/img/fondo_color.png"
import profile from "app/assets/img/perfil.png"
import working from "app/assets/img/working.svg"

const LandingHeader = props => {
  return (
    <Helmet>
      <link rel="preload" href={background} as="image"/>
      <link rel="preload" href={profile} as="image"/>
      <link rel="preload" href={working} as="image"/>
    </Helmet>
  );
};

LandingHeader.propTypes = {

};

export default LandingHeader;
