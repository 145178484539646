import { ofType } from "redux-observable";
import { debounce, debounceTime, map, mapTo, throttle, throttleTime } from "rxjs/operators";
import { validateProfileForm } from "redux/state/forms/profile/operations";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile";
import { getAuthToken } from "redux/state/auth/selectors";
import {
  autocomStudySearchByTerm,
  autocomStudySearchFetchTopRanks
} from "redux/state/autocomplete/study-search/actions";
import api from "api";
import { mapTopRanksToState } from "redux/state/autocomplete/study-search/utils";
import { getProfileMainGameId } from "redux/state/profile/selectors";
import {
  _autocomStudySearchUserSelect,
  autocomStudySearchUserReset,
  _autocomStudySearchCompareSelect,
  autocomStudySearchCompareReset,
  autocomStudySearchCompareSelectSuccess,
  autocomStudySearchUserSelectSuccess
} from "./actions";
import { COMPARISON_UPDATE_COMPARED_NICKNAME } from "redux/state/forms/user-comparison/types";
import {
  getUserComparisonFormComparedGame,
  getUserComparisonFormComparedNickname
} from "redux/state/forms/user-comparison/selectors";
import { GENERIC_SCORE_TYPE } from "config/settings";

const autocomStudySearchUserSelect = value => (dispatch, getState) => {
  dispatch(_autocomStudySearchUserSelect(value));
  dispatch(autocomStudySearchUserSelectSuccess());
};

const autocomStudySearchCompareSelect = value => (dispatch, getState) => {
  dispatch(_autocomStudySearchCompareSelect(value));
  dispatch(autocomStudySearchCompareSelectSuccess());
};

const _fetchStudySearchTopRanks = () => (dispatch, getState) => {
  const state = getState();
  const token = getAuthToken(getState());

  // Generic score type
  const scoreType = GENERIC_SCORE_TYPE;
  // const selectedGameId = "a7f6d2f1-4b2f-49ef-b23f-a31ce691b4bc";
  dispatch(
    autocomStudySearchFetchTopRanks(
      api.score.fetchTopRanksAutocomplete(scoreType, token),
      mapTopRanksToState
    )
  );
};

const _fetchStudySearchWithTerm = () => (dispatch, getState) => {
  const state = getState();
  const token = getAuthToken(getState());

  // Generic score type
  const scoreType = GENERIC_SCORE_TYPE;
  const term = getUserComparisonFormComparedNickname(state);

  if (term !== "")
    dispatch(
      autocomStudySearchByTerm(
        api.score.fetchPlayersWithTerm(scoreType, token, term),
        mapTopRanksToState
      )
    );
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const searchTopRanksAutocompleteEpic = action$ =>
  action$.pipe(
    ofType(PROFILE_CHECK_SUCCESS),
    map(action => _fetchStudySearchTopRanks(action.type))
  );

export const fetchStudySearchTermEpic = action$ =>
  action$.pipe(ofType(COMPARISON_UPDATE_COMPARED_NICKNAME), throttleTime(400), mapTo(_fetchStudySearchWithTerm()));

export {
  autocomStudySearchUserSelect,
  autocomStudySearchUserReset,
  autocomStudySearchCompareSelect,
  autocomStudySearchCompareReset
};
