import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AuthPageTemplate from "app/components/auth-page-template/AuthPageTemplate";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {},
  containerMargin: {
    margin: "auto"
  }
}));

const AuthPageContainer = ({ className, children, ...other }) => {
  const classes = useStyles();
  return (
    <Container maxWidth={"lg"} className={clsx(classes.root, className)} {...other}>
      <Grid container>
        <Grid item xs={12} md={8} className={classes.containerMargin}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

AuthPageContainer.propTypes = {};

export default AuthPageContainer;
