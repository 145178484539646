import * as actions from "./actions";
import * as selectors from "./selectors";
import * as validation from "redux/state/forms/validation";
import { getRegionsFormattedByGame } from "redux/state/regions/selectors";

export const validateRegisterForm = () => (dispatch, getState) => {
  dispatch(actions.validateRegisterFormStart());
  const state = getState();
  const regions = getRegionsFormattedByGame(state);

  const errors = {
    email: validation.validateRegisterEmail(selectors.getRegisterEmail(state)),
    password: validation.validateRegisterPassword(
      selectors.getRegisterPassword(state),
      selectors.getRegisterConfirmPassword(state)
    ),
    confirmPassword: validation.validateRegisterConfirmPassword(
      selectors.getRegisterPassword(state),
      selectors.getRegisterConfirmPassword(state)
    ),
    mindsetUsername: validation.validateRegisterMindsetUsername(selectors.getRegisterFormeProfileTag(state)),
    birthday: validation.validateRegisterBirthDay(selectors.getRegisterBirthDay(state)),
    gender: validation.validateRegisterGender(selectors.getRegisterGender(state)),
    mainGame: validation.validateMainGame(selectors.getRegisterFormMainGame(state)),
    summonerName: 
      regions && regions.length > 0 ? validation.validateRegisterSummonerName(selectors.getRegisterSummonerName(state)) : undefined,
    region:
      regions && regions.length > 0 ? validation.validateRegisterRegion(selectors.getRegisterRegion(state)) : undefined,
    // profileImage: validation.validateImage(selectors.getRegisterProfileImage(state))
    terms: validation.validateRegisterTerms(selectors.getRegisterTerms(state)),
  };
  
  // check for errors. By default is undefined (falsy value) so if is not undefined, this value will be true
  const hasErrors =
    errors.email ||
    errors.password ||
    errors.confirmPassword ||
    errors.mindsetUsername ||
    errors.birthday ||
    errors.gender ||
    errors.profileImage ||
    errors.summonerName ||
    errors.region ||
    errors.mainGame ||
    errors.terms;

  if (hasErrors) {
    dispatch(actions.validateRegisterFormFailure(errors));
  } else {
    dispatch(actions.validateRegisterFormSuccess());
  }
};
//
// export const validateFormAndFetchLolInfo = () => (dispatch, getState) => {
//   dispatch(actions.validateRegisterFormStart());
//   const state = getState();
//
//   const lolSelected = getRegisterLolSelected(state);
//
//   // It validates every input from the formRegister state
//   const name = validation.validateRegisterName(selectors.getRegisterName(state));
//   const lastname = validation.validateRegisterLastname(selectors.getRegisterLastname(state));
//   const email = validation.validateRegisterEmail(selectors.getRegisterEmail(state));
//   const password = validation.validateRegisterPassword(
//     selectors.getRegisterPassword(state),
//     selectors.getRegisterConfirmPassword(state)
//   );
//   const confirmPassword = validation.validateRegisterConfirmPassword(
//     selectors.getRegisterPassword(state),
//     selectors.getRegisterConfirmPassword(state)
//   );
//   const mindsetUsername = validation.validateRegisterMindsetUsername(selectors.getRegisterFormeProfileTag(state));
//   const birthday = validation.validateRegisterBirthDay(selectors.getRegisterBirthDay(state));
//   const gender = validation.validateRegisterGender(selectors.getRegisterGender(state));
//   const profileImage = validation.validateImage(selectors.getRegisterProfileImage(state));
//   const terms = validation.validateRegisterTerms(selectors.getRegisterTerms(state));
//
//   // Single object containing the validations
//   let errors = {
//     name,
//     lastname,
//     email,
//     password,
//     confirmPassword,
//     mindsetUsername,
//     birthday,
//     gender,
//     profileImage,
//     terms
//   };
//   // check for errors. By default is undefined (falsy value) so if is not undefined, this value will be true
//   const hasErrors =
//     name || lastname || email || password || confirmPassword || mindsetUsername || birthday || gender || profileImage || terms;
//
//   // If errors were found
//   if (hasErrors) {
//     // If lol was selected from compare form
//     if (lolSelected) {
//       // It validates the summoner name and region and adds it to the error object
//       const summonerName = validation.validateRegisterSummonerName(selectors.getRegisterSummonerName(state));
//       const region = validation.validateRegisterRegion(selectors.getRegisterRegion(state));
//       if (summonerName || region) {
//         errors = { ...errors, summonerName, region };
//       }
//     }
//     // It dispatches the REGISTER_VALIDATE_FORM_FAILURE action with the errors
//     dispatch(actions.validateRegisterFormFailure(errors));
//   } else {
//     // IF NO ERRORS WERE FOUND
//     // It dispatches a REGISTER_VALIDATE_FORM_SUCCESS
//     dispatch(actions.validateRegisterFormSuccess());
//
//     // AND if lol was selected, it fetches the summoner info and pass the setRegisterFragmentNext function
//     // as a callback to be executed after the response resolve
//     if (lolSelected) {
//       dispatch(fetchSummonerInfo(setRegisterFragmentNext));
//     } else {
//       // If lol wasn't selected it changes the fragment to the next one (ConfirmDisplay)
//       dispatch(setRegisterFragmentNext());
//     }
//   }
// };

// /** ------------------------------------------------------
//  *  EPICS
//  * ------------------------------------------------------*/
// export const validateRegisterFormEpic = action$ =>
//   action$.pipe(ofType(START_REGISTRATION_PROCESS), mapTo(validateRegisterForm()));
