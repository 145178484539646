export const mapValidationToStateErrors = errors => {
  const obj = {
    errors: false,
    name: undefined,
    lastname: undefined,
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    mindsetUsername: undefined,
    birthday: undefined,
    gender: undefined,
    summonerName: undefined,
    region: undefined,
    profileImage: undefined,
    mainGame: undefined,
    terms: undefined,
  };

  const setObjKey = msg => ({ error: true, errorMsg: msg });

  if (errors.name) obj.name = setObjKey(errors.name.name[0]);
  if (errors.lastname) obj.lastname = setObjKey(errors.lastname.lastname[0]);
  if (errors.email) obj.email = setObjKey(errors.email.email[0]);
  if (errors.password) obj.password = setObjKey(errors.password.password[0]);
  if (errors.confirmPassword) obj.confirmPassword = setObjKey(errors.confirmPassword.confirmPassword[0]);
  if (errors.mindsetUsername) obj.mindsetUsername = setObjKey(errors.mindsetUsername.mindsetUsername[0]);
  if (errors.birthday) obj.birthday = setObjKey(errors.birthday.birthday[0]);
  if (errors.gender) obj.gender = setObjKey(errors.gender.gender[0]);
  if (errors.mainGame) obj.mainGame = setObjKey(errors.mainGame.mainGame[0])
  if (errors.summonerName) obj.summonerName = setObjKey(errors.summonerName.summonerName[0]);
  if (errors.region) obj.region = setObjKey(errors.region.region[0]);
  if (errors.profileImage) obj.profileImage = setObjKey(errors.profileImage.profileImage[0]);
  if (errors.terms) obj.terms = setObjKey(errors.terms.terms[0]);

  if (
    errors.name ||
    errors.lastname ||
    errors.email ||
    errors.password ||
    errors.confirmPassword ||
    errors.mindsetUsername ||
    errors.birthday ||
    errors.gender ||
    errors.region ||
    errors.summonerName ||
    errors.profileImage ||
    errors.mainGame ||
    errors.terms
  )
    obj.hasErrors = true;
  return obj;
};
