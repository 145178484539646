const passwordReset = state => state.forms.passwordReset;
const passwordResetForm = state => passwordReset(state).form;
const passwordResetValidate = state => passwordReset(state).validate;

export const passwordResetFormEmail = state => passwordResetForm(state).email;
export const passwordResetFormPassword = state => passwordResetForm(state).password;
export const passwordResetFormRepeatPassword = state => passwordResetForm(state).repeatPassword;

export const passwordResetEmailFormValidate = state => passwordResetValidate(state).emailForm;
export const passwordResetEmailFormValidateErrors = state => passwordResetEmailFormValidate(state).errors;
export const passwordResetEmailFormValidateHasErrors = state => passwordResetEmailFormValidateErrors(state).hasErrors;
export const passwordResetEmailFormValidateEmailErrors = state => passwordResetEmailFormValidateErrors(state).email;

export const passwordResetUpdatePasswordFormValidate = state => passwordResetValidate(state).updatePasswordForm;
export const passwordResetUpdatePasswordFormValidateErrors = state => passwordResetUpdatePasswordFormValidate(state).errors;
export const passwordResetUpdatePasswordFormValidateHasErrors = state => passwordResetUpdatePasswordFormValidateErrors(state).hasErrors;
export const passwordResetUpdatePasswordFormValidatePassword = state => passwordResetUpdatePasswordFormValidateErrors(state).password;
export const passwordResetUpdatePasswordFormValidateRepeatPassword = state => passwordResetUpdatePasswordFormValidateErrors(state).password;
