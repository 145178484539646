import * as aTypes from "./types";
import acFactory from "ejs-acfactory";
// import acFactory from "redux/lib/ac-factory";

//
export const fetchStudy = (promise, transform) => ({
  type: aTypes.STUDY_FETCH_BY_ID,
  payload: promise,
  meta: { transform }
});

export const fetchTopRankStudies = (promise, transform) => ({
  type: aTypes.STUDY_FETCH_TOP_RANKS,
  payload: promise,
  meta: { transform }
});

// export const fetchUserStudy = (promise, transform) => ({
//   type: aTypes.STUDY_FETCH_CURRENT_USER_RESULTS,
//   payload: promise
// });

export const fetchUserStudy = acFactory(aTypes.STUDY_FETCH_CURRENT_USER_RESULTS);
export const fetchStudyByUserId = acFactory(aTypes.STUDY_FETCH_BY_USER_ID);

export const fetchStudyByUserIdStart = () => ({ type: aTypes.STUDY_FETCH_BY_USER_ID_START });
export const fetchStudyByUserIdSuccess = study => ({
  type: aTypes.STUDY_FETCH_BY_USER_ID_SUCCESS,
  payload: study
});
export const fetchStudyByUserIdFailure = error => ({
  type: aTypes.STUDY_FETCH_BY_USER_ID_FAILURE,
  error: true,
  payload: error
});

export const setSelectedCompareStudyByAutocomplete = study => ({
  type: aTypes.STUDY_SET_SELECT_COMPARED_STUDY,
  payload: study
});
export const setSelectedCompareStudyByAutocompleteSuccess = () => ({
  type: aTypes.STUDY_SET_SELECT_COMPARED_STUDY_SUCCESS
});

export const setSelectedUserStudyByAutocomplete = study => ({
  type: aTypes.STUDY_SET_SELECT_USER_STUDY,
  payload: study
});
export const setSelectedUserStudyByAutocompleteSuccess = () => acFactory(aTypes.STUDY_SET_SELECT_USER_STUDY_SUCCESS);

export const validateStudyExistence = studyId => ({ type: aTypes.STUDY_VALIDATE_STUDY_EXISTENCE, payload: studyId });
export const validateStudyExistenceStart = () => ({ type: aTypes.STUDY_VALIDATE_STUDY_EXISTENCE_START });
export const validateStudyExistenceSuccess = () => ({ type: aTypes.STUDY_VALIDATE_STUDY_EXISTENCE_SUCCESS });
export const validateStudyExistenceFailure = error => ({
  type: aTypes.STUDY_VALIDATE_STUDY_EXISTENCE_FAILURE,
  error: true,
  payload: { error: true, status: 500, statusText: error, message: error },
  status: 500
});
