import React from "react";
import PropTypes from "prop-types";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";
import AuthPageTemplate from "app/components/auth-page-template/AuthPageTemplate";
import AuthPageContainer from "app/components/auth-page-container/AuthPageContainer";
import { useDispatch, useSelector } from "react-redux";
import { passwordResetFormLoaded } from "redux/state/ui/pages/password-reset/actions";
import {
  getAuthPasswordResetTokenValidationIsError,
  getAuthPasswordResetTokenValidationStatus,
  getAuthPasswordResetTokenValidationToken,
  getAuthPasswordResetUpdatePasswordStatus
} from "redux/state/auth/selectors";
import PasswordResetFormView from "app/pages/password-reset/password-reset-form/PasswordResetFormView";
import { Redirect } from "react-router-dom";
import routes from "app/routes";
import PasswordUpdateView from "app/pages/password-reset/password-update/PasswordUpdateView";
import PasswordResetFormError from "app/pages/password-reset/password-reset-form/PasswordResetFormError";
import {
  updatePasswordResetPassword,
  updatePasswordResetRepeatPassword
} from "redux/state/forms/password-reset/actions";
import {
  passwordResetFormPassword,
  passwordResetFormRepeatPassword,
  passwordResetUpdatePasswordFormValidateErrors
} from "redux/state/forms/password-reset/selectors";
import { validatePasswordResetUpdatePasswordForm } from "redux/state/forms/password-reset/operations";
import { authPasswordResetTriggerSendMail, authPasswordResetTriggerUpdatePassword } from "redux/state/auth/actions";

const PasswordResetForm = props => {
  const dispatch = useDispatch();
  const tokenHasError = useSelector(state => getAuthPasswordResetTokenValidationIsError(state));
  const tokenStatus = useSelector(state => getAuthPasswordResetTokenValidationStatus(state));
  const tokenFromStore = useSelector(state => getAuthPasswordResetTokenValidationToken(state));
  const password = useSelector(state => passwordResetFormPassword(state));
  const repeatPassword = useSelector(state => passwordResetFormRepeatPassword(state));
  const updatePasswordStatus = useSelector(state => getAuthPasswordResetUpdatePasswordStatus(state));

  const errors = useSelector(state => passwordResetUpdatePasswordFormValidateErrors(state));

  const dispatchUpdatePassword = () => dispatch(authPasswordResetTriggerUpdatePassword());

  const handleClick = () => {
    dispatchUpdatePassword();
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      dispatchUpdatePassword();
    }
  };

  const updatePassword = e => {
    dispatch(updatePasswordResetPassword(e.target.value));
  };
  const updateRepeatPassword = e => {
    dispatch(updatePasswordResetRepeatPassword(e.target.value));
  };

  React.useEffect(() => {
    dispatch(passwordResetFormLoaded());
  }, []);

  const renderComponents = () => {
    if (updatePasswordStatus === 200) {
      return <Redirect to={routes.login.route} />;
    }

    if (tokenHasError || tokenStatus !== 200 || tokenFromStore === "") {
      return <PasswordResetFormError />;
    }

    return (
      <PasswordResetFormView
        errors={errors}
        handleKeyPress={handleKeyPress}
        handleClick={handleClick}
        password={password}
        repeatPassword={repeatPassword}
        updatePassword={updatePassword}
        updateRepeatPassword={updateRepeatPassword}
      />
    );
  };

  return (
    <AuthPageTemplate>
      <AuthPageContainer>
        {/*{tokenHasError || tokenStatus !== 200 || tokenFromStore === "" ? (*/}
        {/*  <PasswordResetFormError />*/}
        {/*) : (*/}
        {/*  <PasswordResetFormView*/}
        {/*    errors={errors}*/}
        {/*    handleKeyPress={handleKeyPress}*/}
        {/*    handleClick={handleClick}*/}
        {/*    password={password}*/}
        {/*    repeatPassword={repeatPassword}*/}
        {/*    updatePassword={updatePassword}*/}
        {/*    updateRepeatPassword={updateRepeatPassword}*/}
        {/*  />*/}
        {/*)}*/}
        {renderComponents()}
      </AuthPageContainer>
    </AuthPageTemplate>
  );
};

PasswordResetForm.propTypes = {};

export default PasswordResetForm;
