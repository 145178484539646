import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";
import { combineReducers } from "redux";

const byIdInit = {};
const allIdsInit = [];
const gameFilterInit = {};

const byId = (state = byIdInit, action) => {
  switch (action.type) {
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_START:
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_FAILURE:
      return state;

    case aTypes.AUTOCOM_FETCH_TOP_RANKS_SUCCESS:
    case aTypes.AUTOCOM_FETCH_BY_TERM_SUCCESS:
      const key = Object.keys(action.payload)[0];

      return { ...state, ...action.payload[key] };

    case LOGOUT:
      return { ...byIdInit };

    default:
      return state;
  }
};

const allIds = (state = allIdsInit, action) => {
  switch (action.type) {
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_START:
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_FAILURE:
      return state;

    case aTypes.AUTOCOM_FETCH_TOP_RANKS_SUCCESS:
    case aTypes.AUTOCOM_FETCH_BY_TERM_SUCCESS:
      const key = Object.keys(action.payload)[0];
      const data = action.payload[key];
      const keys = Object.keys(data);
      return state.indexOf(keys[0]) === -1 ? [...state, ...keys] : state;

    case LOGOUT:
      return [...allIdsInit];

    default:
      return state;
  }
};

const topRankFilter = (state = gameFilterInit, action) => {
  switch (action.type) {
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_START:
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_FAILURE:
      return state;

    case aTypes.AUTOCOM_FETCH_TOP_RANKS_SUCCESS:
      const key = Object.keys(action.payload)[0];
      const data = action.payload[key];
      const keys = Object.keys(data);
      const arrKey = Array.isArray(state[key]) ? state[key] : [];
      return { ...state, [key]: [...new Set([...arrKey, ...keys])] };

    case LOGOUT:
      return { ...gameFilterInit };

    default:
      return state;
  }
};

const gameFilter = (state = gameFilterInit, action) => {
  switch (action.type) {
    case aTypes.AUTOCOMPLETE_FETCH_STUDY_SEARCH_START:
    case aTypes.AUTOCOMPLETE_FETCH_STUDY_SEARCH_FAILURE:
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_START:
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_FAILURE:
      return state;

    case aTypes.AUTOCOMPLETE_FETCH_STUDY_SEARCH_SUCCESS:
    case aTypes.AUTOCOM_FETCH_TOP_RANKS_SUCCESS:
    case aTypes.AUTOCOM_FETCH_BY_TERM_SUCCESS:
      const key = Object.keys(action.payload)[0];
      const data = action.payload[key];
      const keys = Object.keys(data);
      const arrKey = Array.isArray(state[key]) ? state[key] : [];
      return { ...state, [key]: [...new Set([...arrKey, ...keys])] };

    case LOGOUT:
      return { ...gameFilterInit };

    default:
      return state;
  }
};

const userSelected = (state = "", action) => {
  switch (action.type) {
    case aTypes.AUTOCOM_STUDY_SEARCH_USER_SELECT:
      return action.payload;

    case LOGOUT:
    case aTypes.AUTOCOM_STUDY_SEARCH_USER_RESET:
      return "";

    default:
      return state;
  }
};

const comparedSelected = (state = "", action) => {
  switch (action.type) {
    case aTypes.AUTOCOM_STUDY_SEARCH_COMPARE_SELECT:
      return action.payload;

    case LOGOUT:
    // case aTypes.AUTOCOM_STUDY_SEARCH_COMPARE_RESET:
      return "";

    default:
      return state;
  }
};

export default combineReducers({ byId, allIds, topRankFilter, gameFilter, userSelected, comparedSelected });
