import PropTypes from "prop-types";

export const autocompleteProps = {
  byId: PropTypes.object.isRequired,
  bestPlayers: PropTypes.array.isRequired,
  allIds: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  setAutocompleteSelected: PropTypes.func.isRequired,
  resetSelectedAutocomplete: PropTypes.func.isRequired,
  autocompleteValidation: PropTypes.object,
  autocompleteValidationText: PropTypes.func
};

export const userInfoProps = {
  avatarName: PropTypes.string.isRequired,
  mainGame: PropTypes.string.isRequired,
  wins: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  winsText: PropTypes.string.isRequired,
  userId: PropTypes.string
};

export const playerSearchFormProps = {
  handleChangeGame: PropTypes.func.isRequired,
  handleChangeRegion: PropTypes.func.isRequired,
  handleChangeNickname: PropTypes.func.isRequired,
  selectedGame: PropTypes.string.isRequired,
  selectRegion: PropTypes.string.isRequired,
  selectNickname: PropTypes.string.isRequired,
  games: PropTypes.array.isRequired,
  byIdValueName: PropTypes.string.isRequired,
  byIdIdName: PropTypes.string.isRequired
};
