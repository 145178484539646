export const STUDY_FETCH_CURRENT_USER_RESULTS = "STUDY_FETCH_CURRENT_USER_RESULTS";
export const STUDY_FETCH_CURRENT_USER_RESULTS_START = "STUDY_FETCH_CURRENT_USER_RESULTS_START";
export const STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS = "STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS";
export const STUDY_FETCH_CURRENT_USER_RESULTS_FAILURE = "STUDY_FETCH_CURRENT_USER_RESULTS_FAILURE";

export const STUDY_FETCH_TOP_RANKS = "STUDY_FETCH_TOP_RANKS";
export const STUDY_FETCH_TOP_RANKS_START = "STUDY_FETCH_TOP_RANKS_START";
export const STUDY_FETCH_TOP_RANKS_SUCCESS = "STUDY_FETCH_TOP_RANKS_SUCCESS";
export const STUDY_FETCH_TOP_RANKS_FAILURE = "STUDY_FETCH_TOP_RANKS_FAILURE";

export const STUDY_SET_SELECT_COMPARED_STUDY = "STUDY_SET_SELECT_COMPARED_STUDY";
export const STUDY_SET_SELECT_USER_STUDY = "STUDY_SET_SELECT_USER_STUDY";
export const STUDY_SET_SELECT_USER_STUDY_SUCCESS = "STUDY_SET_SELECT_USER_STUDY_SUCCESS";
export const STUDY_SET_SELECT_COMPARED_STUDY_SUCCESS = "STUDY_SET_SELECT_COMPARED_STUDY_SUCCESS";

export const STUDY_VALIDATE_STUDY_EXISTENCE = "STUDY_VALIDATE_STUDY_EXISTENCE";
export const STUDY_VALIDATE_STUDY_EXISTENCE_START = "STUDY_VALIDATE_STUDY_EXISTENCE_START";
export const STUDY_VALIDATE_STUDY_EXISTENCE_SUCCESS = "STUDY_VALIDATE_STUDY_EXISTENCE_SUCCESS";
export const STUDY_VALIDATE_STUDY_EXISTENCE_FAILURE = "STUDY_VALIDATE_STUDY_EXISTENCE_FAILURE";

export const STUDY_FETCH_BY_USER_ID = "STUDY_FETCH_BY_USER_ID";
export const STUDY_FETCH_BY_USER_ID_START = "STUDY_FETCH_BY_USER_ID_START";
export const STUDY_FETCH_BY_USER_ID_SUCCESS = "STUDY_FETCH_BY_USER_ID_SUCCESS";
export const STUDY_FETCH_BY_USER_ID_FAILURE = "STUDY_FETCH_BY_USER_ID_FAILURE";

// STUDY
// @DEPRECATED
export const STUDY_FETCH_BY_ID = "STUDY_FETCH_BY_ID";
export const STUDY_FETCH_BY_ID_START = "STUDY_FETCH_BY_ID_START";
export const STUDY_FETCH_BY_ID_SUCCESS = "STUDY_FETCH_BY_ID_SUCCESS";
export const STUDY_FETCH_BY_ID_FAILURE = "STUDY_FETCH_BY_ID_FAILURE";
