const auth = state => state.auth;
const authError = state => auth(state).error;

/**
 * SELECTORS
 */
export const getAuthLoading = state => auth(state).loading;
export const getAuthAuthenticated = state => auth(state).authenticated;
export const getAuthLoggedOut = state => auth(state).loggedOut;
export const getAuthRegister = state => auth(state).register;

export const getAuthToken = state => auth(state).credentials.token;

export const getAuthError = state => authError(state);
export const getAuthHasError = state => authError(state).error;
export const getAuthErrorStatus = state => authError(state).status;

export const getAuthPasswordReset = state => auth(state).passwordResetSendMail;
export const getAuthPasswordResetIsError = state => getAuthPasswordReset(state).error;
export const getAuthPasswordResetStatus = state => getAuthPasswordReset(state).status;
export const getAuthPasswordResetIsLoading = state => getAuthPasswordReset(state).loading;

export const getAuthPasswordResetTokenValidation = state => auth(state).passwordResetTokenValidation;
export const getAuthPasswordResetTokenValidationIsError = state => getAuthPasswordResetTokenValidation(state).error;
export const getAuthPasswordResetTokenValidationStatus = state => getAuthPasswordResetTokenValidation(state).status;
export const getAuthPasswordResetTokenValidationIsLoading = state => getAuthPasswordResetTokenValidation(state).loading;
export const getAuthPasswordResetTokenValidationToken = state => getAuthPasswordResetTokenValidation(state).token;

export const getAuthPasswordResetUpdatePassword = state => auth(state).passwordResetUpdatePassword;
export const getAuthPasswordResetUpdatePasswordStatus = state => auth(state).passwordResetUpdatePassword.status;
export const getAuthPasswordResetUpdatePasswordError = state => auth(state).passwordResetUpdatePassword.error;
export const getAuthPasswordResetUpdatePasswordIsLoading = state => auth(state).passwordResetUpdatePassword.loading;
