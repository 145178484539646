import React from "react";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import GrayInput from "app/components/CustomInput/GrayInput";
import LightRedButton from "app/components/custom-button/LighRedButton";
import Typography from "@material-ui/core/Typography";
import { REGISTER_FORM, REGISTER_VALIDATION_ERR } from "app/i18n/selectors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  group: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(5),
    textAlign: "left"
  },
  sendButton: {
    marginTop: theme.spacing(5),
    borderRadius: 0
  },
  buttonWrapper: {
    textAlign: "center"
  }
}));

const PasswordResetFormView = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <AuthFormContainer title={"Nueva contraseña"}>
      <Grid item xs={12} sm={6} className={classes.group}>
        <GrayInput
          type={"password"}
          error={props.errors.password.error}
          variant={"outlined"}
          label={"Nueva contraseña*"}
          fullWidth
          helperText={
            props.errors.password.error
              ? t(`${REGISTER_VALIDATION_ERR}.password.${props.errors.password.errorMsg}`)
              : ""
          }
          value={props.password}
          onKeyPress={props.handleKeyPress}
          onChange={props.updatePassword}
        />
        <br />
        <br />
        <GrayInput
          type={"password"}
          error={props.errors.repeatPassword.error}
          variant={"outlined"}
          label={"Repetir nueva contraseña*"}
          fullWidth
          helperText={
            props.errors.repeatPassword.error
              ? t(`${REGISTER_VALIDATION_ERR}.repeatPassword.${props.errors.repeatPassword.errorMsg}`)
              : "*Obligatorio"
          }
          value={props.repeatPassword}
          onKeyPress={props.handleKeyPress}
          onChange={props.updateRepeatPassword}
        />
        <br />
        <br />
        <Typography variant={"body1"}>Tu nueva contraseña debe tener al menos 8 dígitos</Typography>
        <br />
        <br />
        <div className={classes.buttonWrapper}>
          <LightRedButton onClick={props.handleClick} className={classes.sendButton}>
            Crear Contraseña
          </LightRedButton>
        </div>
        <br />
        <br />
      </Grid>
    </AuthFormContainer>
  );
};

PasswordResetFormView.propTypes = {};

export default PasswordResetFormView;
