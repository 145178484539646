import React from "react";
import SurveyView from "./SurveyView";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authValidateToken } from "../../../redux/state/auth/operations";
import CustomLoader from "../../components/custom-loader/CustomLoader";
import SurveyError from "./SurveyError";
import routes from "../../routes";
import { authSelectors } from "redux/state/auth";
import { getAuthErrorStatus } from "redux/state/auth/selectors";
import { useTranslation } from "react-i18next";
import { RESULTS_ERR } from "app/i18n/selectors";

const Survey = props => {
  const { studyId, token } = useParams();
  const { t } = useTranslation();

  const { getAuthAuthenticated, getAuthLoading, getAuthEmail, getAuthHasError } = authSelectors;

  const dispatch = useDispatch();
  const authenticated = useSelector(state => getAuthAuthenticated(state));
  const authLoading = useSelector(state => getAuthLoading(state));
  const email = useSelector(state => getAuthEmail(state));
  const authFailure = useSelector(state => getAuthHasError(state));
  const authErrorStatus = useSelector(state => getAuthErrorStatus(state));

  const errorTexts = {
    helperText:
      authFailure && authErrorStatus !== 0 ? t(`${RESULTS_ERR}.study.${authErrorStatus}`) : ""
  };

  React.useEffect(() => {
    dispatch(authValidateToken(token, studyId));
  }, []);

  const renderComponent = () => {
    if (authFailure) {
      return <SurveyError {...errorTexts} />;
    }

    if (authLoading && !authenticated) {
      return <CustomLoader absolute={true}/>;
    } else {
      return (
        <SurveyView
          history={props.history}
          email={email}
          authenticated={authenticated}
          onSubmit={`${routes.results.routeEmpty}/${studyId}`}
        />
      );
    }
  };

  return renderComponent();
};

export default Survey;
