import { ofType } from "redux-observable";
import { PREFETCH_SOCIALS } from "redux/state/prefetch/types";
import { map } from "rxjs/operators";
import * as actions from "./actions";
import api from "api";
import * as utils from "./utils";

const _fetch_socials = () => {
  return actions.fetchSocials(api.socials.fetchAll(), utils.mapSocialsResponseToState);
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const fetchSocialsEpic = action$ =>
  action$.pipe(
    ofType(PREFETCH_SOCIALS),
    map(action => _fetch_socials())
  );
