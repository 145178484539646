import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const ITEM_HEIGHT = 400;

const WhiteMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    backgroundColor: "white",
    color: "#000"
  }
})(props => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"))
  return (
    <Menu
      aria-haspopup="true"
      keepMounted
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT
        }
      }}
      transformOrigin={{ horizontal: "center", vertical: xs ? -80 : -70 }}
      {...props}
    />
  );
});

WhiteMenu.propTypes = {};

export default WhiteMenu;
