import * as aTypes from "./types";

export const updateProfileFormName = name => ({
  type: aTypes.PROFILE_FORM_UPDATE_NAME,
  payload: name
});

export const updateProfileFormLastname = lastname => ({
  type: aTypes.PROFILE_FORM_UPDATE_LASTNAME,
  payload: lastname
});

export const updateProfileFormEmail = email => ({
  type: aTypes.PROFILE_FORM_UPDATE_EMAIL,
  payload: email
});

export const updateProfileEprofileUsername = username => ({
  type: aTypes.PROFILE_FORM_UPDATE_EPROFILE_USERNAME,
  payload: username
});

// EMAIL
export const validateEmailFailure = error => ({
  type: aTypes.PROFILE_FORM_VALIDATE_EMAIL_FAILURE,
  payload: error
});

export const validateEmailSuccess = email => ({
  type: aTypes.PROFILE_FORM_VALIDATE_EMAIL_SUCCESS,
  payload: { email }
});

export const validateEmailStart = () => ({
  type: aTypes.PROFILE_FORM_VALIDATE_EMAIL_START
});

// EMAIL
export const validateUsernameFailure = error => ({
  type: aTypes.PROFILE_FORM_VALIDATE_USERNAME_FAILURE,
  payload: error
});

export const validateUsernameSuccess = username => ({
  type: aTypes.PROFILE_FORM_VALIDATE_USERNAME_SUCCESS,
  payload: { username }
});
