import React from "react";
import PropTypes from "prop-types";
import AvatarInformationView from "app/components/avatar-information/AvatarInformationView";
import { avatarInfoProps } from "app/components/avatar-information/avatarInformationProps";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdateImage } from "redux/state/profile/actions";
import { getUIHomeIsMyProfileSelected } from "redux/state/ui/pages/home/selectors";

const AvatarInformation = ({ disabled, avatarInfoState, isUser, isPublic }) => {
  const dispatch = useDispatch();
  const handleChangeImage = file => dispatch(profileUpdateImage(file));
  const isUserSelected = useSelector(state => getUIHomeIsMyProfileSelected(state));
  return (
    <AvatarInformationView
      isUserSelected={isUserSelected}
      isPublic={isPublic}
      isUser={isUser}
      {...avatarInfoState}
      disabled={disabled}
      handleChangeImage={handleChangeImage}
    />
  );
};

AvatarInformation.propTypes = {
  avatarInfoState: PropTypes.shape(avatarInfoProps)
};

export default AvatarInformation;
