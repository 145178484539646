import { createSelector } from "reselect";
import { getUserComparisonGame, getUserComparisonNickname } from "redux/state/user-comparison/selectors";
import { getProfileMainGameId } from "redux/state/profile/selectors";
import { getRegisterFormMainGame } from "redux/state/forms/register/selectors";
import { getPublicProfile, getPublicProfileMainGameID } from "redux/state/public-profile/selectors";

const games = state => state.entities.games;

/**
 * SELECTORS
 */
export const getGames = state => games(state);
export const getGamesById = state => games(state).byId;
export const getGameIds = state => games(state).allIds;
export const getLoadingGames = state => games(state).loading;
export const getGameRegionIdsByUserComparisonFormUserGame = createSelector(
  [getGamesById, getUserComparisonGame],
  (gamesById, userGame) => (userGame !== "" ? gamesById[userGame].regions : [])
);
export const getGameRegionIdsByUserComparisonFormComparedGame = createSelector(
  [getGamesById, getUserComparisonNickname],
  (gamesById, comparedGame) => (comparedGame !== "" ? gamesById[comparedGame].regions : [])
);

export const getGameRegionIdsByRegisterGameSelected = createSelector(
  [getRegisterFormMainGame, getGamesById],
  (gameId, gamesById) => (gameId !== "" ? gamesById[gameId].regions : [])
);

export const getSelectedGameId = state => games(state).selected;

export const getGameBySelected = createSelector(
  [getGamesById, getSelectedGameId],
  (games, selectedGame) => games[selectedGame]
);
export const getAllLolRegionIdsByGameLoL = state =>
  state.entities.games.byId["a7f6d2f1-4b2f-49ef-b23f-a31ce691b4bc"].regions;

export const getGameNamesWithIds = createSelector([getGameIds, getGamesById], (ids, games) =>
  ids.map(id => ({ name: games[id].name, id: games[id].game_id }))
);

export const getUserMainGame = createSelector([getProfileMainGameId, getGamesById], (gameId, gamesById) =>
  gameId && gameId !== "" ? gamesById[gameId] : {}
);

export const getComparedUserGame = createSelector([getGamesById, getUserComparisonGame], (gamesById, gameSelected) =>
  gameSelected !== "" && gamesById[gameSelected] ? gamesById[gameSelected] : null
);

export const getPublicProfileMainGame = createSelector(
  [getPublicProfileMainGameID, getGamesById],
  (gameId, gamesById) => (gameId && gameId !== "" ? gamesById[gameId] : {})
);

export const getPublicProfileGameName = createSelector([getPublicProfileMainGame], game =>
  game && Object.keys(game).includes("short_name") ? game.short_name : ""
);

export const getUserProfileGameName = createSelector([getUserMainGame], game =>
  game && Object.keys(game).includes("short_name") ? game.short_name : ""
);

export const getComparedUserGameShortname = createSelector([getComparedUserGame], game =>
  game && Object.keys(game).includes("short_name") ? game.short_name : ""
);
