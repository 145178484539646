import React from "react";
import PasswordResetView from "app/pages/password-reset/PasswordResetView";
import { useTranslation } from "react-i18next";
import { PASSWORD_RESET, PASSWORD_RESET_ERR } from "app/i18n/selectors";
import { useDispatch, useSelector } from "react-redux";
import { passwordResetViewCloseToast, passwordResetViewOpenToast } from "redux/state/ui/pages/password-reset/actions";
import { passwordResetViewIsDisplayingToast } from "redux/state/ui/pages/password-reset/selectors";
import {
  getAuthPasswordResetIsError,
  getAuthPasswordResetIsLoading,
  getAuthPasswordResetStatus
} from "redux/state/auth/selectors";

const PasswordReset = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isToastError = useSelector(state => getAuthPasswordResetIsError(state));
  const status = useSelector(state => getAuthPasswordResetStatus(state));

  const handleOpen = () => {
    dispatch(passwordResetViewOpenToast());
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(passwordResetViewCloseToast());
  };

  const texts = {
    passwordResetTitle: t(`${PASSWORD_RESET}.title`),
    passwordResetSubtitle: t(`${PASSWORD_RESET}.subtitle`),
    passwordResetSnackbar: t(`${PASSWORD_RESET}.toast-message`)
  };

  const toast = {
    isToastOpen: useSelector(state => passwordResetViewIsDisplayingToast(state)),
    isToastError: isToastError,
    toastMsg: isToastError ? t(`${PASSWORD_RESET_ERR}.${status.toString()}`) : t(`${PASSWORD_RESET}.toast-msg`)
  };

  return <PasswordResetView {...toast} handleClose={handleClose} handleOpen={handleOpen} {...texts} />;
};

PasswordReset.propTypes = {};

export default PasswordReset;
