import React, { useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import CustomizedSelects from "app/components/custom-select/CustomSelect";
import CustomInputAutocomplete from "app/components/CustomInput/CustomInputAutocomplete";

const useStyles = makeStyles(theme => ({
  grid: {
    background: "red",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "600px"
  },
  background: {
    backgroundColor: theme.palette.background.paper
  },
  canvas: {
    width: "100%",
    height: "300px",
    // background: "#4a3ac3",
    background: "linear-gradient(to bottom, #1c093d 0%,#401a66 99%,#401a66 99%)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2em"
  }
}));

const TestPage = () => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  React.useEffect(() => {
    if (inputValue === "") {
      setIsInputEmpty(true);
    } else {
      setIsInputEmpty(false);
    }
  }, [inputValue]);

  const handleChange = name => setInputValue(name);

  return (
    <Container maxWidth={"lg"} className={classes.background}>
      <Grid container spacing={3}>
        <h1>Custom Input</h1>
        <Grid item xs={12}>
          <CustomInputAutocomplete
            label={"Ingresa Nickname"}
            value={inputValue}
            isInputEmpty={isInputEmpty}
            change={handleChange}
          />
        </Grid>
        <h2>Gradiente test</h2>
        <Grid item xs={12}>
          <div className={classes.canvas}>I am freaking Gradient</div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TestPage;
