import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ProfileSection from "app/components/my-profile/myprofile-spider-section/profile-section/ProfileSection";
import SpiderSection from "app/components/my-profile/myprofile-spider-section/spider-section/SpiderSection";
import AbilitiesTableSection
  from "app/components/my-profile/myprofile-spider-section/abilities-table-section/AbilitiesTableSection";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.whiteText,
    display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    }
  }
}));

const MyProfileSpiderSectionView = ({ isPublic }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <ProfileSection isPublic={isPublic} />
      <SpiderSection isPublic={isPublic} />
      <AbilitiesTableSection isPublic={isPublic} />
    </Grid>
  );
};

MyProfileSpiderSectionView.propTypes = {};

export default MyProfileSpiderSectionView;
