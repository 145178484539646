import React from "react";
import PropTypes from "prop-types";
import styled from "@material-ui/core/styles/styled";
import { Button } from "@material-ui/core";
import routes from "app/routes";
import RouterLink from "app/components/router-link/RouterLink";
import BigAvatar from "app/components/big-avatar/BigAvatar";
import FormRow from "app/pages/account/profile/form-row/FormRow";

/**
 * You can pass props directly and use it directly. No need to create a function to access props inside
 * const ProfileContainer = styled("div")(({ word, theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: word === "black" ? "black" : "white"
  }));
 */
const ProfileContainer = styled("div")(({ word, theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    paddingBottom: theme.spacing(0)
  }
}));

const ProfileImageSection = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  alignSelf: "flex-start",
  marginTop: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    flexGrow: 0,
    flexShrink: 0,
    width: "270px"
  }
}));

const FormSectionSection = styled("div")(({ theme }) => ({
  flex: 1,
  marginTop: theme.spacing(4),
  display: "flex",
  width: "100%",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: "700px"
  }
}));

const PublicProfileButton = styled(({ children, username, ...other }) => (
  <Button
    {...other}
    to={`${routes.publicProfile.resource}${username}`}
    component={RouterLink}
    children={children}
    color={"secondary"}
    variant={"contained"}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const AvatarContainer = styled("div")(({ theme }) => ({
  maxWidth: "270px",
  margin: "auto",
  [theme.breakpoints.up("md")]: {
    margin: 0
  }
}));

const ProfileView = ({ handleChangeImage, formRows, userImg, username, onSave, submitState }) => {
  return (
    <ProfileContainer word={"black"}>
      <ProfileImageSection>
        <AvatarContainer>
          <BigAvatar handleChangeImage={handleChangeImage} isUser={true} imgUrl={userImg} />
        </AvatarContainer>
        <PublicProfileButton username={username}>eProfile Público</PublicProfileButton>
      </ProfileImageSection>
      <FormSectionSection>
        {formRows.map(row => (
          <FormRow
            key={row.name}
            submitState={submitState}
            label={row.label}
            value={row.value}
            name={row.name}
            type={row.type}
            withForm={row.withForm}
            data={row.data ? row.data : []}
            onSave={onSave}
          />
        ))}
      </FormSectionSection>
    </ProfileContainer>
  );
};

ProfileView.propTypes = {};

export default ProfileView;
