import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as geoipOPS from "./operations";
import * as geoipSelectors from "./selectors";

import { checkGeoIPStatusEpic, fetchGeoIPEpic, fetchGeoIPSuccessEpic } from "./operations";

export { geoipOPS, geoipSelectors };

export const geoipEpic = combineEpics(checkGeoIPStatusEpic, fetchGeoIPEpic, fetchGeoIPSuccessEpic);

export default reducer;
