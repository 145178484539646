import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Carousel } from "react-responsive-carousel";
import "./styles.css";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  imgContainer: {
    backgroundColor: "none"
  },
  img: {
    maxWidth: "360px",
    userSelect: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    opacity: 0.5,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut
    }),
    [theme.breakpoints.up("sm")]: {
      maxWidth: "420px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "500px"
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "600px"
    }
  },
  imgSelected: {
    opacity: 1
  }
}));

const LandingCarousel = React.memo(({ data }) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(1);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up("xs"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const setSlidePercent = () => {
    if (xl) return 25;
    if (lg) return 30;
    if (md) return 40;
    if (sm) return 50;
    if (xs) return 100;
  };

  const handleChange = change => {
    setSelected(change);
  };

  const Item = ({ img, id }) => (
    <div className={classes.imgContainer}>
      <img className={clsx(classes.img, { [classes.imgSelected]: id === selected })} src={img} />
    </div>
  );

  return (
    <Carousel
      interval={4000}
      autoPlay
      showStatus={false}
      infiniteLoop
      dynamicHeight
      onChange={handleChange}
      onClickItem={handleChange}
      selectedItem={selected}
      useKeyboardArrows
      swipeable
      centerMode
      showThumbs={false}
      centerSlidePercentage={setSlidePercent()}
    >
      {data.map(item => (
        <Item key={item.id} img={item.img} id={item.id} />
      ))}
    </Carousel>
  );
});

LandingCarousel.propTypes = {};

export default LandingCarousel;
