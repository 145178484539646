import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as notificationsOPS from "./operations";
import * as notificationsSelectors from "./selectors";

import { fetchNotificationsEpic } from "./operations";

export { notificationsOPS, notificationsSelectors };

export const notificationsEpic = combineEpics(fetchNotificationsEpic);

export default reducer;
