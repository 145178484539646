import { combineReducers } from "redux";
import { AUTH_LOGIN_FAILURE, AUTH_LOGIN_SUCCESS, LOGOUT } from "redux/state/auth";
import * as aTypes from "./types";
import ERROR_SHAPE from "api/error-model";

/**
 * INIT STATE
 */
const loginFormInitState = {
  username: "",
  password: ""
};
const errorInit = ERROR_SHAPE;

const credentials = (state = loginFormInitState, action) => {
  switch (action.type) {
    case aTypes.LOGIN_UPDATE_USERNAME:
      return { ...state, username: action.payload };

    case aTypes.LOGIN_UPDATE_PASSWORD:
      return { ...state, password: action.payload };

    case AUTH_LOGIN_SUCCESS:
    case AUTH_LOGIN_FAILURE:
      return { ...state, username: "", password: "" };

    case LOGOUT:
      return { ...loginFormInitState };

    default:
      return state;
  }
};

const error = (state = errorInit, action) => {
  switch (action.type) {
    case aTypes.LOGIN_UPDATE_USERNAME:
    case aTypes.LOGIN_UPDATE_PASSWORD:
    case AUTH_LOGIN_SUCCESS:
    case LOGOUT:
      return { ...errorInit };

    case AUTH_LOGIN_FAILURE:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({ credentials, error });
