import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { spiderLabels } from "app/components/spider-graph/spiderProps";
import svgs from "app/components/spider-graph/svgs";
import React from "react";

/**
 * This component cant access custom properties because it gets used by the graph
 * library, so it must access internally to the screen dimensions to return the
 * needed svg.
 */
const LabelComponent = ({ id, anchor, angle }) => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const words = id.split(" ");
  const lastWord = words.length > 2 ? words.pop() : undefined;
  const textStyle = { fill: theme.palette.whiteText, fontSize: lg ? 13 : 12 };

  return (
    <g textAnchor={anchor} transform={`translate(0, ${anchor === "middle" && angle === -90 ? -15 : 0})`}>
      {words.length === 2 && !lastWord ? (
        words.map((str, index) => (
          <text y={index * 20} style={textStyle}>
            {str}
          </text>
        ))
      ) : (
        <text y={0} style={textStyle}>
          {words.join(" ")}
        </text>
      )}

      {lastWord && (
        <text y={20} style={textStyle}>
          {lastWord}
        </text>
      )}
    </g>
  );
};

export default LabelComponent;
