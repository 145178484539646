import { createSelector } from "reselect";

const profile = state => state.profile;
const authUser = state => profile(state).user;
const profileCheck = state => profile(state).profileCheck;
const profileError = state => profile(state).error;
const profileResults = state => profile(state).eProfileResults;
const profileUpdate = state => profile(state).updateProfile;
const userSocialNetworks = state => profile(state).userSocialNetworks
export const getProfileScore = state => authUser(state).score;

export const getProfile = state => authUser(state);
export const getProfileName = state => authUser(state).name;
export const getProfileAge = state => authUser(state).age;
export const getProfileUsername = state => authUser(state).eProfileUsername;
export const getProfileEmail = state => authUser(state).email;
export const getProfileStudies = state => authUser(state).studies;
export const getProfileUserId = state => authUser(state).userId;
export const getProfileGender = state => authUser(state).gender;
export const getProfileImage = state => authUser(state).profileImage;
export const getProfileImageForm = state => profile(state).image;
export const getProfileIsVerified = state => authUser(state).isVerified;
export const getProfileIsActive = state => authUser(state).isActive;
export const getProfileCompletedStudies = state => authUser(state).completedStudies;
export const getProfileIsPublic = state => authUser(state).isPublicProfile;
export const getProfileIsComparable = state => authUser(state).isComparable;
export const getProfileMainGame = state => authUser(state).mainGame;
export const getProfileMainGameId = state => getProfileMainGame(state).game_id;
export const getProfileMainGameWins = state => getProfileMainGame(state).wins;
export const getProfileMainGameUsername = state => getProfileMainGame(state).game_username;
export const getFormattedProfileGender = createSelector([getProfileGender], gender => {
  switch (gender) {
    case "male":
      return "Masculino";

    case "female":
      return "Femenino";

    case "other":
      return "Otro";
  }
});

export const getProfileUpdateValue = state => profileUpdate(state).value;
export const getProfileUpdateField = state => profileUpdate(state).field;
export const getProfileUpdateError = state => profileUpdate(state).error;
export const getProfileUpdateIsError = state => profileUpdate(state).error.error;
export const getProfileUpdateErrorCode = state => profileUpdate(state).error.status;
const getProfileUpdateLoading = state => profileUpdate(state).loading;
export const getProfileUpdateIsLoading = state => getProfileUpdateLoading(state).isLoading;
export const getProfileUpdateLoaded = state => getProfileUpdateLoading(state).loaded;

export const getProfileScorePosition = state => getProfileScore(state).position;
export const getProfileScoreTotal = state => getProfileScore(state).total;
export const getProfileScoreGameId = state => getProfileScore(state).game_id;

export const getProfileCheck = state => profileCheck(state);

export const getProfileError = state => profileError(state);

export const getProfileResults = state => profileResults(state);
export const getProfileResultStats = state => profileResults(state).stats;

// social networks
export const getUserSocialNetworksById = state => userSocialNetworks(state).byId;
export const getUserSocialNetworksAllIds = state => userSocialNetworks(state).allIds;
export const getUserSocialNetworksLoading = state => userSocialNetworks(state).loading;
export const getUserSocialNetworksError = state => userSocialNetworks(state).error;
export const getUserSocialNetworks = state => userSocialNetworks(state).socialNetworks;
