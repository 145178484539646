import { ofType } from "redux-observable";
import { mapTo } from "rxjs/operators";
import { getAuthToken } from "redux/state/auth/selectors";
import { getProfileUserId } from "redux/state/profile/selectors";
import api from "api";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile";
import {
  fetchNotifications,
  updateIsReadNotification
} from "redux/state/notifications/actions";
import { mapTransformToNotificationState } from "./utils";

const _fetchNotifications = () => (dispatch, getState) => {
  const state = getState();
  const token = getAuthToken(state);
  const userId = getProfileUserId(state);
  dispatch(fetchNotifications(api.notifications.fetchAll(userId, token), mapTransformToNotificationState));
};

export const setNotificationIsRead = (notificationId) => (dispatch, getState) => {
  const state = getState();
  const token = getAuthToken(state);
  const userId = getProfileUserId(state);
  dispatch(updateIsReadNotification(api.notifications.updateIsRead(notificationId, token, userId)))
}

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const fetchNotificationsEpic = action$ =>
  action$.pipe(ofType(PROFILE_CHECK_SUCCESS), mapTo(_fetchNotifications()));
