import { homePageLoaded, homeAbilitiesSelected, homeCognitiveProfileSelected, homeDownloadSelected } from "./actions";
import { ofType } from "redux-observable";
import { mapTo } from "rxjs/operators";
import { STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS } from "redux/state/studies/types";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile/types";
import { getUIHomeAreComponentsLoaded } from "redux/state/ui/pages/home/selectors";
import { homeComponentsLoaded } from "redux/state/ui/pages/home/actions";

export const setComponentsLoaded = () => (dispatch, getState) => {
  const componentsLoaded = getUIHomeAreComponentsLoaded(getState());
  if (componentsLoaded) {
    dispatch(homeComponentsLoaded());
  }
};

/** ------------------------------------------------------
 *  EPICS
 * ------------------------------------------------------*/
export const homeComponentsLoadedEpic = action$ =>
  action$.pipe(ofType(PROFILE_CHECK_SUCCESS, STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS), mapTo(setComponentsLoaded()));

export { homePageLoaded, homeAbilitiesSelected, homeCognitiveProfileSelected, homeDownloadSelected };
