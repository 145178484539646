export const getSocialNetworks = payload => {
  return Object.keys(payload).reduce((obj, key) => {
    obj[payload[key].social_network_name] = {
      socialNetwork: payload[key].social_network_name,
      userSocialNetworkId: payload[key].id
    };
    return obj;
  }, {});
};

export const mapUserProfileToState = ({ data }) => {
  return {
    // name: data.profile.name,
    userId: data.profile.user_id,
    email: data.profile.email,
    gender: data.profile.sex,
    age: data.profile.age,
    completedStudies: data.profile.completed_studies,
    eProfileUsername: data.profile.username,
    isVerified: data.profile.is_verified,
    profileImage: data.profile.profile_image,
    isActive: data.profile.is_active,
    isPublicProfile: data.profile.is_public_profile,
    isComparable: data.profile.is_comparable,
    mainGame: {
      game_id: data.profile.main_game.game_id,
      region_id: data.profile.main_game.region_id,
      game_username: data.profile.main_game.game_username,
      wins: data.profile.main_game.wins
    },
    score: data.profile.score
  };
};

export const mapUpdateProfileToState = ({ data }) => {
  return {
    age: data.age,
    email: data.email,
    gender: data.sex,
    userId: data.user_id,
    eProfileUsername: data.username
  };
};

export const mapProfileImageToState = ({ data }) => {
  return {
    profileImage: data.profile_image
  };
};

export const mapProfileSocialNetworksToState = ({ data }) => {
  return data.user_social_networks.reduce((obj, user_social) => {
    obj[user_social.id] = user_social;
    return obj;
  }, {});
};

export const mapProfileSocialNetworkToState = ({ data }) => {
  return { [data.user_social_network.id]: data.user_social_network };
};
