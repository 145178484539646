import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RouterLink from "../../../components/router-link/RouterLink";
import Divider from "../../../components/divider/Divider";
import routes from "../../../routes";
import { makeStyles } from "@material-ui/core";
import GrayInput from "app/components/CustomInput/GrayInput";
import LightRedButton from "app/components/custom-button/LighRedButton";
import Link from "@material-ui/core/Link";
import AuthFormContainer from "app/components/auth-form-container/AuthFormContainer";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(4),
    // background: "rgba(255, 255, 255, .09)"
    background: "linear-gradient(to right, rgba(90,121,222, .5) 0%,rgba(106,37,138, .5) 37%, rgba(183,8,141, .5) 100%)"
  },
  container: {
    padding: theme.spacing(4)
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    letterSpacing: ".1em"
  },
  subtitle: {
    textAlign: "center",
    marginBottom: theme.spacing(4)
  },
  group: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(5),
    textAlign: "center"
  },
  groupVerticalSpacing: {
    paddingBottom: theme.spacing(2)
  },
  loginButton: {
    marginTop: theme.spacing(5),
    borderRadius: 0
  },
  linkContainer: {
    marginTop: theme.spacing(8),
    textAlign: "center"
  },
  link: {
    color: theme.palette.text.primary,
    fontSize: "1em",
    textDecoration: "underline"
  },
  resetContainer: {
    textAlign: "center"
  }
}));

const LoginFormView = props => {
  const classes = useStyles();
  const { error, fields } = props;

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      props.loginUser();
    }
  };

  // props.loginTitle
  return (
    <AuthFormContainer title={props.loginTitle} subtitle={props.loginSubtitle}>
      <Grid item xs={12} sm={8} className={classes.group}>
        {fields.map((field, index) => (
          <Fragment key={field.label}>
            <GrayInput
              error={error}
              variant={"outlined"}
              label={field.label}
              fullWidth
              type={field.type}
              helperText={field.helperText}
              value={field.value}
              onKeyPress={handleKeyPress}
              onChange={field.onChange}
            />
            {fields.length - 1 !== index && <div className={classes.groupVerticalSpacing} />}
          </Fragment>
        ))}
        <div className={classes.resetContainer}>
          <Link className={classes.link} component={RouterLink} to={routes.passwordReset.route}>
            {props.passwordReset}
          </Link>
        </div>
        <LightRedButton onClick={props.loginUser} className={classes.loginButton}>
          {props.loginButtonText}
        </LightRedButton>
      </Grid>

      <Grid item xs={12} className={classes.linkContainer}>
        <Typography variant={"body1"}>{props.notUserLink}</Typography>
        <Link className={classes.link} component={RouterLink} to={routes.register.route}>
          {props.registerButton}
        </Link>
      </Grid>
    </AuthFormContainer>
  );
};

LoginFormView.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      helperText: PropTypes.string.isRequired,
      type: PropTypes.string,
      onChange: PropTypes.func.isRequired
    })
  ),
  loginUser: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  loginButtonText: PropTypes.string.isRequired,
  registerButton: PropTypes.string.isRequired,
  notUserLink: PropTypes.string.isRequired,
  loginTitle: PropTypes.string.isRequired,
  loginSubtitle: PropTypes.string.isRequired
};

export default LoginFormView;
