import { combineReducers } from "redux";
import { STUDY_FETCH_BY_ID_FAILURE, STUDY_FETCH_BY_ID_SUCCESS } from "redux/state/studies/types";

/**
 * INIT STATES
 */
const errorsInit = false;
const axisInformationIni = {
  title: "Información de ejes",
  skills: [
    "processing_speed",
    "sustained_attention",
    "divided_attention",
    "task_switching",
    "visuospatial",
    "working_memory",
    "fluid_reasoning",
    "attentional_blink",
    "frustration_tolerance",
    "creativity"
  ]
};

/**
 * REDUCERS
 */
const hasErrors = (state = errorsInit, action) => {
  switch (action.type) {
    case STUDY_FETCH_BY_ID_FAILURE:
      return action.payload.status === 404;

    case STUDY_FETCH_BY_ID_SUCCESS:
      return state ? !state : state;
    default:
      return state;
  }
};

const axisInformation = (state = axisInformationIni, action) => {
  return state;
};

export default combineReducers({ hasErrors, axisInformation });

