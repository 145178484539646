import React from "react";
import PropTypes from "prop-types";
import AbilitiesTableSectionView from "app/components/my-profile/myprofile-spider-section/abilities-table-section/AbilitiesTableSectionView";
import { useSelector } from "react-redux";
import { getPublicProfileStudyResults, getUserProfileStudyResults } from "redux/state/studies/selectors";

const AbilitiesTableSection = ({ isPublic }) => {
  const results = useSelector(state =>
    isPublic ? getPublicProfileStudyResults(state) : getUserProfileStudyResults(state)
  );
  return <AbilitiesTableSectionView results={results} />;
};

AbilitiesTableSection.propTypes = {
  isPublic: PropTypes.bool
};

export default AbilitiesTableSection;
