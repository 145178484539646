import React from "react";
import PropTypes from "prop-types";
import MyProfile from "app/components/my-profile/MyProfile";

const MyProfilePage = ({ isPublic }) => {
  return <MyProfile isPublic={isPublic} />;
};

MyProfilePage.propTypes = {};

export default MyProfilePage;
