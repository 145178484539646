import React, { useRef } from "react";
import SpiderSectionView from "app/pages/home/compare/spider-section/SpiderSectionView";
import { useDispatch, useSelector } from "react-redux";
import { getStudiesForComparison, getStudyValidating } from "redux/state/studies/selectors";
import { useTranslation } from "react-i18next";
import { HOME_SPIDER, HOME_SPIDER_AVATAR_INFORMATION, HOME_SPIDER_FORM_VALIDATION } from "app/i18n/selectors";
import {
  getProfileIsComparable,
  getProfileIsPublic,
  getProfileUsername,
  getProfileMainGameWins,
  getProfileImage, getProfileUserId
} from "redux/state/profile/selectors";
import { userComparisonOPS } from "redux/state/forms/user-comparison";
import {
  getUserComparisonFormComparedGame,
  getUserComparisonFormComparedNickname,
  getUserComparisonFormComparedRegion,
  getUserComparisonFormUserGame,
  getUserComparisonFormUserNickname,
  getUserComparisonFormUserRegion
} from "redux/state/forms/user-comparison/selectors";
import {
  getComparedUserGameShortname,
  getGameNamesWithIds,
  getGamesById,
  getUserMainGame
} from "redux/state/games/selectors";
import {
  getStudySeachAutocomAllIds,
  getStudySeachAutocomById,
  getStudySeachAutocomComparedSelected,
  getStudySeachAutocomUserSelected,
  getStudySearchCompareGameUsername,
  getStudySearchCompareGameWins,
  getStudySearchFilteredByGenericGameAndTopRanks,
  getStudySearchFilteredByUserComparisonFormUserGameSelectedAndTopRanks
} from "redux/state/autocomplete/study-search/selectors";
import {
  autocomStudySearchCompareReset,
  autocomStudySearchCompareSelect,
  autocomStudySearchUserReset,
  autocomStudySearchUserSelect
} from "redux/state/autocomplete/study-search/operations";
import { homeAbilitiesSelected } from "redux/state/ui/pages/home/operations";
import { getUserComparisonProfileImage } from "redux/state/user-comparison/selectors";

const SpiderSection = props => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const commonForms = {
    games: useSelector(state => getGameNamesWithIds(state)),
    byIdIdName: "user_id",
    byIdValueName: "username"
  };

  const userFormState = {
    handleChangeGame: gameId => dispatch(userComparisonOPS.userComparisonFormUpdateUserGame(gameId)),
    handleChangeRegion: regionId => dispatch(userComparisonOPS.userComparisonFormUpdateUserRegion(regionId)),
    handleChangeNickname: nickname => dispatch(userComparisonOPS.userComparisonFormUpdateUserNickname(nickname)),
    selectedGame: useSelector(state => getUserComparisonFormUserGame(state)),
    selectRegion: useSelector(state => getUserComparisonFormUserRegion(state)),
    selectNickname: useSelector(state => getUserComparisonFormUserNickname(state)),
    ...commonForms
  };

  const comparedFormState = {
    handleChangeGame: gameId => dispatch(userComparisonOPS.userComparisonFormUpdateComparedGame(gameId)),
    handleChangeRegion: regionId => dispatch(userComparisonOPS.userComparisonFormUpdateComparedRegion(regionId)),
    handleChangeNickname: nickname => dispatch(userComparisonOPS.userComparisonFormUpdateComparedNickname(nickname)),
    selectedGame: useSelector(state => getUserComparisonFormComparedGame(state)),
    selectRegion: useSelector(state => getUserComparisonFormComparedRegion(state)),
    selectNickname: useSelector(state => getUserComparisonFormComparedNickname(state)),
    ...commonForms
  };

  const userInfoState = {
    avatarName: useSelector(state => getProfileUsername(state)),
    mainGame: useSelector(state => getUserMainGame(state).short_name),
    wins: useSelector(state => getProfileMainGameWins(state)),
    winsText: t(`${HOME_SPIDER_AVATAR_INFORMATION}.wins`),
    imgUrl: useSelector(state => getProfileImage(state))
  };

  const compareInfoState = {
    avatarName: useSelector(state => getStudySearchCompareGameUsername(state)),
    mainGame: useSelector(state => getComparedUserGameShortname(state)),
    wins: useSelector(state => getStudySearchCompareGameWins(state)),
    winsText: t(`${HOME_SPIDER_AVATAR_INFORMATION}.wins`),
    imgUrl: useSelector(state => getUserComparisonProfileImage(state))
  };

  const autocompleteUserItems = {
    byId: useSelector(state => getStudySeachAutocomById(state)),
    hasRelation: false,
    relationships: {},
    bestPlayers: useSelector(state => getStudySearchFilteredByUserComparisonFormUserGameSelectedAndTopRanks(state)),
    // allIds: useSelector(state => getStudySearchFilteredByUserComparisonFormUserGameSelected(state)),
    allIds: useSelector(state => getStudySeachAutocomAllIds(state)),
    selected: useSelector(state => getStudySeachAutocomUserSelected(state)),
    setAutocompleteSelected: value => dispatch(autocomStudySearchUserSelect(value)),
    resetSelectedAutocomplete: () => dispatch(autocomStudySearchUserReset()),
    autocompleteValidationText: errorMsg => t(`${HOME_SPIDER_FORM_VALIDATION}.${errorMsg}`)
  };

  const autocompleteComparedItems = {
    byId: useSelector(state => getStudySeachAutocomById(state)),
    hasRelation: true,
    relationships: {
      game: {
        id_name: "game_id",
        fields: ["name", "short_name"],
        byId: useSelector(state => getGamesById(state))
      }
    },
    // bestPlayers: useSelector(state => getStudySearchFilteredByUserComparisonFormComparedGameSelectedAndTopRanks(state)),
    bestPlayers: useSelector(state => getStudySearchFilteredByGenericGameAndTopRanks(state)),
    // allIds: useSelector(state => getStudySearchFilteredByUserComparisonFormComparedGameSelected(state)),
    allIds: useSelector(state => getStudySeachAutocomAllIds(state)),
    selected: useSelector(state => getStudySeachAutocomComparedSelected(state)),
    setAutocompleteSelected: value => dispatch(autocomStudySearchCompareSelect(value)),
    resetSelectedAutocomplete: () => dispatch(autocomStudySearchCompareReset()),
    autocompleteValidation: useSelector(state => getStudyValidating(state)),
    autocompleteValidationText: errorMsg => t(`${HOME_SPIDER_FORM_VALIDATION}.${errorMsg}`)
  };

  const graphData = useSelector(state => getStudiesForComparison(state));
  const graphKeys = Object.keys(graphData[0] || []).filter(key => key !== "name");
  const isComparable = useSelector(state => getProfileIsComparable(state));
  const isPublicProfile = useSelector(state => getProfileIsPublic(state));

  const changeToAbilitiesPage = () => {
    dispatch(homeAbilitiesSelected());
  };

  const texts = {
    title: t(`${HOME_SPIDER}.title`)
  };

  const state = { graphData, graphKeys, compareDisabled: !isComparable && !isPublicProfile };
  return (
    <SpiderSectionView
      compareInfoState={compareInfoState}
      userInfoState={userInfoState}
      {...texts}
      {...state}
      changeToAbilitiesPage={changeToAbilitiesPage}
      autocompleteUserItems={autocompleteUserItems}
      autocompleteComparedItems={autocompleteComparedItems}
      userFormState={userFormState}
      comparedFormState={comparedFormState}
    />
  );
};

SpiderSection.propTypes = {};

export default SpiderSection;
