import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

/**
 * This component is a spacing component between the navbar and the content of the body, due to a fixed navbar.
 */
const DrawerHeader = props => {
  const classes = useStyles();
  return <div className={classes.drawerHeader} />;
};


export default DrawerHeader;
