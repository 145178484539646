import { createSelector } from "reselect";
import {
  getAllLolRegionIdsByGameLoL, getGameRegionIdsByRegisterGameSelected,
  getGameRegionIdsByUserComparisonFormComparedGame,
  getGameRegionIdsByUserComparisonFormUserGame
} from "redux/state/games/selectors";

const regions = state => state.entities.regions;

export const getRegions = state => regions(state);
export const getRegionsById = state => regions(state).byId;
export const getRegionsIds = state => regions(state).allIds;

export const getUserComparisonRegionsByUserGameSelected = createSelector(
  [getGameRegionIdsByUserComparisonFormUserGame, getRegionsById],
  (regionIds, regionsById) =>
    regionIds.map(region => ({
      name: regionsById[region].region,
      id: regionsById[region].region_id
    }))
);

export const getUserComparisonRegionsByComparedGameSelected = createSelector(
  [getGameRegionIdsByUserComparisonFormComparedGame, getRegionsById],
  (regionIds, regionsById) =>
    regionIds.map(region => ({
      name: regionsById[region].region,
      id: regionsById[region].region_id
    }))
);

export const getRegionsFormattedByGame = createSelector(
  [getGameRegionIdsByRegisterGameSelected, getRegionsById],
  (regionList, regionsById) =>
    regionList && regionList.length >= 1
      ? regionList.map(regionId => ({ id: regionsById[regionId].region_id, name: regionsById[regionId].region }))
      : []
);
