import { combineEpics } from "redux-observable";

import reducer from "./reducer";

import * as userComparisonOPS from "./operations";
import * as userComparisonSelectors from "./selectors";

import { setCompareFormStateFromProfileEpic } from "./operations";

export const userComparisonFormEpic = combineEpics(setCompareFormStateFromProfileEpic);

export { userComparisonSelectors, userComparisonOPS };

export default reducer;
