import {
  PROFILE_FORM_VALIDATE_SUCCESS,
  PROFILE_FORM_VALIDATE_FAILURE,
  PROFILE_FORM_VALIDATE_START,
  PROFILE_FORM_VALIDATE_EMAIL_FAILURE,
  PROFILE_FORM_VALIDATE_USERNAME_FAILURE,
  PROFILE_FORM_VALIDATE_GENDER_FAILURE,
  PROFILE_FORM_VALIDATE_PASSWORD_FAILURE,
  PROFILE_FORM_VALIDATE_EMAIL_SUCCESS,
  PROFILE_FORM_VALIDATE_USERNAME_SUCCESS,
  PROFILE_FORM_VALIDATE_GENDER_SUCCESS,
  PROFILE_FORM_VALIDATE_PASSWORD_SUCCESS,
  PROFILE_FORM_VALIDATE_EMAIL_START,
  PROFILE_FORM_VALIDATE_USERNAME_START,
  PROFILE_FORM_VALIDATE_GENDER_START,
  PROFILE_FORM_VALIDATE_PASSWORD_START
} from "./types";
import { LOGOUT } from "redux/state/auth";
import {
  mapEmailValidationToErrorState,
  mapUsernameValidationToErrorState,
  mapGenderValidationToErrorState
} from "./utils";
import { combineReducers } from "redux";

const baseErrorState = { error: false, errorMsg: "" };

const errorInit = {
  gender: { ...baseErrorState },
  password: { ...baseErrorState },
  repeatPassword: { ...baseErrorState },
  email: { ...baseErrorState },
  mindsetUsername: { ...baseErrorState },
  summonerName: { ...baseErrorState },
  region: { ...baseErrorState },
  profileImage: { ...baseErrorState }
};

const loadingValidation = (state = false, action) => {
  switch (action.type) {
    case PROFILE_FORM_VALIDATE_FAILURE:
    case PROFILE_FORM_VALIDATE_SUCCESS:
    case PROFILE_FORM_VALIDATE_EMAIL_SUCCESS:
    case PROFILE_FORM_VALIDATE_EMAIL_FAILURE:
    case PROFILE_FORM_VALIDATE_USERNAME_SUCCESS:
    case PROFILE_FORM_VALIDATE_USERNAME_FAILURE:
    case PROFILE_FORM_VALIDATE_GENDER_SUCCESS:
    case PROFILE_FORM_VALIDATE_GENDER_FAILURE:
    case PROFILE_FORM_VALIDATE_PASSWORD_SUCCESS:
    case PROFILE_FORM_VALIDATE_PASSWORD_FAILURE:
      return false;

    case PROFILE_FORM_VALIDATE_START:
    case PROFILE_FORM_VALIDATE_EMAIL_START:
    case PROFILE_FORM_VALIDATE_USERNAME_START:
    case PROFILE_FORM_VALIDATE_GENDER_START:
    case PROFILE_FORM_VALIDATE_PASSWORD_START:
      return true;

    default:
      return state;
  }
};

const errors = (state = errorInit, action) => {
  switch (action.type) {
    case PROFILE_FORM_VALIDATE_EMAIL_SUCCESS:
      return { ...state, email: { ...baseErrorState } };

    case PROFILE_FORM_VALIDATE_EMAIL_FAILURE:
      const emailErr = mapEmailValidationToErrorState(action.payload);
      return { ...state, email: emailErr.email ? { ...emailErr.email } : { ...baseErrorState } };

    case PROFILE_FORM_VALIDATE_USERNAME_SUCCESS:
      return { ...state, mindsetUsername: { ...baseErrorState } };

    case PROFILE_FORM_VALIDATE_USERNAME_FAILURE:
      const usernameErr = mapUsernameValidationToErrorState(action.payload);
      return {
        ...state,
        username: usernameErr.username ? { ...usernameErr.username } : { ...baseErrorState }
      };

    case PROFILE_FORM_VALIDATE_GENDER_SUCCESS:
      return { ...state, gender: { ...baseErrorState } };

    case PROFILE_FORM_VALIDATE_GENDER_FAILURE:
      const genderErr = mapGenderValidationToErrorState(action.payload);
      return {
        ...state,
        gender: genderErr.gender ? { ...genderErr.gender } : { ...baseErrorState }
      };

    case PROFILE_FORM_VALIDATE_PASSWORD_SUCCESS:
      return { ...state, password: { ...baseErrorState } };

    case PROFILE_FORM_VALIDATE_PASSWORD_FAILURE:
      const passErr = mapGenderValidationToErrorState(action.payload);
      return {
        ...state,
        password: passErr.password ? { ...passErr.password } : { ...baseErrorState }
      };

    case LOGOUT:
      return { ...errorInit };

    default:
      return state;
  }
};

export default combineReducers({ errors, loadingValidation });
