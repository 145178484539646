import React from "react";
import PropTypes from "prop-types";
import RegisterForm from "./register-form/RegisterForm";
import RegisterSuccess from "app/pages/register/RegisterSuccess/RegisterSuccess";
import AuthPageTemplate from "app/components/auth-page-template/AuthPageTemplate";
import AuthPageContainer from "app/components/auth-page-container/AuthPageContainer";

const RegisterView = props => {
  return (
    <AuthPageTemplate>
      <AuthPageContainer>{props.registerState ? <RegisterSuccess /> : <RegisterForm />}</AuthPageContainer>
    </AuthPageTemplate>
  );
};

RegisterView.propTypes = {
  games: PropTypes.object,
  player: PropTypes.object,
  updatePlayer: PropTypes.object
};

export default RegisterView;
