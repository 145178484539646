import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import BigAvatar from "app/components/big-avatar/BigAvatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import LightGreenButton from "app/components/custom-button/LightGreenButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { triggerUnlockUser } from "redux/state/profile/actions";
import RouterLink from "app/components/router-link/RouterLink";
import routes from "app/routes";
import LightRedButton from "app/components/custom-button/LighRedButton";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  buttonContainer: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  center: {
    margin: "0 auto"
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: "bold"
  },
  disabled: {
    background: "linear-gradient(180deg, rgba(123,115,199,0.5) 50%, rgba(123,115,199,0) 100%)"
  },
  avatarContainer: {
    width: "100%"
  },
  verticalSeparator: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2)
    }
  },
  profileButton: {
    padding: theme.spacing(1, 3),
    marginTop: theme.spacing(4)
  }
}));

const AvatarInformationView = ({
  disabled,
  avatarName,
  wins,
  mainGame,
  winsText,
  isUser,
  isPublic,
  handleChangeImage,
  imgUrl,
  userId,
  isUserSelected
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    handleClose();
    dispatch(triggerUnlockUser());
  };

  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={clsx(classes.container, { [classes.disabled]: disabled })}
    >
      {disabled && (
        <Fragment>
          <Grid item xs={12} className={classes.buttonContainer}>
            <LightGreenButton onClick={handleClickOpen} fullWidth>
              Desbloquear
            </LightGreenButton>{" "}
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Desbloquear comparación"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Deseas desbloquear la funcionalidad de poder compararte con otros usuarios? Al aceptar, espera unos
                segundos segundos y recarga la página.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleAccept} color="primary" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
      <div className={classes.avatarContainer}>
        <BigAvatar
          isPublic={isPublic}
          isUser={isUser}
          disabled={disabled}
          handleChangeImage={handleChangeImage}
          imgUrl={imgUrl}
        />
      </div>

      {!isPublic && (
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.title}>
            {disabled
              ? "Nickname"
              : avatarName && avatarName.length >= 1
              ? isUser
                ? `${avatarName} (tú)`
                : avatarName
              : "Nickname"}
          </Typography>
        </Grid>
      )}

      {isPublic && <div className={classes.verticalSeparator} />}

      <Grid item xs={12}>
        <Typography>
          <b>{mainGame && mainGame.length >= 1 ? mainGame : "Game"}</b>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>{disabled ? "Wins" : wins && winsText && wins > 0 ? `${wins} ${winsText}` : winsText}</Typography>
      </Grid>

      {!xs && !isPublic && isUser && (
        <Grid item xs={12}>
          <Button
            data-tut={"reactour__public-profile"}
            className={classes.profileButton}
            variant={"contained"}
            color={"secondary"}
            component={RouterLink}
            to={`${routes.publicProfile.resource}${avatarName}`}
          >
            eProfile Público
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

AvatarInformationView.propTypes = {
  disabled: PropTypes.bool.isRequired,
  avatarName: PropTypes.string.isRequired,
  wins: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mainGame: PropTypes.string.isRequired,
  winsText: PropTypes.string.isRequired,
  handleChangeImage: PropTypes.func,
  imgUrl: PropTypes.string,
  isUser: PropTypes.bool,
  isPublic: PropTypes.bool
};

AvatarInformationView.defaultProps = {
  disabled: false,
  avatarName: "Compare compare",
  mainGame: "Lol",
  winsText: "wins"
};

export default AvatarInformationView;
