import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { HOME_DOWNLOAD } from "app/i18n/selectors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { PROFILE_CHECKS } from "config/constants";
import { SERVER_URL, STANDALONE_DOWNLOAD_URL } from "config/settings";
import { Container, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(6),
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: "16px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    }
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  title: {
    fontWeight: "bold",
    marginTop: theme.spacing(2)
  },
  mobileSpacing: {
    padding: theme.spacing(1)
  }
}));

const DownloadLogged = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Container maxWidth={"lg"}>
      <Grid item component={"section"} xs={12} sm={12} className={classes.container}>
        {props.checkProfile === PROFILE_CHECKS.FINISH_TESTS && (
          <Typography color={"textPrimary"} variant={"h3"}>
            {t(`${HOME_DOWNLOAD}.finish_tests`)}
          </Typography>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} color={"textPrimary"} variant={`${xs ? "p" : "h6"}`}>
              Descarga aplicación eProfile
            </Typography>
            {xs && <div className={classes.mobileSpacing} />}
            <Typography className={classes.marginTop} color={"textPrimary"} variant={`${xs ? "p" : "h6"}`}>
              {/*{t(`${HOME_DOWNLOAD}.title`)}*/}
              Aquí puedes crear tu gráfico de habilidades cognitivas que aparecerá en tu eProfile.
            </Typography>
            {xs && <div className={classes.mobileSpacing} />}
            <Typography color={"textPrimary"} variant={`${xs ? "p" : "h6"}`}>
              {/*{t(`${HOME_DOWNLOAD}.title`)}*/}
              Lo primero que debes hacer es <b>descargar la aplicación de eProfile.</b> No te tomará más de 5 minutos.
            </Typography>
            {xs && <div className={classes.mobileSpacing} />}
            <Typography color={"textPrimary"} variant={`${xs ? "p" : "h6"}`}>
              {/*{t(`${HOME_DOWNLOAD}.title`)}*/}
              La razón de porqué debes descargar una aplicación, es porque así podemos{" "}
              <b>medir de mejor manera tus habilidades cognitivas.</b>
            </Typography>
            <br />
            {xs && <div className={classes.mobileSpacing} />}
            <Typography color={"textPrimary"} variant={`${xs ? "p" : "h6"}`}>
              Actualmente esta versión está habilitada exclusivamente para <b>escritorio en Windows 64 bit</b>. Las
              versiones disponibles son para todas las de Windows 7,8 y 10. Requisito de sistema: OpenGL 2.0 o superior.
            </Typography>
            <div className={classes.mobileSpacing} />

            <Typography color={"textPrimary"} variant={`${xs ? "p" : "h6"}`}>
              Gracias por confiar en nosotros y ¡buena suerte!
            </Typography>
          </Grid>
        </Grid>
        <Button
          disabled={xs}
          component={"a"}
          href={`${STANDALONE_DOWNLOAD_URL}/standalone/eProfileInstaller.exe`}
          download={"eProfileInstaller.exe"}
          className={classes.button}
          color={"secondary"}
          variant={"contained"}
        >
          {t(`${HOME_DOWNLOAD}.download_button`)}
        </Button>
      </Grid>
    </Container>
  );
};

DownloadLogged.propTypes = {};

export default DownloadLogged;
