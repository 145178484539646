export const mapStudyResponseToState = ({ data: { study } }) => {
  return {
    [study.study_id]: {
      study_id: study.study_id,
      user_id: study.user_id,
      results: study.results
    }
  };
};

export const mapStudyTopRanksToState = ({ data }) => {
  if (Object.keys(data).includes("study")) {
    return {
      [data.study.study_id]: {
        study_id: data.study.study_id,
        user_id: data.study.user_id,
        results: data.study.results
      }
    };
  }
  return data.studies.reduce((obj, study) => {
    obj[study.study_id] = study;
    return obj;
  }, {});
};
