import reducer from "redux/state/games/reducers";

import { fetchGamesEpic } from "./operations";

import * as gameOperations from "./operations";
import * as gameSelectors from "./selectors";
import { combineEpics } from "redux-observable";

export { gameOperations, gameSelectors };

export const gamesEpic = combineEpics(fetchGamesEpic);

export default reducer;
