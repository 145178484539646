import { log, logError } from "redux/lib/loggers";

export const startAsyncAction = action => {
  return {
    type: `${action.type}_START`,
    sourceType: action.type,
    sourceMeta: action.meta,
    meta: {
      promise: action.payload,
      ...action.meta
    }
  };
};

export const resultAsyncAction = async startAction => {
  log(startAction.type, startAction);
  const res = await startAction.meta.promise;

  if (res.error) {
    const error = {
      type: `${startAction.sourceType}_FAILURE`,
      error: true,
      payload: res
    };

    logError(error.type, error);
    return error;
  } else {
    // Creating payload if transformation is passed
    const payload =
      startAction.meta.transform && typeof startAction.meta.transform === "function"
        ? startAction.meta.transform(res)
        : res;

    // Creating the response action with _SUCCESS
    const action = {
      type: `${startAction.sourceType}_SUCCESS`,
      payload: payload,
      [startAction.sourceMeta && "meta"]: { ...startAction.sourceMeta }
    };

    log(action.type, action);
    return action;
  }
};
