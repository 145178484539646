import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as sessionOPS from "./operations";
import * as sessionSelectors from "./selectors";
import { saveUserEpic, saveUserInHomeResultsEpic, homeResultsTourFinishedEpic } from "./operations";

export { sessionOPS, sessionSelectors };

export const sessionsEpic = combineEpics(saveUserEpic, saveUserInHomeResultsEpic, homeResultsTourFinishedEpic);

export default reducer;
