import { combineEpics } from "redux-observable";
import reducer from "./reducer";

import * as passwordResetOPS from "./operations";
import * as passwordResetSelectors from "./selectors";

export { passwordResetOPS, passwordResetSelectors };

export const passwordResetEpic = combineEpics();

export default reducer;
