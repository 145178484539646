import { combineReducers } from "redux";
import { LOGOUT } from "redux/state/auth/types";
import * as aTypes from "./types";
import * as _ from "./utils";

export const REGISTER_FRAGMENTS = {
  PLAYER_INFO: "Player Information",
  GAMES_PLAYED: "Games Played",
  QUESTIONS: "Questions",
  CONFIRM: "Confirm"
};

// INITIAL STATE
const fragmentInit = {
  selected: 0,
  list: [REGISTER_FRAGMENTS.PLAYER_INFO, REGISTER_FRAGMENTS.CONFIRM]
};

const loadingState = { isLoading: false, loaded: false };
const lolSelectedInit = false;

const fragments = (state = fragmentInit, action) => {
  switch (action.type) {
    case aTypes.REGISTER_FRAGMENT_NEXT:
      return _.setNext(state);

    case aTypes.REGISTER_FRAGMENT_BACK:
      return _.setBack(state);

    case LOGOUT:
      return { ...fragmentInit };

    default:
      return state;
  }
};

const loading = (state = loadingState, action) => {
  return state;
};

const lolSelected = (state = false, action) => {
  switch (action.type) {
    case aTypes.REGISTER_TOGGLE_LOLSELECTED:
      return !state;

    case LOGOUT:
      return lolSelectedInit;

    default:
      return state;
  }
};

export default combineReducers({ fragments, loading, lolSelected });
