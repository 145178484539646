import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "relative",
    backgroundImage: theme.palette.home.backgroundGradient
    // backgroundSize: "cover"
  },
  content: {
    width: "100%",
    height: "100%",
    overflowY: "auto"
  },
  headGradient: {
    backgroundImage: "linear-gradient(to right, #ED6292 0%,#B2013F 11%,#5A42EC 100%)",
    width: "100%",
    height: "10px"
  }
}));

const HomePageTemplate = ({ className, children, ...other }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...other}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

HomePageTemplate.propTypes = {
  className: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default HomePageTemplate;
