import { combineReducers } from "redux";
import { STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS } from "redux/state/studies/types";
import { LOGOUT } from "redux/state/auth";
import {
  HOME_ABILITIES_SELECTED,
  HOME_COGNITIVE_PROFILE_SELECTED,
  HOME_DOWNLOAD_SELECTED,
  HOME_MY_PROFILE_SELECTED
} from "redux/state/ui/pages/home/types";
import * as authTypes from "redux/state/auth/types";
import { PROFILE_CHECK_SUCCESS } from "redux/state/profile";
import { FETCH_PROFILE_SUCCESS, PROFILE_CHECK_FINISH_TESTS } from "redux/state/profile/types";
import { PROFILE_CHECK_FAILURE } from "redux/state/profile/types";

const eProfileResultsInit = {
  components: ["bestStat", "worstStat", "ranking"]
};

const homeComponentsInit = {
  components: ["error", "myProfile", "compare", "download", "abilities"],
  selected: 1
};

const componentsLoadedInit = { eProfileResults: false, spider: false };

const homeComponents = (state = homeComponentsInit, action) => {
  switch (action.type) {
    case PROFILE_CHECK_FAILURE:
      return { ...state, selected: 0 };

    case PROFILE_CHECK_SUCCESS:
    case HOME_MY_PROFILE_SELECTED:
      return { ...state, selected: 1 };

    case HOME_COGNITIVE_PROFILE_SELECTED:
      return { ...state, selected: 2 };

    case PROFILE_CHECK_FINISH_TESTS:
    case HOME_DOWNLOAD_SELECTED:
    case authTypes.LOGOUT:
      return { ...state, selected: 3 };

    case HOME_ABILITIES_SELECTED:
      return { ...state, selected: 4 };

    default:
      return state;
  }
};

const componentsLoaded = (state = componentsLoadedInit, action) => {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
      return { ...state, eProfileResults: true };

    case STUDY_FETCH_CURRENT_USER_RESULTS_SUCCESS:
      return { ...state, spider: true };

    case LOGOUT:
      return { ...componentsLoadedInit };

    default:
      return state;
  }
};

const eProfileResults = (state = eProfileResultsInit, action) => {
  return state;
};

export default combineReducers({ eProfileResults, homeComponents, componentsLoaded });
