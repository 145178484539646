import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: props => props.height,
    backgroundColor: ({ color }) => (color ? color : theme.palette.divider),
    borderColor: theme.palette.divider,
    flexGrow: 1,
    width: props => props.width,
    marginLeft: props => (typeof props.marginLeft === "string" ? "auto" : theme.spacing(props.marginLeft)),
    marginRight: props => (typeof props.marginLeft === "string" ? "auto" : theme.spacing(props.marginRight))
  }
}));

const Divider = props => {
  const classes = useStyles(props);
  return <hr className={classes.root} />;
};

Divider.propTypes = {
  height: PropTypes.string,
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.string,
  color: PropTypes.string
};

Divider.defaultProps = {
  height: "1px",
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%"
};

export default Divider;
