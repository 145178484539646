import * as aTypes from "./types";
import { formatDate } from "redux/utils";

export const updateRegisterResetPicture = () => ({ type: aTypes.REGISTER_RESET_PICTURE });

export const updateRegisterPicture = file => ({
  type: aTypes.REGISTER_UPDATE_PICTURE,
  payload: file
});

export const updateRegisterName = name => ({
  type: aTypes.REGISTER_UPDATE_NAME,
  payload: name
});

export const updateRegisterLastname = lastname => ({
  type: aTypes.REGISTER_UPDATE_LASTNAME,
  payload: lastname
});

export const updateRegisterReferredId = referredId => ({
  type: aTypes.REGISTER_UPDATE_REFERRED_ID,
  payload: referredId
});

export const updateRegisterGender = gender => ({
  type: aTypes.REGISTER_UPDATE_GENDER,
  payload: gender
});

export const updateRegisterBirthday = date => ({
  type: aTypes.REGISTER_UPDATE_BIRTHDAY,
  payload: formatDate(new Date(date))
});

export const updateRegisterPassword = password => ({
  type: aTypes.REGISTER_UPDATE_PASSWORD,
  payload: password
});

export const updateRegisterRepeatPassword = password => ({
  type: aTypes.REGISTER_UPDATE_REPEAT_PASSWORD,
  payload: password
});

export const updateRegisterMindsetUsername = username => ({
  type: aTypes.REGISTER_UPDATE_MINDSET_USERNAME,
  payload: username
});

export const updateRegisterEmail = email => ({
  type: aTypes.REGISTER_UPDATE_EMAIL,
  payload: email
});

export const updateRegisterMainGame = mainGame => ({
  type: aTypes.REGISTER_UPDATE_MAIN_GAME,
  payload: mainGame
});

export const updateRegisterSummonerName = summonerName => ({
  type: aTypes.REGISTER_UPDATE_SUMMONER_NAME,
  payload: summonerName
});

export const updateRegisterrRegion = region => ({
  type: aTypes.REGISTER_UPDATE_REGION,
  payload: region
});

export const updateRegisterTerms = checked => ({
  type: aTypes.REGISTER_UPDATE_TERMS,
  payload: checked
});

// PlayerFormErrors
export const validateRegisterFormFailure = errors => ({
  type: aTypes.REGISTER_VALIDATE_FORM_FAILURE,
  payload: errors
});
export const validateRegisterFormStart = () => ({ type: aTypes.REGISTER_VALIDATE_FORM_START });
export const validateRegisterFormSuccess = () => ({ type: aTypes.REGISTER_VALIDATE_FORM_SUCCESS });
