import React from "react";
import PropTypes from "prop-types";
import spider_mock_img from "../../assets/img/spider_mock.png";
import { makeStyles } from "@material-ui/core";
import Navbar from "../../components/navbar/Navbar";
import DrawerHeader from "../../components/drawer-header/DrawerHeader";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "black"
  },
  image: {
    display: "block",
    margin: "auto"
  },
  text: {
    color: "white",
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(4)
  },
  container: {
    marginTop: theme.spacing(5),
    backgroundColor: "black"
  },
  black: {
    backgroundColor: "black"
  }
}));

const SurveyBackup = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navbar />
      <Container maxWidth={"lg"} className={classes.black}>
        <DrawerHeader />
        <Grid container spacing={3} className={classes.container}>
          <img className={classes.image} src={spider_mock_img} alt="an spider graph example" />
          <Typography variant={"h6"} className={classes.text}>
            ¡Muchas gracias por formar parte de nuestra investigación neurocientífica!. En los próximos días recibirás
            tu perfil cognitivo junto a un informe personalizado con los resultados de las pruebas.
          </Typography>
        </Grid>
      </Container>
    </div>
  );
};

SurveyBackup.propTypes = {};

export default SurveyBackup;
