import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const HomeError = props => {
  return (
      <Grid item component={"section"} xs={12}>
        <Typography color={"textPrimary"} variant={"h1"}>There was an error with your account</Typography>
      </Grid>
  );
};

HomeError.propTypes = {

};

export default HomeError;
