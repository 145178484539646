import * as aTypes from "./types";

export const userComparisonFetchStart = () => ({ type: aTypes.USER_COMPARISON_FETCH_START });
export const userComparisonFetchSuccess = users => ({
  type: aTypes.USER_COMPARISON_FETCH_SUCCESS,
  payload: users
});
export const userComparisonFetchFailure = error => ({
  type: aTypes.USER_COMPARISON_FETCH_SUCCESS,
  payload: error,
  error: true
});
export const userComparisonSelect = name => ({
  type: aTypes.USER_COMPARISON_SELECT,
  payload: name
});

export const userComparisonFormUpdateUserGame = gameId => ({
  type: aTypes.COMPARISON_UPDATE_USER_GAME,
  payload: gameId
});

export const userComparisonFormUpdateUserRegion = region => ({
  type: aTypes.COMPARISON_UPDATE_USER_REGION,
  payload: region
});

export const userComparisonFormUpdateUserNickname = nickname => ({
  type: aTypes.COMPARISON_UPDATE_USER_NICKNAME,
  payload: nickname
});

export const userComparisonFormUpdateComparedGame = gameId => ({
  type: aTypes.COMPARISON_UPDATE_COMPARED_GAME,
  payload: gameId
});

export const userComparisonFormUpdateComparedRegion = region => ({
  type: aTypes.COMPARISON_UPDATE_COMPARED_REGION,
  payload: region
});

export const userComparisonFormUpdateComparedNickname = nickname => ({
  type: aTypes.COMPARISON_UPDATE_COMPARED_NICKNAME,
  payload: nickname
});


