import * as aTypes from "./types";
import { LOGOUT } from "redux/state/auth";
import { combineReducers } from "redux";

const nameInit = "";
const lastnameInit = "";
const mindsetUsernameInit = "";
const emailInit = "";

const name = (state = nameInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_FORM_UPDATE_NAME:
      return action.payload;

    case aTypes.PROFILE_FORM_RESET:
    case LOGOUT:
      return nameInit;

    default:
      return state;
  }
};

const lastname = (state = lastnameInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_FORM_UPDATE_LASTNAME:
      return action.payload;

    case aTypes.PROFILE_FORM_RESET:
    case LOGOUT:
      return lastnameInit;

    default:
      return state;
  }
};

const email = (state = emailInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_FORM_UPDATE_EMAIL:
      return action.payload;

    case aTypes.PROFILE_FORM_RESET:
    case LOGOUT:
      return emailInit;

    default:
      return state;
  }
};

const eProfileUsername = (state = mindsetUsernameInit, action) => {
  switch (action.type) {
    case aTypes.PROFILE_FORM_UPDATE_EPROFILE_USERNAME:
      return action.payload;

    case aTypes.PROFILE_FORM_RESET:
    case LOGOUT:
      return mindsetUsernameInit;

    default:
      return state;
  }
};

export default combineReducers({ name, lastname, email, eProfileUsername });
