import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAllIds, getNotificationsById } from "redux/state/notifications/selectors";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import WhiteMenu from "app/components/menu/WhiteMenu";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import { formatDate, formatSmallDate } from "redux/utils";
import { setNotificationIsRead } from "redux/state/notifications/operations";

const useStyles = makeStyles(theme => ({
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whiteSpace: "normal",
    display: "flex",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, .1)"
    }
  },
  circleContainer: {
    flex: "0 0 30px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center"
  },
  circle: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    padding: theme.spacing(0, 2, 2, 0),
    backgroundColor: theme.palette.secondary.main
  },
  read: {
    backgroundColor: theme.palette.grey["400"]
  },
  messageContainer: {
    display: "flex",
    flex: "1 1 100px",
    maxWidth: "400px",
    flexWrap: "wrap"
  },
  message: {
    fontSize: ".9rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem"
    }
  },
  date: {
    flex: "0 0 70px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    opacity: 0.5,
    fontSize: ".8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".9rem"
    }
  }
}));

const NotificationList = ({ handleNotificationElClose, isNotificationOpen, notificationEl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const notificationsById = useSelector(state => getNotificationsById(state));
  const notificationIds = useSelector(state => getNotificationsAllIds(state));

  const handleItemClick = notificationId => () => {
    dispatch(setNotificationIsRead(notificationId));
  };

  return (
    <WhiteMenu
      onClose={handleNotificationElClose}
      open={isNotificationOpen}
      anchorEl={notificationEl}
      id="notification-menu"
    >
      {notificationIds.map(nId => (
        <MenuItem className={classes.item} onClick={handleItemClick(nId)}>
          <div className={classes.circleContainer}>
            <div className={clsx(classes.circle, { [classes.read]: notificationsById[nId].isRead })} />
          </div>
          <div className={classes.messageContainer}>
            <Typography className={classes.message}>{notificationsById[nId].message}</Typography>
          </div>
          <div className={classes.date}>{formatSmallDate(new Date(notificationsById[nId].created))}</div>
        </MenuItem>
      ))}
    </WhiteMenu>
  );
};

NotificationList.propTypes = {};

export default NotificationList;
