import { createSelector } from "reselect";

const registerForm = state => state.forms.registerForm.form;
const registerFormError = state => state.forms.registerForm.form.error;

/**
 * SELECTORS
 */
// FORM
export const getRegisterLastname = state => registerForm(state).lastname;
export const getRegisterName = state => registerForm(state).name;
export const getRegisterFullName = createSelector(
  [getRegisterName, getRegisterLastname],
  (name, lastname) =>
    `${name.slice(0, 1).toUpperCase() + name.slice(1)} ${lastname.slice(0, 1).toUpperCase() +
      lastname.slice(1)}`
);
export const getRegisterGender = state => registerForm(state).gender;
export const getRegisterEmail = state => registerForm(state).email;
export const getRegisterFormeProfileTag = state => registerForm(state).eProfileTag;
export const getRegisterBirthDay = state => registerForm(state).birthday;
export const getRegisterPassword = state => registerForm(state).password;
export const getRegisterConfirmPassword = state => registerForm(state).repeatPassword;
export const getRegisterSummonerName = state => registerForm(state).summonerName;
export const getRegisterRegion = state => registerForm(state).region;
export const getRegisterProfileImage = state => registerForm(state).profilePicture;
export const getRegisterFormMainGame = state => registerForm(state).mainGame;
export const getRegisterTerms = state => registerForm(state).terms;
export const getRegisterFormReferredId = state => registerForm(state).referredId;

// ERROR
export const getRegisterIsError = state => registerFormError(state).error;
export const getRegisterErrorStatus = state => registerFormError(state).status;
